import {
  faAddressCard,
  faClose,
  faEnvelope,
  faImage,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { getCardBgImg, uploadCardBgImage } from "Api/Card";
import {
  createFolder,
  getAllFolders,
  removeSingleFolder,
  removeSingleLink,
  updateFolder,
} from "Api/Folder";
import FileSaver from "file-saver";
import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import QRCode from "react-qr-code";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { RWebShare } from "react-web-share";
import { parseURL } from "utils/fetchURL";
import { downloadContact } from "Api/Contact";
import { scanCard } from "Api/ScanCards";
import {
  getUser,
  updateLiveStatus,
  updateProfile,
  uploadProfilePicture,
  uploadCardLogo,
  setUserCardShare,
} from "Api/User";
import Breadcrumbs from "Components/Breadcrumbs";
import CardPreview from "Components/Card/CardPreview";
import ProfileInputDiv from "Components/Card/Components/ProfileElement";
import Spinner from "Components/Spinner/Spinner";
import SocialBoxs from "Components/Widget/SocialBox";
import { BASE_FRONTEND_URL } from "Helpers/Env";
import useAuth from "Hooks/useAuth";
import defaultUser from "Images/default_user.png";
import { TabSocialIcons } from "../Contacts/Contacts";

function MyCard() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { updateUser } = useAuth();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [card, setCard] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [goLiveLoading, setGoLiveLoading] = useState(false);
  const [addPhoneLoading, setAddPhoneLoading] = useState(false);
  const [cardScanningLoading, setCardScanningLoading] = useState(false);
  const [isCardBgImg, setIsCardBgImg] = useState(null);
  const [folders, setFolders] = useState([]);
  const [showDeleteSingleLinkModal, setShowDeleteSinglerLinkModal] =
    useState(false);
  const [getDeleteSingleLinkId, setGetDeleteSingleLinkId] = useState(null);
  const [showDeleteSingleFolderModal, setShowDeleteSinglerFolderModal] =
    useState(false);
  const [getDeleteSingleFolderId, setGetDeleteSingleFolderId] = useState(null);
  const [showAddNewFolderModal, setShowAddNewFolderModal] = useState(false);
  const [getEditSingleFolderData, setGetEditSingleFolderData] = useState(null);
  const [showEditFolderModal, setShowEditFolderModal] = useState(false);
  const [showAddMoreLinkModal, setShowAddMoreLinkModal] = useState(false);

  const fetchCardBgImg = useCallback(() => {
    getCardBgImg()
      .then((res) => {
        setIsCardBgImg(res?.data?.image);
      })
      .catch((err) => {
        console.log("card bg image error", err);
      });
  }, []);

  const fetchAllFolders = useCallback(async () => {
    getAllFolders()
      .then((res) => {
        setFolders(res?.data?.link_folders);
      })
      .catch((err) => {
        console.log("Folders error", err);
      });
  }, []);

  const fetchUser = useCallback(() => {
    getUser()
      .then((res) => {
        setUser(res.data.user);
        setLoading(false);
        updateUser(res.data.user);
      })
      .catch((err) => console.error(err));
  }, [updateUser]);

  useEffect(() => {
    if (searchParams.get("cart-active")) {
      setOpen(true);
    }

    if (!user) {
      fetchUser();
      fetchAllFolders();
    }
  }, [fetchUser, user, searchParams, fetchAllFolders]);

  useEffect(() => {
    if (!isCardBgImg) {
      fetchCardBgImg();
    }
  }, [fetchCardBgImg, isCardBgImg]);

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    searchParams.delete("cart-active");
    setSearchParams(searchParams);
    setOpen(false);
  };

  const handleAddPhoneClose = () => {
    setCard(null);
    setAddPhoneLoading(false);
  };

  const handleQRCodeDownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = `QRCode_${user?.username}`;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const updateUserProfile = (data, setSubmitting) => {
    updateProfile(data)
      .then((res) => {
        toast.success(res.data.message);
        setSubmitting(false);
        setUser(res.data.user);
        updateUser(res.data.user);
        // fetchUser();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setSubmitting(false);
      });
  };

  const handleUpdateProfile = (values, { setSubmitting }) => {
    setSubmitting(false);
    let userLinks = values?.user_links.map((link, index) => {
      let urlName = parseURL(link?.url);
      return { name: urlName?.host, url: link.url, masking: link.masking };
    });
    const data = {
      ...values,
      designation: values.user_details.designation,
      // portpolio: values.user_details.portpolio,
      user_details: {
        ...values.user_details,
        website: values.user_details.website,
      },
      bio: values.user_details.bio,
      user_link: userLinks,
      user_phone: [
        {
          phone: values.phone,
          masking: values.phoneDisplayText,
        },
      ],
      user_email: [
        {
          email: values.user_email[0].email,
          masking: values.emailDisplayText,
        },
      ],
      website: values.website,
      schedule: values.schedule,
      company: values.company,
    };

    updateUserProfile(data, setSubmitting);
  };

  const handleProfilePicUpload = (img) => {
    const formData = new FormData();
    formData.append("image", img?.data);
    formData.append("type", 1);
    uploadProfilePicture(formData)
      .then((res) => {
        fetchUser();
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        toast.error(err.response.data.image[0]);
        console.log(err);
      });
  };

  const handleLogoUpload = (img) => {
    const formData = new FormData();
    formData.append("image", img?.data);
    formData.append("type", 2);
    uploadCardLogo(formData)
      .then((res) => {
        fetchUser();
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        toast.error(err.response.data.image[0]);
        console.log(err);
      });
  };

  const handleGoLive = () => {
    if (!user?.subscription_status) {
      toast.warning("You haven't any subscription. Please subscribe first!");
      navigate("/packages");
    } else {
      setGoLiveLoading(true);
      updateLiveStatus(!user.go_live_status ? 1 : 0)
        .then((res) => {
          if (res) {
            fetchUser();
            toast.success("Successfully updated you go live status");
            setGoLiveLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message || "Something went wrong!");
          setGoLiveLoading(false);
        });
    }
  };

  const handleScanCard = (event) => {
    setCardScanningLoading(true);
    const data = new FormData();
    data.append("image", event.target.files[0]);
    scanCard(data)
      .then((res) => {
        navigate(`/contacts/${res.data.contact.id}`, {
          state: { contact: res.data.contact },
        });
        toast.success(res.data.message);
        setCard(res.data.contact);
        setCardScanningLoading(false);
      })
      .catch((err) => {
        setCardScanningLoading(false);
        toast.error(err.response.data.image[0]);
      });
  };

  const handleAddToPhone = () => {
    setAddPhoneLoading(true);
    downloadContact(card?.id)
      .then((res) => {
        setAddPhoneLoading(false);
        const blob = new Blob([res], {
          type: "text/plain;charset=utf-8",
        });
        FileSaver.saveAs(blob, `${card?.name}.vcf`);
      })
      .catch(console.log);
  };

  const breadcrumbItems = [
    { label: "Cards", to: "/my-card/all-card", icon: faImage },
    { label: "My Card", to: "/my-card", icon: faAddressCard, isActive: true },
  ];

  const handleChangeCardBgImage = (data) => {
    const formData = new FormData();
    formData.append("image", data?.data);
    formData.append("type", 1);
    uploadCardBgImage(formData)
      .then((res) => {
        fetchCardBgImg();
        toast.success("Successfully Added Card Background Image");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        toast.error(err.response.data.image[0]);
      });
  };

  const handleRemoveLink = () => {
    removeSingleLink(getDeleteSingleLinkId)
      .then((res) => {
        fetchAllFolders();
        fetchUser();
        toast.success(res?.data?.message);
        setShowDeleteSinglerLinkModal(false);
        setGetDeleteSingleLinkId(null);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleRemoveFolder = () => {
    removeSingleFolder(getDeleteSingleFolderId)
      .then((res) => {
        fetchAllFolders();
        fetchUser();
        toast.success(res?.data?.message);
        setShowDeleteSinglerFolderModal(false);
        setGetDeleteSingleFolderId(null);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleCreateFolder = (data) => {
    createFolder(data)
      .then((res) => {
        toast.success(res?.message);
        setShowAddNewFolderModal(false);
        fetchAllFolders();
        fetchUser();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setShowAddNewFolderModal(false);
      });
  };

  const handleUpdateFolder = (data, id, setSubmitting) => {
    updateFolder(data, id)
      .then((res) => {
        toast.success(res?.message);
        setShowEditFolderModal(false);
        setShowAddMoreLinkModal(false);
        fetchAllFolders();
        fetchUser();
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setShowAddMoreLinkModal(false);
        setShowEditFolderModal(false);
        setSubmitting(false);
      });
  };

  return (
    <div className="relative pb-4 mt-0 mb-6">
      <div className="rounded">
        <Breadcrumbs items={breadcrumbItems} shareButton nav />

        {isLoading ? (
          <div className="display-position-center">
            <Spinner />
          </div>
        ) : (
          <div id="tab-contents">
            <div className="container mx-auto">
              <div id="mycard2" className="relative px-4 py-6">
                <div className="cards-wrapper">
                  <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                    <div className="profile-left-block">
                      <ProfileInputDiv
                        user={user}
                        onSubmit={handleUpdateProfile}
                        handleProfilePicUpload={handleProfilePicUpload}
                        handleLogoUpload={handleLogoUpload}
                        handleChangeCardBgImage={handleChangeCardBgImage}
                        folders={folders}
                        handleRemoveLink={handleRemoveLink}
                        setGetDeleteSingleLinkId={setGetDeleteSingleLinkId}
                        setShowDeleteSinglerLinkModal={
                          setShowDeleteSinglerLinkModal
                        }
                        showDeleteSingleLinkModal={showDeleteSingleLinkModal}
                        setShowDeleteSinglerFolderModal={
                          setShowDeleteSinglerFolderModal
                        }
                        setGetDeleteSingleFolderId={setGetDeleteSingleFolderId}
                        showDeleteSingleFolderModal={
                          showDeleteSingleFolderModal
                        }
                        handleRemoveFolder={handleRemoveFolder}
                        setShowAddNewFolderModal={setShowAddNewFolderModal}
                        showAddNewFolderModal={showAddNewFolderModal}
                        setGetEditSingleFolderData={setGetEditSingleFolderData}
                        showEditFolderModal={showEditFolderModal}
                        setShowEditFolderModal={setShowEditFolderModal}
                        getEditSingleFolderData={getEditSingleFolderData}
                        handleCreateFolder={handleCreateFolder}
                        handleUpdateFolder={handleUpdateFolder}
                        setShowAddMoreLinkModal={setShowAddMoreLinkModal}
                        showAddMoreLinkModal={showAddMoreLinkModal}
                      />

                      <SocialBoxs fetchUser={fetchUser} />
                    </div>

                    <div className="hidden mt-12 rounded-3xl md:block">
                      <div className="mobile-phone">
                        <div className="flex items-center justify-center screen">
                          <CardPreview cardBg={isCardBgImg} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="fixed z-10 flex items-center justify-center space-x-2 bottomNav md:hidden bottom-5 right-4">
                <button
                  onClick={() => navigate("/contacts/new-contact")}
                  className="flex items-center justify-center w-12 h-12 rounded-full share-button md:hidden bg-brand-primary"
                >
                  <i className="p-2 text-2xl text-white fas fa-plus" />
                </button>

                <div className="!border-0">
                  <input
                    hidden
                    type="file"
                    accept="image/*"
                    id="business_card_upload"
                    onClick={(event) => (event.target.value = null)}
                    onChange={(event) => handleScanCard(event)}
                  />
                  <label
                    htmlFor="business_card_upload"
                    className="p-0 m-0 !border-0"
                  >
                    <span className="flex items-center justify-center w-12 h-12 rounded-full share-button md:hidden bg-brand-primary">
                      <i className="p-2 text-2xl text-white fa-solid fa-camera-retro"></i>
                    </span>
                  </label>
                </div>

                <RWebShare
                  data={{
                    url: `${BASE_FRONTEND_URL}${user?.username}`,
                    title: "Qliq",
                  }}
                  onClick={() => {
                    toast.success("Shared successfully!");
                    setUserCardShare(user?.username);
                  }}
                >
                  <button className="flex items-center justify-center w-12 h-12 rounded-full share-button md:hidden bg-brand-primary">
                    <i className="p-2 text-2xl text-white fas fa-share" />
                  </button>
                </RWebShare>

                <label
                  className="flex items-center justify-center w-12 h-12 rounded-full md:hidden bg-brand-primary"
                  htmlFor="shareWithQR"
                >
                  <i className="p-2 text-2xl text-white fas fa-qrcode"></i>
                </label>

                <button
                  type="button"
                  onClick={handleClickOpen}
                  className="flex items-center justify-center w-12 h-12 rounded-full previewModal share-button md:hidden bg-brand-primary"
                >
                  <i className="p-2 text-2xl text-white fas fa-eye"></i>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <input type="checkbox" id="shareWithQR" className="modal-toggle" />
      <label htmlFor="shareWithQR" className="cursor-pointer modal">
        <label className="relative modal-box" htmlFor="">
          <label
            htmlFor="shareWithQR"
            className="absolute cursor-pointer right-5 top-3"
          >
            <FontAwesomeIcon icon={faClose} />
          </label>
          <h2 className="font-semibold tracking-tight text-center">
            Share & QR Code
          </h2>
          <p className="mt-2 text-xs leading-5 text-center text-gray-500">
            Here is your unique card QR code that will direct people to your
            Links when scanned.
          </p>
          <span className="flex justify-center mt-4 text-center">
            <QRCode id="QRCode" value={BASE_FRONTEND_URL + user?.username} />
          </span>

          <div className="text-center">
            <p className="mt-2 overflow-hidden text-dark">@{user?.username}</p>
            <div className="flex justify-center mt-6 space-x-2">
              <CopyToClipboard
                text={BASE_FRONTEND_URL + user?.username}
                onCopy={() => {
                  toast.success("Copied Successfully!");
                  setUserCardShare(user?.username);
                }}
              >
                <button className="px-6 py-1 capitalize bg-transparent border rounded-full shadow-md btn btn-ghost border-brand-primary hover:bg-purple-primary hover:text-white">
                  Copy Link
                </button>
              </CopyToClipboard>

              <button
                onClick={handleQRCodeDownload}
                className="px-6 py-1 capitalize bg-transparent border rounded-full shadow-md btn btn-ghost border-brand-primary hover:bg-purple-primary hover:text-white"
              >
                Download
              </button>
            </div>
            <Button
              disabled={goLiveLoading}
              onClick={handleGoLive}
              className="w-full px-6 py-1 mt-4 text-white capitalize border rounded-full shadow-md bg-brand-primary btn btn-ghost hover:bg-purple-primary hover:text-white"
            >
              {goLiveLoading ? (
                "Loading..."
              ) : (
                <>
                  {user?.go_live_status ? "Go Offline" : "Go Live"}
                  <i
                    className={`fa-solid fa-circle ml-2 ${
                      user?.go_live_status === true
                        ? "text-success"
                        : "text-red-600"
                    }`}
                  />
                </>
              )}
            </Button>
          </div>
        </label>
      </label>

      {user && (
        <Dialog
          open={open}
          className="previewModal"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={handleClose}
        >
          <DialogContent className="p-0 transition-qliq">
            <div className="absolute w-6 h-6 top-5 right-5 closeBtn-with-bg">
              <button type="button" className="w-6 h-6" onClick={handleClose}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="h-full">
              <CardPreview
                user={user}
                cardBg={isCardBgImg}
                className="!h-full"
              />
            </div>
          </DialogContent>
        </Dialog>
      )}

      {cardScanningLoading && (
        <Dialog open={cardScanningLoading}>
          <DialogContent className="p-0 transition-qliq">
            <div className="flex items-center p-4 mx-auto text-center">
              <Spinner />
              <p className="ml-1">Scanning, Please wait</p>
            </div>
          </DialogContent>
        </Dialog>
      )}

      {card && (
        <Dialog fullWidth open={Boolean(card)} onClose={handleAddPhoneClose}>
          <DialogContent className="p-0 transition-qliq">
            <div className="absolute w-6 h-6 top-2 right-2 closeBtn-with-bg">
              <button
                type="button"
                className="w-6 h-6"
                onClick={handleAddPhoneClose}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="px-2 py-6">
              <div className="flex flex-col items-center justify-center">
                <img
                  className="object-cover w-24 h-24 p-1 rounded-full bg-brand-primary"
                  src={
                    card?.file_manager ? card?.file_manager?.url : defaultUser
                  }
                  alt="Qliq"
                />
                <h3 className="mt-4 text-xl font-normal text-center">
                  {card?.name}
                </h3>
                <h3 className="text-sm text-center text-gray-500">
                  {card?.phone}
                </h3>
              </div>

              <div className="flex items-center justify-center my-3 space-x-2 popups-icon-header-top">
                {TabSocialIcons?.map((item, index) => (
                  <div className="px-2 py-1">
                    <img
                      className="w-8 h-8"
                      src={item.photo}
                      alt=""
                      onClick={() => {
                        if (item?.name === "Mail")
                          window.location = `mailto:${card ? card?.email : ""}`;
                        else if (item?.name === "Phone")
                          window.location = `tel:${card ? card?.phone : ""}`;
                        else if (item?.name === "Edit")
                          navigate("/contacts/edit", {
                            state: { contact: card && card },
                          });
                        else navigate(item?.link);
                      }}
                    />
                  </div>
                ))}
              </div>

              <div className="mt-3">
                <Table
                  size="small"
                  aria-label="custom table"
                  className="personal-detailsTable"
                >
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className="mr-1 text-brand-primary"
                        />
                        Email
                      </TableCell>
                      <TableCell align="left">{card?.email}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        <i className="mr-1 fa-solid fa-phone"></i> Phone
                      </TableCell>
                      <TableCell align="left">{card?.phone}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>

              <div className="mt-5 text-center">
                <button
                  disabled={addPhoneLoading}
                  onClick={handleAddToPhone}
                  className="btn btn-ghost btn-sm md:btn-md bg-brand-primary hover:bg-purple-primary text-white rounded-full !px-10"
                >
                  <FontAwesomeIcon className="mr-2" icon={faPhone} />
                  Add to Phone
                </button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}

      {/*{card && (*/}
      {/*  <ContactPreview*/}
      {/*    onClose={handleAddPhoneClose}*/}
      {/*    isOpen={Boolean(card)}*/}
      {/*    contact={card}*/}
      {/*    setContact={setCard}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
}

export default MyCard;
