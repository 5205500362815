import axios from "axios";
import { getUser } from "../Api/User";
import { BASE_API_URL } from "../Helpers/Env";
import { isValidToken, setSession } from "../Helpers/AuthUtils";
import { createContext, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { getSocialLoginType } from "Helpers/Utils";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SOCIAL_SIGN_IN = "SOCIAL_SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const UPDATE_USER = "UPDATE_USER";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

export const AuthReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SOCIAL_SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(AuthReducer, initialState);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await getUser();
          const { user } = response.data;
          setLoading(false);

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        setLoading(false);
      }
    };

    initialize();
  }, []);

  const signIn = async (email, password) => {
    const response = await axios.post(BASE_API_URL + "login", {
      email,
      password,
    });
    const { token, user } = response.data;

    setSession(token);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  const socialSignIn = async (id, via) => {
    // console.log("Check via upper");

    try {
      if (via === "facebook_uid") {
        const response = await axios.post(BASE_API_URL + "login-social", {
          facebook_uid: id,
        });
        const { token, user } = response.data;

        setSession(token);
        dispatch({
          type: SOCIAL_SIGN_IN,
          payload: {
            user,
          },
        });
      } else {
        // console.log("check via in else");
        const response = await axios.post(BASE_API_URL + "login-social", {
          google_uid: id,
        });
        const { token, user } = response.data;

        setSession(token);
        dispatch({
          type: SOCIAL_SIGN_IN,
          payload: {
            user,
          },
        });
      }
    } catch (error) {
      // toast.error(error.message);
      console.log(error.message);
    }
  };

  const signOut = async () => {
    const response = await axios.delete(BASE_API_URL + "logout");
    if (response.data) {
      setSession(null);
      dispatch({ type: SIGN_OUT });
    }
  };

  const signUp = async (data) => {
    const response = await axios.post(BASE_API_URL + "register", data);
    const { token, user } = response.data;
    setSession(token);
    navigate(user?.subscription_status ? "/dashboard" : "/packages");
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const resetPassword = (email) => console.log(email);

  const updateUser = (user) => {
    dispatch({
      type: UPDATE_USER,
      payload: {
        user,
      },
    });
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img
          alt="Qliq"
          width={50}
          height={50}
          src="/assets/images/logos/logo.svg"
        />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        socialSignIn,
        signOut,
        signUp,
        resetPassword,
        updateUser,
        isSubscribed: state?.isAuthenticated
          ? state.user?.subscription_status
          : false,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
