import { Outlet } from "react-router-dom";
// import StyleAuth from '../Pages/Auth/auth.css';

const bgImages = {
  imgOne: require("../Images/bg/auth-bg.jpeg"),
};

function AuthLayout() {
  return (
    <div>
      <section className="flex flex-col md:flex-row  min=h-screen items-center">
        <div className="hidden w-full h-screen bg-indigo-600 left-col-block sm:block md:w-1/2 xl:w-2/3">
          <img
            src={bgImages.imgOne}
            alt=""
            className="object-cover w-full h-full"
          />
        </div>

        <div className="flex items-center justify-center w-full px-6 py-8 bg-white right-col-block md:max-w-md lg:max-w-full md:mx-0 md:w-1/2 xl:w-1/3 lg:px-16 xl:px-12">
          <Outlet />
        </div>
      </section>
    </div>
  );
}

export default AuthLayout;
