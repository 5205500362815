// import CardFour from "Components/Card/CardFour";
// import CardOne from "Components/Card/CardOne";
// import CardThree from "Components/Card/CardThree";
// import CardTwo from "Components/Card/CardTwo";
// import CardFive from "Components/Card/CardFive";
// import CardSix from "Components/Card/CardSix";
// import CardNew1 from "Components/Card/CardNew1Test";

// import CardNew1 from "Components/Card/CardNew1";
// import CardNew2 from "Components/Card/CardNew2";
// import CardNew3 from "Components/Card/CardNew3";
// import CardNew4 from "Components/Card/CardNew4";
// import CardNew5 from "Components/Card/CardNew5";
// import CardNew6 from "Components/Card/CardNew6";
import CardNew7 from "Components/Card/CardNew7";

export const cardsList = {
  // v1
  // 1: { name: "cardOne", card: (props) => <CardOne {...props} /> },
  // 2: { name: "cardTwo", card: (props) => <CardTwo {...props} /> },
  // 3: { name: "cardThree", card: (props) => <CardThree {...props} /> },
  // 4: { name: "cardFour", card: (props) => <CardFour {...props} /> },
  // 5: { name: "cardFive", card: (props) => <CardFive {...props} /> },
  // 6: { name: "cardSix", card: (props) => <CardSix {...props} /> },

  // v2
  // 7: { name: "CardNew1", card: (props) => <CardNew1 {...props} /> },
  // 2: { name: "CardNew2", card: (props) => <CardNew2 {...props} /> },
  // 3: { name: "CardNew3", card: (props) => <CardNew3 {...props} /> },
  // 4: { name: "CardNew4", card: (props) => <CardNew4 {...props} /> },
  // 5: { name: "CardNew5", card: (props) => <CardNew5 {...props} /> },
  // 6: { name: "CardNew6", card: (props) => <CardNew6 {...props} /> },

  // v3
  1: { name: "CardNew7", card: (props) => <CardNew7 {...props} /> },
};
