import axios from "axios";
import { BASE_API_URL } from "../Helpers/Env";

export const getScanCards = () => axios.get(BASE_API_URL + "scan-cards");

export const scanCard = (data) => axios.post(BASE_API_URL + "scan-card", data);

export const deleteScanCard = (id) =>
  axios.delete(BASE_API_URL + "scan-cards/" + id);

export const updateScanCard = async (id, data) => {
  try {
    let res = await axios.post(`${BASE_API_URL}contacts/${id}`, data, {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
