import { Dialog, DialogContent } from "@mui/material";
import CropBox from "Components/CropBox";
import { Formik } from "formik";
import { useRef, useState } from "react";
import defaultUser from "../../Images/default_user.png";

const defaultValues = {
  first_name: "",
  last_name: "",
  username: "",
  phone: "",
  image: null,
  email: "",
};

function General({ user, handleUpdateUser, setProfileImage }) {
  const [image, setImage] = useState({ preview: "", data: "" });
  const [imgUploadModalOpen, setImgUploadModalOpen] = useState(false);

  const uploadRef = useRef(null);

  if (user) {
    user = {
      ...user,
      phone: user?.user_phone[0]?.phone,
    };
  }

  const handleChooseImage = (event) => {
    const image = {
      preview: URL.createObjectURL(event.target.files[0]),
      data: event.target.files[0],
    };
    setImage(image);
    setImgUploadModalOpen(true);
  };

  const handleCloseCropModal = () => {
    uploadRef.current.value = null;
    setImage(null);
    setImgUploadModalOpen(false);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-1">
      <div className="bg-white rounded-xl my-4">
        <h2 className="text-xl  text-gray-800">General Setting</h2>
        <p className="text-gray-500 mb-6 mt-2 text-xs">
          Choose what to display on your public profile:
        </p>
        <Formik
          initialValues={user ?? defaultValues}
          onSubmit={handleUpdateUser}
          className="max-w-100"
        >
          {({
            handleSubmit,
            handleChange,
            values,
            setFieldValue,
            isSubmitting,
            // errors,
          }) => (
            <form className="max-w-100" onSubmit={handleSubmit}>
              <div className="flex items-center space-x-2 mb-6">
                <input
                  hidden
                  id="image"
                  type="file"
                  name="image"
                  accept="image/*"
                  ref={uploadRef}
                  onChange={(e) => {
                    handleChooseImage(e);
                    setFieldValue("image", e.target.files[0]);
                  }}
                />
                {image?.preview ? (
                  <img
                    className="w-16 h-16 rounded-full object-cover"
                    src={image.preview}
                    id="image"
                    alt="user"
                  />
                ) : user?.profile ? (
                  <img
                    alt={user?.username}
                    src={user?.profile?.url}
                    className="w-16 h-16 rounded-full object-cover"
                  />
                ) : (
                  <img
                    className="w-16 h-16 rounded-full object-cover"
                    src={defaultUser}
                    id="image"
                    alt="cover"
                  />
                )}

                <label
                  for="image"
                  className="btn-sm btn btn-ghost bg-brand-primary border-gray-200 text-white capitalize rounded-lg"
                >
                  Change Avatar
                </label>
              </div>

              <div className="flex space-x-2">
                <div className="mb-6 w-full">
                  <label
                    htmlFor="first_name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Jhon"
                  />
                  {/* {errors.first_name && <div>{errors.first_name}</div>} */}
                </div>
                <div className="mb-6 w-full">
                  <label
                    htmlFor="last_name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Doe"
                  />
                  {/* {errors.last_name && <div>{errors.last_name}</div>} */}
                </div>
              </div>
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Your Email
                </label>
                <input
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="qliq@example.com"
                />
                {/* {errors.email && <div>{errors.email}</div>} */}
              </div>
              <div className="mb-6">
                <label
                  htmlFor="phone"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Phone
                </label>
                <input
                  type="text"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="1254785488"
                />
                {/* {errors.phone && <div>{errors.phone}</div>} */}
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                className="text-white bg-brand-primary hover:bg-purple-primary focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Update Profile
              </button>
            </form>
          )}
        </Formik>
      </div>
      <Dialog
        open={imgUploadModalOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="p-0 transition-qliq">
          <div className="absolute top-5 right-5 h-6 w-6 closeBtn-with-bg">
            <button
              type="button"
              className="h-6 w-6"
              onClick={handleCloseCropModal}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div>
            <CropBox
              uploadedImg={image?.preview}
              onImageCrop={(img) => {
                setImgUploadModalOpen(false);
                setImage(img);
                setProfileImage(img);
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default General;
