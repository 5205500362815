import ContactPage from "./Contacts";
import Breadcrumbs from "../../Components/Breadcrumbs";
import {
  faBuilding,
  faAddressBook,
  faAddressCard,
} from "@fortawesome/free-solid-svg-icons";

const breadcrumbItems = [
  { label: "Contacts", to: "/contacts", icon: faAddressBook, isActive: true },
  { label: "Organizations", to: "/contacts/companys", icon: faBuilding },
  {
    label: "Business Cards",
    to: "/contacts/business-cards",
    icon: faAddressCard,
  },
];

function Contacts() {
  return (
    <div className=" pb-4 mb-6 relative mt-0">
      <div className="rounded">
        <Breadcrumbs items={breadcrumbItems} nav />
        <div id="tab-contents">
          <div className="container mx-auto">
            <div className="px-4 py-6">
              <ContactPage />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
