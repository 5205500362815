import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { firebaseConfig } from "Helpers/Env";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();

export const signInWithGoogle = () =>
  signInWithPopup(auth, googleAuthProvider)
    .then((result) => {
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;
      const user = result.user;
      // console.log("google login success", {
      //   token,
      //   user,
      // });
      return user;
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);

      console.log("google login error", {
        errorCode,
        errorMessage,
        email,
        credential,
      });
    });

export const signInWithFacebook = () =>
  signInWithPopup(auth, facebookAuthProvider)
    .then((result) => {
      // const credential = FacebookAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;
      const user = result.user;
      // console.log("facebook login success", {
      //   token,
      //   user,
      // });
      return user;
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = FacebookAuthProvider.credentialFromError(error);

      console.log("google login error", {
        errorCode,
        errorMessage,
        email,
        credential,
      });
    });
