import axios from "axios";
import { BASE_API_URL } from "../Helpers/Env";

export const getCards = (config) => axios.get(`${BASE_API_URL}/cards`, config);

export const getCard = (id) => axios.get(`${BASE_API_URL}/cards/${id}`);

export const activateCard = (id) =>
  axios.get(BASE_API_URL + `active-card/${id}`);

export const getActiveCard = () => axios.get(BASE_API_URL + `active-card`);

export const activeCardImagePositionUpdate = (data, id) => axios.post(BASE_API_URL + `active-card/position/${id}`, data);

export const getCardAnalytics = (config) =>
  axios.get(BASE_API_URL + `analytics`, config);

export const getCardBgImg = () => axios.get(`${BASE_API_URL}user-bg/get`);

export const uploadCardBgImage = (data) =>
  axios.post(BASE_API_URL + "user-bg/set", data, {
    "Content-Type": "multipart/form-data",
  });
