import { Field, FieldArray, Formik } from "formik";
import Spinner from "Components/Spinner/Spinner";
import { Button, FormControl, FormText } from "react-bootstrap";
import * as yup from "yup";

const defaultValue = {
  name: "",
  masking: "Default",
  user_links: [
    {
      url: "",
      masking: "Default",
    },
  ],
};

const EditFolder = ({
  setShowEditFolderModal,
  getEditSingleFolderData,
  handleUpdateFolder,
}) => {
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    let formData = new FormData();
    formData.append("_method", "put");
    formData.append("name", values?.name);
    formData.append("masking", values?.masking);

    values?.user_links?.forEach((link, index) => {
      formData.append(`links[${index}][id]`, link?.id);
      formData.append(`links[${index}][masking]`, link?.masking);
      formData.append(`links[${index}][url]`, link?.url);
    });

    handleUpdateFolder(formData, getEditSingleFolderData?.id);
  };
  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-6">
        <div className="text-[#1F2937] text-xl font-semibold">Edit Folder</div>
        <div
          className="font-semibold text-xl text-[#1F2937] w-4 h-4 cursor-pointer"
          onClick={() => setShowEditFolderModal(false)}
        >
          X
        </div>
      </div>
      <div>
        <Formik
          initialValues={getEditSingleFolderData ?? defaultValue}
          onSubmit={onSubmit}
          validationSchema={yup.object().shape({
            name: yup.string().required("Folder Name is required!"),
          })}
        >
          {({
            handleSubmit,
            handleChange,
            errors,
            touched,
            isSubmitting,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="folderName"
                  className="text-[#4F4F4F] mb-2 font-medium  text-base"
                >
                  Folder Name
                </label>
                <FormControl
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  placeholder="Folder Name"
                  isInvalid={Boolean(errors.name && touched.name)}
                  className="w-full py-2 bg-[#FAFAFB] border border-[#E3E3E3] rounded-lg input focus:outline-0 text-black text-base"
                />
                {Boolean(errors.title) && (
                  <FormText className="mt-1 text-red-600">
                    {errors.name}
                  </FormText>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="folderDisplayText"
                  className="text-[#4F4F4F] mb-2 font-medium  text-base"
                >
                  Folder Display Text
                </label>
                <FormControl
                  name="masking"
                  value={values.masking === null ? "Default" : values.masking}
                  onChange={handleChange}
                  placeholder="Folder Display Text"
                  className="w-full py-2 bg-[#FAFAFB] border border-[#E3E3E3] rounded-lg input focus:outline-0 text-black text-base"
                />
              </div>
              <FieldArray
                name="user_links"
                render={(arrayHelpers) => (
                  <div>
                    {values.user_links && values.user_links.length > 0 ? (
                      values?.user_links.map((link, index) => (
                        <div key={index}>
                          <div className="w-full mb-4">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              Link Display Text
                            </label>
                            <Field
                              name={`user_links.${index}.masking`}
                              type="text"
                              placeholder="Link Display Text"
                              value={
                                link?.masking === null
                                  ? "Default"
                                  : link?.masking
                              }
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            />
                          </div>
                          <div className="w-full mb-4">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              Add Link
                            </label>
                            <section className="">
                              <div className="relative singleWrap !w-full">
                                <div className="mb-4">
                                  <Field
                                    name={`user_links.${index}.url`}
                                    type="text"
                                    placeholder="Add Link"
                                    value={link?.url}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  />
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>No Link. Please Create</div>
                    )}
                    <div className="flex justify-end mt-0">
                      <button
                        className="text-brand-primary py-2 lg:py-3 border-brand-primary cursor-pointer text-[15px] font-[500] flex items-center justify-center"
                        type="button"
                        onClick={() => arrayHelpers.push("")}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.41406 2.96875H10.5859C10.6901 2.96875 10.7422 3.02083 10.7422 3.125V16.875C10.7422 16.9792 10.6901 17.0312 10.5859 17.0312H9.41406C9.3099 17.0312 9.25781 16.9792 9.25781 16.875V3.125C9.25781 3.02083 9.3099 2.96875 9.41406 2.96875Z"
                            fill="#E45C8C"
                          />
                          <path
                            d="M3.4375 9.25781H16.5625C16.6667 9.25781 16.7188 9.3099 16.7188 9.41406V10.5859C16.7188 10.6901 16.6667 10.7422 16.5625 10.7422H3.4375C3.33333 10.7422 3.28125 10.6901 3.28125 10.5859V9.41406C3.28125 9.3099 3.33333 9.25781 3.4375 9.25781Z"
                            fill="#E45C8C"
                          />
                        </svg>
                        Add More Links
                      </button>
                    </div>
                  </div>
                )}
              />

              <Button
                type="submit"
                disabled={isSubmitting}
                className="w-full lg:mt-0 border-0 mt-2 lg:ml-2 text-white capitalize rounded-full py-2 lg:py-3 bg-brand-primary hover:bg-purple-primary text-[15px] font-[500]"
              >
                {isSubmitting ? <Spinner /> : <div>Update Folder</div>}
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditFolder;
