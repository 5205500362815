import { updatePassword } from "Api/User";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const defaultValues = {
  current_password: "",
  password: "",
  confirm_password: "",
};

const updatePasswordSchema = Yup.object().shape({
  current_password: Yup.string()
    .required("Please enter your current password.")
    .min(6, "Please enter minimum 6 length password."),
  password: Yup.string()
    .required("Please type the new password.")
    .min(6, "The password should be minimum 6 charecter long.")
    .matches(
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
      "Write a password which has at least a symbol, upper and lower case letters and a number"
    ),
  confirm_password: Yup.string()
    .required("Please type the new password again.")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

function Password() {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewConfirmPassword, setShowNewConfirmPassword] = useState(false);

  const handleUpdatePassword = (values, { setSubmitting, dirty }) => {
    updatePassword(values, setSubmitting)
      .then((res) => {
        toast.success(res.message);
        setSubmitting(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setSubmitting(false);
      });
  };

  return (
    <div className="grid my-4">
      <Formik
        validationSchema={updatePasswordSchema}
        initialValues={defaultValues}
        onSubmit={handleUpdatePassword}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form action="#" method="post" className="max-w-100">
            <div className="mb-6">
              <label
                for="currentPassword"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Current Password
              </label>
              <div className="relative">
                <Field
                  type={showCurrentPassword ? "text" : "password"}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  id="currentPassword"
                  placeholder="Current password"
                  name="current_password"
                />
                <div
                  className="absolute top-[10px] right-3 cursor-pointer"
                  onClick={() => setShowCurrentPassword((state) => !state)}
                >
                  {showCurrentPassword ? (
                    <AiFillEyeInvisible size={25} color="#939292" />
                  ) : (
                    <AiFillEye size={25} color="#939292" />
                  )}{" "}
                </div>
              </div>

              <ErrorMessage
                name="current_password"
                className="text-xs text-red-500"
                component="div"
              />
            </div>
            <div className="mb-6">
              <label
                for="newPassword"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                New Password
              </label>
              <div className="relative">
                <Field
                  type={showNewPassword ? "text" : "password"}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  id="newPassword"
                  placeholder="New password"
                  name="password"
                />
                <div
                  className="absolute top-[10px] right-3 cursor-pointer"
                  onClick={() => setShowNewPassword((state) => !state)}
                >
                  {showNewPassword ? (
                    <AiFillEyeInvisible size={25} color="#939292" />
                  ) : (
                    <AiFillEye size={25} color="#939292" />
                  )}{" "}
                </div>
              </div>

              <ErrorMessage
                name="password"
                className="text-xs text-red-500"
                component="div"
              />
            </div>
            <div className="mb-6">
              <label
                for="confirmNewPassword"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                New Confirm Password
              </label>
              <div className="relative">
                <Field
                  type={showNewConfirmPassword ? "text" : "password"}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  id="confirmNewPassword"
                  placeholder="Confirm new password"
                  name="confirm_password"
                />
                <div
                  className="absolute top-[10px] right-3 cursor-pointer"
                  onClick={() => setShowNewConfirmPassword((state) => !state)}
                >
                  {showNewConfirmPassword ? (
                    <AiFillEyeInvisible size={25} color="#939292" />
                  ) : (
                    <AiFillEye size={25} color="#939292" />
                  )}{" "}
                </div>
              </div>

              <ErrorMessage
                name="confirm_password"
                className="text-xs text-red-500"
                component="div"
              />
            </div>
            <div className="m-0">
              <button
                type="submit"
                disabled={isSubmitting}
                className="text-white bg-brand-primary hover:bg-purple-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 "
              >
                Change Password
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Password;
