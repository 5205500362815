import { faCubes } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../Components/Spinner/Spinner";
import useAuth from "../../Hooks/useAuth";
import { submitFeedback } from "Api/Feedback";

// and design
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const Packages = () => {
  const { user, updateUser, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append("images[]", file.originFileObj);
      });
      formData.append("description", e.target[0].value);

      const res = await submitFeedback(formData);

      if (res?.data?.status === "success") {
        toast.success(res?.data?.message || "Feedback submitted successfully");
      } else {
        toast.error(res?.data?.message || "Feedback submission failed");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="relative pb-4 mb-6">
        <div className="rounded">
          <div className="flex items-center justify-center px-4 py-4 sm:py-6 h-[calc(100vh-67px-16px)]">
            <form
              onSubmit={handleSubmit}
              className="relative w-full max-w-[710px] px-4 sm:px-[45px] py-4 sm:py-[35px] bg-white rounded-[20px]"
            >
              <h3 className="text-neutral-900 text-[24px] sm:text-[32px] font-bold font-['Inter'] capitalize leading-[38.40px] mb-2">
                Feedback
              </h3>
              {/* line */}
              <div className="w-full h-[0px] border border-black border-opacity-10" />
              <p className="text-neutral-900 text-base font-normal font-['Inter'] mt-[26px] mb-[11px]">
                Do you have any thoughts you’d like to share?
              </p>
              <textarea
                placeholder="Write here"
                className="w-full h-[177px] bg-gray-100 rounded-lg border border-[#0000001A] text-base font-normal font-['Inter'] p-[15px] resize-none"
              />
              <p className="text-neutral-900 text-base font-normal font-['Inter'] mt-6 mb-2">
                Attach Image
              </p>
              <Upload
                action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length < 8 && (
                  <button
                    style={{ border: 0, background: "none" }}
                    type="button"
                  >
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </button>
                )}
              </Upload>

              <div className="flex justify-center w-full">
                <button className="w-full max-w-[331px] px-16 py-4 mt-10 bg-[#665C82] rounded-3xl justify-center items-center flex text-white text-[13px] font-semibold font-['Inter']">
                  {isLoading ? <Spinner /> : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};

export default Packages;
