const SocialMedia = ({handleTrial}) => {
    return (
        <section className="hero bg-white py-8">
            <div className="container px-4 sm:px-8 lg:px-16 xl:px-20 mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-12 gap-8 items-center">
                    <div className="hero-image col-span-6 md:hidden flex items-center justify-center">
                        <img className="w-80 h-80" src={require('../assets/images/social2.gif')} alt="Qliq"/>
                    </div>


                    <div className="col-span-6">
                        <h1 className="font-bold text-3xl md:text-5xl max-w-xl text-gray-800 leading-tight tracking-tight">
                            Harness the power of social media
                        </h1>
                        <hr className=" w-12 h-1 bg-orange-500 rounded-full mt-8"/>
                        <p className="text-gray-600 text-md leading-7 mt-6 tracking-tight">
                            Display your online presence from platforms like
                            LinkedIn, Twitter, Facebook, Instagram, TikTok and many more!
                        </p>
                        <div className="flex space-x-2 mt-10 justify-center md:justify-start">
                            <button onClick={handleTrial} className="btn btn-ghost capitalize border border-gray-200 text-gray-800 px-6">
                                Start your 30 day free trial
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-1 text-[#5d5478]"
                                     fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="hero-image col-span-6 hidden md:block">
                        <img src={require('../assets/images/social2.gif')} alt="Qliq"/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SocialMedia;
