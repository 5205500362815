import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faAddressCard,
  faBuilding,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  deleteScanCard,
  getScanCards,
  scanCard,
  updateScanCard,
} from "../../Api/ScanCards";
import Spinner from "../../Components/Spinner/Spinner";
import useAuth from "../../Hooks/useAuth";
import ContactPreview from "../Contacts/ContactPreview";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import EditBusinessCard from "./EditBusinessCardModal";
import Dropdown from "Components/Dropdown/Dropdown";
import { addTagToContact, getTags, tagCreate } from "Api/Contact";
import Tag from "Components/Tags/Tag";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, FormControl, FormText } from "react-bootstrap";
import { CHARACTERS_ALLOWED } from "Helpers/Env";

const defaultImage = { scanCard: "", data: "" };

const breadcrumbItems = [
  { label: "Contacts", to: "/contacts", icon: faAddressBook },
  { label: "Organizations", to: "/contacts/companys", icon: faBuilding },
  {
    label: "Business Cards",
    to: "/contacts/business-cards",
    icon: faAddressCard,
    isActive: true,
  },
];

function BusinessCards() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [image, setImage] = useState(defaultImage);
  const [isLoading, setLoading] = useState(true);
  const [scanCards, setScanCards] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [cardData, setCardData] = useState(null);
  const [cardPreviewPopup, setCardPreviewPopup] = useState(false);
  const [selectedAddTagContact, setSelectedAddTagContact] = useState(null);
  const [openAddTagsModal, setOpenAddTagsModal] = useState(false);
  const [selectedDeleteCard, setSelectedDeleteCard] = useState(null);
  const [showDeleteCardModal, setShowDeleteCardModal] = useState(false);
  const [tagArr, setTagArr] = useState([]);
  const [isShowMore, setIsShowMore] = useState(false);

  const fetchTags = useCallback(() => {
    getTags()
      .then((tags) => {
        setTagArr(tags.filter((tag) => tag?.user_id === user?.id));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user?.id]);

  const handleAssignTagToContact = (tag) => {
    addTagToContact({ contact_id: selectedAddTagContact.id, tag_id: tag.id })
      .then((response) => {
        setSelectedAddTagContact(response?.data?.contact);
        fetchTags();
        fetchScanCards();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    if (tagArr.length === 0) {
      fetchTags();
    }
  }, [tagArr, fetchTags]);

  const handleCloseCardPreview = () => {
    setCardPreviewPopup(false);
    setCardData(null);
    fetchScanCards();
  };

  const handleOpenCardPreview = (card) => {
    setCardData(card);
    //setCardPreviewPopup(true);
    navigate(`/contacts/${card.id}`, { state: { contact: card } });
  };

  const handleChooseImage = (event) => {
    const image = {
      scanCard: URL.createObjectURL(event.target.files[0]),
      data: event.target.files[0],
    };
    setImage(image);
  };

  const handleClose = () => {
    setOpen(false);
    setImage(defaultImage);
  };

  const handleScanCard = () => {
    setSubmitting(true);
    const data = new FormData();
    data.append("image", image.data);

    try {
      scanCard(data)
        .then((res) => {
          handleClose();
          setSubmitting(false);
          toast.success("Card scanned successfully");
          navigate(`/contacts/${res?.data?.contact?.id}?openTag=true`, {
            state: { contact: res?.data?.contact },
          });
        })
        .catch((err) => {
          setSubmitting(false);
          console.log(err);
          toast.error(err.response.data.message);
        });
    } catch (error) {
      console.log("handleScanCard error", error);
      toast.error(error.response.data.message);
    }
  };

  const fetchScanCards = () => {
    getScanCards()
      .then((res) => {
        setScanCards(res.data.contacts.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleUpdateCard = (values, { setSubmitting }) => {
    setSubmitting(true);
    let formData = new FormData();
    formData.append("_method", "put");
    formData.append("name", values?.name);
    formData.append("designation", values?.designation);
    formData.append("country", values?.country);
    formData.append("state", values?.state);
    formData.append("street", values?.street);
    formData.append("phone", values?.phone);
    formData.append("email", values?.email);
    // formData.append("company_id", data?.company);
    formData.append("website", values?.website);

    updateScanCard(values?.id, formData)
      .then((res) => {
        if (res) {
          setSubmitting(false);
          toast.success("Card updated successfully");
          fetchScanCards();
          setOpenEditModal(false);
        } else {
          setSubmitting(false);
          toast.error("Card cannot be updated");
        }
      })
      .catch((err) => {
        setSubmitting(false);
        toast.error("Card cannot be updated");
      });
  };

  const handleDeleteContactTag = (tagId) => {
    let formData = new FormData();
    formData.append("_method", "put");
    formData.append("name", selectedAddTagContact?.name);
    formData.append("designation", selectedAddTagContact?.designation);
    formData.append("country", selectedAddTagContact?.country);
    formData.append("state", selectedAddTagContact?.state);
    formData.append("street", selectedAddTagContact?.street);
    formData.append("phone", selectedAddTagContact?.phone);
    formData.append("email", selectedAddTagContact?.email);
    // formData.append("company_id", data?.company);
    formData.append("website", selectedAddTagContact?.website);

    selectedAddTagContact?.tag?.forEach((tag, index) => {
      if (tag?.id !== tagId) formData.append(`tags[${index}][id]`, tag?.id);
    });

    updateScanCard(selectedAddTagContact?.id, formData)
      .then((res) => {
        if (res) {
          toast.success("Tag Delete successfully");
          fetchScanCards();
          setSelectedAddTagContact(res?.contact);
          fetchTags();
          fetchScanCards();
        } else {
          toast.error("Tag cannot be Deleted");
        }
      })
      .catch((err) => {
        toast.error("Tag cannot be Deleted");
      });
  };

  useEffect(() => {
    fetchScanCards();
  }, []);

  const handleDelete = () => {
    deleteScanCard(selectedDeleteCard?.id)
      .then((res) => {
        console.log("Res", res);
        if (res) {
          toast.success("Contact is deleted successfully");
          fetchScanCards();
          setShowDeleteCardModal(false);
          setSelectedDeleteCard(null);
        } else {
          toast.error("Contact is not deleted successfully");
          // setDeleteCardLoading(false);
          setShowDeleteCardModal(false);
          setSelectedDeleteCard(null);
        }
      })
      .catch((err) => {
        toast.error("Contact is not deleted successfully");
        // setDeleteCardLoading(false);
        setShowDeleteCardModal(false);
        setSelectedDeleteCard(null);
      });
  };

  const makeLink = (url) => {
    let link = url.split(".");
    if (!link.includes("http")) {
      return `http://${url}`;
    }
    return url;
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    if (values.title.length > CHARACTERS_ALLOWED) {
      toast.error(
        `Tag Name cannot be greater than ${CHARACTERS_ALLOWED} characters`,
        { toastId: 1 }
      );
      setSubmitting(false);
    } else {
      const tagData = {
        name: values.title,
        color_id: 1,
        description: "description",
      };
      tagCreate(tagData)
        .then((res) => {
          if (res?.data?.message.includes("Tag Already Exists!")) {
            toast.error(res?.data?.message);
            setSubmitting(false);
          } else {
            addTagToContact({
              contact_id: selectedAddTagContact?.id,
              tag_id: res.data.tag.id,
            })
              .then((response) => {
                setSelectedAddTagContact(response.data.contact);
                setSubmitting(false);
                fetchScanCards();
                resetForm();
              })
              .catch((err) => {
                setSubmitting(false);
                console.log(err);
                toast.error(err.response.data.message);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const showTag = (cards) => {
    if (cards.length > 5) return true;
  };

  return (
    <>
      <div className="relative pb-4 mt-0 mb-6">
        <div className="rounded">
          <Breadcrumbs items={breadcrumbItems} nav />

          {Boolean(user?.subscription_status) === false ? (
            <div className="mt-5">
              <Alert severity="warning">
                Your haven't subscription. Please subscribe first.
              </Alert>
            </div>
          ) : (
            <div id="tab-contents">
              <div className="container mx-auto">
                <div className="p-4 mt-5 bg-white rounded">
                  <div className="flex flex-wrap items-center gap-2">
                    <div
                      className="flex items-center cursor-pointer bg-red-300 py-[4px] px-[14px] rounded tracking-tight"
                      onClick={() => navigate(-1)}
                    >
                      <BsArrowLeftCircleFill color="white" />
                      <span className="pl-1 text-white">Back</span>
                    </div>
                    <button
                      onClick={() => setOpen(true)}
                      className="tracking-tight text-white capitalize rounded btn btn-ghost btn-sm qliq-active-btn"
                    >
                      Add Business Card
                      <i className="ml-1 fa-solid fa-circle-plus"></i>
                    </button>
                  </div>
                </div>

                {isLoading ? (
                  <div className="flex justify-center mt-10">
                    <Spinner />
                  </div>
                ) : (
                  <div className="mt-5">
                    <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 2xl:grid-cols-3">
                      {scanCards
                        ?.sort((a, b) => b.id - a.id)
                        ?.map((card) => (
                          <div className="block">
                            <div
                              key={card.id}
                              className="relative md:flex gap-2 mt-1 bg-white single-business-card md:gap-4 md:ml-2 md:mt-4 p-[16px]"
                            >
                              <div className="w-full mr-2 overflow-hidden md:w-2/5 sm:mr-0">
                                <img
                                  src={card.file_manager?.url}
                                  alt={card.name}
                                  className="h-full"
                                />
                              </div>

                              <div className="w-full mt-4 md:w-3/5 md:mt-0">
                                {/* options */}
                                <section className="flex items-start justify-between w-full">
                                  <p
                                    className="text-xs not-italic font-medium leading-6"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    E-Business Card
                                  </p>
                                  <div>
                                    <Dropdown
                                      param={card}
                                      menuItems={[
                                        {
                                          icon: (
                                            <i className="mr-1 fa-sharp fa-solid fa-expand" />
                                          ),
                                          name: "Show details",
                                          function: () => {
                                            handleOpenCardPreview(card);
                                          },
                                        },
                                        {
                                          icon: (
                                            <i className="mr-1 fa-sharp fa-solid fa-edit" />
                                          ),
                                          name: "Edit Card",
                                          function: () => {
                                            setCardData(card);
                                            // setOpenEditModal(true);
                                            navigate(
                                              "/contacts/business-cards/edit",
                                              {
                                                state: { card },
                                              }
                                            );
                                          },
                                        },
                                        // {
                                        //   icon: (
                                        //     <i className="mr-1 fa-sharp fa-solid fa-edit" />
                                        //   ),
                                        //   name: "Add Tags",
                                        //   function: () => {
                                        //     setSelectedAddTagContact(card);
                                        //     setOpenAddTagsModal(true);
                                        //   },
                                        // },
                                        {
                                          icon: (
                                            <i className="mr-1 fa-sharp fa-solid fa-trash" />
                                          ),
                                          name: "Delete this Card",
                                          function: () => {
                                            // handleDeleteCard(card?.id);
                                            setSelectedDeleteCard(card);
                                            setShowDeleteCardModal(true);
                                          },
                                        },
                                      ]}
                                      rotate
                                    />
                                  </div>
                                </section>

                                {/* name */}
                                <h3 className="mb-3 text-xl font-bold text-black">
                                  {card.name}
                                </h3>

                                <p
                                  className="text-xs font-medium text-black"
                                  style={{ marginBottom: "10px" }}
                                >
                                  Contact with
                                </p>

                                <div className="flex items-center">
                                  {card?.phone && (
                                    <a
                                      className="mr-3"
                                      href={`tel:${card?.phone}`}
                                    >
                                      <svg
                                        width="23"
                                        height="23"
                                        viewBox="0 0 23 23"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.3491 22.6557C16.8949 22.4172 14.5558 21.6376 12.3319 20.3168C10.1071 18.9966 8.18339 17.3501 6.56062 15.3771C4.93702 13.4048 3.69169 11.2 2.82464 8.76273C1.95676 6.32613 1.64207 3.88068 1.88059 1.42639C1.91387 1.08393 2.05575 0.809643 2.30625 0.603524C2.55674 0.397405 2.85322 0.310987 3.19568 0.344268L7.81888 0.793566C8.08524 0.819451 8.31474 0.928178 8.50738 1.11975C8.70002 1.31131 8.80242 1.54213 8.81459 1.81218L9.1683 5.87965C9.18046 6.14971 9.15234 6.39165 9.08393 6.60548C9.01469 6.81999 8.88613 7.00454 8.69826 7.15913L5.68679 9.68963C6.35273 11.1371 7.22677 12.5184 8.30889 13.8335C9.39102 15.1486 10.6125 16.314 11.9734 17.3297L14.9167 14.9078C15.1046 14.7532 15.3408 14.6463 15.6254 14.5872C15.9092 14.5287 16.1824 14.5315 16.4451 14.5954L20.3057 15.7772C20.5665 15.8602 20.7728 16.0097 20.9247 16.2257C21.0765 16.4425 21.1394 16.6841 21.1135 16.9504L20.6642 21.5736C20.631 21.9161 20.4891 22.1904 20.2386 22.3965C19.9881 22.6026 19.6916 22.689 19.3491 22.6557Z"
                                          fill="#EA4C89"
                                        />
                                      </svg>
                                    </a>
                                  )}

                                  {card?.phone && (
                                    <a
                                      className="mr-3"
                                      href={`sms://${card?.phone}`}
                                    >
                                      <svg
                                        width="29"
                                        height="29"
                                        viewBox="0 0 29 29"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.9329 7.5H8.46626C8.16293 7.5 7.87126 7.51167 7.59126 7.54667C4.45293 7.815 2.87793 9.67 2.87793 13.0883V17.755C2.87793 22.4217 4.7446 23.3433 8.46626 23.3433H8.93293C9.1896 23.3433 9.52793 23.5183 9.6796 23.7167L11.0796 25.5833C11.6979 26.4117 12.7013 26.4117 13.3196 25.5833L14.7196 23.7167C14.8946 23.4833 15.1746 23.3433 15.4663 23.3433H15.9329C19.3513 23.3433 21.2063 21.78 21.4746 18.63C21.5096 18.35 21.5213 18.0583 21.5213 17.755V13.0883C21.5213 9.36667 19.6546 7.5 15.9329 7.5ZM8.12793 16.8333C7.4746 16.8333 6.96126 16.3083 6.96126 15.6667C6.96126 15.025 7.48626 14.5 8.12793 14.5C8.7696 14.5 9.2946 15.025 9.2946 15.6667C9.2946 16.3083 8.7696 16.8333 8.12793 16.8333ZM12.1996 16.8333C11.5463 16.8333 11.0329 16.3083 11.0329 15.6667C11.0329 15.025 11.5579 14.5 12.1996 14.5C12.8413 14.5 13.3663 15.025 13.3663 15.6667C13.3663 16.3083 12.8529 16.8333 12.1996 16.8333ZM16.2829 16.8333C15.6296 16.8333 15.1163 16.3083 15.1163 15.6667C15.1163 15.025 15.6413 14.5 16.2829 14.5C16.9246 14.5 17.4496 15.025 17.4496 15.6667C17.4496 16.3083 16.9246 16.8333 16.2829 16.8333Z"
                                          fill="#ED6298"
                                        />
                                        <path
                                          d="M26.1877 8.42171V13.0884C26.1877 15.4217 25.4644 17.0084 24.0177 17.8834C23.6677 18.0934 23.2594 17.8134 23.2594 17.405L23.2711 13.0884C23.2711 8.42171 20.5994 5.75004 15.9327 5.75004L8.82773 5.76171C8.41939 5.76171 8.1394 5.35337 8.3494 5.00337C9.2244 3.55671 10.8111 2.83337 13.1327 2.83337H20.5994C24.3211 2.83337 26.1877 4.70004 26.1877 8.42171Z"
                                          fill="#ED6298"
                                        />
                                      </svg>
                                    </a>
                                  )}

                                  {card?.website && (
                                    <a
                                      target="_blank"
                                      className="mr-3"
                                      href={makeLink(card?.website)}
                                      rel="noreferrer"
                                    >
                                      <svg
                                        width="25"
                                        height="25"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M17.843 14.9092C17.9393 14.1141 18.0116 13.3191 18.0116 12.4999C18.0116 11.6808 17.9393 10.8857 17.843 10.0907H21.9146C22.1074 10.8616 22.2278 11.6687 22.2278 12.4999C22.2278 13.3311 22.1074 14.1382 21.9146 14.9092M15.7108 21.607C16.4335 20.2698 16.9877 18.8243 17.3732 17.3185H20.9268C19.7597 19.3281 17.9081 20.8505 15.7108 21.607ZM15.4096 14.9092H9.77192C9.65146 14.1141 9.57918 13.3191 9.57918 12.4999C9.57918 11.6808 9.65146 10.8737 9.77192 10.0907H15.4096C15.518 10.8737 15.6023 11.6808 15.6023 12.4999C15.6023 13.3191 15.518 14.1141 15.4096 14.9092ZM12.5908 22.0888C11.5909 20.6433 10.7838 19.0411 10.2899 17.3185H14.8916C14.3977 19.0411 13.5906 20.6433 12.5908 22.0888ZM7.77223 7.68141H4.2547C5.40977 5.66632 7.26002 4.14158 9.45872 3.39292C8.73594 4.73006 8.19385 6.17562 7.77223 7.68141ZM4.2547 17.3185H7.77223C8.19385 18.8243 8.73594 20.2698 9.45872 21.607C7.26463 20.8501 5.41699 19.3275 4.2547 17.3185ZM3.2669 14.9092C3.07416 14.1382 2.9537 13.3311 2.9537 12.4999C2.9537 11.6687 3.07416 10.8616 3.2669 10.0907H7.33856C7.24219 10.8857 7.16991 11.6808 7.16991 12.4999C7.16991 13.3191 7.24219 14.1141 7.33856 14.9092M12.5908 2.89902C13.5906 4.34458 14.3977 5.95879 14.8916 7.68141H10.2899C10.7838 5.95879 11.5909 4.34458 12.5908 2.89902ZM20.9268 7.68141H17.3732C16.9961 6.18942 16.4378 4.74921 15.7108 3.39292C17.9273 4.15184 19.7704 5.68172 20.9268 7.68141ZM12.5908 0.453613C5.92914 0.453613 0.544434 5.87446 0.544434 12.4999C0.544434 15.6948 1.8136 18.7589 4.07272 21.018C5.19133 22.1366 6.5193 23.0239 7.98083 23.6293C9.44236 24.2347 11.0088 24.5463 12.5908 24.5463C15.7856 24.5463 18.8497 23.2771 21.1088 21.018C23.3679 18.7589 24.6371 15.6948 24.6371 12.4999C24.6371 10.918 24.3255 9.35154 23.7201 7.89001C23.1147 6.42848 22.2274 5.10051 21.1088 3.9819C19.9902 2.8633 18.6622 1.97597 17.2007 1.37059C15.7392 0.765201 14.1727 0.453613 12.5908 0.453613Z"
                                          fill="#EA4C89"
                                        />
                                      </svg>
                                    </a>
                                  )}

                                  {card?.email && (
                                    <a
                                      className="mr-3"
                                      href={`mailto:${card?.email}`}
                                    >
                                      <svg
                                        width="25"
                                        height="21"
                                        viewBox="0 0 25 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M22.3206 0.461426H3.04647C1.72138 0.461426 0.649253 1.59077 0.649253 2.97108L0.637207 18.029C0.637207 19.4093 1.72138 20.5386 3.04647 20.5386H22.3206C23.6457 20.5386 24.7299 19.4093 24.7299 18.029V2.97108C24.7299 1.59077 23.6457 0.461426 22.3206 0.461426ZM22.3206 5.48073L12.6835 11.7549L3.04647 5.48073V2.97108L12.6835 9.24521L22.3206 2.97108V5.48073Z"
                                          fill="#EA4C89"
                                        />
                                      </svg>
                                    </a>
                                  )}

                                  {card?.state && (
                                    <a
                                      target="_blank"
                                      className=""
                                      href={`https://www.google.com/maps/place/${card?.state}`}
                                      rel="noreferrer"
                                    >
                                      <svg
                                        width="23"
                                        height="29"
                                        viewBox="0 0 23 29"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M11.7724 14.5C12.5316 14.5 13.1817 14.2245 13.7228 13.6736C14.263 13.1236 14.5331 12.4621 14.5331 11.6892C14.5331 10.9162 14.263 10.2542 13.7228 9.70332C13.1817 9.15334 12.5316 8.87835 11.7724 8.87835C11.0133 8.87835 10.3636 9.15334 9.82345 9.70332C9.28237 10.2542 9.01183 10.9162 9.01183 11.6892C9.01183 12.4621 9.28237 13.1236 9.82345 13.6736C10.3636 14.2245 11.0133 14.5 11.7724 14.5ZM11.7724 28.554C8.06862 25.345 5.30248 22.3641 3.47403 19.6114C1.64466 16.8596 0.72998 14.3126 0.72998 11.9702C0.72998 8.45673 1.84021 5.65763 4.06066 3.57295C6.2802 1.48826 8.85079 0.445923 11.7724 0.445923C14.6941 0.445923 17.2647 1.48826 19.4842 3.57295C21.7047 5.65763 22.8149 8.45673 22.8149 11.9702C22.8149 14.3126 21.9007 16.8596 20.0722 19.6114C18.2429 22.3641 15.4763 25.345 11.7724 28.554Z"
                                          fill="#EA4C89"
                                        />
                                      </svg>
                                    </a>
                                  )}

                                  <div className="flex items-center border border-[#C6C6C6] h-[13px] mr-[16.44px]" />

                                  <div
                                    className="flex items-center cursor-pointer"
                                    onClick={() => {
                                      setSelectedAddTagContact(card);
                                      setOpenAddTagsModal(true);
                                    }}
                                  >
                                    <svg
                                      width="23"
                                      height="22"
                                      viewBox="0 0 23 22"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M20.3777 7.15L15.0927 1.865C13.9844 0.756663 12.4561 0.161663 10.8927 0.243329L5.05939 0.523329C2.72606 0.628329 0.871062 2.48333 0.754395 4.805L0.474395 10.6383C0.404395 12.2017 0.987728 13.73 2.09606 14.8383L7.38106 20.1233C9.55106 22.2933 13.0744 22.2933 15.2561 20.1233L20.3777 15.0017C22.5594 12.8433 22.5594 9.32 20.3777 7.15ZM8.32606 11.4433C6.48273 11.4433 4.96606 9.93833 4.96606 8.08333C4.96606 6.22833 6.48273 4.72333 8.32606 4.72333C10.1694 4.72333 11.6861 6.22833 11.6861 8.08333C11.6861 9.93833 10.1694 11.4433 8.32606 11.4433ZM17.6944 12.785L13.0277 17.4517C12.8527 17.6267 12.6311 17.7083 12.4094 17.7083C12.1877 17.7083 11.9661 17.6267 11.7911 17.4517C11.4527 17.1133 11.4527 16.5533 11.7911 16.215L16.4577 11.5483C16.7961 11.21 17.3561 11.21 17.6944 11.5483C18.0327 11.8867 18.0327 12.4467 17.6944 12.785Z"
                                        fill="#EA4C89"
                                      />
                                    </svg>
                                    <span className="ml-[7px] text-[#333333] font-medium text-[12px]">
                                      Add Tags
                                    </span>
                                  </div>
                                </div>
                                <div className="flex flex-wrap gap-2 md:mt-4 mt-[35px] mb-6 divide-y">
                                  {card?.tag?.length !== 0
                                    ? card?.tag
                                        .slice(
                                          0,
                                          isShowMore ? card?.tag.length : 5
                                        )
                                        .map((tagObj, idx) => {
                                          return (
                                            <React.Fragment key={idx}>
                                              <Tag
                                                title={tagObj?.name}
                                                tagId={tagObj?.id}
                                                index={idx}
                                                className="capitalize"
                                              />
                                            </React.Fragment>
                                          );
                                        })
                                    : "No tags"}
                                  {showTag(card?.tag) && (
                                    <div
                                      className="bg-[#D9D9D9] text-[#333] px-2 sm:px-3 py-1.5 rounded-md flex align-center cursor-pointer  transition duration-300 ease  max-w-xl overflow-hidden text-xs font-medium break-words sm:text-sm"
                                      onClick={() =>
                                        handleOpenCardPreview(card)
                                      }
                                    >
                                      Show More
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {openAddTagsModal && (
        <Dialog
          open={openAddTagsModal}
          onClose={() => setOpenAddTagsModal(false)}
        >
          <DialogContent className="p-0 transition-qliq">
            <div className="p-4">
              <div className="flex items-center justify-between">
                <h3 className="mb-3 text-base font-semibold sm:text-xl">
                  Tag List
                </h3>
                <button
                  type="button"
                  className="w-6 h-6"
                  onClick={() => setOpenAddTagsModal(false)}
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </div>
              {selectedAddTagContact?.tag?.length !== 0 && (
                <div className="flex flex-wrap items-center gap-2 mb-4">
                  {selectedAddTagContact?.tag.map((tag) => (
                    <Tag
                      key={tag.id}
                      tagId={tag?.id}
                      title={tag.name}
                      handleDeleteTag={handleDeleteContactTag}
                    />
                  ))}
                </div>
              )}
              <div>
                <Formik
                  initialValues={{ title: "" }}
                  //   onSubmit={(e) => {
                  //     handleTagAssignToContact(e);
                  //     fetchTags();
                  //   }}
                  onSubmit={onSubmit}
                  validationSchema={yup.object().shape({
                    title: yup.string().required("Tag is required!"),
                  })}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    errors,
                    touched,
                    isSubmitting,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="relative">
                        <FormControl
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          placeholder="Add a new tag"
                          isInvalid={Boolean(errors.title && touched.title)}
                          className="w-full py-2 bg-gray-200 input focus:outline-0"
                        />
                        {Boolean(errors.title) && (
                          <FormText className="mt-1 text-red-600">
                            {errors.title}
                          </FormText>
                        )}

                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          className="absolute top-0 right-0 border-none bg-primary"
                        >
                          {isSubmitting ? (
                            <Spinner />
                          ) : (
                            <FontAwesomeIcon
                              icon={faPlus}
                              className="text-2xl"
                            />
                          )}
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>

              <div className="mt-3 all-tags">
                <div className="flex flex-wrap gap-2">
                  {tagArr
                    ?.filter(
                      (tag) =>
                        !selectedAddTagContact?.tag
                          ?.map((t) => t?.id)
                          .includes(tag?.id)
                    )
                    ?.map((tag) => (
                      <Tag
                        key={tag.id}
                        title={tag?.name}
                        tagId={tag?.id}
                        // handleDeleteTag={handleDeleteTag}
                        onClick={() => handleAssignTagToContact(tag)}
                      />
                    ))}
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}

      {/* choose card */}
      {open && (
        <Dialog fullWidth open={open} onClose={handleClose}>
          <DialogContent className="p-0 transition-qliq">
            <div className="absolute w-6 h-6 top-5 right-5 closeBtn-with-bg">
              <button type="button" className="w-6 h-6" onClick={handleClose}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="p-10">
              <div className="pt-4 user-info-header position-relative">
                <div className="flex flex-col items-center justify-between">
                  <input
                    hidden
                    id="image"
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={(e) => {
                      handleChooseImage(e);
                    }}
                  />
                  {image.scanCard ? (
                    <img
                      alt=""
                      src={image.scanCard}
                      className="w-[80%] min-h-16 rounded-lg mb-8"
                    />
                  ) : null}
                </div>
                <div
                  className={`flex flex-col gap-2 md:flex-row ${
                    image.data ? "justify-between" : "justify-center"
                  }`}
                >
                  <label
                    htmlFor="image"
                    className="text-gray-800 border border-gray-200 rounded-full btn btn-ghost btn-sm md:btn-md"
                  >
                    <i className="mr-3 fa-solid fa-images"></i> Choose a card
                  </label>

                  {image.data && (
                    <button
                      onClick={handleScanCard}
                      disabled={isSubmitting}
                      className="btn btn-ghost btn-sm md:btn-md bg-brand-primary hover:bg-purple-primary text-white rounded-full !px-10"
                    >
                      <i className="mr-2 fa-solid fa-qrcode"></i>
                      {isSubmitting ? "Scanning..." : "Scan Your Card"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}

      {/* preview card */}
      {cardPreviewPopup && (
        <ContactPreview
          contact={cardData}
          setContact={setCardData}
          isOpen={cardPreviewPopup}
          onClose={handleCloseCardPreview}
        />
      )}

      {/* update card */}
      {openEditModal && (
        <EditBusinessCard
          cardData={cardData}
          open={openEditModal}
          setOpen={setOpenEditModal}
          onSubmit={handleUpdateCard}
        />
      )}
      {showDeleteCardModal && (
        <Dialog
          PaperProps={{ width: "100%" }}
          open={showDeleteCardModal}
          onClose={() => setShowDeleteCardModal(false)}
        >
          <DialogContent
            className="p-0 transition-qliq"
            style={{ overflowY: "unset" }}
          >
            <div className="relative p-6 w-[290px] sm:w-[350px]">
              <div className="absolute top-0 transform -translate-x-1/2 -translate-y-1/2 left-1/2">
                <div className=" flex justify-center items-center w-[70px] h-[70px] rounded-full bg-[#f3f3f3] border-[6px] border-white">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M28 8.97333C27.9733 8.97333 27.9333 8.97333 27.8933 8.97333C20.84 8.26667 13.8 8 6.82668 8.70667L4.10668 8.97333C3.54668 9.02667 3.05334 8.62667 3.00001 8.06667C2.94668 7.50667 3.34668 7.02667 3.89334 6.97333L6.61334 6.70667C13.7067 5.98667 20.8933 6.26667 28.0933 6.97333C28.64 7.02667 29.04 7.52 28.9867 8.06667C28.9467 8.58667 28.5067 8.97333 28 8.97333Z"
                      fill="#EB5757"
                    />
                    <path
                      d="M11.3333 7.62667C11.28 7.62667 11.2267 7.62667 11.16 7.61333C10.6267 7.52 10.2533 7 10.3467 6.46667L10.64 4.72C10.8533 3.44 11.1467 1.66667 14.2533 1.66667H17.7467C20.8667 1.66667 21.16 3.50667 21.36 4.73333L21.6533 6.46667C21.7467 7.01333 21.3733 7.53333 20.84 7.61333C20.2933 7.70667 19.7733 7.33333 19.6933 6.8L19.4 5.06667C19.2133 3.90667 19.1733 3.68 17.76 3.68H14.2667C12.8533 3.68 12.8267 3.86667 12.6267 5.05333L12.32 6.78667C12.24 7.28 11.8133 7.62667 11.3333 7.62667Z"
                      fill="#EB5757"
                    />
                    <path
                      d="M20.28 30.3333H11.72C7.06667 30.3333 6.88001 27.76 6.73334 25.68L5.86667 12.2533C5.82667 11.7067 6.25334 11.2267 6.80001 11.1867C7.36001 11.16 7.82667 11.5733 7.86667 12.12L8.73334 25.5467C8.88001 27.5733 8.93334 28.3333 11.72 28.3333H20.28C23.08 28.3333 23.1333 27.5733 23.2667 25.5467L24.1333 12.12C24.1733 11.5733 24.6533 11.16 25.2 11.1867C25.7467 11.2267 26.1733 11.6933 26.1333 12.2533L25.2667 25.68C25.12 27.76 24.9333 30.3333 20.28 30.3333Z"
                      fill="#EB5757"
                    />
                    <path
                      d="M18.2133 23H13.7733C13.2266 23 12.7733 22.5467 12.7733 22C12.7733 21.4533 13.2266 21 13.7733 21H18.2133C18.76 21 19.2133 21.4533 19.2133 22C19.2133 22.5467 18.76 23 18.2133 23Z"
                      fill="#EB5757"
                    />
                    <path
                      d="M19.3334 17.6667H12.6667C12.12 17.6667 11.6667 17.2133 11.6667 16.6667C11.6667 16.12 12.12 15.6667 12.6667 15.6667H19.3334C19.88 15.6667 20.3334 16.12 20.3334 16.6667C20.3334 17.2133 19.88 17.6667 19.3334 17.6667Z"
                      fill="#EB5757"
                    />
                  </svg>
                </div>
              </div>
              <div className="mt-5 mb-4 text-center">
                <h4 className="text-base font-[500] text-[#000000d9]">
                  Are you sure to delete the <br /> Contact?
                </h4>
                <h4 className="text-[12px] mt-2 font-[500] text-[#000000d9]">
                  You can’t undo this action
                </h4>
              </div>

              <div className="flex items-center justify-center mt-8">
                <div
                  className="px-4 mr-2 py-2 text-base border border-[#D9D9D9] text-[#000000d9] text-white bg-white rounded cursor-pointer"
                  onClick={() => setShowDeleteCardModal(false)}
                >
                  Cancel
                </div>
                <div
                  className="px-[15px] py-[6.4px] text-base text-white bg-[#EF5367] rounded cursor-pointer"
                  onClick={handleDelete}
                >
                  Delete
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default BusinessCards;
