import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';


function StepTwo({handleChange}) {
    return (
        <div className="w-full display-position-center">
            <div className='text-center mb-50'>
                <p className="text-dark-600 text-sm mb-1">We just need to ask you some Questions</p>
                <h1 className="text-xl md:text-2xl font-bold leading-tight">You’re almost done</h1>
            </div>


            <div className="mt-6 w-full" >
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label htmlFor="first_name" className="block text-gray-700">First Name</label>
                        <input
                            onChange={handleChange}
                            type="text" name="first_name" id="first_name" placeholder="Enter First Name"
                            className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-purple-500 focus:bg-white focus:outline-none"
                            required autoFocus
                        />
                    </div>
                    <div>
                        <label htmlFor="last_name" className="block text-gray-700">Last Name</label>
                        <input
                            onChange={handleChange}
                            type="text" name="last_name" id="last_name" placeholder="Enter Last Name"
                            className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-purple-500 focus:bg-white focus:outline-none"
                            required
                        />
                    </div>
                </div>

                <div className="mt-4">
                    <label htmlFor="phone" className="block text-gray-700 mb-2">Phone Number</label>
                    <IntlTelInput
                        id="phone"
                        name="phone"
                        placeholder="Enter Phone"
                        containerClassName="intl-tel-input"
                        inputClassName="form-control w-full px-4 py-3 rounded-lg bg-gray-200 border focus:border-purple-500 focus:bg-white focus:outline-none"
                    />
                </div>

                <div className="mt-4">
                    <label htmlFor="company" className="block text-gray-700">Company</label>
                    <input
                        onChange={handleChange}
                        type="text" name="company" id="company" placeholder="Enter Your Company Name" 
                        className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-purple-500 focus:bg-white focus:outline-none"
                    />
                </div>

                <div className="mt-4">
                    <label htmlFor="designation" className="block text-gray-700">How would you describe your job tittle?</label>
                    <input
                        onChange={handleChange}
                        type="text" name="designation" id="designation" placeholder="Enter Job Title"
                        className="username w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-purple-500 focus:bg-white focus:outline-none"
                    />
                </div>
               
                {/*<button onClick={()=> setStep(3)} type="button" className="w-full block bg-brand-primary hover:bg-purple-600  focus:bg-purple-700 text-white font-semibold rounded-lg px-4 py-3 mt-6">*/}
                {/*    <i className="fas fa-check-circle mr-1"></i>  Next Step */}
                {/*</button>*/}
            </div>
        </div>
    );
}

export default StepTwo;