import vactor from "../../../Images/bg/welcome.svg";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
// import InputLabel from '@mui/material/InputLabel';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};

function StepFour() {
  return (
    <div className="w-full display-position-center">
      <div className="flex justify-center">
        <img
          className="object-center w-32 h-auto transition duration-500 transform rounded-3xl hover:scale-110"
          src={vactor}
          alt="vactor"
        />
      </div>

      <h1 className="mt-12 text-xl font-bold leading-tight md:text-2xl">
        Choose your Social Link's
      </h1>
      <p className="mt-1 text-sm text-gray-600">Lorem, ipsum dolor.</p>

      <form className="w-full mt-6" method="post" action="#">
        <Select
          labelId="demo-simple-select-standard-label"
          className="w-16 h-48-px"
          id="demo-controlled-open-select"
          MenuProps={MenuProps}
        >
          <MenuItem value={10}>
            <i className="fa-brands fa-facebook"></i>
          </MenuItem>
        </Select>
        <div className="content-wrap">
          <FormControl className="single-social-wrap">
            <InputGroup className="w-full">
              <Form.Control
                placeholder="@username"
                className="w-full px-4 py-3 bg-gray-200 border focus:border-purple-500 focus:bg-white focus:outline-none h-48-px"
              />
              <Button
                className="bg-red-500 rounded-r-lg hover:bg-red-600 focus:bg-red-700 border-red"
                variant="danger"
              >
                <i className="fa-solid fa-trash"></i>
              </Button>
            </InputGroup>
            <Form.Text className="text-purple-600 " id="usernameFullView" muted>
              www.facebook.com/dinmohammad
            </Form.Text>

            <Form.Control
              placeholder="Enter Label"
              className="w-full px-4 py-3 bg-gray-200 border rounded-lg focus:border-purple-500 focus:bg-white focus:outline-none"
            />
          </FormControl>

          <Form.Group className="mt-6 text-center">
            <button
              type="button"
              className="tracking-tight text-white capitalize bg-purple-500 uploadBtnWithIcon btn-sm hover:bg-purple-600 focus:bg-purple-700 rounded-2xl d-flex"
            >
              Add a new social link's{" "}
              <i className="ml-2 fa-solid fa-circle-plus"></i>
            </button>
          </Form.Group>
        </div>
        <div className="flex space-x-2">
          <a
            href="/register/step-4"
            className="w-full px-2 py-3 mt-6 font-semibold tracking-tight text-center text-gray-700 bg-gray-300 rounded-lg hover:bg-gray-400 focus:bg-gray-500"
          >
            Back
          </a>
          <button
            type="button"
            className="block w-full px-2 py-3 mt-6 font-semibold tracking-tight text-center text-white rounded-lg bg-brand-primary hover:bg-purple-600 focus:bg-purple-700"
          >
            Finish <i className="ml-1 fas fa-check-circle"></i>
          </button>
          <a
            href="/home"
            className="w-full px-2 py-3 mt-6 font-semibold tracking-tight text-center text-gray-700 bg-gray-300 rounded-lg hover:bg-gray-400 focus:bg-gray-500"
          >
            Skip
          </a>
        </div>
      </form>
    </div>
  );
}

export default StepFour;
