import classNames from "classnames";
import {Link} from "react-router-dom";

const Footer = ({className}) => {
    return (
        <footer
            className={classNames(className, "footer md:px-4 px-24 py-8 bg-gray-100/0  bg-white md:bg-white text-base-content grid grid-cols-1 px-16 md:grid-cols-6 flex flex-col items-center justify-center")}>

            <div className="col-span-2 md:col-span-1">

            </div>

            <div>
                <span className=" text-gray-800 font-bold text-xl text-center">Help</span>
                <Link to="/sample-page" className="link link-hover">Support</Link>
                <Link to="/sample-page" className="link link-hover ">Knowledge Base</Link>
                <Link to="/sample-page" className="link link-hover">Tutorials</Link>
            </div>

            <div>
                <span className=" text-gray-800 font-bold text-xl">Legal</span>
                <Link to="/sample-page" className="link link-hover">Privacy Policy</Link>
                <Link to="/sample-page" className="link link-hover">Terms of Service</Link>
                <Link to="/sample-page" className="link link-hover">DMCA</Link>
            </div>

            <div>
                <span className=" text-gray-800 font-bold text-xl">Company</span>
                <Link to="/sample-page" className="link link-hover">QliQ In The Press</Link>
                <Link to="/sample-page" className="link link-hover">Earn with Qliq</Link>
                <Link to="/sample-page" className="link link-hover">About</Link>
            </div>

            <div>
                <span className=" text-gray-800 font-bold text-xl">QliQ App</span>
                <Link to="/sample-page" className="link link-hover">Business Cards</Link>
                <Link to="/sample-page" className="link link-hover">Email Signatures</Link>
                <Link to="/sample-page" className="link link-hover">Address Book</Link>
            </div>
        </footer>
    );
};

export default Footer;
