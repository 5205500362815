import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { Dialog, DialogContent } from "@mui/material";
import Spinner from "../../Components/Spinner/Spinner";
import defaultUser from "../../Images/default_user.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import "react-toastify/dist/ReactToastify.css";
import FileSaver from "file-saver";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import {
  getCompanies,
  deleteCompany,
  searchComapny,
  downloadCompany,
} from "../../Api/Company";

// const TabSocialIcons = [
//   {
//     name: "Edit",
//     link: "/contacts/edit-company",
//     photo: require("../../Images/icons/edit.png"),
//   },
//   {
//     name: "Delete",
//     link: "/",
//     photo: require("../../Images/icons/bin.png"),
//   },
// ];

function Companys() {
  const navigate = useNavigate();
  const [companies, setCompanies] = useState([]);
  const [isSpinner, setIsSpinner] = useState(false);
  const [previewCompany, setPreviewCompany] = useState(false);
  const [showCompanyDetailsModal, setShowCompanyDetailsModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteCompanyId, setDeleteCompanyId] = useState(null);

  useEffect(() => {
    getSetCompanies();
    // if (state) {
    //   const { company } = state;
    //   if (company) {
    //     companyModalRef.current.checked = true;
    //     setPreviewCompany(company);
    //   }
    // }
  }, []);

  const getSetCompanies = () => {
    setIsSpinner(true);
    getCompanies()
      .then((res) => {
        setIsSpinner(false);
        setCompanies(res);
      })
      .catch((err) => {
        setIsSpinner(false);
      });
  };

  const handleEdit = (company) => {
    navigate("/contacts/edit-company", { state: { company: company } });
  };

  const handleDelete = () => {
    setIsSpinner(true);

    deleteCompany(deleteCompanyId)
      .then((res) => {
        setOpenDeleteModal(false);
        setShowCompanyDetailsModal(false);
        if (res) {
          toast.success("Company is deleted successfully");
          getSetCompanies();
        } else {
          toast.error("Company is not deleted successfully");
          setIsSpinner(false);
        }
        setDeleteCompanyId(null);
      })
      .catch((err) => {
        toast.error("Company is not deleted successfully");
        setIsSpinner(false);
        setDeleteCompanyId(null);
      });
  };

  const searchCompanyFunc = async (event) => {
    setIsSpinner(true);
    try {
      let res = await searchComapny(event.target.value);
      setCompanies(res);
      if (res) setIsSpinner(false);
    } catch (err) {
      setIsSpinner(false);
      console.log(err);
    }
  };
  const handleAddToPhone = () => {
    downloadCompany(previewCompany?.id)
      .then((res) => {
        const blob = new Blob([res], {
          type: "text/plain;charset=utf-8",
        });
        FileSaver.saveAs(blob, `${previewCompany?.name}.vcf`);
      })
      .catch(console.log);
  };

  return (
    <div className="container mx-auto">
      <div className="p-4 bg-white rounded">
        <div className="flex flex-wrap gap-2">
          <div
            className="flex items-center cursor-pointer bg-red-300 py-[4px] px-[14px] rounded tracking-tight"
            onClick={() => navigate(-1)}
          >
            <BsArrowLeftCircleFill color="white" />
            <span className="pl-1 text-white">Back</span>
          </div>
          <Link to="/contacts/new-company">
            <button className="tracking-tight text-white capitalize rounded btn btn-ghost btn-sm qliq-active-btn">
              New Company
              <i className="ml-1 fa-solid fa-circle-plus"></i>
            </button>
          </Link>
        </div>
      </div>

      <div className="search-company">
        <div className="mt-4 form-control">
          <div className="input-group input-group-md">
            <span className="bg-white border border-gray-200 h-48-px border-r-transparent">
              <i className="fa-solid fa-magnifying-glass"></i>
            </span>
            <input
              type="text"
              placeholder="Type a Company Name..."
              className="w-full pl-0 border border-gray-200 input input-bordered border-l-transparent input-md focus:outline-none"
              onChange={debounce(searchCompanyFunc, 200)}
            />
          </div>
        </div>
      </div>

      {isSpinner && (
        <div className="flex items-center justify-center w-full h-full mt-16">
          <Spinner />
        </div>
      )}

      <div className="grid grid-cols-1 gap-3 mt-4 md:grid-cols-3">
        {companies?.map((item, idx) => (
          <div
            key={idx}
            className="relative px-3 py-3 bg-white shadow cursor-pointer rounded-xl"
          >
            <div className="flex items-center justify-between">
              <div
                className="flex items-center w-full space-x-2"
                onClick={() => {
                  setPreviewCompany(item);
                  setShowCompanyDetailsModal(true);
                }}
              >
                <div>
                  <img
                    className="object-cover rounded-full modal-button w-9 h-9 modal-open"
                    src={
                      item?.file_manager ? item?.file_manager?.url : defaultUser
                    }
                    alt=""
                  />
                </div>

                <div className="w-full">
                  <h2 className="text-lg tracking-tight font-monospace contact-name">
                    {item?.name}{" "}
                  </h2>
                </div>
              </div>
              <div>
                <Dropdown
                  param={item}
                  menuItems={[
                    { name: "Edit", function: handleEdit },
                    {
                      name: "Delete",
                      function: () => {
                        setOpenDeleteModal(true);
                        setDeleteCompanyId(item?.id);
                      },
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <Dialog
        open={showCompanyDetailsModal}
        onClose={() => setShowCompanyDetailsModal(false)}
        maxWidth={"sm"}
        fullWidth={true}
        className="rounded-lg"
      >
        <div className="absolute top-3 right-5">
          <button onClick={() => setShowCompanyDetailsModal(false)}>
            <i className="cursor-pointer fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="px-4 py-8 rounded-lg md:px-8">
          <div className="flex flex-col items-center justify-center">
            <img
              className="object-cover w-24 h-24 p-1 rounded-full bg-brand-primary"
              src={
                previewCompany?.file_manager
                  ? previewCompany?.file_manager?.url
                  : defaultUser
              }
              alt="qliq"
            />
          </div>
          <div>
            <h3 className="mt-4 text-xl font-normal text-center">
              {previewCompany?.name}
            </h3>
            <h3 className="text-sm text-center text-gray-500">
              {previewCompany?.city}
            </h3>
          </div>
          <div className="flex items-center justify-center my-3 space-x-2 popups-icon-header-top">
            <img
              className="w-6 h-6 cursor-pointer"
              src={require("../../Images/icons/edit.png")}
              alt="qliq"
              onClick={() =>
                navigate("/contacts/edit-company", {
                  state: { company: previewCompany },
                })
              }
            />
            <img
              className="w-6 h-6 cursor-pointer"
              src={require("../../Images/icons/bin.png")}
              alt="qliq"
              onClick={() => {
                setOpenDeleteModal(true);
                setDeleteCompanyId(previewCompany?.id);
              }}
            />
          </div>

          <div className="flex flex-col items-center justify-center mt-4">
            <TableContainer component={Paper} className="personal-details-wrap">
              <Table
                size="small"
                aria-label="custom table"
                className="personal-detailsTable"
              >
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <i className="fa-solid fa-user"></i> Owner Name
                    </TableCell>
                    <TableCell align="left">{previewCompany?.owner}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <i className="mr-1 fa-solid fa-phone"></i> Phone
                    </TableCell>
                    <TableCell align="left">{previewCompany?.phone}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="flex justify-center mt-6">
            <button
              onClick={handleAddToPhone}
              className="capitalize bg-white add-tagsBtn btn btn-ghost btn-sm text-dark hover:bg-primary hover:text-white"
            >
              <i className="mr-1 fa fa-phone" />
              Add to Phone
            </button>
          </div>
        </div>
      </Dialog>

      {openDeleteModal && (
        <Dialog
          PaperProps={{ width: "100%" }}
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
        >
          <DialogContent
            className="p-0 transition-qliq"
            style={{ overflowY: "unset" }}
          >
            <div className="relative p-6 w-[290px] sm:w-[350px]">
              <div className="absolute top-0 transform -translate-x-1/2 -translate-y-1/2 left-1/2">
                <div className=" flex justify-center items-center w-[70px] h-[70px] rounded-full bg-[#f3f3f3] border-[6px] border-white">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M28 8.97333C27.9733 8.97333 27.9333 8.97333 27.8933 8.97333C20.84 8.26667 13.8 8 6.82668 8.70667L4.10668 8.97333C3.54668 9.02667 3.05334 8.62667 3.00001 8.06667C2.94668 7.50667 3.34668 7.02667 3.89334 6.97333L6.61334 6.70667C13.7067 5.98667 20.8933 6.26667 28.0933 6.97333C28.64 7.02667 29.04 7.52 28.9867 8.06667C28.9467 8.58667 28.5067 8.97333 28 8.97333Z"
                      fill="#EB5757"
                    />
                    <path
                      d="M11.3333 7.62667C11.28 7.62667 11.2267 7.62667 11.16 7.61333C10.6267 7.52 10.2533 7 10.3467 6.46667L10.64 4.72C10.8533 3.44 11.1467 1.66667 14.2533 1.66667H17.7467C20.8667 1.66667 21.16 3.50667 21.36 4.73333L21.6533 6.46667C21.7467 7.01333 21.3733 7.53333 20.84 7.61333C20.2933 7.70667 19.7733 7.33333 19.6933 6.8L19.4 5.06667C19.2133 3.90667 19.1733 3.68 17.76 3.68H14.2667C12.8533 3.68 12.8267 3.86667 12.6267 5.05333L12.32 6.78667C12.24 7.28 11.8133 7.62667 11.3333 7.62667Z"
                      fill="#EB5757"
                    />
                    <path
                      d="M20.28 30.3333H11.72C7.06667 30.3333 6.88001 27.76 6.73334 25.68L5.86667 12.2533C5.82667 11.7067 6.25334 11.2267 6.80001 11.1867C7.36001 11.16 7.82667 11.5733 7.86667 12.12L8.73334 25.5467C8.88001 27.5733 8.93334 28.3333 11.72 28.3333H20.28C23.08 28.3333 23.1333 27.5733 23.2667 25.5467L24.1333 12.12C24.1733 11.5733 24.6533 11.16 25.2 11.1867C25.7467 11.2267 26.1733 11.6933 26.1333 12.2533L25.2667 25.68C25.12 27.76 24.9333 30.3333 20.28 30.3333Z"
                      fill="#EB5757"
                    />
                    <path
                      d="M18.2133 23H13.7733C13.2266 23 12.7733 22.5467 12.7733 22C12.7733 21.4533 13.2266 21 13.7733 21H18.2133C18.76 21 19.2133 21.4533 19.2133 22C19.2133 22.5467 18.76 23 18.2133 23Z"
                      fill="#EB5757"
                    />
                    <path
                      d="M19.3334 17.6667H12.6667C12.12 17.6667 11.6667 17.2133 11.6667 16.6667C11.6667 16.12 12.12 15.6667 12.6667 15.6667H19.3334C19.88 15.6667 20.3334 16.12 20.3334 16.6667C20.3334 17.2133 19.88 17.6667 19.3334 17.6667Z"
                      fill="#EB5757"
                    />
                  </svg>
                </div>
              </div>
              <div className="mt-5 mb-4 text-center">
                <h4 className="text-base font-[500] text-[#000000d9]">
                  Are you sure to delete the <br /> Company?
                </h4>
                <h4 className="text-[12px] mt-2 font-[500] text-[#000000d9]">
                  You can’t undo this action
                </h4>
              </div>

              <div className="flex items-center justify-center mt-8">
                <div
                  className="px-4 mr-2 py-2 text-base border border-[#D9D9D9] text-[#000000d9] text-white bg-white rounded cursor-pointer"
                  onClick={() => setOpenDeleteModal(false)}
                >
                  Cancel
                </div>
                <div
                  className="px-[15px] py-[6.4px] text-base text-white bg-[#EF5367] rounded cursor-pointer"
                  onClick={handleDelete}
                >
                  Delete
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default Companys;
