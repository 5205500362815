// import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useState } from "react";
import Spinner from "Components/Spinner/Spinner";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
import defaultUser from "Images/default_user.png";
import { getContacts, searchContact } from "Api/Contact";
import { debounce } from "lodash";
import profileIcon from "icons/profile-icon.svg";
import calendarIcon from "icons/calendar-icon.svg";

const AddSubTaskModal = ({
  setShowAddSubTaskModal,
  handleUpdateSubTask,
  subTaskText,
  setSubTaskText,
  isDate,
  setIsDate,
}) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [error, setError] = useState({
    title: "",
    date: "",
  });
  const [isTitleLength, setIsTitleLength] = useState("");
  const [isDateLength, setIsDateLength] = useState("");
  const [contacts, setContacts] = useState([]);
  const [contactSearchLoading, setContactSearchLoading] = useState(false);
  const [contactSearchParam, setContactSearchParam] = useState("");
  const [assignContacts, setAssignContacts] = useState([]);
  const [searchContacts, setSearchContacts] = useState([]);

  useEffect(() => {
    getContacts()
      .then((res) => {
        setContacts(res?.data);
        setSearchContacts(res?.data);
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    const handleSearchContact = debounce(async (param) => {
      setContactSearchLoading(true);
      const data = await searchContact(param);
      // setContacts(data);
      setSearchContacts(data);
      setContactSearchLoading(false);
    }, 1000);

    handleSearchContact(contactSearchParam);
  }, [contactSearchParam]);

  const handleAddSubTask = (e) => {
    e.preventDefault();
    let isValidYear = false;

    if (isDate?.$y > 2022 && isDate?.$y < 2025) {
      isValidYear = true;
    }

    if (isTitleLength.length === 0) {
      setError({ ...error, title: "You must add Sub Task Name" });
      setShowErrorMessage(true);
    } else if (
      isDateLength === null ||
      Object.keys(isDateLength).length === 0 ||
      String(isDate?.$d).includes("Invalid Date") ||
      isValidYear === false
    ) {
      setError({ ...error, date: "You must add a deadline" });
      setShowErrorMessage(true);
    } else {
      setError({ ...error, title: "", date: "" });
      setShowErrorMessage(false);
      handleUpdateSubTask(e, assignContacts);
      setAssignContacts([]);
    }
  };

  return (
    <>
      <div className="flex items-center justify-between mb-[4px]">
        <h3 className="text-[16px] font-semibold leading-5 text-[#1F2937]">
          Create Sub Task
        </h3>

        <button type="button" onClick={() => setShowAddSubTaskModal(false)}>
          ✕
        </button>
      </div>

      <form onSubmit={handleAddSubTask}>
        <p
          className="font-medium text-sm leading-4 text-[#4F4F4F] mt-[16px]"
          style={{ marginBottom: "10px" }}
        >
          Sub Task Name
        </p>
        <input
          required
          className="w-full p-[12px] border border-[#E3E3E3] rounded-[8px] bg-[#FAFAFB] font-normal text-sm text-[#9FA6B2]"
          placeholder="Sub Task Name"
          value={subTaskText}
          onChange={(e) => {
            setSubTaskText(e.target.value);
            setIsTitleLength(e.target.value);
          }}
        />

        {showErrorMessage && error.title.length > 0 && (
          <p className="font-medium text-xs leading-4 text-[#EB5757]">
            {error.title}
          </p>
        )}

        {/* Assignee */}
        <div className="my-4">
          {/* <section>
            <p
              className="font-medium text-sm leading-4 text-[#4F4F4F] mt-[16px]"
              style={{ marginBottom: "10px" }}
            >
              Assignee
            </p>
            <div className="w-full p-[12px] border border-[#E3E3E3] rounded-[8px] bg-[#FAFAFB] mt-[10px] font-normal text-sm text-[#9FA6B2]">
              <div className="flex items-center">
                {contactSearchLoading ? (
                  <div className="flex justify-center mr-[4px]">
                    <Spinner />
                  </div>
                ) : (
                  <img src={profileIcon} alt="profile" className="mr-[4px]" />
                )}
                <input
                  type="text"
                  className="w-full font-normal text-sm text-[#9FA6B2] bg-transparent focus:outline-none"
                  placeholder="Write Name"
                  onChange={(event) =>
                    setContactSearchParam(event.target.value)
                  }
                />
              </div>

              {contacts?.filter((contact) =>
                assignContacts.some(
                  (selectedContact) => selectedContact.id === contact.id
                )
              ).length > 0 && (
                <>
                  <p
                    className="text-xs font-medium leading-4 text-[#4F4F4F] mt-[16px]"
                    style={{
                      marginBottom: "8px",
                    }}
                  >
                    Assigned Contact
                  </p>

                  <div className="flex flex-wrap gap-[8px]">
                    {contacts
                      ?.filter((contact) =>
                        assignContacts.some(
                          (selectedContact) => selectedContact.id === contact.id
                        )
                      )
                      .map((contact, index) => (
                        <div
                          className="flex items-center rounded-[23px] border border-[#EAE7E7] bg-white py-[4px] pl-[4px] pr-[8px]"
                          key={index}
                        >
                          <img
                            className="w-6 h-6 rounded-full"
                            src={
                              contact?.file_manager
                                ? contact?.file_manager?.url
                                : defaultUser
                            }
                            alt="avatar"
                          />
                          <p
                            className="text-sm font-medium leading-4 text-[#4F4F4F] mx-[8px]"
                            style={{ marginBottom: 0 }}
                          >
                            {contact?.name}
                          </p>
                          <button
                            className="h-[20px] w-[20px] rounded-full bg-[#EAEAEA] flex justify-center items-center"
                            onClick={() => {
                              setAssignContacts(
                                assignContacts.filter(
                                  (selectedContact) =>
                                    selectedContact.id !== contact.id
                                )
                              );
                            }}
                          >
                            <span className="text-[11.25px] leading-[0px] text-[#EA4C89]">
                              ✕
                            </span>
                          </button>
                        </div>
                      ))}
                  </div>
                </>
              )}
            </div>

            <div className="flex flex-wrap gap-[8px] mt-[10px] mb-[20px]">
              {searchContacts
                ?.filter(
                  (contact) =>
                    !assignContacts.some(
                      (selectedContact) => selectedContact.id === contact.id
                    )
                )
                ?.slice(0, 7)
                ?.map((contact, index) => (
                  <div
                    className="flex items-center rounded-[23px] border border-[#EAE7E7] py-[4px] pl-[4px] pr-[8px] cursor-pointer bg-[#F2F2F2]"
                    key={index}
                    onClick={() => {
                      setAssignContacts([...assignContacts, contact]);
                    }}
                  >
                    <img
                      className="w-6 h-6 rounded-full"
                      src={
                        contact?.file_manager
                          ? contact?.file_manager?.url
                          : defaultUser
                      }
                      alt="avatar"
                    />
                    <p
                      className="text-sm font-medium leading-4 text-[#4F4F4F] ml-[8px]"
                      style={{ marginBottom: 0 }}
                    >
                      {contact?.name}
                    </p>
                  </div>
                ))}
            </div>
          </section> */}

          {/* deadline */}
          <p
            className="text-xs font-medium leading-4 text-[#4F4F4F] mt-[16px]"
            style={{ marginBottom: "8px" }}
          >
            Deadline
          </p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={isDate}
              minDate={moment().toDate()}
              onChange={(newValue) => {
                setIsDate(newValue);
                setIsDateLength(newValue);
                setError({ ...error, date: "" });
              }}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <div className="border border-[#E3E3E3] rounded-[8px] bg-[#FAFAFB] flex justify-between items-center p-[12px]">
                  <input
                    ref={inputRef}
                    {...inputProps}
                    className="w-full bg-transparent focus:outline-none"
                    // placeholder="dd/mm/year"
                  />
                  {InputProps?.endAdornment}
                </div>
              )}
              // className="w-full border border-[#E3E3E3] rounded-[8px] bg-[#FAFAFB] font-normal text-sm text-[#9FA6B2]"
              components={{
                OpenPickerIcon: () => (
                  <img src={calendarIcon} alt="calendar icon" />
                ),
              }}
            />
          </LocalizationProvider>

          {showErrorMessage && error.date.length > 0 && (
            <p className="font-medium text-xs leading-4 text-[#EB5757]">
              {error.date}
            </p>
          )}
        </div>

        <button
          type="submit"
          // disabled={isSubmitting}
          className="w-full h-[54px] lg:mt-0 border-0 mt-2 lg:ml-2 text-white capitalize rounded-full py-2 lg:py-3 bg-[#E45C8C] hover:bg-purple-primary text-[15px] font-[500]"
        >
          Save Sub Task
        </button>
      </form>
      {/* </div> */}
    </>
  );
};

export default AddSubTaskModal;
