import axios from "axios";
import { BASE_API_URL } from "../Helpers/Env";

export const getUserSocials = () => axios.get(BASE_API_URL + "user-socials");

export const getSocials = () => axios.get(BASE_API_URL + "socials");

export const socialAddToUser = (data) =>
  axios.post(BASE_API_URL + "user-socials/add", data);

export const userSocialDelete = (id) =>
  axios.delete(BASE_API_URL + "user-socials/" + id);

export const socialDeleteToUser = (id) =>
  axios.delete(BASE_API_URL + "user-socials/delete/" + id);

export const userSocialEdit = (id, data) =>
  axios.put(BASE_API_URL + `user-socials/${id}`, data);

export const addSocial = (data) =>
  axios.post(BASE_API_URL + "user-socials", data);

export const userLink = () => axios.get(BASE_API_URL + "link-categories");