import axios from "axios";
import {BASE_API_URL} from "../Helpers/Env";

export const getPackages = () =>
    axios.post(BASE_API_URL + "package/list?sort=asc");

export const preparePayment = (package_id) =>
    axios.post(
        BASE_API_URL + `subscription/prepare-payment?package_id=${package_id}`
    );

export const makePayment = (package_id, payment_id, stripe_token) =>
    axios.post(
        BASE_API_URL +
        `subscription/make-payment?package_id=${package_id}&payment_id=${payment_id}&stripe_token=${stripe_token}`
    );

export const activeTrial = () => axios.get(BASE_API_URL + 'subscription/trial')
