import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
// import { Link, useNavigate } from "react-router-dom";
// import useAuth from "../../Hooks/useAuth";
// import { activeTrial } from "../../Api/Packages";
// import { toast } from "react-toastify";

const PackageCard = ({ variant = "dark", data, handleTrial }) => {
  // const {isAuthenticated} = useAuth();
  // const navigate = useNavigate();
  const getDurationType = (days) => {
    if (days >= 30) return "month";
    else return "day";
  };
  //
  // const handleTrial = ()=>{
  //     if(isAuthenticated){
  //         activeTrial().then(res=>{
  //             toast.success(res.data.message);
  //             navigate("/cards");
  //         }).catch(err=>{
  //             console.log(err);
  //             toast.success(err.response.data.message);
  //         })
  //     }else {
  //         navigate("/login?trial=yes");
  //     }
  // }

  return (
    <div
      className={`shadow rounded pl-11 pr-8 py-5 ${
        variant === "dark" ? "bg-dark-indigo" : "bg-white"
      }`}
    >
      <div className="flex justify-between">
        <span
          className={`text-sm font-bold uppercase ${
            variant === "dark" ? "text-white" : "text-[#666]"
          }`}
        >
          {data.name}
        </span>
        {data?.popular && (
          <span className="px-4 py-1 text-xs text-white uppercase bg-yellow-500 rounded-full">
            Popular
          </span>
        )}
      </div>
      <div
        className={`mt-7 mb-2 ${
          variant === "dark" ? "text-white" : ""
        } font-bold`}
      >
        <sup className="text-3xl">$</sup>
        <span className="text-5xl">{data.price}</span>
        <sub className="text-base">
          /{" "}
          {data.duration_type === "day"
            ? getDurationType(data.duration)
            : data.duration_type}
        </sub>
      </div>
      <p
        className={`text-sm ${
          variant === "dark" ? "text-white" : "text-[#666]"
        } mb-7`}
      >
        {data.details}
      </p>

      <ul
        className={`mb-9 leading-9 ${variant === "dark" ? "text-white" : ""}`}
      >
        {data.features?.map((feature) => (
          <li className="relative pl-6" key={feature.id}>
            <FontAwesomeIcon
              icon={faCheck}
              className="absolute left-0 top-2.5"
            />
            {feature.name}
          </li>
        ))}
      </ul>

      <button
        onClick={handleTrial}
        className={`${
          variant === "dark"
            ? "bg-white text-brand-primary"
            : "bg-brand-primary text-white"
        } font-bold px-6 py-3.5 rounded`}
      >
        Start 30 Day Free Trial
      </button>

      <div
        className={`${
          variant === "dark" ? "text-white" : ""
        } text-sm block mt-4`}
      >
        No credit card required
      </div>
    </div>
  );
};

export default PackageCard;
