import axios from "axios";
import { BASE_API_URL } from "../Helpers/Env";

export const getAllFolders = () => axios.get(`${BASE_API_URL}link-folders`);
export const removeSingleLink = (id) =>
  axios.delete(`${BASE_API_URL}link-folders/remove-link/${id}`);

export const removeSingleFolder = (id) =>
  axios.delete(`${BASE_API_URL}link-folders/${id}`);

export const createFolder = async (body) => {
  try {
    let res = await axios.post(`${BASE_API_URL}link-folders`, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const updateFolder = async (body, id) => {
  try {
    let res = await axios.post(`${BASE_API_URL}link-folders/${id}`, body);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
