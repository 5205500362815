export const makeLink = (url) => {
  let link = url?.split(".");
  if (link?.length > 1 && link[0]?.startsWith("http")) {
    return url;
  }
  return `http://${url}`;
};

export const makeSocialLink = (url, userName) => {
  const isValid = userName?.includes("/");
  if (isValid) {
    const validLink = userName?.split("/");
    return url + validLink[1];
  }
  return url + userName;
};

export const formatDateWithTime = (dateAndTime) => {
  const date = new Date(dateAndTime);
  return date.toLocaleString();
};
