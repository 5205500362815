import axios from "axios";
import jwtDecode from "jwt-decode";
import { googleLogout } from '@react-oauth/google';

export const userIsLoggedIn = () => {
  const user = localStorage.getItem("accessToken");
  return !!user;
};
//
// export const userData = () => {
//     const user = localStorage.getItem('user');
//     return JSON.parse(user);
// }
//
// const userInfo = userData();
//
// export const reqHeaders = {
//     Authorization: userInfo ? userInfo['token'] : ''
// }

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  accessToken.replace("Bearer ");
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = accessToken;
  } else {
    localStorage.removeItem("accessToken");
    googleLogout()
    delete axios.defaults.headers.common.Authorization;
  }
};
