import { getSocialIcon } from "Helpers/GetSocialIcon";
import AddSocialLinkDialog from "Pages/Cards/components/AddSocialLinkDialog";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getSocials,
  getUserSocials,
  socialAddToUser,
  userSocialDelete,
} from "../../Api/Socials";
import LinkBlock from "../Card/Components/LinkBlock";
import Confirmation from "../Confirmation";

import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

function SocialBox({ fetchUser }) {
  const [socials, setSocials] = useState([]);
  const [userSocials, setUserSocials] = useState([]);
  const [open, setOpen] = useState(false);
  const [selSocial, setSelSocial] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [seeMoreSocials, setSeeMoreSocials] = useState(false);

  const fetchUserSocials = () => {
    getUserSocials()
      .then((res) => setUserSocials(res.data.social))
      .catch((err) => console.log(err));
  };

  const fetchSocials = () => {
    getSocials()
      .then((res) => setSocials(res.data.socials.data))
      .catch((err) => console.log(err));
  };

  const handleOpenAddPopup = (social) => {
    setSelSocial(social);
    setOpen(true);
  };

  const handleCloseAddPopup = () => {
    setSelSocial(null);
    setOpen(false);
  };

  const handleSocialAddToUser = (values, { setSubmitting }) => {
    const data = {
      social_id: selSocial.id,
      social_url: values.url,
      active_status: "1",
    };

    socialAddToUser(data)
      .then(() => {
        fetchUserSocials();
        fetchUser();
        handleCloseAddPopup();
        toast.success("Social media add to user successfully");
        setSubmitting(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err);
        setSubmitting(false);
      });
  };

  const handleDeleteConfirmOpen = (social) => {
    setSelSocial(social);
    setDeleteConfirm(true);
  };

  const handleDeleteConfirmClose = () => {
    setSelSocial(null);
    setDeleteConfirm(false);
  };

  const handleDeleteSocial = (id) => {
    userSocialDelete(id)
      .then((res) => {
        fetchUserSocials();
        fetchUser();
        toast.success(res.data.message);
        handleDeleteConfirmClose();
      })
      .catch((err) => {
        handleDeleteConfirmClose();
        toast.error(err.response.data.message);
        console.log(err);
      });
  };

  useEffect(() => {
    fetchUserSocials();
    fetchSocials();
  }, []);

  return (
    <>
      <div className="px-4 py-6 mt-6 mb-6 md:mb-0 bg-white card-m rounded-2xl">
        <div className="flex justify-between card-header-m">
          <div className="inline-flex items-center">
            <h2 className="text-[#1F2937] font-bold text-[19px] mb-[23px]">
              Social Links
            </h2>
          </div>
        </div>

        <section className="pt-[22px] social-list-wrap">
          <div className="relative grid grid-cols-4 gap-6 sm:grid-cols-3 lg:grid-cols-5 md:grid-cols-3 sm:gap-5">
            {socials
              ?.slice(0, seeMoreSocials ? socials.length : 10)
              .map((social, index) => (
                <button
                  key={index}
                  className="flex flex-col items-center text-center single-social-box"
                  onClick={() => handleOpenAddPopup(social)}
                >
                  {getSocialIcon(social?.name)}
                  <h3 className="text-center text-[12px] md:text-[17px] mt-[6px] mb-0 text-black font-poppins">
                    {social?.name}
                  </h3>
                </button>
              ))}

            <div
              // className="pt-[36px] pb-[43px] flex justify-center absolute bottom-0 w-full min-h-[48px]"
              className={`pt-[36px] pb-[43px] flex justify-center absolute ${
                seeMoreSocials ? "bottom-[-115px]" : "bottom-[-45px]"
              } w-full min-h-[48px]`}
              style={{
                background: seeMoreSocials
                  ? "transparent"
                  : "linear-gradient(180deg, rgba(255, 255, 255, 0.49) 0%, #FFFFFF 48.96%)",
              }}
            >
              <button
                type="button"
                onClick={() => setSeeMoreSocials(!seeMoreSocials)}
                className="min-w-[118px] h-[48px] bg-[#F4F4F4] rounded-[32px] py-[12px] px-[16px] text-[#000000]"
              >
                {seeMoreSocials ? "See Less" : "See All"}
                {seeMoreSocials ? (
                  <IoIosArrowUp className="inline-block ml-2" />
                ) : (
                  <IoIosArrowDown className="inline-block ml-2" />
                )}
              </button>
            </div>
          </div>
        </section>

        {userSocials.length > 0 && (
          <div
            className={`social-details-wrap ${
              seeMoreSocials ? "mt-[165px]" : "mt-[95px]"
            }`}
          >
            <h3 className="text-[#1F2937] font-bold text-[19px] mb-[23px]">
              Your Social Links
            </h3>
            {userSocials.map((social) => (
              <LinkBlock
                key={social.id}
                social={social}
                fetchUser={fetchUser}
                handleDelete={() => handleDeleteConfirmOpen(social)}
              />
            ))}
          </div>
        )}
        {/* <div className="flex justify-center mt-4">
          <button
            onClick={() => setOpenAddSocial(true)}
            type="button"
            className="bg-brand-primary w-[107px] h-[29px] rounded-full text-[14px] text-white"
          >
            Add Newsss
          </button>
        </div> */}
      </div>

      {open && selSocial && (
        <AddSocialLinkDialog
          handleSocialAddToUser={handleSocialAddToUser}
          handleClosePopup={handleCloseAddPopup}
          selSocial={selSocial}
          open={open}
        />
      )}

      {deleteConfirm && (
        <Confirmation
          title="Delete Social"
          open={deleteConfirm}
          onConfirm={() => handleDeleteSocial(selSocial.id)}
          onCancel={() => {
            setSelSocial(null);
            setDeleteConfirm(false);
          }}
        />
      )}
    </>
  );
}

SocialBox.propTypes = {
  fetchUser: PropTypes.func.isRequired,
};

export default SocialBox;
