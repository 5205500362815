import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";

// icons
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// hooks
import useAuth from "Hooks/useAuth";

// env
import { BASE_FRONTEND_URL } from "Helpers/Env";
import { getUser, setUserCardShare, updateLiveStatus } from "Api/User";

export default function ShareCard({ buttonType, children }) {
  const navigate = useNavigate();
  const { user, updateUser } = useAuth();
  const [goLiveLoading, setGoLiveLoading] = useState(false);

  const handleQRCodeDownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = `QRCode_${user?.username}`;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const fetchUser = useCallback(() => {
    getUser()
      .then((res) => updateUser(res.data.user))
      .catch((err) => console.error(err));
  }, [updateUser]);

  const handleGoLive = () => {
    if (!user?.subscription_status) {
      toast.warning("You haven't any subscription. Please subscribe first!");
      navigate("/packages");
    } else {
      setGoLiveLoading(true);
      updateLiveStatus(!user.go_live_status ? 1 : 0)
        .then((res) => {
          if (res) {
            fetchUser();
            toast.success("Successfully updated you go live status");
            setGoLiveLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message || "Something went wrong!");
          setGoLiveLoading(false);
        });
    }
  };

  return (
    <>
      {/* button */}
      {/* <label
        htmlFor="shareWithQR"
        className="cursor-pointer hidden md:block px-4 bg-primary hover:bg-purple-primary py-2 rounded text-md absolute right-5 text-white text-sm mt-0.5"
      >
        Share My Links <i className="ml-1 fas fa-share"></i>
      </label> */}
      {children}

      {/* hidden checkbox */}
      <input type="checkbox" id="shareWithQR" className="modal-toggle" />

      {/* modal */}
      <label htmlFor="shareWithQR" className="cursor-pointer modal">
        <label className="relative modal-box" htmlFor="">
          <label
            htmlFor="shareWithQR"
            className="absolute cursor-pointer right-5 top-3"
          >
            <FontAwesomeIcon icon={faClose} />
          </label>
          <h2 className="font-semibold tracking-tight text-center">
            Share & QR Code
          </h2>
          <p className="mt-2 text-xs leading-5 text-center text-gray-500">
            Here is your unique card QR code that will direct people to your
            Links when scanned.
          </p>
          <span className="flex justify-center mt-4 text-center">
            <QRCode id="QRCode" value={BASE_FRONTEND_URL + user?.username} />
          </span>

          <div className="text-center">
            <p className="mt-2 overflow-hidden text-dark">@{user?.username}</p>
            <div className="flex justify-center mt-6 space-x-2">
              <CopyToClipboard
                text={BASE_FRONTEND_URL + user?.username}
                onCopy={() => {
                  toast.success("Copied Successfully!");
                  setUserCardShare(user?.username);
                }}
              >
                <button className="px-6 py-1 capitalize bg-transparent border rounded-full shadow-md btn btn-ghost border-brand-primary hover:bg-purple-primary hover:text-white">
                  Copy Link
                </button>
              </CopyToClipboard>

              <button
                onClick={handleQRCodeDownload}
                className="px-6 py-1 capitalize bg-transparent border rounded-full shadow-md btn btn-ghost border-brand-primary hover:bg-purple-primary hover:text-white"
              >
                Download
              </button>
            </div>
            <Button
              disabled={goLiveLoading}
              onClick={handleGoLive}
              className="w-full px-6 py-1 mt-4 text-white capitalize border rounded-full shadow-md bg-brand-primary btn btn-ghost hover:bg-purple-primary hover:text-white"
            >
              {goLiveLoading ? (
                "Loading..."
              ) : (
                <>
                  {user?.go_live_status ? "Go Offline" : "Go Live"}
                  <i
                    className={`fa-solid fa-circle ml-2 ${
                      user?.go_live_status === true
                        ? "text-success"
                        : "text-red-600"
                    }`}
                  />
                </>
              )}
            </Button>
          </div>
        </label>
      </label>
    </>
  );
}
