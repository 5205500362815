import { useLocation } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { getSocialIcon } from "Helpers/GetSocialIcon";
import { makeLink, makeSocialLink } from "utils";

// images
import avatar from "Images/Avatar/card-avatar/new-card-6-avatar.png";
import logo from "Images/default_logo.png";
import phoneIcon from "icons/phone-icon.svg";
import textIcon from "icons/text-icon.svg";
import mailIcon from "icons/mail-icon.svg";
import websiteIcon from "icons/website-icon.svg";
import scheduleIcon from "icons/schedule-icon.svg";
// import saveIcon from "icons/save-icon.svg";
// import shareIcon from "icons/share-icon.svg";
// import inIcon from "icons/in-icon.svg";

const CardNew7 = ({ user, cardBg, className, isSpaceZero }) => {
  const location = useLocation();

  const icons = [
    {
      icon: phoneIcon,
      text: "Mobile",
      url: `tel:${user?.user_phone[0]?.phone || ""}`,
      isDynamic: false,
    },
    {
      icon: textIcon,
      text: "Text",
      url: `sms:${user?.user_phone[0]?.phone || ""}`,
      isDynamic: false,
    },
    {
      icon: mailIcon,
      text: "Mail",
      url: `mailto:${user?.user_email[0]?.email || ""}`,
      isDynamic: false,
    },
    {
      icon: websiteIcon,
      text: "Website",
      url: makeLink(user?.user_details?.website || ""),
      isDynamic: false,
    },
    {
      icon: scheduleIcon,
      text: "Schedule",
      url: makeLink(user?.user_details?.schedule || ""),
      isDynamic: false,
    },
    ...(user?.socials
      ?.filter((social) => social?.active_status === 1)
      ?.map((social) => ({
        icon: getSocialIcon(social?.social?.name, "h-16 w-16 rounded-full"),
        text: social?.social?.name,
        url: makeSocialLink(social?.social?.url, social?.url),
        isDynamic: true,
      })) || []),
  ];

  return (
    <PerfectScrollbar className={`w-full h-[667px] bg-white ${className}`}>
      <div
        className={`relative overflow-auto px-[10px] ${
          isSpaceZero ? "py-0" : "py-[30px]"
        }`}
        style={{
          pointerEvents:
            location.pathname !== "/my-card/all-card" ? "auto" : "none",
        }}
      >
        <main
          className={`relative flex flex-col items-start ${
            isSpaceZero ? "" : "mt-7"
          }`}
        >
          <div className="w-full">
            {/* new design */}
            <div className="w-full">
              {/* <p className="max-w-[226px] mb-5 text-xl font-normal text-black font-raleway">
                Nice meeting you, let’s stay in touch!
              </p> */}

              <section className="w-full min-h-[146px] [background:linear-gradient(180deg,rgba(71,159,232,0.79)_9.12%,rgb(219.94,206.87,189.7)_41.92%,rgb(217,217,217)_79.12%,rgba(217,217,217,0.65)_100%)] rounded-[25px] flex items-center justify-between shadow-card-info px-5">
                {/* profile photo */}
                <img
                  src={user?.profile?.url || avatar}
                  alt={user?.username || "avatar"}
                  className="w-[90px] h-[90px] rounded-full shadow-xl"
                />

                {/* user info */}
                <div className="flex flex-col items-center justify-center">
                  <img
                    src={user?.logo?.url || logo}
                    alt={user?.username || "logo"}
                    className="w-[55px] h-[55px] rounded-full shadow-xl"
                  />
                  <h2 className="text-black text-[20px] font-normal font-raleway">
                    {user?.first_name || user?.last_name
                      ? `${user?.first_name} ${user?.last_name}`
                      : "fname lname"}
                  </h2>
                  <h5 className="text-black text-[10px] font-normal font-raleway">
                    {user?.user_details?.designation || "designation"}
                  </h5>
                  <h5 className="h-2 text-black text-[10px] font-normal font-raleway">
                    {user?.user_details?.company || "company"}
                  </h5>
                </div>
              </section>
            </div>

            {/* social icons */}
            <section className="mt-[33px]">
              <div className="grid grid-cols-3 items-center gap-y-[22px]">
                {icons?.map((icon) => (
                  <div
                    key={icon.text}
                    className="flex items-center justify-center w-full rounded-full"
                  >
                    <a
                      href={icon.url}
                      target="_blank"
                      rel="noreferrer"
                      className="flex flex-col items-center justify-center w-16 gap-1"
                    >
                      {icon.isDynamic ? (
                        icon.icon
                      ) : (
                        <div className="flex items-center justify-center w-16 h-16 rounded-full bg-social-icon">
                          <img
                            className="w-6 h-6"
                            alt="phoneIcon"
                            src={icon.icon}
                          />
                        </div>
                      )}
                      <p className="font-raleway font-normal text-[#808080] text-[15px] tracking-[0] leading-[normal] whitespace-nowrap">
                        {icon.text}
                      </p>
                    </a>
                  </div>
                ))}
              </div>
            </section>

            {/* <section className="flex justify-between items-center mt-[22px]">
              <button className="w-[148px] h-[54px] bg-light-gray rounded-[20px] flex justify-center items-center gap-2 font-raleway font-normal text-[#4d4d4d] text-[15px] tracking-[0] leading-[normal] shadow-card-button">
                Save
                <img src={saveIcon} alt="save" />
              </button>

              <button className="w-[148px] h-[54px] bg-light-gray rounded-[20px] flex justify-center items-center gap-2 font-raleway font-normal text-[#4d4d4d] text-[15px] tracking-[0] leading-[normal] shadow-card-button">
                Share
                <img src={shareIcon} alt="share" />
              </button>
            </section> */}

            {/* <button className="w-full h-[54px] bg-light-gray rounded-[20px] flex justify-center items-center gap-2 font-raleway font-normal text-[#4d4d4d] text-[15px] tracking-[0] leading-[normal] shadow-card-button mt-4">
              Get Your Own Business Card
              <img src={inIcon} alt="in" />
            </button> */}
          </div>
        </main>
      </div>
    </PerfectScrollbar>
  );
};

CardNew7.propTypes = {
  user: PropTypes.object.isRequired,
  cardBg: PropTypes.string,
  className: PropTypes.string,
  isSpaceZero: PropTypes.bool,
};

export default CardNew7;
