const getContactDetailsIcon = (name) => {
  if (name === "email") {
    return (
      <svg
        width="25"
        height="21"
        viewBox="0 0 25 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.4134 0.0153809H3.13925C1.81415 0.0153809 0.742027 1.14472 0.742027 2.52503L0.72998 17.5829C0.72998 18.9633 1.81415 20.0926 3.13925 20.0926H22.4134C23.7385 20.0926 24.8226 18.9633 24.8226 17.5829V2.52503C24.8226 1.14472 23.7385 0.0153809 22.4134 0.0153809ZM22.4134 5.03468L12.7763 11.3088L3.13925 5.03468V2.52503L12.7763 8.79916L22.4134 2.52503V5.03468Z"
          fill="#EA4C89"
        />
      </svg>
    );
  } else if (name === "phone") {
    return (
      <svg
        width="23"
        height="24"
        viewBox="0 0 23 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.4419 23.2098C16.9876 22.9713 14.6485 22.1916 12.4246 20.8708C10.1999 19.5507 8.27616 17.9042 6.6534 15.9312C5.02979 13.9589 3.78447 11.7541 2.91742 9.3168C2.04953 6.88021 1.73484 4.43476 1.97336 1.98047C2.00664 1.63801 2.14853 1.36372 2.39902 1.1576C2.64952 0.951482 2.94599 0.865064 3.28845 0.898345L7.91165 1.34764C8.17801 1.37353 8.40751 1.48226 8.60015 1.67382C8.79279 1.86539 8.89519 2.0962 8.90736 2.36626L9.26107 6.43373C9.27324 6.70378 9.24512 6.94572 9.17671 7.15955C9.10746 7.37407 8.9789 7.55862 8.79103 7.71321L5.77956 10.2437C6.44551 11.6912 7.31954 13.0725 8.40167 14.3876C9.48379 15.7027 10.7053 16.8681 12.0662 17.8838L15.0095 15.4619C15.1974 15.3073 15.4336 15.2004 15.7182 15.1413C16.002 15.0828 16.2752 15.0855 16.5379 15.1495L20.3985 16.3313C20.6593 16.4143 20.8656 16.5637 21.0175 16.7798C21.1692 16.9966 21.2322 17.2381 21.2063 17.5045L20.757 22.1277C20.7237 22.4702 20.5818 22.7444 20.3314 22.9506C20.0809 23.1567 19.7844 23.2431 19.4419 23.2098Z"
          fill="#EA4C89"
        />
      </svg>
    );
  } else if (name === "message") {
    return (
      <svg
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.0257 7.05408H8.55904C8.2557 7.05408 7.96404 7.06574 7.68404 7.10074C4.5457 7.36908 2.9707 9.22408 2.9707 12.6424V17.3091C2.9707 21.9757 4.83737 22.8974 8.55904 22.8974H9.0257C9.28237 22.8974 9.6207 23.0724 9.77237 23.2707L11.1724 25.1374C11.7907 25.9657 12.794 25.9657 13.4124 25.1374L14.8124 23.2707C14.9874 23.0374 15.2674 22.8974 15.559 22.8974H16.0257C19.444 22.8974 21.299 21.3341 21.5674 18.1841C21.6024 17.9041 21.614 17.6124 21.614 17.3091V12.6424C21.614 8.92074 19.7474 7.05408 16.0257 7.05408ZM8.2207 16.3874C7.56737 16.3874 7.05404 15.8624 7.05404 15.2207C7.05404 14.5791 7.57904 14.0541 8.2207 14.0541C8.86237 14.0541 9.38737 14.5791 9.38737 15.2207C9.38737 15.8624 8.86237 16.3874 8.2207 16.3874ZM12.2924 16.3874C11.639 16.3874 11.1257 15.8624 11.1257 15.2207C11.1257 14.5791 11.6507 14.0541 12.2924 14.0541C12.934 14.0541 13.459 14.5791 13.459 15.2207C13.459 15.8624 12.9457 16.3874 12.2924 16.3874ZM16.3757 16.3874C15.7224 16.3874 15.209 15.8624 15.209 15.2207C15.209 14.5791 15.734 14.0541 16.3757 14.0541C17.0174 14.0541 17.5424 14.5791 17.5424 15.2207C17.5424 15.8624 17.0174 16.3874 16.3757 16.3874Z"
          fill="#ED6298"
        />
        <path
          d="M26.2805 7.97578V12.6425C26.2805 14.9758 25.5572 16.5625 24.1105 17.4375C23.7605 17.6475 23.3522 17.3675 23.3522 16.9591L23.3638 12.6425C23.3638 7.97578 20.6922 5.30412 16.0255 5.30412L8.9205 5.31578C8.51217 5.31578 8.23217 4.90745 8.44217 4.55745C9.31717 3.11078 10.9038 2.38745 13.2255 2.38745H20.6922C24.4138 2.38745 26.2805 4.25412 26.2805 7.97578Z"
          fill="#ED6298"
        />
      </svg>
    );
  } else if (name === "website") {
    return (
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.9357 14.4633C18.0321 13.6682 18.1044 12.8732 18.1044 12.054C18.1044 11.2349 18.0321 10.4398 17.9357 9.64475H22.0074C22.2001 10.4157 22.3206 11.2228 22.3206 12.054C22.3206 12.8852 22.2001 13.6923 22.0074 14.4633M15.8035 21.161C16.5263 19.8239 17.0804 18.3783 17.4659 16.8725H21.0196C19.8525 18.8822 18.0009 20.4045 15.8035 21.161ZM15.5024 14.4633H9.86469C9.74423 13.6682 9.67195 12.8732 9.67195 12.054C9.67195 11.2349 9.74423 10.4278 9.86469 9.64475H15.5024C15.6108 10.4278 15.6951 11.2349 15.6951 12.054C15.6951 12.8732 15.6108 13.6682 15.5024 14.4633ZM12.6835 21.6429C11.6837 20.1973 10.8766 18.5952 10.3827 16.8725H14.9844C14.4905 18.5952 13.6834 20.1973 12.6835 21.6429ZM7.865 7.23549H4.34748C5.50254 5.2204 7.35279 3.69566 9.55149 2.94699C8.82871 4.28414 8.28662 5.7297 7.865 7.23549ZM4.34748 16.8725H7.865C8.28662 18.3783 8.82871 19.8239 9.55149 21.161C7.3574 20.4041 5.50976 18.8815 4.34748 16.8725ZM3.35968 14.4633C3.16694 13.6923 3.04647 12.8852 3.04647 12.054C3.04647 11.2228 3.16694 10.4157 3.35968 9.64475H7.43134C7.33497 10.4398 7.26269 11.2349 7.26269 12.054C7.26269 12.8732 7.33497 13.6682 7.43134 14.4633M12.6835 2.4531C13.6834 3.89865 14.4905 5.51286 14.9844 7.23549H10.3827C10.8766 5.51286 11.6837 3.89865 12.6835 2.4531ZM21.0196 7.23549H17.4659C17.0889 5.7435 16.5306 4.30329 15.8035 2.94699C18.0201 3.70591 19.8631 5.2358 21.0196 7.23549ZM12.6835 0.00769043C6.02192 0.00769043 0.637207 5.42854 0.637207 12.054C0.637207 15.2489 1.90637 18.3129 4.16549 20.5721C5.2841 21.6907 6.61208 22.578 8.0736 23.1834C9.53513 23.7888 11.1016 24.1003 12.6835 24.1003C15.8784 24.1003 18.9424 22.8312 21.2016 20.5721C23.4607 18.3129 24.7299 15.2489 24.7299 12.054C24.7299 10.4721 24.4183 8.90562 23.8129 7.44409C23.2075 5.98256 22.3202 4.65458 21.2016 3.53598C20.083 2.41737 18.755 1.53005 17.2935 0.924663C15.8319 0.319278 14.2655 0.00769043 12.6835 0.00769043Z"
          fill="#EA4C89"
        />
      </svg>
    );
  } else if (name === "website") {
    return (
      <svg
        width="23"
        height="29"
        viewBox="0 0 23 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.8652 14.054C12.6244 14.054 13.2745 13.7786 13.8156 13.2277C14.3558 12.6777 14.6258 12.0162 14.6258 11.2432C14.6258 10.4703 14.3558 9.80832 13.8156 9.2574C13.2745 8.70742 12.6244 8.43243 11.8652 8.43243C11.1061 8.43243 10.4564 8.70742 9.91623 9.2574C9.37514 9.80832 9.1046 10.4703 9.1046 11.2432C9.1046 12.0162 9.37514 12.6777 9.91623 13.2277C10.4564 13.7786 11.1061 14.054 11.8652 14.054ZM11.8652 28.1081C8.16139 24.8991 5.39526 21.9182 3.56681 19.1655C1.73744 16.4137 0.822754 13.8667 0.822754 11.5243C0.822754 8.01081 1.93298 5.21171 4.15344 3.12703C6.37297 1.04234 8.94357 0 11.8652 0C14.7869 0 17.3575 1.04234 19.577 3.12703C21.7975 5.21171 22.9077 8.01081 22.9077 11.5243C22.9077 13.8667 21.9935 16.4137 20.165 19.1655C18.3356 21.9182 15.569 24.8991 11.8652 28.1081Z"
          fill="#EA4C89"
        />
      </svg>
    );
  }
};

export default getContactDetailsIcon;
