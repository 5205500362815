import { useLocation } from "react-router-dom";

export const getLocalAuthData = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

export const urlPramsToObj = (history) => {
  const search = history.location.search.substring(1);
  return JSON.parse(
    '{"' +
      decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
};

export const objToUrlPrams = (obj) => {
  const str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

export const arrayToUrlParams = (key, arr) => {
  const str = [];
  arr.map((item) => str.push(`${key}=${item}`));
  if (arr.length > 0) {
    return "&".concat(str.join("&"));
  } else {
    return str.join("&");
  }
};

export const generateUserName = (lastName) => {
  let lowerCaseFirstName = lastName.toLowerCase();
  return `${lowerCaseFirstName}S@92`;
};
