import React, { useState } from "react";
import { Switch } from "@headlessui/react";
import { userSocialEdit } from "../../../Api/Socials";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { getSocialAddIcon } from "Helpers/GetSocialIcon";

const LinkBlock = ({ social, handleDelete, fetchUser }) => {
  const [enabled, setEnabled] = useState(parseInt(social.active_status) === 1);
  const [socialURL, setSocialURL] = useState(social?.url);

  const handleURLUpdate = () => {
    if (social.url !== socialURL) {
      userSocialEdit(social.id, {
        social_id: social.social_id,
        social_url: socialURL,
        active_status: social.active_status,
      })
        .then((res) => {
          if (res) {
            toast.success("URL Update Successfully");
            fetchUser();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleStatusUpdate = (status) => {
    userSocialEdit(social.id, {
      social_url: social.url,
      active_status: status ? 1 : 0,
      social_id: social.social_id,
    })
      .then((res) => {
        if (res) {
          toast.success("Status Update Successfully");
          fetchUser();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="single-social-link">
      <div className="flex items-center">
        <div className="border border-[#D9D9D9] rounded flex h-[50px] items-center w-full">
          <div className="pr-5 pl-4">
            {getSocialAddIcon(social?.social?.name)}
          </div>
          <div className="w-full pl-4 border-l border-[#D9D9D9]">
            <input
              name="url"
              defaultValue={social?.url}
              onChange={(e) => setSocialURL(e.target.value)}
              onBlur={handleURLUpdate}
              placeholder="username"
              className="text-gray-900 text-sm block w-full h-[46px] dark:placeholder-gray-400 custom-input"
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end mt-2">
        <h3 className="mr-3 text-[#727272] text-[12px] font-[500]">
          Turn {enabled ? "On" : "Off"}
        </h3>
        <div className="mr-3">
          <Switch
            checked={enabled}
            onChange={async (e) => {
              await setEnabled(e);
              handleStatusUpdate(e);
            }}
            className={`${
              enabled ? "bg-primary" : "bg-gray-200"
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">Enable Link</span>
            <span
              className={`${
                enabled ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white`}
            />
          </Switch>
        </div>
        <div className="">
          <button type="button" onClick={handleDelete}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 6.72998C20.98 6.72998 20.95 6.72998 20.92 6.72998C15.63 6.19998 10.35 5.99998 5.11998 6.52998L3.07998 6.72998C2.65998 6.76998 2.28998 6.46998 2.24998 6.04998C2.20998 5.62998 2.50998 5.26998 2.91998 5.22998L4.95998 5.02998C10.28 4.48998 15.67 4.69998 21.07 5.22998C21.48 5.26998 21.78 5.63998 21.74 6.04998C21.71 6.43998 21.38 6.72998 21 6.72998Z"
                fill="#EB5757"
              />
              <path
                d="M8.50001 5.72C8.46001 5.72 8.42001 5.72 8.37001 5.71C7.97001 5.64 7.69001 5.25 7.76001 4.85L7.98001 3.54C8.14001 2.58 8.36001 1.25 10.69 1.25H13.31C15.65 1.25 15.87 2.63 16.02 3.55L16.24 4.85C16.31 5.26 16.03 5.65 15.63 5.71C15.22 5.78 14.83 5.5 14.77 5.1L14.55 3.8C14.41 2.93 14.38 2.76 13.32 2.76H10.7C9.64001 2.76 9.62001 2.9 9.47001 3.79L9.24001 5.09C9.18001 5.46 8.86001 5.72 8.50001 5.72Z"
                fill="#EB5757"
              />
              <path
                d="M15.21 22.75H8.79002C5.30002 22.75 5.16002 20.82 5.05002 19.26L4.40002 9.19001C4.37002 8.78001 4.69002 8.42001 5.10002 8.39001C5.52002 8.37001 5.87002 8.68001 5.90002 9.09001L6.55002 19.16C6.66002 20.68 6.70002 21.25 8.79002 21.25H15.21C17.31 21.25 17.35 20.68 17.45 19.16L18.1 9.09001C18.13 8.68001 18.49 8.37001 18.9 8.39001C19.31 8.42001 19.63 8.77001 19.6 9.19001L18.95 19.26C18.84 20.82 18.7 22.75 15.21 22.75Z"
                fill="#EB5757"
              />
              <path
                d="M13.66 17.25H10.33C9.92002 17.25 9.58002 16.91 9.58002 16.5C9.58002 16.09 9.92002 15.75 10.33 15.75H13.66C14.07 15.75 14.41 16.09 14.41 16.5C14.41 16.91 14.07 17.25 13.66 17.25Z"
                fill="#EB5757"
              />
              <path
                d="M14.5 13.25H9.5C9.09 13.25 8.75 12.91 8.75 12.5C8.75 12.09 9.09 11.75 9.5 11.75H14.5C14.91 11.75 15.25 12.09 15.25 12.5C15.25 12.91 14.91 13.25 14.5 13.25Z"
                fill="#EB5757"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

LinkBlock.propTypes = {
  social: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
};

export default LinkBlock;
