const PhoneIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={512}
    height={512}
    viewBox="0 0 32 32"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M30.035 22.6c-.082-.065-6.035-4.356-7.669-4.048-.78.138-1.226.67-2.121 1.735-.144.172-.49.584-.759.877a12.517 12.517 0 0 1-1.651-.672 13.7 13.7 0 0 1-6.321-6.321 12.458 12.458 0 0 1-.672-1.651c.294-.27.706-.616.882-.764 1.06-.89 1.593-1.336 1.731-2.118.283-1.62-4.005-7.614-4.05-7.668A2.289 2.289 0 0 0 7.7 1C5.962 1 1 7.437 1 8.521c0 .063.091 6.467 7.988 14.5C17.013 30.909 23.416 31 23.479 31 24.564 31 31 26.038 31 24.3a2.283 2.283 0 0 0-.965-1.7z"
      fill="#6a6087"
      data-original="#000000"
    />
  </svg>
);

export default PhoneIcon;
