import { Button, FormControl, FormText } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Dialog } from "@mui/material";
import Spinner from "../Spinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { editSubTaskStatus } from "Api/Task";
import { toast } from "react-toastify";

function TaskTabs({
  contact,
  handleAssignTask,
  handleAssignSubTaskToContactTask,
  subTaskModal,
  handleCloseSubTaskModal,
  handleOpenSubTaskModal,
}) {
  return (
    <>
      <div className="tab-content">
        <div>
          <Formik
            initialValues={{ title: "" }}
            onSubmit={handleAssignTask}
            validationSchema={yup
              .object()
              .shape({ title: yup.string().required("Title is Required!") })}
          >
            {({
              handleSubmit,
              handleChange,
              errors,
              touched,
              isSubmitting,
              values,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="relative text-left">
                  <FormControl
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    placeholder="Create Task"
                    isInvalid={Boolean(errors.title && touched.title)}
                    className="input w-full bg-gray-200 focus:outline-0 py-2 !pl-2 !pr-[65px]"
                  />

                  {Boolean(errors.title) && (
                    <FormText className="text-red-600">{errors.title}</FormText>
                  )}

                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className="absolute top-0 right-0 border-none bg-primary"
                  >
                    {isSubmitting ? (
                      <Spinner />
                    ) : (
                      <FontAwesomeIcon icon={faAdd} className="text-2xl" />
                    )}
                  </Button>
                </div>
              </form>
            )}
          </Formik>

          <div className="mt-6 contact-task-accordion">
            <h3 className="mb-2 font-medium text-primary">
              {contact?.task_assigns?.length > 0
                ? "Created Tasks"
                : "No Create Tasks"}
            </h3>
            {contact?.task_assigns?.map((task) => (
              <Accordion key={task?.id} className="">
                <AccordionSummary id={task.id}>
                  <div className="flex items-center justify-between w-full">
                    <span>{task?.task?.title}</span>
                    <div>
                      <FontAwesomeIcon icon={faChevronDown} />
                      <FontAwesomeIcon icon={faChevronUp} />
                    </div>
                  </div>
                </AccordionSummary>

                <AccordionDetails className="text-left">
                  <hr className="mb-4 mt-1.5 border-gray-300 w-full"></hr>
                  {task?.task?.deadline && (
                    <>
                      <h2 className="timelineTask">
                        <span className>{task?.task?.deadline}</span>
                      </h2>
                      <hr className="w-full my-4 border-gray-300"></hr>
                    </>
                  )}
                  <div className="mt-6 mb-4 space-y-4">
                    {task?.task?.sub_tasks?.length !== 0 ? (
                      task?.task?.sub_tasks?.map((subTask, index) => {
                        return (
                          <label
                            className={`text-left flex cursor-pointer items-center ${
                              subTask?.status === 1 ? "text-line-through" : ""
                            }`}
                            key={index}
                          >
                            <input
                              onChange={async (event) => {
                                let formData = new FormData();
                                formData.append("_method", "put");
                                if (event.target.checked === true) {
                                  event.target.parentNode.classList.add(
                                    "text-line-through"
                                  );
                                  formData.append(`status`, 2);
                                } else {
                                  event.target.parentNode.classList.remove(
                                    "text-line-through"
                                  );
                                  formData.append(`status`, 1);
                                }
                                await editSubTaskStatus(formData, subTask?.id);
                                toast.success("Sub Task Status Updated");
                              }}
                              type="checkbox"
                              className="relative"
                              defaultChecked={subTask?.status === 1}
                            />
                            <div className="checkmark text-dark bg-dark ml-1 leading-[0]">
                              {subTask?.title}
                            </div>
                          </label>
                        );
                      })
                    ) : (
                      <h4 className="text-sm">No subtasks assigned</h4>
                    )}

                    <button
                      onClick={() => handleOpenSubTaskModal(task?.task)}
                      className="px-3 py-2 text-xs font-medium bg-white rounded"
                    >
                      + Add Sub Task
                    </button>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>

      {/* Sub Task Modal */}
      <Dialog
        PaperProps={{ sx: { width: 400 } }}
        open={subTaskModal}
        onClose={handleCloseSubTaskModal}
      >
        <div className="absolute top-3 right-5">
          <button onClick={handleCloseSubTaskModal}>
            <i className="cursor-pointer fa-solid fa-xmark"></i>
          </button>
        </div>

        <div className="px-3 md:px-6 py-7">
          <div className="mb-4 modal-content">
            <h4 className="text-lg">Add Subtask</h4>
          </div>

          <div className="mt-5">
            <Formik
              initialValues={{ title: "" }}
              onSubmit={handleAssignSubTaskToContactTask}
              validationSchema={yup
                .object()
                .shape({ title: yup.string().required("Title is Required!") })}
            >
              {({
                handleChange,
                handleSubmit,
                values,
                errors,
                touched,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="mb-4 text-left">
                    <FormControl
                      name="title"
                      className="input w-full bg-gray-100 py-2 !px-2"
                      placeholder="Write here your subtask"
                      onChange={handleChange}
                      isInvalid={Boolean(errors.title && touched.title)}
                      value={values.title}
                    />

                    {Boolean(errors.title) && (
                      <FormText className="text-red-600">
                        {errors.title}
                      </FormText>
                    )}
                  </div>

                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full px-4 py-1 mt-1 font-semibold text-white rounded-full btn bg-primary hover:bg-purple-primary border-1"
                  >
                    {isSubmitting ? "Creating..." : "Create Sub-Task"}
                  </Button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default TaskTabs;
