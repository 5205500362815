import { useRef, useState } from "react";
import { toast } from "react-toastify";
import useAuth from "../../Hooks/useAuth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoIosLogOut } from "react-icons/io";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, user, signOut } = useAuth();
  const [logoutLoading, setLogoutLoading] = useState(false);

  const handleShow = () => {
    const oldStyle = document.getElementById("isbody").className;
    document.getElementById("isbody").className =
      oldStyle === "collapse-sidebar" ? "" : "collapse-sidebar";
  };

  const handleLogout = async () => {
    setLogoutLoading(true);
    try {
      await signOut();
      setLogoutLoading(false);
      navigate("/");
    } catch (err) {
      console.log(err);
      setLogoutLoading(false);
      toast.error(err.response.data.message);
    }
  };

  const backDropRef = useRef();
  const triggerFileSelectPopup = () => backDropRef.current.click();

  const isNavDropdownHidden = location.pathname === "/dashboard";

  return (
    <div>
      <div
        onClick={triggerFileSelectPopup}
        className="modal-backdrop fade"
      ></div>

      <div className="bg-white border-b navbar">
        <div className="navbar-start md:hidden">
          <div className="dropdown">
            <button
              className="btn btn-ghost btn-circle"
              onClick={handleShow}
              ref={backDropRef}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex-1" />

        {isNavDropdownHidden ? (
          <div className="flex-none pr-2">
            {isAuthenticated && (
              <button
                onClick={handleLogout}
                className="active:bg-transparent active:text-inherit"
              >
                {logoutLoading ? "Logging out..." : <IoIosLogOut />}
              </button>
            )}
          </div>
        ) : (
          <div className="flex-none">
            <div className="dropdown">
              <div className="dropdown dropdown-end">
                <label tabIndex="0" className="btn btn-ghost btn-circle avatar">
                  <div className="w-10 rounded-full">
                    <img
                      alt={user?.first_name}
                      src={
                        user?.profile
                          ? user?.profile?.url
                          : require("../../Images/default_user.png")
                      }
                    />
                  </div>
                </label>
                <ul
                  tabIndex="0"
                  className="p-2 mt-3 rounded shadow menu menu-compact dropdown-content bg-base-100 w-52"
                >
                  <li>
                    <Link
                      to={`/dashboard`}
                      className="justify-between active:bg-transparent active:text-inherit"
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/my-card`}
                      className="justify-between active:bg-transparent active:text-inherit"
                    >
                      Profile
                      {/*<span className="badge">New</span>*/}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/analytics"
                      className="active:bg-transparent active:text-inherit"
                    >
                      Analytics
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/setting"
                      className="active:bg-transparent active:text-inherit"
                    >
                      Settings
                    </Link>
                  </li>
                  {isAuthenticated && (
                    <li>
                      <button
                        onClick={handleLogout}
                        className="active:bg-transparent active:text-inherit"
                      >
                        {logoutLoading ? "Loading..." : "Logout"}
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
