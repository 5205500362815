import { faCheck, faClose, faCubes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getPackages, makePayment, preparePayment } from "../../Api/Packages";
import Breadcrumbs from "../../Components/Breadcrumbs";
import Spinner from "../../Components/Spinner/Spinner";
import StripePayment from "../../Components/Stripe";
import useAuth from "../../Hooks/useAuth";
import CouponForm from "./components/CouponForm";
// import { Button } from "react-bootstrap";

const breadcrumbItems = [
  { label: "Packages", to: "/packages", icon: faCubes, isActive: true },
];

const Packages = () => {
  const { user, updateUser, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [selPackage, setSelPackage] = useState(null);
  const [payLoading, setPayLoading] = useState(false);
  const [couponPrice, setCouponPrice] = useState(0);
  const [couponText, setCouponText] = useState("");

  const handleMakePayment = (stripe_token) => {
    setPayLoading(true);
    preparePayment(selPackage.id).then((res) => {
      if (res) {
        makePayment(selPackage.id, res.data.data.payment_data.id, stripe_token)
          .then((response) => {
            updateUser({ ...user, subscription_status: true });
            toast.success(response.data.message);
            setPayLoading(false);
            handlePaymentPopupClose();
            navigate("/my-card");
          })
          .catch((err) => {
            setPayLoading(false);
            console.log("handleMakePayment", err);
            toast.error(err.response.data.message);
          });
      }
    });
  };

  const handlePaymentPopupClose = () => {
    setPaymentPopup(false);
    setPayLoading(false);
    setSelPackage(null);
  };

  const fetchPackages = useCallback(() => {
    getPackages()
      .then((res) => {
        if (res) {
          const packs = res.data.data.packages;
          const updatedPacks = packs.map((pack) => {
            return {
              ...pack,
              is_activated: Boolean(
                pack.id === user?.subscription?.package_details?.package.id
              ),
            };
          });
          setPackages(updatedPacks);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("fetchPackages", err);
        setLoading(false);
      });
  }, [user?.subscription?.package_details?.package.id]);

  useEffect(() => {
    fetchPackages();
  }, [fetchPackages]);

  return (
    <div>
      <div className="relative pb-4 mb-6">
        <div className="rounded">
          <Breadcrumbs items={breadcrumbItems} />

          <div className="px-4 py-6">
            {isLoading ? (
              <div className="display-position-center">
                <Spinner />
              </div>
            ) : (
              <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-7">
                {packages.map((pack) => (
                  <div
                    key={pack.id}
                    className={`${
                      pack.is_activated
                        ? "bg-dark-indigo text-white"
                        : "bg-white"
                    } shadow rounded px-12 py-7`}
                  >
                    <span
                      className={`text-xs font-bold uppercase ${
                        pack.is_activated ? "text-white" : "text-[#666]"
                      }`}
                    >
                      {pack.name}
                    </span>
                    <div className="mb-4 font-bold mt-7">
                      <sup className="text-3xl">$</sup>
                      <span className="text-6xl">{pack.price}</span>
                      <sub className="text-sm">
                        / {`${pack.duration} ${pack.duration_type}s`}
                      </sub>
                    </div>
                    <p
                      className={`text-sm ${
                        pack.is_activated ? "text-white" : "text-[#666]"
                      }`}
                    >
                      {pack.details}
                    </p>

                    <ul className="leading-9 my-7">
                      {pack.features.map((feature) => (
                        <li key={feature.id} className="relative pl-6">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="absolute left-0 top-2.5"
                          />
                          {feature.name}
                        </li>
                      ))}
                    </ul>

                    <button
                      onClick={() => {
                        if (isAuthenticated) {
                          setPaymentPopup(true);
                          setSelPackage(pack);
                        } else {
                          navigate("/login");
                        }
                      }}
                      className={`font-bold px-8 py-3 rounded ${
                        pack.is_activated
                          ? "bg-white text-dark-indigo pointer-events-none"
                          : "bg-brand-primary text-white "
                      }`}
                    >
                      {pack.is_activated ? "Subscribed" : "Subscribe"}
                    </button>

                    {/*<span className="text-[#666] text-sm block mt-4">*/}
                    {/*    No credit card required*/}
                    {/*</span>*/}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {paymentPopup && selPackage && (
        <Dialog fullWidth open={paymentPopup} onClose={handlePaymentPopupClose}>
          <DialogContent className="bg-[#f3f3f1] !py-12 transition-qliq">
            <div className="absolute w-6 h-6 top-5 right-5 closeBtn-with-bg">
              <button
                type="button"
                className="w-6 h-6"
                onClick={handlePaymentPopupClose}
              >
                <FontAwesomeIcon icon={faClose} />
              </button>
            </div>
            <div>
              <div>
                <h3 className="mb-3 text-lg">Plan Details</h3>
                <div className="px-6 py-4 bg-white rounded shadow-md">
                  <ul className="text-[#666] text-sm space-y-4">
                    <li className="flex items-center justify-between">
                      <span>Plan Name: </span>
                      <span>{selPackage.name}</span>
                    </li>
                    <li className="flex items-center justify-between">
                      <span>Price: </span>
                      <span>${selPackage.price}</span>
                    </li>
                    <li className="flex items-center justify-between">
                      <span>Fees: </span>
                      <span>$0.00</span>
                    </li>
                    <li className="flex items-center justify-between">
                      <span>Discount: </span>
                      <span>${couponPrice}</span>
                    </li>
                    <li className="flex items-center justify-between font-bold text-black">
                      <span>Grand Total: </span>
                      <span>
                        $
                        {selPackage.price < couponPrice
                          ? 0
                          : `${selPackage.price - couponPrice}`}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="mt-6">
                <div className="justify-between mb-3 sm:flex">
                  <h3 className="mb-3 text-lg sm:mb-0">Payment</h3>
                  <CouponForm
                    setCouponPrice={setCouponPrice}
                    setCouponText={setCouponText}
                    couponText={couponText}
                    totalPrice={selPackage?.price || 0}
                  />
                </div>
                <div className="px-6 py-4 bg-white rounded shadow-md">
                  <StripePayment
                    onMakePayment={handleMakePayment}
                    isLoading={payLoading}
                  />
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default Packages;
