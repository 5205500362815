// import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField } from "@mui/material";
import { updateTask } from "Api/Task";
import classNames from "classnames";
import Spinner from "Components/Spinner/Spinner";
import Tag from "Components/Tags/Tag";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styles from "../styles.module.css";
// import { MdDeleteOutline } from "react-icons/md";
// import { BiEdit } from "react-icons/bi";
import { Dialog, DialogContent } from "@mui/material";
import UpdateSubTaskModal from "./updateSubTaskModal";
import AddSubTaskModal from "./addSubTaskModal";
import { CHARACTERS_ALLOWED } from "Helpers/Env";
import { createTag, getContacts, getTags } from "Api/Contact";
import Dropdown from "Components/Dropdown/Dropdown";
// import { notify } from "index";
import createdIcon from "icons/calendar-icon.svg";
import deadlineIcon from "icons/timer-start.svg";
import shieldIcon from "icons/shield-tick.svg";

const TaskModal = ({
  previewTask,
  handleCheckbox,
  // tagArr,
  fetchTasks,
  setPreviewTask,
  onClosePreviewPopup,
  deleteSubTaskHandler,
  updateSubTaskHandler,
}) => {
  // const [selectedTagId, setSelectedTagId] = useState();
  // const [taskTagSpinner, setTaskTagSpinner] = useState(false);
  const [isTagSpinner, setIsTagSpinner] = useState(false);
  const [subTaskText, setSubTaskText] = useState("");
  const [tagArr, setTagArr] = useState([]);
  const [tag, setTag] = useState("");
  // const [selectedTagsId, setSelectedTagsId] = useState([]);
  const [showEditSubTaskModal, setShowEditSubTaskModal] = useState(false);
  const [singleSubTask, setSingleSubTask] = useState();
  const [singleSubTaskDate, setSingleSubTaskDate] = useState();
  const [showAddSubTaskModal, setShowAddSubTaskModal] = useState(false);
  const [isDate, setIsDate] = useState(null);
  const [showDeleteCardModal, setShowDeleteCardModal] = useState(false);
  const [selectSubTask, setSelectSubTask] = useState();
  const [contacts, setContacts] = useState([]);
  const [showAllTags, setShowAllTags] = useState(false);

  const fetchTags = () => {
    setIsTagSpinner(true);
    getTags()
      .then((tags) => {
        setTagArr(tags);
        setIsTagSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setIsTagSpinner(false);
      });
  };

  useEffect(() => {
    if (tagArr.length === 0) {
      fetchTags();
    }
  }, [tagArr.length]);

  useEffect(() => {
    getContacts()
      .then((res) => {
        setContacts(res?.data);
      })
      .catch(console.log);
  }, []);

  // useEffect(() => {
  //   setSelectedTagsId(previewTask?.task_tags.map((tag) => tag?.tag_id));
  // }, [previewTask?.task_tags]);

  const getTaskFormData = () => {
    let formData = new FormData();
    formData.append("_method", "put");
    formData.append("title", previewTask?.title);
    formData.append("status", previewTask?.status);
    if (previewTask.deadline)
      formData.append("deadline", previewTask?.deadline);
    // eslint-disable-next-line array-callback-return
    previewTask?.sub_tasks?.map((subTask, index) => {
      formData.append(`subtasks[${index}][id]`, subTask?.id);
      formData.append(`subtasks[${index}][title]`, subTask?.title);
      formData.append(`subtasks[${index}][status]`, subTask?.status);
      if (subTask?.deadline) {
        formData.append(`subtasks[${index}][deadline]`, subTask?.deadline);
      }
      // old subtask assign
      subTask?.sub_task_assigns?.forEach((subTaskAssign, _index) => {
        formData.append(
          `subtasks[${index}][assigns][${_index}][id]`,
          subTaskAssign?.id
        );
        formData.append(
          `subtasks[${index}][assigns][${_index}][sub_task_id]`,
          subTaskAssign?.sub_task_id
        );
        formData.append(
          `subtasks[${index}][assigns][${_index}][contact_id]`,
          subTaskAssign?.contact_id
        );
      });
    });
    // eslint-disable-next-line array-callback-return
    previewTask?.task_assigns?.map((contact, index) => {
      formData.append(
        `task_assigns[${index}][contact_id]`,
        contact?.contact_id
      );
    });
    // eslint-disable-next-line array-callback-return
    previewTask?.task_tags?.map((tag, index) => {
      formData.append(`task_tags[${index}][tag_id]`, tag?.tag_id);
      formData.append(`task_tags[${index}][id]`, tag?.id);
    });
    return formData;
  };

  const handleTaskTags = (tagId, index) => {
    let formData = getTaskFormData();
    if (index > -1) {
      formData.delete(`task_tags[${index}][tag_id]`);
      formData.delete(`task_tags[${index}][id]`);
    } else
      formData.append(
        `task_tags[${previewTask?.task_tags?.length}][tag_id]`,
        tagId
      );
    // for (const pair of formData.entries()) {
    //   console.log(`${pair[0]}, ${pair[1]}`);
    // }

    setIsTagSpinner(true);
    updateTask(formData, previewTask?.id)
      .then((res) => {
        // setSelectedTagId("");
        setIsTagSpinner(false);
        if (res?.task) {
          fetchTasks();
          setPreviewTask(res?.task);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsTagSpinner(true);
        toast.error(err.response.data.message);
      });
  };

  const handleUpdateSubTask = (e, assignContacts) => {
    e.preventDefault();
    let formData = getTaskFormData();
    formData.append(
      `subtasks[${previewTask?.sub_tasks?.length}][title]`,
      subTaskText
    );
    // formData.append(
    //   `subtasks[${previewTask?.sub_tasks?.length}][deadline]`,
    //   isDate.format("YYYY-MM-DD")
    // );

    // console.log("isDate", isDate.format("YYYY-MM-DD"));

    if (typeof isDate === "object") {
      formData.append(
        `subtasks[${previewTask?.sub_tasks?.length}][deadline]`,
        isDate.format("YYYY-MM-DD")
      );
    } else {
      formData.append(
        `subtasks[${previewTask?.sub_tasks?.length}][deadline]`,
        isDate
      );
    }

    // new subtask assign
    // Object.keys(e?.target)?.forEach((key, index) => {
    //   let { checked, value } = e.target[key];
    //   if (checked) {
    //     value = JSON.parse(value);
    //     formData.append(
    //       `subtasks[${previewTask?.sub_tasks?.length}][assigns][${index}][contact_id]`,
    //       value?.id
    //     );
    //   }
    // });

    // new subtask assign with new design
    assignContacts?.forEach((contact, index) => {
      formData.append(
        `subtasks[${previewTask?.sub_tasks?.length}][assigns][${index}][contact_id]`,
        contact?.id
      );
    });

    formData.append(`subtasks[${previewTask?.sub_tasks?.length}][status]`, 1);
    updateTask(formData, previewTask?.id)
      .then((res) => {
        if (res?.task) {
          setPreviewTask(res.task);
          fetchTasks();
          // toast.success(res?.message);
          toast.success("Sub Task Added Successfully");
          setIsDate(null);
          // notify("success", "Sub Task Added Successfully");

          setShowAddSubTaskModal(false);
        } else {
          toast.error(res?.message);
          // notify("error", res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        // notify("error", err.response.data.message);
      });
    setSubTaskText("");
  };

  const handleUpdateSubTaskModal = (data) => {
    setShowEditSubTaskModal(true);
    setSingleSubTask(data);
    setSingleSubTaskDate(data?.deadline);
    // console.log("Sub Task Data", data?.deadline);
  };

  const handleAddTags = async () => {
    if (tag.length > CHARACTERS_ALLOWED) {
      toast.error(
        `Tag Name cannot be greater than ${CHARACTERS_ALLOWED} characters`,
        { toastId: 1 }
      );
    } else {
      setIsTagSpinner(true);
      try {
        const res = await createTag({
          name: tag,
          color_id: 1,
          description: "test",
        });
        // setSelectedTagId(res?.tag?.id);
        if (res?.message.includes("Tag Already Exists")) {
          toast.error(res?.message);
          setTag("");
          setIsTagSpinner(false);
        } else {
          await handleTaskTags(res?.tag?.id);
          setTag("");
          fetchTags();
          toast.success("Task Create Successfully");
          setIsTagSpinner(false);
        }
      } catch (err) {
        console.log(err);
        setIsTagSpinner(false);
      }
    }
  };

  const handleUpdateTags = async () => {
    await handleAddTags();
  };

  const menuItems = [
    {
      icon: <i className="mr-1 fa-sharp fa-solid fa-edit" />,
      name: "Edit Sub Task",
      function: (subTask) => {
        handleUpdateSubTaskModal(subTask);
      },
    },
    {
      icon: <i className="mr-1 fa-sharp fa-solid fa-flag" />,
      name: "Update Sub Task Status",
      function: async (subTask) => {
        updateSubTaskHandler(subTask.title, {
          task_id: subTask.task_id,
          status: subTask?.status === 1 ? 2 : 1,
          id: subTask.id,
          assigns: subTask?.sub_task_assigns,
        });
      },
    },
    {
      icon: <i className="mr-1 fa-sharp fa-solid fa-trash" />,
      name: "Delete Sub Task",
      function: (subTask) => {
        setSelectSubTask(subTask);
        setShowDeleteCardModal(true);
      },
    },
  ];

  const handleDeleteSubTask = async () => {
    await deleteSubTaskHandler(selectSubTask);
    setShowDeleteCardModal(false);
  };

  const getContact = (_contact) => {
    return contacts.find((contact) => contact.id === _contact?.contact_id);
  };

  return (
    <div className={classNames("md:py-4", styles.taskModal)}>
      <div className="mb-[16px]">
        {/* title section */}
        <section className="flex items-center justify-between mb-[24px]">
          <h3 className="text-[20px] font-semibold leading-6 text-[#1F2937]">
            {previewTask?.title}
          </h3>

          <button onClick={onClosePreviewPopup}>✕</button>
        </section>

        {/* Task Assigned To section */}
        {/* {previewTask?.task_assigns?.length > 0 && (
          <section>
            <p
              className="font-medium text-sm leading-4 text-[#4F4F4F] mt-[16px]"
              style={{ marginBottom: "10px" }}
            >
              Task Assigned To
            </p>

            <div className="flex flex-wrap gap-[8px]">
              {previewTask?.task_assigns?.map((item, index) => (
                <div
                  className="flex items-center rounded-[23px] border border-[#EAE7E7] bg-white py-[4px] pl-[4px] pr-[8px]"
                  key={index}
                >
                  {getContact(item)?.file_manager?.url ? (
                    <img
                      className="w-6 h-6 rounded-full"
                      src={getContact(item)?.file_manager?.url}
                      alt="avatar"
                    />
                  ) : (
                    <div className="text-white bg-[#31C48D] w-6 h-6 text-[8px] font-medium rounded-full flex justify-center items-center">
                      {item?.contact?.name.slice(0, 2)}
                    </div>
                  )}
                  <p
                    className="text-sm font-medium leading-4 text-[#4F4F4F] ml-[8px]"
                    style={{ marginBottom: 0 }}
                  >
                    {item?.contact?.name}
                  </p>
                </div>
              ))}
            </div>
          </section>
        )} */}

        {/* Contact Linked section */}
        {/* {previewTask?.task_adds?.length > 0 && (
          <section>
            <p
              className="font-medium text-sm leading-4 text-[#4F4F4F] mt-[16px]"
              style={{ marginBottom: "10px" }}
            >
              Contact Linked
            </p>

            <div className="flex flex-wrap gap-[8px]">
              {previewTask?.task_adds?.map((item, index) => (
                <div
                  className="flex items-center rounded-[23px] border border-[#EAE7E7] bg-white py-[4px] pl-[4px] pr-[8px]"
                  key={index}
                >
                  {getContact(item)?.file_manager?.url ? (
                    <img
                      className="w-6 h-6 rounded-full"
                      src={getContact(item)?.file_manager?.url}
                      alt="avatar"
                    />
                  ) : (
                    <div className="text-white bg-[#31C48D] w-6 h-6 text-[8px] font-medium rounded-full flex justify-center items-center">
                      {item?.contact?.name.slice(0, 2)}
                    </div>
                  )}
                  <p
                    className="text-sm font-medium leading-4 text-[#4F4F4F] ml-[8px]"
                    style={{ marginBottom: 0 }}
                  >
                    {item?.contact?.name}
                  </p>
                </div>
              ))}
            </div>
          </section>
        )} */}
      </div>

      {/* task time */}
      <div>
        <TimeComponent
          text="Created"
          time={previewTask?.created_at}
          icon={createdIcon}
        />
        <TimeComponent
          text="Deadline"
          time={previewTask?.deadline}
          icon={deadlineIcon}
          className="mt-[10px]"
        />
      </div>

      <hr className="w-full my-7 border-[#D0D0D0]" />

      {/* subtask */}
      <section className="mb-8">
        {/* title */}
        <section className="flex items-center justify-between mb-[24px]">
          <h3 className="text-[20px] font-semibold leading-6 text-[#1F2937]">
            Sub Task List
          </h3>

          <button
            onClick={() => setShowAddSubTaskModal(true)}
            className="text-sm font-medium leading-4 text-white bg-[#E45C8C] rounded-full py-[8px] px-[12px]"
          >
            + Add Sub Task
          </button>
        </section>

        {/* sub task list */}
        <div className="mb-4 md:mb-6">
          {previewTask?.sub_tasks?.length !== 0 ? (
            previewTask?.sub_tasks?.map((subTask, index) => {
              return (
                <div
                  className={`flex items-start justify-between mb-[24px] ${styles.subTaskLabel}`}
                  key={index}
                >
                  <div>
                    <div className="flex items-center">
                      <img
                        src={shieldIcon}
                        alt="shield"
                        className="w-6 h-6 mr-[8px]"
                      />
                      <p className="font-semibold text-[16px] leading-5 text-[#4F4F4F]">
                        {subTask?.title}
                      </p>
                    </div>

                    <div className="ml-[32px]">
                      <div className="flex items-center mt-[6px] mb-[10px]">
                        <div
                          className={`h-[11px] w-[11px] ${
                            subTask?.status === 2
                              ? "bg-[#0ACF1E]"
                              : "bg-[#0A65CF]"
                          } border border-[#E6E6E6] mr-[4px] rounded-full`}
                        />
                        <p
                          className={`${
                            subTask?.status === 2
                              ? "text-[#0ACF1E]"
                              : "text-[#0A65CF]"
                          } font-medium leading-3 text-[11px]`}
                        >
                          {subTask?.status === 2 ? "Completed" : "In Progress"}
                        </p>
                      </div>

                      <TimeComponent
                        text="Created"
                        time={subTask?.created_at}
                        icon={createdIcon}
                        sm
                      />
                      <TimeComponent
                        text="Deadline"
                        time={subTask?.deadline}
                        icon={deadlineIcon}
                        className="mt-[10px]"
                        sm
                      />

                      {/* Sub Task Assigned To section */}
                      {/* {subTask?.sub_task_assigns?.length > 0 && (
                        <section>
                          <p
                            className="font-medium text-sm leading-4 text-[#4F4F4F] mt-[16px]"
                            style={{ marginBottom: "10px" }}
                          >
                            Assigned To
                          </p>

                          <div className="flex flex-wrap gap-[8px]">
                            {subTask?.sub_task_assigns?.map((item, index) => (
                              <div
                                className="flex items-center rounded-[23px] border border-[#EAE7E7] bg-white py-[4px] pl-[4px] pr-[8px]"
                                key={index}
                              >
                                {getContact(item)?.file_manager?.url ? (
                                  <img
                                    className="w-6 h-6 rounded-full"
                                    src={getContact(item)?.file_manager?.url}
                                    alt="avatar"
                                  />
                                ) : (
                                  <div className="text-white bg-[#31C48D] w-6 h-6 text-[8px] font-medium rounded-full flex justify-center items-center">
                                    {item?.contact?.name.slice(0, 2)}
                                  </div>
                                )}
                                <p
                                  className="text-sm font-medium leading-4 text-[#4F4F4F] ml-[8px]"
                                  style={{ marginBottom: 0 }}
                                >
                                  {item?.contact?.name}
                                </p>
                              </div>
                            ))}
                          </div>
                        </section>
                      )} */}
                    </div>
                  </div>

                  <Dropdown
                    param={subTask}
                    rotate={true}
                    menuItems={menuItems.filter((item) => !item.hidden)}
                  />
                </div>
              );
            })
          ) : (
            <h4>No subtasks assigned</h4>
          )}
        </div>
      </section>

      <hr className="w-full mb-7 border-[#D0D0D0]" />

      <div>
        <h3 className="mb-3 text-base font-semibold sm:text-xl">Tag List</h3>
        <p
          className="font-medium text-sm leading-4 text-[#4F4F4F] mt-[16px]"
          style={{ marginBottom: "8px" }}
        >
          {previewTask?.task_tags?.length > 0 ? "Tags Added" : "No tags"}
        </p>

        <div>
          <div className="flex flex-wrap gap-2 mb-6 divide-y">
            {previewTask?.task_tags?.map((tagObj, idx) => {
              return (
                <React.Fragment key={idx}>
                  <Tag
                    title={tagObj?.tag?.name}
                    tagId={tagObj?.tag?.id}
                    index={idx}
                    handleDeleteTag={handleTaskTags}
                    className="capitalize"
                  />
                </React.Fragment>
              );
            })}
            {isTagSpinner && <Spinner />}
          </div>
        </div>
        <div>
          <p
            className="font-medium text-sm leading-4 text-[#4F4F4F] mt-[16px]"
            style={{ marginBottom: "8px" }}
          >
            Add Tags
          </p>

          {/* add tag */}
          <div className="relative pr-10 overflow-hidden rounded-lg max-w-112 h-[52px] sm:pr-14 border border-[#E3E3E3]">
            <TextField
              className="bg-[#FAFAFB] w-full h-full pl-5 justify-center text-[15px]"
              placeholder="Write Tag"
              onChange={(e) => setTag(e.target.value)}
              value={tag}
              sx={{
                paddingLeft: "10px",
                ".MuiAutocomplete-endAdornment": {
                  display: "none",
                },
                fieldset: {
                  display: "none",
                },
                input: {
                  padding: 0,
                  fontSize: "inherit",
                },
                ".MuiInputBase-root": {
                  fontSize: "inherit",
                },
              }}
            />

            <div className="absolute top-0 right-0 flex items-center h-full">
              <button
                className={`h-[36px] ${
                  !tag
                    ? "bg-[#EAEAEA] text-[#4F4F4F]"
                    : "bg-[#E45C8C] text-white"
                } font-medium text-sm leading-4 rounded-[4px] px-[8px] py-[8px] mr-[8px]`}
                onClick={() => handleUpdateTags()}
                disabled={!tag}
              >
                + Add Tag
              </button>
            </div>
          </div>

          {/* rest of tags */}
          <section>
            <p
              className="font-medium text-sm leading-4 text-[#4F4F4F] mt-[16px]"
              style={{ marginBottom: "8px" }}
            >
              Tags Suggestions
            </p>
            <div className="flex flex-wrap gap-2 mt-4">
              {showAllTags
                ? tagArr
                    ?.filter(
                      (tag) =>
                        !previewTask?.task_tags
                          ?.map((tagObj) => tagObj?.tag?.id)
                          .includes(tag?.id)
                    )
                    ?.map((tag, idx) => (
                      <Tag
                        key={idx}
                        title={tag?.name}
                        tagId={idx}
                        onClick={() => handleTaskTags(tag.id)}
                      />
                    ))
                : tagArr
                    ?.filter(
                      (tag) =>
                        !previewTask?.task_tags
                          ?.map((tagObj) => tagObj?.tag?.id)
                          .includes(tag?.id)
                    )
                    ?.slice(0, 7)
                    ?.map((tag, idx) => (
                      <Tag
                        key={idx}
                        title={tag?.name}
                        tagId={idx}
                        onClick={() => handleTaskTags(tag.id)}
                      />
                    ))}

              <button
                type="button"
                className="px-2 sm:px-3 py-1.5 rounded-md flex align-center text-[#333333] cursor-pointer transition duration-300 ease max-w-xl bg-[#D9D9D9] font-medium text-xs leading-4"
                onClick={() => setShowAllTags(!showAllTags)}
              >
                {showAllTags ? "Show Less" : "Show More"}
              </button>
            </div>
          </section>
        </div>
      </div>
      {showAddSubTaskModal && (
        <Dialog open={showAddSubTaskModal} onClose={onClosePreviewPopup}>
          <DialogContent className="p-2 transition-qliq w-full min-w-[310px] sm:w-[400px]">
            <AddSubTaskModal
              setShowAddSubTaskModal={setShowAddSubTaskModal}
              handleUpdateSubTask={handleUpdateSubTask}
              subTaskText={subTaskText}
              setSubTaskText={setSubTaskText}
              isDate={isDate}
              setIsDate={setIsDate}
            />
          </DialogContent>
        </Dialog>
      )}
      {showEditSubTaskModal && (
        <Dialog open={showEditSubTaskModal} onClose={onClosePreviewPopup}>
          <DialogContent className="p-2 transition-qliq w-full min-w-[310px] sm:w-[400px]">
            <UpdateSubTaskModal
              updateSubTaskHandler={updateSubTaskHandler}
              singleSubTask={singleSubTask}
              setShowEditSubTaskModal={setShowEditSubTaskModal}
              singleSubTaskDate={singleSubTaskDate}
            />
          </DialogContent>
        </Dialog>
      )}
      {showDeleteCardModal && (
        <Dialog
          PaperProps={{ width: "100%" }}
          open={showDeleteCardModal}
          onClose={() => setShowDeleteCardModal(false)}
        >
          <DialogContent
            className="p-0 transition-qliq"
            style={{ overflowY: "unset" }}
          >
            <div className="relative p-6 w-[290px] sm:w-[350px]">
              <div className="absolute top-0 transform -translate-x-1/2 -translate-y-1/2 left-1/2">
                <div className=" flex justify-center items-center w-[70px] h-[70px] rounded-full bg-[#f3f3f3] border-[6px] border-white">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M28 8.97333C27.9733 8.97333 27.9333 8.97333 27.8933 8.97333C20.84 8.26667 13.8 8 6.82668 8.70667L4.10668 8.97333C3.54668 9.02667 3.05334 8.62667 3.00001 8.06667C2.94668 7.50667 3.34668 7.02667 3.89334 6.97333L6.61334 6.70667C13.7067 5.98667 20.8933 6.26667 28.0933 6.97333C28.64 7.02667 29.04 7.52 28.9867 8.06667C28.9467 8.58667 28.5067 8.97333 28 8.97333Z"
                      fill="#EB5757"
                    />
                    <path
                      d="M11.3333 7.62667C11.28 7.62667 11.2267 7.62667 11.16 7.61333C10.6267 7.52 10.2533 7 10.3467 6.46667L10.64 4.72C10.8533 3.44 11.1467 1.66667 14.2533 1.66667H17.7467C20.8667 1.66667 21.16 3.50667 21.36 4.73333L21.6533 6.46667C21.7467 7.01333 21.3733 7.53333 20.84 7.61333C20.2933 7.70667 19.7733 7.33333 19.6933 6.8L19.4 5.06667C19.2133 3.90667 19.1733 3.68 17.76 3.68H14.2667C12.8533 3.68 12.8267 3.86667 12.6267 5.05333L12.32 6.78667C12.24 7.28 11.8133 7.62667 11.3333 7.62667Z"
                      fill="#EB5757"
                    />
                    <path
                      d="M20.28 30.3333H11.72C7.06667 30.3333 6.88001 27.76 6.73334 25.68L5.86667 12.2533C5.82667 11.7067 6.25334 11.2267 6.80001 11.1867C7.36001 11.16 7.82667 11.5733 7.86667 12.12L8.73334 25.5467C8.88001 27.5733 8.93334 28.3333 11.72 28.3333H20.28C23.08 28.3333 23.1333 27.5733 23.2667 25.5467L24.1333 12.12C24.1733 11.5733 24.6533 11.16 25.2 11.1867C25.7467 11.2267 26.1733 11.6933 26.1333 12.2533L25.2667 25.68C25.12 27.76 24.9333 30.3333 20.28 30.3333Z"
                      fill="#EB5757"
                    />
                    <path
                      d="M18.2133 23H13.7733C13.2266 23 12.7733 22.5467 12.7733 22C12.7733 21.4533 13.2266 21 13.7733 21H18.2133C18.76 21 19.2133 21.4533 19.2133 22C19.2133 22.5467 18.76 23 18.2133 23Z"
                      fill="#EB5757"
                    />
                    <path
                      d="M19.3334 17.6667H12.6667C12.12 17.6667 11.6667 17.2133 11.6667 16.6667C11.6667 16.12 12.12 15.6667 12.6667 15.6667H19.3334C19.88 15.6667 20.3334 16.12 20.3334 16.6667C20.3334 17.2133 19.88 17.6667 19.3334 17.6667Z"
                      fill="#EB5757"
                    />
                  </svg>
                </div>
              </div>
              <div className="mt-5 mb-4 text-center">
                <h4 className="text-base font-[500] text-[#000000d9]">
                  Are you sure to delete the <br /> Contact?
                </h4>
                <h4 className="text-[12px] mt-2 font-[500] text-[#000000d9]">
                  You can’t undo this action
                </h4>
              </div>

              <div className="flex items-center justify-center mt-8">
                <div
                  className="px-4 mr-2 py-2 text-base border border-[#D9D9D9] text-[#000000d9] text-white bg-white rounded cursor-pointer"
                  onClick={() => setShowDeleteCardModal(false)}
                >
                  Cancel
                </div>
                <div
                  className="px-[15px] py-[6.4px] text-base text-white bg-[#EF5367] rounded cursor-pointer"
                  onClick={handleDeleteSubTask}
                >
                  Delete
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

const TimeComponent = ({ text, time, icon, className, sm }) => {
  return (
    <div className={`flex items-center ${className}`}>
      {time !== null && (
        <>
          <img
            src={icon}
            alt="icon"
            className={`${
              sm ? "w-[16px] h-[16px] mr-[8px]" : "w-6 h-6 mr-[11px]"
            }`}
          />
          <p
            className={`font-medium ${
              sm ? "text-[12px]" : "text-sm"
            } text-[#4F4F4F]`}
          >
            {text}: {moment(time).format("DD MMMM, YYYY")}
          </p>
        </>
      )}
    </div>
  );
};

export default TaskModal;
