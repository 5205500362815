import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import DotPhoto from "../../Images/icons/DotsThreeOutline.png";

const Dropdown = ({ param, menuItems, icon, rotate }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full text-sm font-medium text-gray-700 bg-white focus:outline-none">
          <img
            src={DotPhoto}
            className={`h-8 w-8 object-cover ${rotate && "rotate-90"}`}
            alt="qliq"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ml-44 ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {menuItems?.map((menuItem) => {
              return (
                <Menu.Item>
                  {({ active }) =>
                    menuItem?.htmlFor ? (
                      <label
                        htmlFor={menuItem.htmlFor}
                        onClick={(e) => {
                          e.stopPropagation();
                          menuItem?.function(param);
                          menuItem?.openModal();
                        }}
                        className="cursor-pointer"
                      >
                        <div
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          {menuItem?.icon && (
                            <span className="mr-2">{menuItem?.icon}</span>
                          )}

                          {menuItem?.name}
                        </div>
                      </label>
                    ) : (
                      <div
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm cursor-pointer"
                        )}
                        onClick={(e) => {
                          e.stopPropagation();
                          menuItem?.function(param);
                        }}
                      >
                        {menuItem?.icon}

                        {menuItem?.name}
                      </div>
                    )
                  }
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Dropdown;
