import * as yup from "yup";
import { Formik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import useAuth from "../../Hooks/useAuth";
import IntlTelInput from "react-intl-tel-input";
import InputGroup from "react-bootstrap/InputGroup";
import { FormControl, FormText } from "react-bootstrap";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BASE_FRONTEND_URL } from "../../Helpers/Env";
import { checkEmailUsername } from "../../Api/Auth";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { googleLogout } from "@react-oauth/google";
import { signInWithFacebook, signInWithGoogle } from "service/firebase";
import { generateUserName } from "Helpers/Utils";

const Images = {
  brandLogo: require("../../Images/logos/brand-logo.png"),
};

const defaultValues = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  password_confirmation: "",
  phone: "",
  company: "",
  designation: "",
  username: "",
  policy: false,
};

const validationSchema = [
  yup.object().shape({
    email: yup
      .string()
      .email("Enter Valid Email")
      .required("Email is required")
      .test("is_Email_Username", "Email already exists", async (email) => {
        if (email) {
          try {
            let response = await checkEmailUsername({ email: email });
            return !response.data.is_email;
          } catch (error) {
            console.log({ error });
          }
        }
      }),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*#?&])[a-zA-Z\d@$!%*#?&]{8,}$/,
        "Password must contain 8 characters, One digit, One letter and One special character"
      ),
    password_confirmation: yup
      .string()
      .required("Confirm Password is required")
      .when("password", {
        is: (val) => val && val.length > 0,
        then: yup
          .string()
          .oneOf([yup.ref("password")], "Both password need to be the same"),
      }),
    policy: yup
      .boolean()
      .oneOf([true], "You must accept the terms and conditions"),
  }),
  yup.object().shape({
    first_name: yup
      .string()
      .required("First name is required")
      .max(15, "Must be at most 15 characters"),
    last_name: yup
      .string()
      .required("Last name is required")
      .max(15, "Must be at most 15 characters"),
    phone: yup.string(),
  }),
  yup.object().shape({
    username: yup
      .string()
      .required("Username is required")
      .matches(
        /^[a-zA-Z0-9_.]+$/,
        "Username cannot contain space and special character"
      )
      .test(
        "is_Email_Username",
        "Username already exists",
        async (username) => {
          if (username) {
            try {
              let response = await checkEmailUsername({ username: username });
              return !response.data.is_username;
            } catch (error) {
              console.log({ error });
            }
          }
        }
      ),
  }),
];

function Register() {
  const navigate = useNavigate();
  const {
    signIn,
    isAuthenticated,
    isInitialized,
    socialSignIn,
    signUp,
    isSubscribed,
  } = useAuth();
  const [step, setStep] = useState(1);
  const totalSteps = 3;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginOption, setLoginOption] = useState("");

  if (isAuthenticated && isInitialized) {
    navigate(isSubscribed ? "/dashboard" : "/packages");
  }

  const socialLoginHandle = (id) => {
    setLoginLoading(true);

    try {
      socialSignIn(id, loginOption)
        .then((res) => {
          setLoginLoading(false);
          if (res !== undefined) {
            toast.success("Login Successful");
            navigate("/my-card");
          } else {
            toast.success(
              "You already registered with email. Please set the password from Setting page."
            );
          }
        })
        .catch((err) => {
          setLoginLoading(false);
          // toast.error(err.response.data.message);
          toast.error(
            "This social account is already registered with email and password. Please login with email and password."
          );
        });
    } catch (error) {
      console.log("socialLoginHandle Error", error);
      setLoginLoading(false);
      googleLogout();
      // toast.error(error.response.data.message);
    }
  };

  const socialSignupHandle = (values) => {
    setLoginLoading(true);
    signUp(values)
      .then((res) => {
        console.log("Registered", res);
        setLoginLoading(false);
      })
      .catch(function (err) {
        setLoginLoading(false);
        googleLogout();
        toast.error(err.response.data.message);
      });
  };

  const handleRegister = (values, { setTouched, setSubmitting }) => {
    if (step === totalSteps) {
      signUp(values)
        .then((e) => {
          console.log("Registered", e);
        })
        .catch(function (err) {
          setSubmitting(false);
          toast.error(err.response.data.message);
          console.log("error", err.response.data);
        });
    } else {
      setStep((prevState) => prevState + 1);
      setTouched({});
      setSubmitting(false);
    }
  };

  const handleGoogleLogin2 = async () => {
    const res = await signInWithGoogle();

    // console.log("handleGoogleLogin", res);

    try {
      if (res) {
        setLoginOption("google_uid");

        const validEmailRes = await checkEmailUsername({
          email: res?.email,
        });
        console.log("validEmailRes", validEmailRes);
        if (validEmailRes.data.is_email === true) {
          socialLoginHandle(res?.uid);
          console.log("BEFORE API CALL: social account login");
        } else {
          console.log("BEFORE API CALL: new social account signup");
          socialSignupHandle({
            first_name: res.displayName,
            // last_name: "qliq",
            last_name: "",
            email: res?.email,
            password: generateUserName(res.displayName),
            password_confirmation: generateUserName(res.displayName),
            phone: "",
            company: "",
            designation: "",
            // username: generateUserName(res.displayName).slice(0, 14),
            username:
              res?.email?.split("@")[0] + Math.floor(Math.random() * 100),
            google_uid: res?.uid,
            policy: false,
          });
        }
      } else {
        console.log("handleGoogleLogin2", res);
      }
    } catch (error) {
      console.log("handleGoogleLogin2", error);
      toast.error(error.response.data.message);
    }
  };

  const handleFacebookLogin2 = async () => {
    const res = await signInWithFacebook();

    console.log("handleFacebookLogin2", res);

    if (res) {
      setLoginOption("facebook_uid");

      const validEmailRes = await checkEmailUsername({
        email: res?.email,
      });
      if (validEmailRes.data.is_email === true) {
        socialLoginHandle(res?.uid);
      } else {
        socialSignupHandle({
          first_name: res.displayName,
          // last_name: "qliq",
          last_name: "",
          email: res?.email,
          password: generateUserName(res.displayName),
          password_confirmation: generateUserName(res.displayName),
          phone: "",
          company: "",
          designation: "",
          // username: generateUserName(res.displayName).slice(0, 14),
          username: res?.email?.split("@")[0] + Math.floor(Math.random() * 100),
          google_uid: res?.uid,
          policy: false,
        });
      }
    }
  };

  return (
    <div className="w-full display-position-center">
      <div>
        <div className="flex justify-center mb-6">
          <Link to="/">
            <img
              alt="brand logo"
              src={Images.brandLogo}
              className="object-center w-32 h-auto transition duration-500 transform rounded-3xl hover:scale-110"
            />
          </Link>
        </div>

        <Formik
          onSubmit={handleRegister}
          initialValues={defaultValues}
          validationSchema={validationSchema[step - 1]}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              {step === 1 && (
                <div>
                  <div className="pb-4 mb-8 text-center">
                    <h1 className="text-xl font-bold leading-tight md:text-2xl">
                      Create Account
                    </h1>
                    <p className="mt-1 text-sm text-gray-600">
                      You're just seconds away from sharing your digital cards.
                    </p>
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-gray-700">
                      Email Address{" "}
                      <span className="text-red-500 text-lg leading-[0]">
                        *
                      </span>
                    </label>
                    <FormControl
                      name="email"
                      onChange={handleChange}
                      defaultValue={values.email}
                      placeholder="example@mail.com"
                      isInvalid={errors.email && touched.email}
                      className="w-full px-4 py-3 mt-2 bg-gray-200 border rounded-lg focus:border-primary focus:bg-white focus:outline-none"
                    />
                    {errors.email && touched.email && (
                      <FormText className="text-red-700">
                        {errors.email}
                      </FormText>
                    )}
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="mt-4">
                      <label htmlFor="password" className="block text-gray-700">
                        Password{" "}
                        <span className="text-red-500 text-lg leading-[0]">
                          *
                        </span>
                      </label>
                      <div className="relative">
                        <FormControl
                          type={showPassword ? "text" : "password"}
                          name="password"
                          onChange={handleChange}
                          placeholder="Enter Password"
                          defaultValue={values.password}
                          isInvalid={errors.password && touched.password}
                          className="w-full px-4 py-3 mt-2 bg-gray-200 border rounded-lg focus:border-primary focus:bg-white focus:outline-none"
                        />
                        <div
                          className="absolute top-[13px] right-3 cursor-pointer"
                          onClick={() => setShowPassword((state) => !state)}
                        >
                          {showPassword ? (
                            <AiFillEyeInvisible size={25} color="#939292" />
                          ) : (
                            <AiFillEye size={25} color="#939292" />
                          )}{" "}
                        </div>
                      </div>

                      {errors.password && touched.password && (
                        <FormText className="text-red-700">
                          {errors.password}
                        </FormText>
                      )}
                    </div>

                    <div className="mt-4">
                      <label
                        htmlFor="password_confirmation"
                        className="block text-gray-700"
                      >
                        Confirm Password{" "}
                        <span className="text-red-500 text-lg leading-[0]">
                          *
                        </span>
                      </label>
                      <div className="relative">
                        <FormControl
                          type={showConfirmPassword ? "text" : "password"}
                          onChange={handleChange}
                          name="password_confirmation"
                          placeholder="Confirm your password"
                          defaultValue={values.password_confirmation}
                          isInvalid={
                            errors.password_confirmation &&
                            touched.password_confirmation
                          }
                          className="w-full px-4 py-3 mt-2 bg-gray-200 border rounded-lg focus:border-primary focus:bg-white focus:outline-none"
                        />
                        <div
                          className="absolute top-[13px] right-3 cursor-pointer"
                          onClick={() =>
                            setShowConfirmPassword((state) => !state)
                          }
                        >
                          {showConfirmPassword ? (
                            <AiFillEyeInvisible size={25} color="#939292" />
                          ) : (
                            <AiFillEye size={25} color="#939292" />
                          )}{" "}
                        </div>
                      </div>
                      {errors.password_confirmation &&
                        touched.password_confirmation && (
                          <FormText className="text-red-700">
                            {errors.password_confirmation}
                          </FormText>
                        )}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="policy"
                      style={{ alignItems: "flex-start" }}
                      className="inline-flex mt-4 text-sm text-gray-700 cursor-pointer"
                    >
                      <FormControl
                        onChange={handleChange}
                        id="policy"
                        type="checkbox"
                        name="policy"
                        className="mt-1.5 form-checkbox w-[20px]"
                        isInvalid={errors.policy && touched.policy}
                      />
                      <p className="ml-2">
                        By creating an account, you agree to our
                        <Link
                          to="/"
                          className="ml-auto text-sm font-semibold no-underline whitespace-no-wrap text-brand-primary hover:text-purple-primary hover:underline"
                          target="_blank"
                        >
                          Terms & Conditions
                        </Link>
                        <span> and </span>
                        <Link
                          to="/"
                          target="_blank"
                          className="ml-auto text-sm font-semibold no-underline whitespace-no-wrap text-brand-primary hover:text-purple-primary hover:underline"
                        >
                          Privacy Policy.
                        </Link>
                      </p>
                    </label>
                    {errors.policy && touched.policy && (
                      <FormText className="text-red-700">
                        {errors.policy}
                      </FormText>
                    )}
                  </div>
                </div>
              )}

              {step === 2 && (
                <div>
                  <div className="pb-4 mb-8 text-center">
                    <p className="text-sm text-dark-600">
                      We just need to ask you some Questions
                    </p>
                    <h1 className="text-xl font-bold leading-tight md:text-2xl">
                      You’re almost done
                    </h1>
                  </div>

                  <div className="w-full mt-6">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label
                          htmlFor="first_name"
                          className="block text-gray-700"
                        >
                          First Name{" "}
                          <span className="text-red-500 text-lg leading-[0]">
                            *
                          </span>
                        </label>
                        <FormControl
                          type="text"
                          name="first_name"
                          onChange={handleChange}
                          placeholder="Enter First Name"
                          defaultValue={values.first_name}
                          isInvalid={errors.first_name && touched.first_name}
                          className="w-full px-4 py-3 mt-2 bg-gray-200 border rounded-lg focus:border-primary focus:bg-white focus:outline-none"
                        />
                        {Boolean(errors.first_name && touched.first_name) && (
                          <FormText className="text-red-700">
                            {errors.first_name}
                          </FormText>
                        )}
                      </div>
                      <div>
                        <label
                          htmlFor="last_name"
                          className="block text-gray-700"
                        >
                          Last Name{" "}
                          <span className="text-red-500 text-lg leading-[0]">
                            *
                          </span>
                        </label>
                        <FormControl
                          type="text"
                          name="last_name"
                          onChange={handleChange}
                          placeholder="Enter Last Name"
                          defaultValue={values.last_name}
                          isInvalid={errors.last_name && touched.last_name}
                          className="w-full px-4 py-3 mt-2 bg-gray-200 border rounded-lg focus:border-primary focus:bg-white focus:outline-none"
                        />
                        {Boolean(errors.last_name && touched.last_name) && (
                          <FormText className="text-red-700">
                            {errors.last_name}
                          </FormText>
                        )}
                      </div>
                    </div>

                    <div className="mt-4">
                      <label
                        htmlFor="phone"
                        className="block mb-2 text-gray-700"
                      >
                        Phone Number
                      </label>
                      <IntlTelInput
                        fieldName="phone"
                        placeholder="Enter Phone"
                        defaultValue={values.phone}
                        containerClassName="intl-tel-input"
                        onPhoneNumberChange={(status, phoneNumber) =>
                          setFieldValue("phone", phoneNumber)
                        }
                        inputClassName="form-control w-full px-4 py-3 rounded-lg bg-gray-200 border focus:border-primary focus:bg-white focus:outline-none"
                      />
                      {Boolean(errors.phone && touched.phone) && (
                        <FormText className="text-red-700">
                          {errors.phone}
                        </FormText>
                      )}
                    </div>

                    <div className="mt-4">
                      <label htmlFor="company" className="block text-gray-700">
                        Company
                      </label>
                      <FormControl
                        type="text"
                        name="company"
                        onChange={handleChange}
                        defaultValue={values.company}
                        placeholder="Enter Your Company Name"
                        isInvalid={errors.company && touched.company}
                        className="w-full px-4 py-3 mt-2 bg-gray-200 border rounded-lg focus:border-primary focus:bg-white focus:outline-none"
                      />
                      {Boolean(errors.company && touched.company) && (
                        <FormText className="text-red-700">
                          {errors.company}
                        </FormText>
                      )}
                    </div>

                    <div className="mt-4">
                      <label
                        htmlFor="designation"
                        className="block text-gray-700"
                      >
                        How would you describe your job tittle?
                      </label>
                      <FormControl
                        type="text"
                        name="designation"
                        onChange={handleChange}
                        placeholder="Enter Job Title"
                        defaultValue={values.designation}
                        isInvalid={errors.designation && touched.designation}
                        className="w-full px-4 py-3 mt-2 bg-gray-200 border rounded-lg username focus:border-primary focus:bg-white focus:outline-none"
                      />
                      {Boolean(errors.designation && touched.designation) && (
                        <FormText className="text-red-700">
                          {errors.designation}
                        </FormText>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {step === 3 && (
                <div>
                  <div className="pb-4 mb-8 text-center">
                    <h1 className="text-xl font-bold leading-tight md:text-2xl">
                      Choose a username
                    </h1>
                    <p className="mt-1 text-sm text-dark-600">
                      Make it Unique!
                    </p>
                  </div>

                  <div className="content-wrapper">
                    <InputGroup>
                      <InputGroup.Text id="basic-addon3" className="h-50-px">
                        {BASE_FRONTEND_URL.replace(/(^\w+:|^)\/\//, "")}
                      </InputGroup.Text>
                      <Form.Control
                        name="username"
                        placeholder="username"
                        onChange={handleChange}
                        defaultValue={values.username}
                        isInvalid={errors.username && touched.username}
                        className="w-full px-4 py-3 bg-white border rounded-lg focus:border-primary focus:bg-white focus:outline-none"
                      />
                    </InputGroup>
                    {Boolean(errors.username && touched.username) && (
                      <FormText className="text-red-700">
                        {errors.username}
                      </FormText>
                    )}
                  </div>
                </div>
              )}

              <button
                type="submit"
                disabled={isSubmitting}
                className="block w-full px-4 py-3 mt-6 font-semibold text-white rounded-lg bg-brand-primary hover:bg-purple-primary focus:bg-purple-primary"
              >
                {step === totalSteps
                  ? isSubmitting
                    ? "Loading..."
                    : "Sign Up"
                  : "Next"}
              </button>

              {step !== 1 && (
                <div className="text-center">
                  <button
                    type="button"
                    onClick={() => setStep((prevState) => prevState - 1)}
                    className="mt-4 font-semibold group text-brand-primary hover:text-purple-primary"
                  >
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      className="mr-2 duration-300 group-hover:pr-2"
                    />
                    Back
                  </button>
                </div>
              )}
            </form>
          )}
        </Formik>

        <hr className="w-full my-6 border-gray-300" />

        <p className="text-center">Or</p>

        <div className="flex justify-center">
          <div
            className="bg-[#df4930] text-white uppercase w-full text-center p-3 font-semibold rounded-lg text-sm cursor-pointer"
            onClick={handleGoogleLogin2}
          >
            Continue with Google
          </div>
        </div>

        <br />

        {/* new */}
        {/* <div className="flex justify-center">
          <div
            className="bg-[#4c69ba] text-white uppercase w-full text-center p-3 font-semibold rounded-lg text-sm cursor-pointer"
            onClick={handleFacebookLogin2}
          >
            Login with Facebook
          </div>
        </div> */}

        <p className="mt-8 mb-8 text-center">
          Already have an account?
          <Link
            to="/login"
            className="font-semibold text-brand-primary hover:text-purple-primary"
          >
            <span> Login</span>
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Register;
