import moment from "moment";
import { useEffect, useState } from "react";
import Box from "../../Components/Widget/Box";
import { getCardAnalytics } from "../../Api/Card";
import DashboardActivitiesChart from "Components/Charts/DashboardActivitiesChart";

const defaultChartData = [
  { label: "1th june 2022", value: 0, type: "View" },
  { label: "7th june 2022", value: 0, type: "Share" },
];

function Dashboard() {
  const [cardData, setCardData] = useState([]);
  const [chartData, setChartData] = useState(defaultChartData);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    const params = {
      end: moment().format("Y-M-D"), // means today
      start: moment().subtract(6, "d").format("Y-M-D"), // means 7 days ago
    };
    getCardAnalytics({ params })
      .then((res) => {
        const data = res.data;
        setCardData(data);

        const views = data?.chart_data?.map((item) => {
          return { label: item?.label, value: item?.visit, type: "View" };
        });

        const shares = data?.chart_data?.map((item) => {
          return { label: item?.label, value: item?.share, type: "Share" };
        });
        setChartData([...views, ...shares]);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <div className="container px-4 py-10 mx-auto">
      <h3 className="text-[#6A6087] text-2xl font-medium mb-6">Analytics</h3>
      <div className="grid grid-cols-2 gap-6 lg:grid-cols-4 mb-9">
        <Box
          title="Card Views"
          desc="Since Feb 2022"
          value={cardData?.total_visited ?? 0}
        />
        <Box
          title="Card Shares"
          desc="Since Feb 2022"
          value={cardData?.total_shared ?? 0}
        />
        <Box
          title="Card Views"
          desc="Last month"
          value={cardData?.last_one_month_visited ?? 0}
        />
        <Box
          title="Card Shares"
          desc="Last month"
          value={cardData?.last_one_month_shared ?? 0}
        />
      </div>
      <div className="bg-white shadow rounded-2xl">
        <DashboardActivitiesChart data={chartData} />
      </div>
    </div>
  );
}

export default Dashboard;
