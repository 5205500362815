import { useState } from "react";
import getCroppedImg from "utils/ImageCrop";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  // Slider,
  // Typography,
} from "@mui/material";
import Cropper from "react-easy-crop";

const CropBox = ({ uploadedImg, onImageCrop, imgUploadModalOpen }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const cropComplete = (_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async () => {
    try {
      const { file, url } = await getCroppedImg(
        uploadedImg,
        croppedAreaPixels,
        rotation
      );
      onImageCrop({ preview: url, data: file });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <DialogContent
        dividers
        sx={{
          background: "#333",
          position: "relative",
          height: 400,
          width: "auto",
          minWidth: { sm: 500 },
        }}
      >
        <Cropper
          image={uploadedImg}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={1}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={cropComplete}
        />
      </DialogContent>
      <DialogActions sx={{ flexDirection: "column", mx: 3, my: 2 }}>
        <Box sx={{ width: "100%", mb: 1 }}>
          {/* <Box>
            <Typography style={{ marginBottom: "0" }}>
              Zoom: {zoomPercent(zoom)}
            </Typography>
            <Slider
              valueLabelDisplay="auto"
              min={1}
              max={3}
              step={0.1}
              value={zoom}
              onChange={(e, zoom) => setZoom(zoom)}
              valueLabelFormat={zoomPercent}
            />
          </Box> */}
          {/* <Box>
            <Typography style={{ marginBottom: "0" }}>
              Rotation: {rotation + "°"}
            </Typography>
            <Slider
              valueLabelDisplay="auto"
              min={0}
              max={360}
              value={rotation}
              onChange={(e, rotation) => setRotation(rotation)}
            />
          </Box> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          <Button variant="outlined" onClick={() => imgUploadModalOpen(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={cropImage}>
            Crop
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};

export default CropBox;

// const zoomPercent = (value) => {
//   return `${Math.round(value * 100)}%`;
// };
