import { Route, Routes } from "react-router-dom";
import "./App.css";
import AppLayout from "./Layout/AppLayout";
import Home from "./Pages/Home";
import Dashboard from "./Pages/Dashboard";

import EditContact from "./Pages/Contacts/Edit";
import Contacts from "./Pages/Contacts/Index";
import NewContact from "./Pages/Contacts/New";

import EditCompany from "./Pages/Companys/Edit";
import Companys from "./Pages/Companys/Index";
import NewCompany from "./Pages/Companys/New";

import AuthLayout from "./Layout/AuthLayout";
import Login from "./Pages/Auth/Login";
import Register from "./Pages/Auth/Register";
import RegisterStepFive from "./Pages/Auth/RegisterStep/StepFive";
import RegisterStepFour from "./Pages/Auth/RegisterStep/StepFour";
import RegisterStepThree from "./Pages/Auth/RegisterStep/StepThree";
import RegisterStepTwo from "./Pages/Auth/RegisterStep/StepTwo";

import AllCardModule from "./Pages/Cards/AllCard";
import MyCardModule from "./Pages/Cards/MyCard";
import UserCard from "./Pages/Cards/UserCard";

import Analytics from "./Pages/Analytics/Dashboard";

import EmailSignature from "./Pages/EmailSignature/Index";
import Setting from "./Pages/Setting/Index";

import SocialsPage from "Pages/Cards/SocialsPage";
import AuthGuard from "./Pages/Auth/AuthGuard";
import BusinessCards from "./Pages/BusinessCard";
import LandingPage from "./Pages/Home/index";
import Packages from "./Pages/Packages";
import Feedback from "./Pages/Feedback";
import TaskModule from "./Pages/Task/Index";
import Contact from "./Pages/Contacts/Contact";
import PageLayout from "./Layout/PageLayout";
import GettingStarted from "./Pages/GettingStarted";
import ForgotPassword from "Pages/forgot-password/forgot-password";
import ResetPassword from "Pages/forgot-password/reset-password";
import EditBusinessCard from "Pages/BusinessCard/EditBusinessCard";

function App() {
  return (
    <Routes>
      <Route extact path="/:username" element={<UserCard />} />
      <Route extact path="/u/:username" element={<UserCard />} />

      <Route element={<PageLayout />}>
        <Route path="/" element={<LandingPage />} />
        <Route path="/getting-started" element={<GettingStarted />} />
        <Route path="/find-out-more" element={<GettingStarted />} />
        <Route path="/sample-page" element={<GettingStarted />} />
      </Route>

      <Route element={<AuthGuard />}>
        <Route element={<AppLayout />}>
          <Route path="/home" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/contacts/:id" element={<Contact />} />
          <Route path="/contacts/new-contact" element={<NewContact />} />
          <Route path="/contacts/edit" element={<EditContact />} />

          <Route path="/contacts/companys" element={<Companys />} />
          <Route path="/contacts/new-company" element={<NewCompany />} />
          <Route path="/contacts/edit-company" element={<EditCompany />} />

          <Route path="/contacts/business-cards" element={<BusinessCards />} />
          <Route
            path="/contacts/business-cards/edit"
            element={<EditBusinessCard />}
          />

          <Route path="/packages" element={<Packages />} />
          <Route path="/feedback" element={<Feedback />} />

          <Route path="/my-card" element={<MyCardModule />} />
          <Route path="/my-card/:card" element={<AllCardModule />} />
          <Route path="/analytics" element={<Analytics />} />

          <Route path="/setting" element={<Setting />} />

          <Route path="/email-signature" element={<EmailSignature />} />

          <Route path="/task" element={<TaskModule />} />

          <Route path="/my-card/socials" element={<SocialsPage />} />
        </Route>
      </Route>

      <Route element={<AuthLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/password/:token" element={<ResetPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/step-2" element={<RegisterStepTwo />} />
        <Route path="/register/step-3" element={<RegisterStepThree />} />
        <Route path="/register/step-4" element={<RegisterStepFour />} />
        <Route path="/register/step-5" element={<RegisterStepFive />} />
      </Route>
    </Routes>
  );
}

export default App;
