import { faAddressCard, faImage } from "@fortawesome/free-solid-svg-icons";
import Breadcrumbs from "../../Components/Breadcrumbs";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { getUser } from "Api/User";
import Spinner from "Components/Spinner/Spinner";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAuth from "Hooks/useAuth";
import { activateCard, getCardBgImg } from "Api/Card";

// cards
// import CardFour from "../../Components/Card/CardFour";
// import CardOne from "../../Components/Card/CardOne";
// import CardThree from "../../Components/Card/CardThree";
// import CardTwo from "../../Components/Card/CardTwo";
// import CardFive from "../../Components/Card/CardFive";
// import CardSix from "../../Components/Card/CardSix";
// import CardNew1 from "Components/Card/CardNew1Test";

// import CardNew1 from "Components/Card/CardNew1";
// import CardNew2 from "Components/Card/CardNew2";
// import CardNew3 from "Components/Card/CardNew3";
// import CardNew4 from "Components/Card/CardNew4";
// import CardNew5 from "Components/Card/CardNew5";
// import CardNew6 from "Components/Card/CardNew6";
import CardNew7 from "Components/Card/CardNew7";

const breadcrumbItems = [
  { label: "Cards", to: "/my-card/all-card", icon: faImage, isActive: true },
  { label: "My Card", to: "/my-card", icon: faAddressCard },
];

function AllCard() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const { updateUser } = useAuth();
  const [open, setOpen] = useState(false);
  const [selCard, setSelCard] = useState(null);
  const [activeLoading, setActiveLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [isCardBgImg, setIsCardBgImg] = useState("");

  useEffect(() => {
    fetchUser();
    fetchCardBgImg();
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const fetchCardBgImg = () => {
    getCardBgImg()
      .then((res) => {
        setIsCardBgImg(res?.data?.image);
        setLoading(false);
      })
      .catch((err) => console.log("card bg image error", err));
  };

  const handleClose = () => {
    setOpen(false);
    setSelCard(null);
  };

  const handleCardSelect = (card) => {
    setSelCard(card);
    setOpen(true);
  };

  const handleActiveCard = () => {
    setActiveLoading(true);
    activateCard(selCard)
      .then((res) => {
        updateUser(res.data.user);
        toast.success(res.data.message);
        setActiveLoading(false);
        handleClose();
        if (windowWidth < 768) {
          navigate("/my-card?cart-active=true");
        } else {
          navigate("/my-card");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setActiveLoading(false);
        handleClose();
      });
  };

  const fetchUser = () => {
    getUser()
      .then((res) => {
        setUser(res.data.user);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  };

  // const cardItems = [
  //   {
  //     id: 1,
  //     Card: CardNew7,
  //   },
  //   {
  //     id: 2,
  //     Card: CardNew2,
  //   },
  //   {
  //     id: 3,
  //     Card: CardNew3,
  //   },
  //   {
  //     id: 4,
  //     Card: CardNew4,
  //   },
  //   {
  //     id: 5,
  //     Card: CardNew5,
  //   },
  //   {
  //     id: 6,
  //     Card: CardNew6,
  //   },
  //   {
  //     id: 7,
  //     Card: CardNew1,
  //   },
  // ];

  return (
    <>
      <div className="relative pb-4 mt-0 mb-6">
        <div className="rounded">
          <Breadcrumbs items={breadcrumbItems} nav />
          {isLoading ? (
            <div className="display-position-center">
              <Spinner />
            </div>
          ) : (
            <div className="px-4 py-6">
              {/* <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"> */}
              <div className="flex flex-wrap gap-5">
                {/* <section
                  className="cursor-pointer"
                  onClick={() => handleCardSelect(1)}
                >
                  <div>
                    <CardOne
                      user={user}
                      jobTitle="Strock Brocker"
                      socials={user?.socials}
                      cardBg={isCardBgImg}
                    />
                  </div>
                </section> */}

                {/* <section
                  className="cursor-pointer"
                  onClick={() => handleCardSelect(2)}
                >
                  <div>
                    <CardTwo
                      user={user}
                      designation="Sr. Graphic Designer"
                      background="#ffffff"
                      description="Lorem Ipsum is simply dummy text of the printing"
                      cardBg={isCardBgImg}
                    />
                  </div>
                </section> */}

                {/* <section
                  onClick={() => handleCardSelect(3)}
                  className="flex items-start justify-center cursor-pointer"
                >
                  <CardThree user={user} cardBg={isCardBgImg} />
                </section> */}

                {/* <section
                  className="cursor-pointer"
                  onClick={() => handleCardSelect(5)}
                >
                  <div>
                    <CardFour user={user} cardBg={isCardBgImg} />
                  </div>
                </section> */}

                {/* <section
                  className="cursor-pointer"
                  onClick={() => handleCardSelect(6)}
                >
                  <div>
                    <CardFive user={user} cardBg={isCardBgImg} />
                  </div>
                </section> */}

                {/* <section
                  className="cursor-pointer"
                  onClick={() => handleCardSelect(7)}
                >
                  <div>
                    <CardSix user={user} cardBg={isCardBgImg} />
                  </div>
                </section> */}

                {/* <section
                  className="cursor-pointer"
                  onClick={() => handleCardSelect(8)}
                >
                  <div>
                    <CardNew1 user={user} cardBg={isCardBgImg} />
                  </div>
                </section> */}

                {/* <section
                  className="cursor-pointer"
                  onClick={() => handleCardSelect(1)}
                >
                  <div>
                    <CardNew1 user={user} cardBg={isCardBgImg} />
                  </div>
                </section> */}

                {/* {cardItems?.map((card) => (
                  <CardItem
                    key={card.id}
                    id={card.id}
                    Card={card.Card}
                    user={user}
                    cardBg={isCardBgImg}
                    handleCardSelect={handleCardSelect}
                  />
                ))} */}

                <CardItem
                  id={1}
                  Card={CardNew7}
                  user={user}
                  cardBg={isCardBgImg}
                  handleCardSelect={handleCardSelect}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {open && (
        <Dialog open={open} fullWidth onClose={handleClose}>
          <DialogContent className="p-0 transition-qliq">
            <div className="absolute w-6 h-6 top-2 right-2 closeBtn-with-bg">
              <button type="button" className="w-6 h-6" onClick={handleClose}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="px-8 py-10 text-center">
              <h3 className="mb-4 text-3xl font-bold text-black">
                Are you sure?
              </h3>
              <p className="text-[#666] text-sm lg:w-2/3 mx-auto">
                Your current theme will be overridden, you can switch back
                anytime.
              </p>
              <Button
                disabled={activeLoading}
                onClick={handleActiveCard}
                className="px-10 mt-5 text-white btn btn-ghost bg-primary hover:bg-purple-primary"
              >
                {activeLoading ? "Activating..." : "I'm Sure"}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

const CardItem = ({ id, Card, user, cardBg, handleCardSelect }) => {
  const location = useLocation();

  return (
    <section
      className="cursor-pointer w-[343px] h-[667px]"
      onClick={() => handleCardSelect(id)}
    >
      <div
        className={`w-full h-full ${
          location.pathname === "/my-card/all-card"
            ? "pointer-events-none"
            : "pointer-events-auto"
        }
      `}
      >
        <Card user={user} cardBg={cardBg} />
      </div>
    </section>
  );
};

export default AllCard;
