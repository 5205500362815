import {forgotPasswordMail} from "Api/Auth";
import {useState} from "react";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";

import BrandLogo from "./../../Images/logos/brand-logo.png";

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [loginLoading, setLoginLoading] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoginLoading(true);

        forgotPasswordMail(email).then((res) => {
            localStorage.setItem("resetEmail", email);
            toast.success(res.data.message);
            setLoginLoading(false);
        }).catch((err) => {
            console.log(err)
            setLoginLoading(false);
            toast.error(err.response.data.message);
        });
    };

    const handleChange = (event) => {
        if (event.target.id === "email") {
            setEmail(event.target.value);
        }
    };

    return (
        <div className="w-full display-position-center ">
            <div className="flex justify-center flex-col w-full">
                <div className="flex justify-center">
                    <Link to="/">
                        <img
                            alt="brand logo"
                            src={BrandLogo}
                            className="object-center rounded-3xl transform transition duration-500 hover:scale-110 w-32 h-auto"
                        />
                    </Link>
                </div>
                <h1 className="text-center text-xl md:text-2xl font-bold leading-tight mt-12">
                    Welcome Back
                </h1>

                <form className="mt-6 w-full" onSubmit={onSubmit}>
                    <div>
                        <label htmlFor="email" className="block text-gray-700">
                            Email Address
                        </label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="example@mail.com"
                            onChange={handleChange}
                            defaultValue={email}
                            required
                            className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-brand-primary focus:bg-white focus:outline-none"
                        />
                    </div>

                    <button
                        type="submit"
                        className="w-full block bg-brand-primary hover:bg-purple-primary focus:bg-purple-primary text-white font-semibold rounded-lg px-4 py-3 mt-6"
                    >
                        {loginLoading ? "Loading..." : "Send Email"}
                    </button>
                </form>
            </div>
        </div>
    );
}

export default ForgotPassword;
