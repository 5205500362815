import { useState } from "react";
// import FileSaver from "file-saver";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import "react-tabs/style/react-tabs.css";
import Dialog from "@mui/material/Dialog";
import { TabSocialIcons } from "./Contacts";
import { useNavigate } from "react-router-dom";
import Notes from "../../Components/Contacts/NoteTabs";
import DialogContent from "@mui/material/DialogContent";
import defaultUser from "../../Images/default_user.png";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import TaskTabs from "../../Components/Contacts/TaskTabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DetailsTabs from "../../Components/Contacts/DetailsTabs";
import {
  faInfoCircle,
  faListOl,
  faNoteSticky,
  faTags,
} from "@fortawesome/free-solid-svg-icons";
import {
  addNoteToContact,
  addSubTaskToContactTask,
  addTagToContact,
  addTaskToContact,
  // createTag,
  // downloadContact,
  tagCreate,
  updateContact,
} from "../../Api/Contact";
import { Button } from "react-bootstrap";
import * as React from "react";
import TagsTabs from "../../Components/Contacts/TagsTabs";
import { BASE_API_URL } from "../../Helpers/Env";

const ContactPreview = ({ contact, setContact, isOpen, onClose }) => {
  const navigate = useNavigate();
  const [selTask, setSelTask] = useState(null);
  const [subTaskModal, setSubTaskModal] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [contactTagSpinner, setContactTagSpinner] = useState(false);

  console.log(contactTagSpinner);

  const handleDeleteContactTag = (tagId) => {
    let formData = new FormData();
    formData.append("_method", "put");
    formData.append("name", contact?.name);
    formData.append("phone", contact?.phone);
    formData.append("image", "");
    formData.append("designation", contact?.designation);
    formData.append("email", contact?.email);
    formData.append("first_met", contact?.first_met);
    formData.append("company_id", contact?.company);

    contact?.tag?.forEach((tag, index) => {
      if (tag?.id !== tagId) formData.append(`tags[${index}][id]`, tag?.id);
    });

    contact?.notes?.forEach((note, index) => {
      formData.append(`notes[${index}][note]`, note?.note);
      formData.append(`notes[${index}][id]`, note?.id);
      formData.append(`notes[${index}][date]`, note?.date);
    });

    setContactTagSpinner(true);

    updateContact(formData, contact?.id)
      .then((res) => {
        if (res) {
          setContact(res?.contact);
          setContactTagSpinner(false);
        } else {
          toast.error("Contact cannot be updated");
          setContactTagSpinner(false);
        }
      })
      .catch((err) => {
        toast.error("Contact cannot be updated");
        setContactTagSpinner(false);
      });
  };

  const handleAssignTagToContact = (values, { setSubmitting, resetForm }) => {
    const data = {
      name: values.title,
      color_id: 1,
      description: "description",
    };
    tagCreate(data)
      .then((res) => {
        addTagToContact({ contact_id: contact.id, tag_id: res.data.tag.id })
          .then((response) => {
            setContact(response.data.contact);
            setSubmitting(false);
            resetForm();
          })
          .catch((err) => {
            setSubmitting(false);
            console.log(err);
            toast.error(err.response.data.message);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddNoteToContact = (values, { setSubmitting, resetForm }) => {
    addNoteToContact({ contact_id: contact?.id, note: values?.note })
      .then((res) => {
        setContact(res.data.contact);
        setSubmitting(false);
        resetForm();
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
        toast.error(err.response.data.message);
      });
  };

  const handleAssignTaskToContact = (values, { setSubmitting, resetForm }) => {
    const data = {
      contact_id: contact.id,
      title: values.title,
    };
    addTaskToContact(data)
      .then((res) => {
        if (res) {
          setSubmitting(false);
          setContact(res.data.contact);
          toast.success(res.data.message);
          resetForm();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setSubmitting(false);
      });
  };

  const handleSubTaskToContactTask = (values, { setSubmitting, resetForm }) => {
    const data = {
      contact_id: contact.id,
      task_id: selTask.id,
      title: values.title,
    };
    addSubTaskToContactTask(data)
      .then((res) => {
        if (res) {
          setSubmitting(false);
          setContact(res.data.contact);
          toast.success(res.data.message);
          resetForm();
          setSubTaskModal(false);
          setSelTask(null);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setSubmitting(false);
      });
  };

  const handleCloseSubTaskModal = () => {
    setSelTask(null);
    setSubTaskModal(false);
  };

  const handleOpenSubTaskModal = (task) => {
    setSelTask(task);
    setSubTaskModal(true);
  };

  const handleAddToPhone = () => {
    setDownloadLoading(true);
    // downloadContact(contact?.id).then(res => {
    //     setDownloadLoading(false);
    //     const blob = new Blob([res], {
    //         type: "text/plain;charset=utf-8"
    //     });
    //     FileSaver.saveAs(blob, `${contact?.name}.vcf`);
    // }).catch(console.log);
    window.open(`${BASE_API_URL}contacts/${contact.id}/download`, "_blank");
    setDownloadLoading(false);
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogContent className="p-0 transition-qliq">
        <div className="absolute top-3 right-5">
          <button onClick={onClose}>
            <i className="cursor-pointer fa-solid fa-xmark"></i>
          </button>
        </div>

        <div className="px-3 sm:px-6 py-7">
          <div className="flex flex-col items-center justify-center">
            <img
              className="object-cover w-24 h-24 p-1 rounded-full bg-brand-primary"
              src={
                contact?.file_manager ? contact?.file_manager?.url : defaultUser
              }
              alt=""
            />
          </div>

          <div>
            <h3 className="mt-4 text-xl font-normal text-center">
              {contact?.name}
            </h3>
            <h3 className="text-sm text-center text-gray-500">
              {contact?.designation}
            </h3>
          </div>

          <div className="flex items-center justify-center my-3 space-x-2 popups-icon-header-top">
            {TabSocialIcons?.map((item, index) => (
              <div key={index} className="px-2 py-1">
                <img
                  alt={item?.name}
                  src={item?.photo}
                  className="w-8 h-8 cursor-pointer"
                  onClick={() => {
                    if (item?.name === "Mail")
                      window.location = `mailto:${contact?.email ?? ""}`;
                    else if (item?.name === "Phone")
                      window.location = `tel:${contact?.phone ?? ""}`;
                    else if (item?.name === "Massage")
                      window.location = `sms://${contact?.phone ?? ""}`;
                    else if (item?.name === "Edit")
                      navigate("/contacts/edit", {
                        state: { contact: contact },
                      });
                    else navigate(item?.link);
                  }}
                />
              </div>
            ))}
          </div>

          <div className="flex flex-col items-center justify-center mt-5">
            <Tabs className="contact-deatils-tabs">
              <TabList className="flex items-center justify-center mb-9 ">
                <Tab className="px-2 pb-1 text-sm text-gray-400 border-b-2 cursor-pointer">
                  <FontAwesomeIcon className="mr-1" icon={faInfoCircle} />
                  Details
                </Tab>
                <Tab className="px-2 pb-1 text-sm text-gray-400 border-b-2 cursor-pointer">
                  <FontAwesomeIcon className="mr-1" icon={faTags} />
                  Tags
                </Tab>
                <Tab className="px-2 pb-1 text-sm text-gray-400 border-b-2 cursor-pointer">
                  <FontAwesomeIcon className="mr-1" icon={faNoteSticky} />
                  Notes
                </Tab>
                <Tab className="px-2 pb-1 text-sm text-gray-400 border-b-2 cursor-pointer">
                  <FontAwesomeIcon className="mr-1" icon={faListOl} />
                  Tasks
                </Tab>
              </TabList>

              <TabPanel>
                <DetailsTabs contact={contact} />
              </TabPanel>

              <TabPanel>
                <TagsTabs
                  contact={contact}
                  setContact={setContact}
                  handleDeleteTag={handleDeleteContactTag}
                  handleTagAssignToContact={handleAssignTagToContact}
                />
              </TabPanel>

              <TabPanel>
                <Notes
                  contact={contact}
                  handleCreateNote={handleAddNoteToContact}
                />
              </TabPanel>

              <TabPanel>
                <TaskTabs
                  contact={contact}
                  subTaskModal={subTaskModal}
                  handleCloseSubTaskModal={handleCloseSubTaskModal}
                  handleOpenSubTaskModal={handleOpenSubTaskModal}
                  handleAssignTask={handleAssignTaskToContact}
                  handleAssignSubTaskToContactTask={handleSubTaskToContactTask}
                />
              </TabPanel>
            </Tabs>
          </div>

          <div className="mt-4 text-center">
            <Button
              disabled={downloadLoading}
              onClick={handleAddToPhone}
              className="add-tagsBtn btn btn-ghost btn-sm  capitalize !bg-primary hover:bg-purple-primary text-white min-h-[40px] px-5"
            >
              {downloadLoading ? (
                "Adding..."
              ) : (
                <>
                  <i className="mr-2 fa fa-phone" /> Add to Phone
                </>
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

ContactPreview.propTypes = {
  contact: PropTypes.object.isRequired,
  setContact: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  fetchContacts: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
};

export default ContactPreview;
