import * as React from "react";
import useAuth from "../../Hooks/useAuth";
import { Navigate, useLocation, Outlet } from "react-router-dom";

// For routes that can only be accessed by authenticated users
function AuthGuard() {
  const location = useLocation();
  const { isAuthenticated, isInitialized, isSubscribed } = useAuth();

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (
    !isSubscribed &&
    isAuthenticated &&
    location.pathname !== "/packages"
  ) {
    return <Navigate to="/packages" state={{ from: location }} replace />;
  }

  return <Outlet />;
}

export default AuthGuard;
