import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUserCardInfo, setUserCardShare } from "../../Api/User";
import { BASE_API_URL, BASE_FRONTEND_URL } from "../../Helpers/Env";
import { Box, Dialog, DialogContent } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../../Components/TabPanel";
import { toast } from "react-toastify";
import { scanCard } from "../../Api/ScanCards";
import Spinner from "../../Components/Spinner/Spinner";
import { Formik } from "formik";
import { Button, FormControl, FormGroup } from "react-bootstrap";
import { sendContactInformation } from "../../Api/Contact";
// import { getCardBgImg } from "Api/Card";
import { RWebShare } from "react-web-share";

// icons
import saveIcon from "icons/save-icon.svg";
import shareIcon from "icons/share-icon.svg";
import inIcon from "icons/in-icon.svg";

// cards
// import CardOne from "../../Components/Card/CardOne";
// import CardTwo from "../../Components/Card/CardTwo";
// import CardFour from "../../Components/Card/CardFour";
// import CardThree from "../../Components/Card/CardThree";
// import CardNew1 from "Components/Card/CardNew1Test";
// import CardFive from "../../Components/Card/CardFive";
// import CardSix from "../../Components/Card/CardSix";

// import CardNew1 from "Components/Card/CardNew1";
// import CardNew2 from "Components/Card/CardNew2";
// import CardNew3 from "Components/Card/CardNew3";
// import CardNew4 from "Components/Card/CardNew4";
// import CardNew5 from "Components/Card/CardNew5";
// import CardNew6 from "Components/Card/CardNew6";
import CardNew7 from "Components/Card/CardNew7";

const defaultValues = {
  name: "",
  email: "",
  phone: "",
  company: "",
  designation: "",
};

function UserCard() {
  const params = useParams();
  const navigate = useNavigate();
  const { username } = params;
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [activeCard, setActiveCard] = useState(4);
  const [isScanning, setIsScanning] = useState(false);
  const [openSaveContactDialog, setOpenSaveContactDialog] = useState(false);
  // const [isCardBgImg, setIsCardBgImg] = useState("");

  // useEffect(() => {
  //   fetchCardBgImg();
  // }, []);

  // const fetchCardBgImg = () => {
  //   getCardBgImg()
  //     .then((res) => {
  //       setIsCardBgImg(res?.data?.image);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => console.log("card bg image error", err));
  // };

  const fetchUserCardInfo = useCallback(() => {
    getUserCardInfo(username)
      .then(async (res) => {
        const user = res.data.user;
        setUser(user);
        const activeCardId = user?.active_card ? user?.active_card?.id : 1;
        setActiveCard(activeCardId);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }, [username]);

  const handleAddToPhone = () =>
    window.open(`${BASE_API_URL}user-card-info/${username}/download`, "_blank");

  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  const handleScanningCard = (event) => {
    const image = {
      scanCard: URL.createObjectURL(event.target.files[0]),
      data: event.target.files[0],
    };
    setIsScanning(true);
    const data = new FormData();
    data.append("image", image.data);
    scanCard(data)
      .then((res) => {
        setIsScanning(false);
        toast.success(res.data.message);
        setOpenSaveContactDialog(false);
      })
      .catch((err) => {
        setIsScanning(false);
        console.log(err);
        toast.error(err.response.data.message);
      });
  };

  const handleSaveContact = (values, { setSubmitting }) => {
    sendContactInformation(values, username)
      .then((res) => {
        if (res) {
          setOpenSaveContactDialog(false);
          setSubmitting(false);
          toast.success("Contact info successfully save!");
        }
      })
      .catch((err) => {
        setSubmitting(false);
        toast.error(err.response.data.message);
      });
  };

  // const renderCard = () => {
  //   switch (activeCard) {
  //     case 1:
  //       return <CardOne user={user} cardBg={isCardBgImg} className="h-full" />;
  //     case 2:
  //       return <CardTwo user={user} className="h-full" />;
  //     case 3:
  //       return <CardThree user={user} className="h-full shadow" />;
  //     case 4:
  //       return <CardFour user={user} className="h-full" />;
  //     case 5:
  //       return <CardFive user={user} className="h-full" />;
  //     case 6:
  //       return <CardSix user={user} className="h-full" />;
  //     case 7:
  //       return <CardNew1 user={user} className="h-full" />;
  //     default:
  //       return <CardFive user={user} className="h-full" />;
  //   }
  // };

  const renderCard = () => {
    // switch (activeCard) {
    // case 7:
    //   return <CardNew1 user={user} className="!h-auto" />;
    // case 2:
    //   return <CardNew2 user={user} className="!h-auto" />;
    // case 3:
    //   return <CardNew3 user={user} className="!h-auto" />;
    // case 4:
    //   return <CardNew4 user={user} className="!h-auto" />;
    // case 5:
    //   return <CardNew5 user={user} className="!h-auto" />;
    // case 6:
    //   return <CardNew6 user={user} className="!h-auto" />;
    // case 1:
    //   return <CardNew7 user={user} className="!h-auto" isSpaceZero />;
    // default:
    //   return <CardNew7 user={user} className="!h-auto" isSpaceZero />;
    // }

    return <CardNew7 user={user} className="!h-auto" isSpaceZero />;
  };

  useEffect(() => {
    fetchUserCardInfo();
  }, [fetchUserCardInfo]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <div
        // style={{
        //   backgroundImage: "url('/assets/images/dashboard-bg.png')",
        // }}
        className={`flex items-start justify-center w-full scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-300 hover:scrollbar-thumb-gray-700 sm:!bg-none bg-no-repeat bg-cover bg-center min-h-[calc(100vh-67px)]`}
        // className="w-full h-[652px] flex justify-center items-center scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-300 overflow-y-scroll hover:scrollbar-thumb-gray-700"
        // className="flex items-start justify-center w-full scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-300 hover:scrollbar-thumb-gray-700"
      >
        {user ? (
          <div className="w-full md:w-[370px] h-full md:h-auto md:min-h-[500px] md:py-10 mt-5 mb-5">
            {/* greeting */}
            <p className="px-2.5 mb-5 text-lg font-normal text-black font-raleway">
              Nice meeting you, let’s stay in touch! 😊
            </p>

            {/* card design */}
            {renderCard()}

            {/* bottom extra button */}
            <section className="px-2.5">
              {/* <div className="flex justify-center gap-4 my-5">
                <button
                  onClick={handleAddToPhone}
                  className="px-5 py-3 text-sm text-white rounded bg-primary hover:bg-purple-primary"
                >
                  Add to Phone
                </button>
                <button
                  onClick={() => setOpenSaveContactDialog(true)}
                  className="px-5 py-3 text-sm text-white bg-black rounded hover:bg-primary"
                >
                  Save Contact
                </button>
              </div> */}

              {/* save and share */}
              <div className="flex justify-between items-center mt-[22px]">
                <button
                  onClick={handleAddToPhone}
                  className="w-[148px] h-[54px] bg-light-gray rounded-[20px] flex justify-center items-center gap-2 font-raleway font-normal text-[#4d4d4d] text-[15px] tracking-[0] leading-[normal] shadow-card-button"
                >
                  Save
                  <img src={saveIcon} alt="save" />
                </button>

                <RWebShare
                  data={{
                    url: `${BASE_FRONTEND_URL}${user?.username}`,
                    title: "Qliq",
                  }}
                  onClick={() => {
                    toast.success("Shared successfully!");
                    setUserCardShare(username);
                  }}
                >
                  <button className="w-[148px] h-[54px] bg-light-gray rounded-[20px] flex justify-center items-center gap-2 font-raleway font-normal text-[#4d4d4d] text-[15px] tracking-[0] leading-[normal] shadow-card-button">
                    Share
                    <img src={shareIcon} alt="share" />
                  </button>
                </RWebShare>
              </div>

              {/* signup */}
              <button
                onClick={() => navigate("/register")}
                className="w-full h-[54px] bg-light-gray rounded-[20px] flex justify-center items-center gap-2 font-raleway font-normal text-[#4d4d4d] text-[15px] tracking-[0] leading-[normal] shadow-card-button mt-4"
              >
                Get Your Own Business Card
                <img src={inIcon} alt="in" />
              </button>
            </section>
          </div>
        ) : (
          <div className="text-center display-position-center">
            <img
              src={require("../../Images/offline-logo.png")}
              className="mx-auto"
              alt="Qliq"
            />
            <p className="mt-4 text-2xl font-bold text-white">
              The user is offline
            </p>
          </div>
        )}
      </div>

      {openSaveContactDialog && (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={openSaveContactDialog}
          PaperProps={{ sx: { paddingTop: 2 } }}
          onClose={() => setOpenSaveContactDialog(false)}
        >
          <button
            onClick={() => setOpenSaveContactDialog(false)}
            className="absolute right-2.5 top-2.5 h-7 w-7 rounded-full bg-primary text-white"
          >
            <i className="fa-solid fa-xmark"></i>
          </button>

          <DialogContent>
            <Tabs
              centered
              value={activeTab}
              onChange={handleTabChange}
              sx={{
                ".Mui-selected": {
                  color: "#5d5478",
                },
                ".MuiTabs-indicator": {
                  backgroundColor: "#5d5478",
                },
              }}
            >
              <Tab label="Scan Card" />
              <Tab label="Add Contact" />
            </Tabs>

            <Box className="py-4 mt-4">
              <TabPanel value={activeTab} index={0}>
                <div className="user-info-header position-relative">
                  <div className="flex flex-col items-center justify-between">
                    <input
                      hidden
                      id="image"
                      type="file"
                      name="image"
                      accept="image/*"
                      onChange={(e) => handleScanningCard(e)}
                    />
                  </div>
                  <div className="w-full">
                    {isScanning ? (
                      <div className="flex items-center justify-center">
                        <Spinner />
                        <p className="ml-1">Scanning, Please wait</p>
                      </div>
                    ) : (
                      <label
                        htmlFor="image"
                        className="w-full font-medium text-gray-800 border border-gray-200 rounded-full btn btn-ghost hover:bg-primary hover:text-white"
                      >
                        <i className="mr-3 fa-solid fa-images" /> Choose a card
                      </label>
                    )}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <h3 className="font-medium text-center text-heading">
                  Send your information back to{" "}
                  {user?.first_name + " " + user?.last_name}
                </h3>

                <Formik
                  initialValues={defaultValues}
                  onSubmit={handleSaveContact}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    touched,
                    errors,
                    values,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit} className="mt-5 space-y-4">
                      <FormGroup>
                        <FormControl
                          required
                          name="name"
                          placeholder="Name"
                          onChange={handleChange}
                          defaultValue={values.name}
                          isInvalid={errors.name && touched.name}
                          className="w-full px-4 py-3 bg-gray-100 border rounded-lg focus:border-primary focus:bg-white focus:outline-none"
                        />
                      </FormGroup>

                      <FormGroup>
                        <FormControl
                          required
                          name="phone"
                          placeholder="Phone"
                          onChange={handleChange}
                          defaultValue={values.phone}
                          isInvalid={errors.phone && touched.phone}
                          className="w-full px-4 py-3 bg-gray-100 border rounded-lg focus:border-primary focus:bg-white focus:outline-none"
                        />
                      </FormGroup>

                      <FormGroup>
                        <FormControl
                          type="email"
                          name="email"
                          placeholder="Email"
                          onChange={handleChange}
                          defaultValue={values.email}
                          isInvalid={errors.email && touched.email}
                          className="w-full px-4 py-3 bg-gray-100 border rounded-lg focus:border-primary focus:bg-white focus:outline-none"
                        />
                      </FormGroup>

                      <FormGroup>
                        <FormControl
                          name="company"
                          placeholder="Company"
                          onChange={handleChange}
                          defaultValue={values.company}
                          isInvalid={errors.company && touched.company}
                          className="w-full px-4 py-3 bg-gray-100 border rounded-lg focus:border-primary focus:bg-white focus:outline-none"
                        />
                      </FormGroup>

                      <FormGroup>
                        <FormControl
                          name="designation"
                          onChange={handleChange}
                          placeholder="Designation"
                          defaultValue={values.designation}
                          isInvalid={errors.designation && touched.designation}
                          className="w-full px-4 py-3 bg-gray-100 border rounded-lg focus:border-primary focus:bg-white focus:outline-none"
                        />
                      </FormGroup>

                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        className="w-full px-5 py-3 text-white rounded bg-primary"
                      >
                        {isSubmitting ? "Sending..." : "Send"}
                      </Button>
                    </form>
                  )}
                </Formik>
              </TabPanel>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default UserCard;
