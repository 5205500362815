let MODE = "Local";
// let MODE = 'Development';
// let MODE = 'Staging';
// let MODE = 'Sandbox';
// let MODE = 'Live';

let base_api_url = null;
if (MODE === "Local") {
  // base_api_url = "http://localhost/qliq-backend/api/v1/"
  base_api_url = "https://backend.dev.qliq.bio/api/v1/";
} else if (MODE === "Development") {
  base_api_url = "https://thetech-people.dev/qliq-backend/api/v1/";
} else if (MODE === "Staging") {
  base_api_url = "https://thetech-people.dev/qliq-staging/api/v1/";
} else if (MODE === "Sandbox") {
  base_api_url = "https://thetech-people.dev/qliq-sandbox/api/v1/";
} else if (MODE === "Live") {
  base_api_url = "https://qliq.bio/api/v1/";
}
export const BASE_API_URL = `${base_api_url}`;

export const BASE_FRONTEND_URL =
  process.env.NODE_ENV === "production"
    ? "https://dev.qliq.bio/"
    : "http://localhost:3000/";
export const STRIPE_PUBLISHABLE_KEY =
  "pk_test_WPVXI3n6fqovetvtP3FCbhPg00c2Z9yZTe";
export const CHARACTERS_ALLOWED = 15;
// export const FACEBOOK_APP_ID = "660494419090887";
export const FACEBOOK_APP_ID = "2292700894296779";
export const GOOGLE_CLIENT_ID =
  "86172023122-785lfqg67avp55nva3dhd4smthlqf0pq.apps.googleusercontent.com";

export const firebaseConfig = {
  apiKey: "AIzaSyBn_hVOV1FdoPQuZC0Cn36X8g7Xnzrf6jA",
  authDomain: "qliq-spacecats.firebaseapp.com",
  projectId: "qliq-spacecats",
  storageBucket: "qliq-spacecats.appspot.com",
  messagingSenderId: "60423988136",
  appId: "1:60423988136:web:95052e403b2e5f7ae1f39d",
};
