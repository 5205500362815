import { Alert } from "@mui/material";
import useAuth from "../../Hooks/useAuth";
import CompanyPage from "./Companys";
import Breadcrumbs from "../../Components/Breadcrumbs";
import React from "react";
import {
  faAddressBook,
  faAddressCard,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";

const breadcrumbItems = [
  { label: "Contacts", to: "/contacts", icon: faAddressBook },
  {
    label: "Organizations",
    to: "/contacts/companys",
    icon: faBuilding,
    isActive: true,
  },
  {
    label: "Business Cards",
    to: "/contacts/business-cards",
    icon: faAddressCard,
  },
];

function Contacts() {
  const { user } = useAuth();
  return (
    <div className="pb-4 mb-6 relative mt-0">
      <div className="rounded">
        <Breadcrumbs items={breadcrumbItems} nav />

        {Boolean(user?.subscription_status) === false ? (
          <div className="mt-5">
            <Alert severity="warning">
              Your haven't subscription. Please subscribe first.
            </Alert>
          </div>
        ) : (
          <div id="tab-contents">
            <div className="container mx-auto">
              <div className="px-4 py-6">
                <CompanyPage />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Contacts;
