import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ className, items, shareButton, nav }) => {
  // const navigate = useNavigate();

  return (
    <div className={classNames(className)}>
      {/*<div className="px-6 py-3 bg-[#f3f3f1] md:hidden">*/}
      {/*  <button onClick={() => navigate(-1)} className="text-sm font-medium">*/}
      {/*    <FontAwesomeIcon icon={faLongArrowLeft} className="inline-block" />*/}
      {/*    <span className="inline-block ml-2">Back</span>*/}
      {/*  </button>*/}
      {/*</div>*/}
      <ul
        id="tabs"
        className="relative inline-flex flex-wrap w-full px-1 py-2 tracking-tight bg-white border-b border-gray-200 border-2xl"
      >
        {items.map((item, index) =>
          nav ? (
            <li
              key={index}
              className={classNames(
                "px-4 py-2 text-gray-800 rounded-t text-md",
                {
                  "opacity-50": !item?.isActive,
                  "opacity-100": item?.isActive,
                }
              )}
            >
              <Link to={item.to}>
                <FontAwesomeIcon icon={item.icon} />
                <span className="inline-block ml-1">{item.label}</span>
              </Link>
            </li>
          ) : (
            <li
              key={index}
              className={classNames(
                "px-4 py-2 text-gray-800 rounded-t text-md",
                {
                  "opacity-50": items.length - 1 !== index,
                  "opacity-100": items.length - 1 === index,
                }
              )}
            >
              <Link to={item.to}>
                <FontAwesomeIcon icon={item.icon} />
                <span className="inline-block ml-1">{item.label}</span>
              </Link>
            </li>
          )
        )}
        {shareButton && (
          <label
            htmlFor="shareWithQR"
            className="cursor-pointer hidden md:block px-4 bg-primary hover:bg-purple-primary py-2 rounded text-md absolute right-5 text-white text-sm mt-0.5"
          >
            Share My Links <i className="ml-1 fas fa-share"></i>
          </label>
        )}
      </ul>
    </div>
  );
};

Breadcrumbs.propTypes = {
  items: PropTypes.array.isRequired,
};

export default Breadcrumbs;
