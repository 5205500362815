import { loadStripe } from "@stripe/stripe-js";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { STRIPE_PUBLISHABLE_KEY } from "../../Helpers/Env";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

const ELEMENT_OPTIONS = {
  showIcon: true,
};

const inputStyle = "bg-white shadow rounded px-4 py-3 md:py-4 mt-1";

const CheckoutForm = ({ onMakePayment, isLoading }) => {
  const elements = useElements();
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardNumberElement);

    if (card == null) {
      return;
    }

    const payload = await stripe.createToken(card);

    if (payload.error) {
      //   console.log("[error]", payload.error);
      toast.error(payload.error.message);
      setLoading(false);
    } else {
      onMakePayment(payload.token.id);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 !font-base">
      <div>
        <label htmlFor="cardNumber" className="text-sm">
          Card Number
        </label>
        <CardNumberElement
          id="cardNumber"
          options={ELEMENT_OPTIONS}
          className={inputStyle}
        />
      </div>

      <div className="grid gap-5 md:grid-cols-2">
        <div>
          <label htmlFor="expiry" className="text-sm">
            Expiration
          </label>
          <CardExpiryElement
            id="expiry"
            options={ELEMENT_OPTIONS}
            className={inputStyle}
          />
        </div>

        <div>
          <label htmlFor="cvc" className="text-sm">
            CVC
          </label>
          <CardCvcElement
            id="cvc"
            options={ELEMENT_OPTIONS}
            className={inputStyle}
          />
        </div>
      </div>

      <Button
        type="submit"
        disabled={!stripe || loading || isLoading}
        className={`btn btn-ghost bg-brand-primary w-full text-white rounded hover:bg-purple-primary !mt-6 ${
          isLoading ? "opacity-80 pointer-events-none" : ""
        }`}
      >
        {loading || isLoading ? "Loading..." : "Make Payment"}
      </Button>
    </form>
  );
};

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const StripePayment = ({ onMakePayment, isLoading }) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm onMakePayment={onMakePayment} isLoading={isLoading} />
    </Elements>
  );
};

export default StripePayment;
