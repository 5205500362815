import SampleComponent from "../Components/SampleComponent";

const GettingStarted = () => {
    return (
        <div className="container mx-auto px-4 sm:px-8 lg:px-16 xl:px-20 mt-7">
            <SampleComponent/>
        </div>
    );
};

export default GettingStarted;
