import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Formik } from "formik";
// import { getScanCards, updateScanCard } from "Api/ScanCards";
// import { toast } from "react-toastify";

const EditBusinessCard = ({ cardData, open, setOpen, onSubmit }) => {
  // const [error, setError] = React.useState({});

  // const onSubmit = (values, { setSubmitting }) => {
  //   setSubmitting(true);
  //   let formData = new FormData();
  //   formData.append("_method", "put");
  //   formData.append("name", values?.name);
  //   formData.append("designation", values?.designation);
  //   formData.append("country", values?.country);
  //   formData.append("state", values?.state);
  //   formData.append("street", values?.street);
  //   formData.append("phone", values?.phone);
  //   formData.append("email", values?.email);
  //   // formData.append("company_id", data?.company);
  //   formData.append("website", values?.website);

  //   updateScanCard(values?.id, formData)
  //     .then((res) => {
  //       if (res) {
  //         setSubmitting(false);
  //         console.log("Updated card res", res);
  //         toast.success("Card updated successfully");
  //         // getScanCards();
  // setTimeout(
  //   () =>
  //     navigate("/contacts/business-cards", {
  //       state: { card: res?.contact },
  //     }),
  //   500
  // );
  //       } else {
  //         setSubmitting(false);
  //         toast.error("Card cannot be updated");
  //       }
  //     })
  //     .catch((err) => {
  //       setSubmitting(false);
  //       toast.error("Card cannot be updated");
  //     });
  // };

  return (
    <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
      <DialogContent className="p-0 transition-qliq">
        <section className="absolute w-6 h-6 top-5 right-5 closeBtn-with-bg">
          <button
            type="button"
            className="w-6 h-6"
            onClick={() => setOpen(false)}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </section>
        <main className="p-10">
          <div className="pt-4 user-info-header position-relative">
            <Formik initialValues={cardData} onSubmit={onSubmit}>
              {({
                handleSubmit,
                handleChange,
                values,
                setFieldValue,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  {/* form input */}
                  <div>
                    <Input
                      label="Full Name"
                      name="name"
                      placeholder="Full Name"
                      onChange={handleChange}
                      defaultValue={values?.name}
                      required
                    />

                    <Input
                      label="Designation"
                      name="designation"
                      placeholder="Designation"
                      onChange={handleChange}
                      defaultValue={values?.designation}
                      required
                    />

                    <section className="flex w-full space-x-2">
                      <div className="w-12/12 sm:w-6/12">
                        <Input
                          label="Country"
                          name="country"
                          placeholder="Country"
                          onChange={handleChange}
                          defaultValue={values?.country}
                          required
                        />
                      </div>
                      <div className="w-12/12 sm:w-6/12">
                        <Input
                          label="State"
                          name="state"
                          placeholder="State"
                          onChange={handleChange}
                          defaultValue={values?.state}
                          required
                        />
                      </div>
                    </section>

                    <Input
                      label="Street"
                      name="street"
                      placeholder="Street"
                      onChange={handleChange}
                      defaultValue={values?.street}
                      required
                    />

                    <Input
                      label="Phone"
                      name="phone"
                      placeholder="Phone"
                      onChange={handleChange}
                      defaultValue={values?.phone}
                      required
                    />

                    <Input
                      type="email"
                      label="Email"
                      name="email"
                      placeholder="Email"
                      onChange={handleChange}
                      defaultValue={values?.email}
                      required
                    />

                    {/* <Input
                      label="Company"
                      name="company"
                      placeholder="Company"
                      onChange={handleChange}
                      defaultValue={values?.company?.name}
                      required
                    /> */}

                    <Input
                      label="Website"
                      name="website"
                      placeholder="Website"
                      onChange={handleChange}
                      defaultValue={values?.website}
                      required
                    />
                  </div>

                  {/* submit btn */}
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full mt-2 text-white capitalize rounded-full py-2 lg:py-3 bg-brand-primary hover:bg-purple-primary text-[15px] font-[500]"
                  >
                    Update Card
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </main>
      </DialogContent>
    </Dialog>
  );
};

const Input = ({ label, ...props }) => {
  return (
    <div className="w-full mb-4">
      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        {label}
        {props.required && <span className="ml-1 text-red-500">*</span>}
      </label>
      <input
        {...props}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      />
    </div>
  );
};

export default EditBusinessCard;
