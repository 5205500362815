import PropTypes from "prop-types";

const CardItem = ({ icon, title, className, onClick, htmlFor }) => {
  return (
    <label
      className={`w-full h-[160px] sm:w-[190px] sm:h-[190px] bg-transparent sm:bg-dashboard-icon shadow-dashboard-card sm:shadow-none rounded-3xl cursor-pointer ${className}`}
      onClick={htmlFor.length === 0 && onClick}
      htmlFor={htmlFor}
    >
      <div className="w-full h-[100px] sm:h-[131px] p-4 flex justify-center items-center">
        <img src={icon} alt={title} className="w-[75px] sm:w-[95px] mt-8" />
      </div>
      <div className="w-full h-[59px] flex justify-center items-center">
        <span className="text-sm font-normal text-center sm:text-base text-[#4D4D4D] font-raleway">
          {title}
        </span>
      </div>
    </label>
  );
};

CardItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  htmlFor: PropTypes.string,
};

export default CardItem;
