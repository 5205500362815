import { useLayoutEffect, useState } from "react";
import { toast } from "react-toastify";
import useAuth from "../../Hooks/useAuth";
// import { useGoogleLogin } from "@react-oauth/google";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
// import jwt_decode from "jwt-decode";
import { checkEmailUsername } from "../../Api/Auth";
import { generateUserName } from "../../Helpers/Utils";
import { googleLogout } from "@react-oauth/google";
// import FacebookLogin from "react-facebook-login";
import CollectEmailFromUser from "Components/GetEmailModal";
// import { FACEBOOK_APP_ID } from "../../Helpers/Env";
import { activeTrial } from "../../Api/Packages";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { isValidEmailAndPass } from "utils/validEmailAndPass";
import { signInWithFacebook, signInWithGoogle } from "service/firebase";

const Images = {
  brandLogo: require("../../Images/logos/brand-logo.png"),
  facebookIcon: require("../../Images/icons/facebook.png"),
  googleIcon: require("../../Images/icons/google.png"),
};

function Login() {
  const {
    signIn,
    isAuthenticated,
    isInitialized,
    socialSignIn,
    signUp,
    isSubscribed,
  } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginOption, setLoginOption] = useState("");
  const [isShowEmailModal, setIsShowEmailModal] = useState(false);
  const [facebookUserInfo] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isEmailAndPassValid, setIsEmailAndPassValid] = useState(null);

  const isTrialPage = Boolean(searchParams.get("trial"));
  const isTokenAvailable = searchParams.get("accessToken");

  const handleGoogleLogin2 = async () => {
    const res = await signInWithGoogle();

    // console.log("handleGoogleLogin", res);

    try {
      if (res) {
        setLoginOption("google_uid");

        const validEmailRes = await checkEmailUsername({
          email: res?.email,
        });
        console.log("validEmailRes", validEmailRes);
        if (validEmailRes.data.is_email === true) {
          socialLoginHandle(res?.uid);
          console.log("BEFORE API CALL: social account login");
        } else {
          console.log("BEFORE API CALL: new social account signup");
          socialSignupHandle({
            first_name: res.displayName,
            // last_name: "qliq",
            last_name: "",
            email: res?.email,
            password: generateUserName(res.displayName),
            password_confirmation: generateUserName(res.displayName),
            phone: "",
            company: "",
            designation: "",
            // username: generateUserName(res.displayName).slice(0, 14),
            username:
              res?.email?.split("@")[0] + Math.floor(Math.random() * 100),
            google_uid: res?.uid,
            policy: false,
          });
        }
      } else {
        console.log("handleGoogleLogin2", res);
      }
    } catch (error) {
      console.log("handleGoogleLogin2", error);
      toast.error(error.response.data.message);
    }
  };

  // const handleFacebookLogin2 = async () => {
  //   const res = await signInWithFacebook();

  //   console.log("handleFacebookLogin2", res);

  //   if (res) {
  //     setLoginOption("facebook_uid");

  //     const validEmailRes = await checkEmailUsername({
  //       email: res?.email,
  //     });
  //     if (validEmailRes.data.is_email === true) {
  //       socialLoginHandle(res?.uid);
  //     } else {
  //       socialSignupHandle({
  //         first_name: res.displayName,
  //         // last_name: "qliq",
  //         last_name: "",
  //         email: res?.email,
  //         password: generateUserName(res.displayName),
  //         password_confirmation: generateUserName(res.displayName),
  //         phone: "",
  //         company: "",
  //         designation: "",
  //         // username: generateUserName(res.displayName).slice(0, 14),
  //         username: res?.email?.split("@")[0] + Math.floor(Math.random() * 100),
  //         google_uid: res?.uid,
  //         policy: false,
  //       });
  //     }
  //   }
  // };

  const onSubmit = async (event) => {
    setLoginLoading(true);
    event.preventDefault();
    let validEmailAndPass = isValidEmailAndPass(email, password);

    try {
      if (validEmailAndPass === true) {
        console.log("IsEmail and Pass True");
        if (!isTrialPage) {
          await signIn(email, password);
          setLoginLoading(false);
          navigate("/dashboard");
        } else {
          await signIn(email, password);
          setLoginLoading(false);
          activeTrial()
            .then((res) => {
              toast.success(res.data.message);
              navigate("/dashboard");
            })
            .catch((err) => {
              console.log(err);
              navigate("/dashboard");
              toast.success(err.response.data.message);
            });
        }
      } else {
        setIsEmailAndPassValid("Invalid email or pass");
        setLoginLoading(false);
      }
    } catch (error) {
      setLoginLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const handleChange = (event) => {
    if (event.target.id === "email") {
      setEmail(event.target.value);
    }
    if (event.target.id === "password") {
      setPassword(event.target.value);
    }
  };

  if (isAuthenticated && isInitialized) {
    navigate(isSubscribed ? "/dashboard" : "/packages");
  }

  const socialLoginHandle = (id) => {
    setLoginLoading(true);

    try {
      socialSignIn(id, loginOption)
        .then((res) => {
          setLoginLoading(false);
          if (res !== undefined) {
            toast.success("Login Successful");
            navigate("/my-card");
          } else {
            toast.success(
              "You already registered with email. Please set the password from Setting page."
            );
          }
        })
        .catch((err) => {
          setLoginLoading(false);
          // toast.error(err.response.data.message);
          toast.error(
            "This social account is already registered with email and password. Please login with email and password."
          );
        });
    } catch (error) {
      console.log("socialLoginHandle Error", error);
      setLoginLoading(false);
      googleLogout();
      // toast.error(error.response.data.message);
    }
  };

  const socialSignupHandle = (values) => {
    setLoginLoading(true);
    signUp(values)
      .then((res) => {
        console.log("Registered", res);
        setLoginLoading(false);
      })
      .catch(function (err) {
        setLoginLoading(false);
        googleLogout();
        toast.error(err.response.data.message);
      });
  };

  const handleCloseGetEmailModal = () => {
    setIsShowEmailModal(false);
  };

  const checkEmailHandler = async (email) => {
    const validEmailRes = await checkEmailUsername(email);

    if (validEmailRes?.data.is_email === true) {
      socialLoginHandle(facebookUserInfo?.id);
    } else {
      let nameArr = facebookUserInfo?.name?.split(" ");
      socialSignupHandle({
        first_name: nameArr[0],
        last_name: nameArr[1],
        email: email,
        password: generateUserName(nameArr[0]),
        password_confirmation: generateUserName(nameArr[0]),
        phone: "",
        company: "",
        designation: "",
        username: generateUserName(nameArr[0]),
        policy: false,
        facebook_uid: facebookUserInfo?.id,
      });
    }
  };

  const getEmailFromModal = (email) => {
    if (email.length > 0) {
      checkEmailHandler(email);
    } else {
      toast.error("Please Type Your Email");
    }
  };

  // const handleGoogleLogin = useGoogleLogin({
  //   onSuccess: async (credentialResponse) => {
  //     console.log("Google Login Success", credentialResponse);
  //     setLoginOption("google_uid");

  //     const decoded = jwt_decode(credentialResponse?.credential);
  //     // const decoded2 = jwt_decode(credentialResponse?.access_token);

  //     // console.log("Decoded", decoded);
  //     // console.log("Decoded2", decoded2);

  //     const validEmailRes = await checkEmailUsername({
  //       email: decoded?.email,
  //     });
  //     if (validEmailRes.data.is_email === true) {
  //       socialLoginHandle(decoded?.sub);
  //     } else {
  //       socialSignupHandle({
  //         first_name: decoded?.family_name,
  //         last_name: decoded?.given_name,
  //         email: decoded?.email,
  //         password: generateUserName(decoded?.family_name, decoded?.given_name),
  //         password_confirmation: generateUserName(
  //           decoded?.family_name,
  //           decoded?.given_name
  //         ),
  //         phone: "",
  //         company: "",
  //         designation: "",
  //         username: generateUserName(decoded?.family_name, decoded?.given_name),
  //         google_uid: decoded?.sub,
  //         policy: false,
  //       });
  //     }
  //   },
  //   onError: () => {
  //     console.log("Login Failed");
  //   },
  // });

  useLayoutEffect(() => {
    if (isTokenAvailable) {
      localStorage.setItem("accessToken", `Bearer ${isTokenAvailable}`);
      window.location.href = isSubscribed ? "/dashboard" : "/packages";
    }
  }, [isTokenAvailable]);

  return (
    <div className="w-full display-position-center loginWrapper">
      <div className="flex flex-col justify-center w-full">
        <div className="flex justify-center">
          <Link to="/">
            <img
              alt="brand logo"
              src={Images.brandLogo}
              className="object-center w-32 h-auto transition duration-500 transform rounded-3xl hover:scale-110"
            />
          </Link>
        </div>
        <h1 className="mt-12 text-xl font-bold leading-tight text-center md:text-2xl">
          Welcome Back
        </h1>
        <form className="w-full mt-6" onSubmit={onSubmit}>
          <div>
            <label htmlFor="email" className="block text-gray-700">
              Email Address{" "}
              <span className="text-red-500 text-lg leading-[0]">*</span>
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="example@mail.com"
              onChange={handleChange}
              defaultValue={email}
              required
              className="w-full px-4 py-3 mt-2 bg-gray-200 border rounded-lg focus:border-brand-primary focus:bg-white focus:outline-none"
            />
          </div>

          <div className="mt-4">
            <label htmlFor="password" className="block text-gray-700">
              Password{" "}
              <span className="text-red-500 text-lg leading-[0]">*</span>
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                placeholder="Enter Your Password"
                onChange={handleChange}
                className="w-full px-4 py-3 mt-2 bg-gray-200 border rounded-lg focus:border-brand-primary focus:bg-white focus:outline-none"
                required
              />
              <div
                className="absolute cursor-pointer top-5 right-5"
                onClick={() => setShowPassword((state) => !state)}
              >
                {showPassword ? (
                  <AiFillEyeInvisible size={25} color="#939292" />
                ) : (
                  <AiFillEye size={25} color="#939292" />
                )}{" "}
              </div>
            </div>
            {isEmailAndPassValid !== null ? (
              <span className="text-red-700">{isEmailAndPassValid}</span>
            ) : null}
          </div>

          <div className="flex items-center mt-4">
            <label
              className="inline-flex items-center text-sm text-gray-700"
              htmlFor="remember"
            >
              <input
                type="checkbox"
                name="remember"
                id="remember"
                className="form-checkbox"
              />
              <span className="ml-2">Remember Me</span>
            </label>

            <Link
              className="ml-auto text-sm no-underline whitespace-no-wrap text-brand-primary hover:text-purple-primary hover:underline"
              to="/forgot-password"
            >
              Forgot Your Password?
            </Link>
          </div>

          <button
            type="submit"
            className="block w-full px-4 py-3 mt-6 font-semibold text-white rounded-lg bg-brand-primary hover:bg-purple-primary focus:bg-purple-primary"
          >
            {loginLoading ? "Loading..." : "Log In"}
          </button>
        </form>
        <hr className="w-full my-6 border-gray-300" />
        {/*<button*/}
        {/*    onClick={() => handleGoogleLogin()}*/}
        {/*    className="block w-full px-4 py-3 font-semibold text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:bg-gray-100"*/}
        {/*>*/}
        {/*    <div className="flex items-center justify-center">*/}
        {/*        <img src={Images.googleIcon} alt=" google img"/>*/}
        {/*        <span className="ml-4">Log in with Google</span>*/}
        {/*    </div>*/}
        {/*</button>*/}

        {/* <div className="flex justify-center">
          <div
            className="bg-[#df4930] text-white uppercase w-full text-center p-3 font-semibold rounded-lg text-sm cursor-pointer"
            onClick={() => handleGoogleLogin()}
          >
            Login with Google
          </div>
        </div>

        <br /> */}
        <p className="text-center">Or</p>

        <div className="flex justify-center">
          <div
            className="bg-[#df4930] text-white uppercase w-full text-center p-3 font-semibold rounded-lg text-sm cursor-pointer"
            onClick={handleGoogleLogin2}
          >
            Login with Google
          </div>
        </div>

        <br />

        {/* 660494419090887
                ace325d0688105bfe83dbb9405b1370d */}

        {/* <FacebookLogin
          appId={FACEBOOK_APP_ID}
          autoLoad={false}
          fields="name,email,picture"
          callback={(res) => {
            if (res.status !== "unknown") {
              setIsShowEmailModal(true);
            }
            setFacebookUserInfo(res);
            setLoginOption("facebook_uid");
          }}
          className="block w-full text-sm font-semibold text-gray-900 bg-white border border-gray-300 rounded-lg x-4 hover:bg-gray-100 focus:bg-gray-100"
        />

        <br /> */}

        {/* new */}
        {/* <div className="flex justify-center">
          <div
            className="bg-[#4c69ba] text-white uppercase w-full text-center p-3 font-semibold rounded-lg text-sm cursor-pointer"
            onClick={handleFacebookLogin2}
          >
            Login with Facebook
          </div>
        </div> */}

        <p className="mt-8 mb-8 text-center">
          <span>Need an account? </span>
          <Link
            to="/register"
            className="font-semibold text-brand-primary hover:text-purple-primary"
          >
            Create an account
          </Link>
        </p>
      </div>

      {isShowEmailModal && (
        <CollectEmailFromUser
          closeModal={handleCloseGetEmailModal}
          isShowModal={isShowEmailModal}
          getEmailFromModal={getEmailFromModal}
        />
      )}
    </div>
  );
}

export default Login;
