import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { MdExpandMore } from "react-icons/md";
import CloseCircleIcon from "icons/CloseCircleIcon";

const ShowAllFolders = ({
  folders,
  setGetDeleteSingleLinkId,
  setShowDeleteSinglerLinkModal,
  setShowAddMoreLinkModal,
  setShowEditFolderModal,
  setShowDeleteSinglerFolderModal,
  setGetEditSingleFolderData,
  setGetDeleteSingleFolderId,
}) => {
  return (
    <>
      {folders.length > 0 && (
        <div className="show-add-folder-wrapper">
          <h3 className="mb-3 text-xl font-semibold text-black">
            Your Folders
          </h3>
          <div>
            {folders.map((singleFolder, index) => {
              return (
                <div key={index}>
                  <Accordion>
                    <AccordionSummary
                      id="contact_tags"
                      expandIcon={<MdExpandMore size={28} color={"#292D32"} />}
                    >
                      <h2 className="font-semibold text-[#4F4F4F] text-base">
                        <span className="ml-2">{singleFolder?.name}</span>
                      </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        {singleFolder?.user_links.length > 0 ? (
                          singleFolder?.user_links.map((singleLink, index) => {
                            return (
                              <div key={index}>
                                <div className="mb-4">
                                  <label
                                    htmlFor="folderName"
                                    className="text-[#4F4F4F] mb-2 font-medium  text-base"
                                  >
                                    Link Display Text
                                  </label>
                                  <input
                                    type="text"
                                    className="w-full py-2 bg-[#FAFAFB] border border-[#E3E3E3] rounded-lg input focus:outline-0 text-black text-base"
                                    value={
                                      singleLink?.masking === null
                                        ? "Default"
                                        : singleLink?.masking
                                    }
                                  />
                                </div>
                                <div className="mb-4">
                                  <label
                                    htmlFor="folderName"
                                    className="text-[#4F4F4F] mb-2 font-medium  text-base"
                                  >
                                    Enter Social Link
                                  </label>
                                  <div className="relative folderLink">
                                    <input
                                      type="text"
                                      className="w-full py-2 bg-[#FAFAFB] border border-[#E3E3E3] rounded-lg input focus:outline-0 text-black text-base"
                                      value={singleLink?.url}
                                    />
                                    <div className="absolute top-[14px] right-[10px] folderHoverBtn">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setGetDeleteSingleLinkId(
                                            singleLink?.id
                                          );
                                          setShowDeleteSinglerLinkModal(true);
                                        }}
                                        className=""
                                      >
                                        <CloseCircleIcon />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div>No Links found</div>
                        )}

                        <div className="flex items-center justify-end">
                          <button
                            className="text-[#EA4C89] py-2 lg:py-3 border-brand-primary cursor-pointer text-[15px] font-[500] flex items-center justify-center"
                            type="button"
                            onClick={() => {
                              setShowAddMoreLinkModal(true);
                              setGetEditSingleFolderData(singleFolder);
                            }}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16 2H8C4 2 2 4 2 8V21C2 21.55 2.45 22 3 22H16C20 22 22 20 22 16V8C22 4 20 2 16 2Z"
                                stroke="#EA4C89"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.5 12H15.5"
                                stroke="#EA4C89"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12 15.5V8.5"
                                stroke="#EA4C89"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span className="ml-[7px]">Add Link</span>
                          </button>
                          <button
                            type="button"
                            className="text-[#1877F2] py-2 lg:py-3 border-brand-primary cursor-pointer text-[15px] font-[500] flex items-center justify-center ml-4"
                            onClick={() => {
                              setShowEditFolderModal(true);
                              setGetEditSingleFolderData(singleFolder);
                            }}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13"
                                stroke="#1877F2"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M16.0399 3.02001L8.15988 10.9C7.85988 11.2 7.55988 11.79 7.49988 12.22L7.06988 15.23C6.90988 16.32 7.67988 17.08 8.76988 16.93L11.7799 16.5C12.1999 16.44 12.7899 16.14 13.0999 15.84L20.9799 7.96001C22.3399 6.60001 22.9799 5.02001 20.9799 3.02001C18.9799 1.02001 17.3999 1.66001 16.0399 3.02001Z"
                                stroke="#1877F2"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M14.9102 4.1499C15.5802 6.5399 17.4502 8.4099 19.8502 9.0899"
                                stroke="#1877F2"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span className="ml-[7px]">Edit</span>
                          </button>
                          <button
                            className="text-[#DD0202] py-2 lg:py-3 border-brand-primary cursor-pointer text-[15px] font-[500] flex items-center justify-center ml-4"
                            onClick={() => {
                              setShowDeleteSinglerFolderModal(true);
                              setGetDeleteSingleFolderId(singleFolder?.id);
                            }}
                            type="button"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                                stroke="#DD0202"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                                stroke="#DD0202"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M18.8504 9.13989L18.2004 19.2099C18.0904 20.7799 18.0004 21.9999 15.2104 21.9999H8.79039C6.00039 21.9999 5.91039 20.7799 5.80039 19.2099L5.15039 9.13989"
                                stroke="#DD0202"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M10.3301 16.5H13.6601"
                                stroke="#DD0202"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9.5 12.5H14.5"
                                stroke="#DD0202"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span className="ml-[7px]">Delete</span>
                          </button>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default ShowAllFolders;
