import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { toast } from "react-toastify";
import useAuth from "../../../Hooks/useAuth";
import logo from "../../../Images/logos/brand-logo.png";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, user, signOut } = useAuth();
  const [logoutLoading, setLogoutLoading] = useState(false);

  const handleLogout = async () => {
    setLogoutLoading(true);
    try {
      await signOut();
      setLogoutLoading(false);
      navigate("/");
    } catch (err) {
      console.log(err);
      setLogoutLoading(false);
      toast.error(err.response.data.message);
    }
  };

  return (
    <nav className="sticky top-0 z-20 w-full m-0 font-sans bg-gray-100">
      <div className="bg-white">
        <div className="container px-4 mx-auto">
          <div className="flex items-center py-4">
            <div className="w-12 md:w-14">
              <Link to="/">
                <img src={logo} alt="Qliq" />
              </Link>
            </div>

            <div className="hidden ml-6 sm:flex sm:items-left">
              {location.pathname === "/sample-page" ? (
                <Link
                  to="/"
                  className="cursor-pointer text-gray-800 text-md font-semibold hover:text-[#5d5478] mr-4"
                >
                  Home
                </Link>
              ) : (
                <ScrollLink
                  to="slider"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  className="cursor-pointer text-gray-800 text-md font-semibold hover:text-[#5d5478] mr-4"
                >
                  Home
                </ScrollLink>
              )}

              {location.pathname === "/sample-page" ? (
                <Link
                  to="/"
                  className="cursor-pointer text-gray-800 text-md font-semibold hover:text-[#5d5478] mr-4"
                >
                  Pricing
                </Link>
              ) : (
                <ScrollLink
                  to="pricing"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  className="cursor-pointer text-gray-800 text-md font-semibold hover:text-[#5d5478] mr-4"
                >
                  Pricing
                </ScrollLink>
              )}

              <Link
                to="/sample-page"
                className="cursor-pointer text-gray-800 text-md font-semibold hover:text-[#5d5478] mr-4"
              >
                Features
              </Link>
              <Link
                to="/sample-page"
                className="cursor-pointer text-gray-800 text-md font-semibold hover:text-[#5d5478] mr-4"
              >
                Support
              </Link>
            </div>

            <div className="hidden ml-auto -space-x-4 sm:flex sm:items-center">
              {isAuthenticated ? (
                <div className="dropdown dropdown-end">
                  <div className="dropdown dropdown-end">
                    <label
                      tabIndex="0"
                      className="btn btn-ghost btn-circle avatar"
                    >
                      <div className="w-10 rounded-full">
                        <img
                          alt={user?.first_name}
                          src={
                            user?.profile
                              ? user?.profile?.url
                              : require("./../../../Images/default_user.png")
                          }
                        />
                      </div>
                    </label>
                    <ul
                      tabIndex="0"
                      className="p-2 mt-3 rounded shadow menu menu-compact dropdown-content bg-base-100 w-52"
                      style={{ boxShadow: "0 0 20px 0 #00000012" }}
                    >
                      <li>
                        <Link
                          to={`/dashboard`}
                          className="justify-between active:bg-transparent active:text-inherit"
                        >
                          Dashboard
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/my-card`}
                          className="justify-between active:bg-transparent active:text-inherit"
                        >
                          Profile
                          {/*<span className="badge">New</span>*/}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/analytics"
                          className="active:bg-transparent active:text-inherit"
                        >
                          Analytics
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/setting"
                          className="active:bg-transparent active:text-inherit"
                        >
                          Settings
                        </Link>
                      </li>
                      {isAuthenticated ? (
                        <li>
                          <button
                            onClick={handleLogout}
                            className="active:bg-transparent active:text-inherit"
                          >
                            {logoutLoading ? "Loading..." : "Logout"}
                          </button>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
              ) : (
                <>
                  <Link
                    to="/login"
                    className="mr-4 text-sm font-semibold text-gray-800 capitalize btn btn-ghost hover:text-brand-primary hover:bg-transparent"
                  >
                    Sign in
                  </Link>
                  <Link
                    to="/register"
                    className="btn btn-md btn-ghost capitalize text-sm font-semibold border px-4 py-2 rounded-lg bg-[#5d5478] text-white hover:text-brand-primary hover:border-brand-primary shadow-2xl hover:bg-transparent"
                  >
                    Sign Up
                  </Link>
                </>
              )}
            </div>

            <div className="absolute dropdown dropdown-end sm:hidden right-5 ">
              <label
                tabIndex="0"
                className="m-1 text-gray-800 bg-gray-100 border-none btn hover:bg-primary hover:text-white "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h8m-8 6h16"
                  />
                </svg>
              </label>
              <ul
                tabIndex="0"
                className="p-2 shadow dropdown-content menu bg-base-100 rounded-box w-52"
              >
                <li>
                  <ScrollLink
                    to="slider"
                    spy={true}
                    smooth={true}
                    className="active:bg-white focus:bg-white"
                  >
                    Home
                  </ScrollLink>
                </li>
                <li>
                  <ScrollLink
                    to="pricing"
                    spy={true}
                    smooth={true}
                    className="active:bg-white focus:bg-white"
                  >
                    Pricing
                  </ScrollLink>
                </li>
                <li>
                  <Link
                    to="/sample-page"
                    className="active:bg-white focus:bg-white"
                  >
                    Features
                  </Link>
                </li>
                <li>
                  <Link
                    to="/sample-page"
                    className="active:bg-white focus:bg-white"
                  >
                    Support
                  </Link>
                </li>
                {isAuthenticated ? (
                  <>
                    <li>
                      <Link
                        to="/analytics"
                        className="active:bg-white focus:bg-white"
                      >
                        Analytics
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/dashboard"
                        className="active:bg-white focus:bg-white"
                      >
                        Dashboard
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link
                        to="/login"
                        className="active:bg-white focus:bg-white"
                      >
                        Sign In
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/register"
                        className="active:bg-white focus:bg-white"
                      >
                        Sign Up
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
