import Axios from "axios";
import { BASE_API_URL } from "../Helpers/Env";

export const getContacts = async (config) => {
  try {
    let res = await Axios.get(`${BASE_API_URL}contacts`, config);
    return res.data.contacts;
  } catch (err) {
    console.log(err);
  }
};

export const getContact = (id) => Axios.get(`${BASE_API_URL}contacts/${id}`);

export const createContact = async (body) => {
  try {
    let res = await Axios.post(`${BASE_API_URL}contacts`, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateContact = async (body, id) => {
  try {
    let res = await Axios.post(`${BASE_API_URL}contacts/${id}`, body, {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const searchContact = async (keyword) => {
  let res = await Axios.get(`${BASE_API_URL}contacts?search=${keyword}`);
  return res.data.contacts.data;
};

export const deleteContact = async (id) => {
  try {
    let res = await Axios.delete(`${BASE_API_URL}contacts/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getTags = async () => {
  try {
    let res = await Axios.get(`${BASE_API_URL}tags?limit=100`);
    return res.data.tags.data;
  } catch (err) {
    console.log(err);
  }
};

export const createTag = async (body) => {
  try {
    let res = await Axios.post(`${BASE_API_URL}tags`, body);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateTag = async (body) => {
  const id = body.id;
  delete body.id;
  try {
    let res = await Axios.put(`${BASE_API_URL}tags/${id}`, body);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const tagCreate = (body) => Axios.post(`${BASE_API_URL}tags`, body);

export const deleteTag = async (id) => {
  try {
    let res = await Axios.delete(`${BASE_API_URL}tags/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const importContact = async (body) => {
  try {
    let res = await Axios.post(`${BASE_API_URL}contacts/import`, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const downloadContact = async (id) => {
  try {
    let res = await Axios.get(`${BASE_API_URL}contacts/${id}/download`, {
      headers: {
        "Content-Type": "text/vcard",
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const createTaskOfContact = async (body) => {
  return Axios.post(`${BASE_API_URL}contacts/task`, body, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

export const deleteContactSubTask = async (id) => {
  try {
    let res = await Axios.delete(
      `${BASE_API_URL}contacts/subtask/delete/${id}`
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateContactSubTaskStatus = async (data, id) => {
  try {
    let res = await Axios.put(
      `${BASE_API_URL}contacts/subtasks/status/${id}`,
      data
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateContactSubTask = async (data, id) => {
  try {
    let res = await Axios.post(
      `${BASE_API_URL}contacts/subtask/update/${id}`,
      data
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const createContactSubTask = async (body) => {
  try {
    let res = await Axios.post(`${BASE_API_URL}contacts/subtask`, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const addTagToContact = (data) => {
  return Axios.post(`${BASE_API_URL}contacts/tag`, data);
};

export const addNoteToContact = (data) => {
  return Axios.post(`${BASE_API_URL}contacts/note`, data);
};

export const addTaskToContact = (data) => {
  return Axios.post(`${BASE_API_URL}contacts/task`, data);
};

export const addSubTaskToContactTask = (data) =>
  Axios.post(`${BASE_API_URL}contacts/subtask`, data);

export const downloadContactNoAuth = (username) =>
  Axios.get(BASE_API_URL + `user-card-info/${username}/download`);

export const sendContactInformation = (data, username) =>
  Axios.post(BASE_API_URL + `user-card-info/${username}/contact`, data);
