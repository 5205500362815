import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tag from "../../Components/Tags/Tag";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InfiniteList from "react-infinite-scroll-list";
import { Dialog, DialogContent } from "@mui/material";
import {
  getContacts,
  getTags,
  createTag,
  searchContact,
  deleteContact,
  deleteTag,
  importContact,
  updateTag,
} from "../../Api/Contact";
import { debounce } from "lodash";
import Spinner from "../../Components/Spinner/Spinner";
import Dropdown from "../../Components/Dropdown/Dropdown";
import defaultUser from "../../Images/default_user.png";
import useAuth from "../../Hooks/useAuth";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { CHARACTERS_ALLOWED } from "../../Helpers/Env";
import ContactPreview from "./ContactPreview";
// import ExclamationCircleIcon from "icons/ExclamationCircale";

export const TabSocialIcons = [
  {
    name: "Massage",
    link: "/",
    photo: require("../../Images/icons/ChatDots.png"),
  },
  {
    name: "Phone",
    link: "/",
    photo: require("../../Images/icons/PhoneCall.png"),
  },
  {
    name: "Mail",
    link: "/",
    photo: require("../../Images/icons/Envelope.png"),
  },
  {
    name: "Edit",
    link: "/contacts/edit",
    photo: require("../../Images/icons/edit.png"),
  },
];

export default function Contacts() {
  let { user } = useAuth();
  const [contactsPerPage, setContactsPerPage] = useState(10);
  const [isLoading, setLoading] = useState(false);
  const [isEndReached, setEndReached] = useState(false);
  const [preview, setPreview] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [tag, setTag] = useState("");
  const [tagArr, setTagArr] = useState([]);
  const [tagArrAll, setTagArrAll] = useState([]);
  const [contactsSpinner, setContactsSpinner] = useState(true);
  const [isTagSpinner, setIsTagSpinner] = useState(true);
  const [tagAddLoading, setTagAddLoading] = useState(false);
  const [importedContact, setImportedContact] = useState();
  const [contactPreviewOpen, setContactPreviewOpen] = useState(false);
  const [showDeleteContactModal, setShowDeleteContactModal] = useState(false);
  const [selectedDeleteContact, setSelectedDeleteContact] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [updateTagModalOpen, setUpdateTagModalOpen] = useState(false);

  const allowedImportContactsFormats = ["vcf"];
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  const contactPreviewPopupOpen = (contact) => {
    //setPreview(contact);
    //setContactPreviewOpen(true);
    navigate(`/contacts/${contact.id}`, { state: { contact } });
  };

  const contactPreviewPopupClose = () => {
    setPreview(null);
    setContactPreviewOpen(false);
    fetchContacts(contactsPerPage);
  };

  const fetchContacts = useCallback(
    (limit) => {
      setLoading(true);
      setContactsPerPage(limit);
      getContacts({ params: { limit: contactsPerPage } })
        .then((_contacts) => {
          setContacts(_contacts.data);
          setContactsSpinner(false);
          setLoading(false);
          setEndReached(_contacts.current_page === _contacts.last_page);
        })
        .catch((err) => console.log(err));
    },
    [contactsPerPage]
  );

  const fetchTags = useCallback(() => {
    getTags()
      .then((tags) => {
        setIsTagSpinner(false);
        setTagArr(tags.filter((tag) => tag?.user_id === user?.id));
      })
      .catch((err) => {
        setIsTagSpinner(false);
        console.log(err);
      });
  }, [user]);

  const fetchAllTags = () => {
    getTags()
      .then((tags) => {
        // setIsTagSpinner(false);
        setTagArrAll(tags);
      })
      .catch((err) => {
        // setIsTagSpinner(false);
        console.log(err);
      });
  };

  useEffect(() => {
    fetchContacts();
    fetchTags();
    fetchAllTags();
    if (state) {
      const { contact } = state;
      if (contact) {
        setContactPreviewOpen(true);
        setPreview(contact);
        navigate(pathname, {});
      }
    }
  }, [fetchContacts, fetchTags, navigate, pathname, state]);

  const handleContactUpload = async () => {
    let formData = new FormData();
    formData.append("contact", importedContact);

    if (!importedContact) {
      toast.error("Upload file cannot be empty", { toastId: 1 });
    } else if (
      !allowedImportContactsFormats.includes(
        importedContact?.name?.split(".")[1]
      )
    ) {
      toast.error(`${allowedImportContactsFormats} formats are allowed`, {
        toastId: 1,
      });
    } else {
      importContact(formData)
        .then((res) => {
          if (res) {
            toast.success("Contact is created successfully", { toastId: 2 });
          } else {
            toast.error("Contact cannot be created", { toastId: 3 });
          }
        })
        .catch((err) => {
          toast.error("Contact cannot be created", { toastId: 4 });
        });
    }
  };

  const handleEdit = (contact) => {
    navigate("/contacts/edit", { state: { contact: contact } });
  };

  const searchContactFunc = async (event) => {
    try {
      let result = await searchContact(event.target.value);
      setContacts(result);
      if (result) setContactsSpinner(false);
    } catch (err) {
      setContactsSpinner(false);
      console.log(err);
    }
  };

  const handleChangeTag = (event) => {
    setTag(event.target.value);
  };

  const handleAddTags = async () => {
    if (tag.length > CHARACTERS_ALLOWED) {
      toast.error(
        `Tag Name cannot be greater than ${CHARACTERS_ALLOWED} characters`,
        { toastId: 1 }
      );
    } else {
      setTagAddLoading(true);
      let { id: user_id } = user;
      try {
        const res = await createTag({
          name: tag,
          color_id: 1,
          description: "test",
          user_id,
        });
        if (res?.message.includes("Tag Already Exists!")) {
          toast.error(res?.message);
        } else {
          toast.success(res?.message);
        }

        fetchTags();
        setTagAddLoading(false);
        setTag("");
      } catch (err) {
        setTagAddLoading(false);
        console.log(err);
      }
    }
  };

  const handleUpdateTag = () => {
    setIsTagSpinner(true);
    const reqObj = {
      ...selectedTag,
      color_id: selectedTag.color_id || 1,
    };

    updateTag(reqObj)
      .then((res) => {
        toast.success(res?.message);
        setUpdateTagModalOpen(false);
        fetchTags();
      })
      .catch((err) => {
        console.error(err.message);
        toast.error(err.message);
        setIsTagSpinner(false);
        setUpdateTagModalOpen(false);
      });
  };

  const handleDeleteTag = (tagId) => {
    setIsTagSpinner(true);
    deleteTag(tagId)
      .then((res) => {
        fetchTags();
        toast.success(res?.message);
      })
      .catch((err) => {
        console.error(err.response.data.message);
        setIsTagSpinner(false);
      });
  };

  const [deleteContactLoading, setDeleteContactLoading] = useState(false);
  const [willDeleteContact, setWillDeleteContact] = useState(null);

  const handleDelete = () => {
    setWillDeleteContact(selectedDeleteContact);
    setDeleteContactLoading(true);
    deleteContact(selectedDeleteContact?.id)
      .then((res) => {
        if (res) {
          toast.success("Contact is deleted successfully");
          fetchContacts();
          setDeleteContactLoading(fetchContacts);
          setShowDeleteContactModal(false);
          setSelectedDeleteContact(null);
        } else {
          toast.error("Contact is not deleted successfully");
          setDeleteContactLoading(false);
          setShowDeleteContactModal(false);
          setSelectedDeleteContact(null);
        }
      })
      .catch((err) => {
        toast.error("Contact is not deleted successfully");
        setDeleteContactLoading(false);
        setShowDeleteContactModal(false);
        setSelectedDeleteContact(null);
      });
  };

  const [isShown, setIsShown] = useState(false);
  const addNewTag = (value) => {
    setTag(value);
    setIsShown(false);
  };

  const handleClose = () => {
    setIsShown(false);
  };

  return (
    <div className="container mx-auto">
      <div className="p-4 bg-white rounded">
        <div className="flex flex-wrap gap-2">
          <Link to="/contacts/new-contact">
            <button className="tracking-tight w-[140px] text-white capitalize rounded btn btn-ghost btn-sm qliq-active-btn">
              New Contact
              <i className="ml-1 fa-solid fa-circle-plus"></i>
            </button>
          </Link>

          <label
            className="tracking-tight w-[145px] text-white capitalize rounded btn btn-ghost btn-sm qliq-active-btn"
            htmlFor="importContact"
          >
            Import Contact
            <i className="ml-1 fas fa-file-import"></i>
          </label>

          <label
            htmlFor="myTagPreview"
            className="m-0 tracking-tight w-[140px] text-white capitalize rounded mytags-btn btn btn-ghost btn-sm bg-brand-primary"
          >
            Add Tags
            <i className="ml-1 fa-solid fa-tag"></i>
          </label>
        </div>
      </div>

      <div className="mt-4 form-control">
        <div className="flex">
          <div className="input-group input-group-md">
            <span className="bg-white border border-gray-200 h-48-px border-r-transparent">
              <FontAwesomeIcon icon={faSearch} />
            </span>
            <input
              onChange={debounce(searchContactFunc, 300)}
              type="text"
              placeholder="Search by company, tag or name"
              className="w-full pl-0 border border-gray-200 input input-bordered border-l-transparent input-md focus:outline-none"
            />
          </div>
        </div>
      </div>

      {/* contacts & tags list */}
      <div className="flex justify-between w-full gap-12 py-8">
        {/*contacts list*/}
        <div className="w-full grow">
          <h4 className="text-xl font-bold">Contact List</h4>
          {contactsSpinner ? (
            <div className="flex items-center justify-center w-full md:h-2/4">
              <Spinner />
            </div>
          ) : contacts?.length > 0 ? (
            <div>
              <InfiniteList
                root="viewport"
                isLoading={isLoading}
                isEndReached={isEndReached}
                onReachThreshold={() => fetchContacts(contactsPerPage + 5)}
                containerClassName="grid grid-cols-1 mt-4 gap-2 w-full content-center min-h-88"
                containerTagName="div"
                threshold={150}
              >
                {contacts?.map((contact) => (
                  <div
                    key={contact?.id}
                    className="relative w-full px-2 py-3 mt-2 bg-white shadow cursor-pointer rounded-xl lg:w-3/4"
                  >
                    <div className="flex items-center w-full space-x-2">
                      <div className="order-1">
                        {deleteContactLoading &&
                        willDeleteContact.id === contact.id ? (
                          <Spinner />
                        ) : (
                          <Dropdown
                            param={contact}
                            menuItems={[
                              { name: "Edit", function: handleEdit },
                              {
                                name: "Delete",
                                function: () => {
                                  setSelectedDeleteContact(contact);
                                  setShowDeleteContactModal(true);
                                },
                              },
                            ]}
                          />
                        )}
                      </div>

                      <div
                        className="flex items-center w-full"
                        onClick={() => contactPreviewPopupOpen(contact)}
                      >
                        <img
                          className="object-cover rounded-full w-9 h-9 contact-photo"
                          src={
                            contact?.file_manager
                              ? contact?.file_manager?.url
                              : defaultUser
                          }
                          alt=""
                        />
                        <div className="flex flex-col w-3/4 ml-3 overflow-hidden">
                          <h2 className="mb-1 text-lg tracking-tight font-monospace contact-name">
                            {contact?.name}
                          </h2>
                          <div className="flex flex-wrap gap-1">
                            {contact?.tag?.map((item) => (
                              <span className="px-2 py-1 text-xs font-medium text-white rounded-md bg-primary">
                                {item?.name}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {isLoading && !isEndReached && (
                  <div className="flex items-center justify-center w-full mt-5 md:h-2/4">
                    <Spinner />
                  </div>
                )}
              </InfiniteList>
            </div>
          ) : (
            <h4 className="mt-4 font-lg text-md">No Contacts Found</h4>
          )}
        </div>

        {/* tags list */}
        <div className="hidden w-full p-8 border-l-2 border-gray-300 grow md:pt-0 md:inline">
          <h4 className="text-xl font-bold">Tags List</h4>
          <div className="flex">
            <input
              value={tag}
              className="w-full p-2 mt-4 mr-2 bg-gray-200 rounded-lg"
              placeholder="+ Create New Tags"
              onChange={handleChangeTag}
            />

            <Button
              size="sm"
              disabled={tagAddLoading}
              className="px-4 py-2 mt-5 font-semibold text-gray-600 bg-transparent border-2 border-gray-600 rounded hover:bg-purple-primary hover:text-white hover:border-transparent"
              onClick={handleAddTags}
            >
              {tagAddLoading ? "Adding..." : "Add Tag"}
            </Button>
          </div>
          {isTagSpinner ? (
            <div className="flex justify-center w-full mt-4 h-3/4">
              <Spinner />
            </div>
          ) : (
            <div className="flex flex-wrap gap-4 mt-8">
              {tagArr.length === 0 ? (
                <h4>There are no tags</h4>
              ) : (
                tagArr?.map((tag) => (
                  <Tag
                    key={tag.id}
                    title={tag?.name}
                    tagId={tag?.id}
                    handleDeleteTag={handleDeleteTag}
                    onClick={() => {
                      setSelectedTag(tag);
                      setUpdateTagModalOpen(true);
                    }}
                  />
                ))
              )}
            </div>
          )}
        </div>
      </div>

      {showDeleteContactModal && (
        <Dialog
          PaperProps={{ width: "100%" }}
          open={showDeleteContactModal}
          onClose={() => setShowDeleteContactModal(false)}
        >
          <DialogContent
            className="p-0 transition-qliq"
            style={{ overflowY: "unset" }}
          >
            <div className="relative p-6 w-[290px] sm:w-[350px]">
              <div className="absolute top-0 transform -translate-x-1/2 -translate-y-1/2 left-1/2">
                <div className=" flex justify-center items-center w-[70px] h-[70px] rounded-full bg-[#f3f3f3] border-[6px] border-white">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M28 8.97333C27.9733 8.97333 27.9333 8.97333 27.8933 8.97333C20.84 8.26667 13.8 8 6.82668 8.70667L4.10668 8.97333C3.54668 9.02667 3.05334 8.62667 3.00001 8.06667C2.94668 7.50667 3.34668 7.02667 3.89334 6.97333L6.61334 6.70667C13.7067 5.98667 20.8933 6.26667 28.0933 6.97333C28.64 7.02667 29.04 7.52 28.9867 8.06667C28.9467 8.58667 28.5067 8.97333 28 8.97333Z"
                      fill="#EB5757"
                    />
                    <path
                      d="M11.3333 7.62667C11.28 7.62667 11.2267 7.62667 11.16 7.61333C10.6267 7.52 10.2533 7 10.3467 6.46667L10.64 4.72C10.8533 3.44 11.1467 1.66667 14.2533 1.66667H17.7467C20.8667 1.66667 21.16 3.50667 21.36 4.73333L21.6533 6.46667C21.7467 7.01333 21.3733 7.53333 20.84 7.61333C20.2933 7.70667 19.7733 7.33333 19.6933 6.8L19.4 5.06667C19.2133 3.90667 19.1733 3.68 17.76 3.68H14.2667C12.8533 3.68 12.8267 3.86667 12.6267 5.05333L12.32 6.78667C12.24 7.28 11.8133 7.62667 11.3333 7.62667Z"
                      fill="#EB5757"
                    />
                    <path
                      d="M20.28 30.3333H11.72C7.06667 30.3333 6.88001 27.76 6.73334 25.68L5.86667 12.2533C5.82667 11.7067 6.25334 11.2267 6.80001 11.1867C7.36001 11.16 7.82667 11.5733 7.86667 12.12L8.73334 25.5467C8.88001 27.5733 8.93334 28.3333 11.72 28.3333H20.28C23.08 28.3333 23.1333 27.5733 23.2667 25.5467L24.1333 12.12C24.1733 11.5733 24.6533 11.16 25.2 11.1867C25.7467 11.2267 26.1733 11.6933 26.1333 12.2533L25.2667 25.68C25.12 27.76 24.9333 30.3333 20.28 30.3333Z"
                      fill="#EB5757"
                    />
                    <path
                      d="M18.2133 23H13.7733C13.2266 23 12.7733 22.5467 12.7733 22C12.7733 21.4533 13.2266 21 13.7733 21H18.2133C18.76 21 19.2133 21.4533 19.2133 22C19.2133 22.5467 18.76 23 18.2133 23Z"
                      fill="#EB5757"
                    />
                    <path
                      d="M19.3334 17.6667H12.6667C12.12 17.6667 11.6667 17.2133 11.6667 16.6667C11.6667 16.12 12.12 15.6667 12.6667 15.6667H19.3334C19.88 15.6667 20.3334 16.12 20.3334 16.6667C20.3334 17.2133 19.88 17.6667 19.3334 17.6667Z"
                      fill="#EB5757"
                    />
                  </svg>
                </div>
              </div>
              <div className="mt-5 mb-4 text-center">
                <h4 className="text-base font-[500] text-[#000000d9]">
                  Are you sure to delete the <br /> Contact?
                </h4>
                <h4 className="text-[12px] mt-2 font-[500] text-[#000000d9]">
                  You can’t undo this action
                </h4>
              </div>

              <div className="flex items-center justify-center mt-8">
                <div
                  className="px-4 mr-2 py-2 text-base border border-[#D9D9D9] text-[#000000d9] text-white bg-white rounded cursor-pointer"
                  onClick={() => setShowDeleteContactModal(false)}
                >
                  Cancel
                </div>
                <div
                  className="px-[15px] py-[6.4px] text-base text-white bg-[#EF5367] rounded cursor-pointer"
                  onClick={handleDelete}
                >
                  Delete
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}

      {contactPreviewOpen && preview && (
        <ContactPreview
          contact={preview}
          setContact={setPreview}
          isOpen={contactPreviewOpen}
          onClose={contactPreviewPopupClose}
        />
      )}

      <input type="checkbox" id="myTagPreview" className={`modal-toggle`} />
      <label className="cursor-pointer modal modalButton ">
        <label
          className="relative py-6 overflow-hidden modal-box"
          htmlFor=""
          onMouseLeave={() => handleClose()}
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-action">
                <label htmlFor="myTagPreview">
                  <i className="cursor-pointer fa-solid fa-xmark"></i>
                </label>
              </div>
              <h2 className="text-lg font-semibold tracking-tight user-name">
                Add Tags
              </h2>
            </div>
            <div className="mt-2 modal-body">
              <form action="">
                <InputGroup className="w-full ">
                  <Form.Control
                    value={tag}
                    placeholder="Create New Tag"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={handleChangeTag}
                  />
                  <Button
                    className="rounded-r-lg qliq-active-btn"
                    variant="success"
                    onClick={handleAddTags}
                  >
                    <i className="fa-solid fa-check"></i>
                  </Button>
                </InputGroup>

                {isShown && (
                  <div className="sticky w-full mt-2 h-60">
                    <ul className="w-full h-56 overflow-x-auto  mt-0.2 absolute border border-gray-200 rounded shadow-md z-50">
                      {tagArrAll?.map((tag) => (
                        <li
                          className="px-4 py-2 transition-all duration-300 ease-in-out bg-white border-b border-gray-200 cursor-pointer hover:bg-gray-700 hover:text-gray-50 last:border-none"
                          onClick={() => addNewTag(`${tag.name}`)}
                        >
                          {tag.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </form>
              <div className="mt-3 all-tags">
                <div className="flex flex-wrap gap-2">
                  {isTagSpinner ? (
                    <Spinner />
                  ) : (
                    tagArr?.map((tag, idx) => (
                      <Tag
                        key={idx}
                        title={tag?.name}
                        tagId={tag?.id}
                        handleDeleteTag={handleDeleteTag}
                        onClick={() => {
                          setSelectedTag(tag);
                          setUpdateTagModalOpen(true);
                        }}
                      />
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </label>
      </label>

      {/* Import contact modal */}
      <input type="checkbox" id="importContact" className={`modal-toggle`} />
      <label className="cursor-pointer modal modalButton ">
        <label className="relative py-6 modal-box" htmlFor="">
          <h3 className="mb-4 text-xl font-bold">Import Contacts</h3>

          <div className="flex justify-center">
            <div className="mb-3 w-96">
              <label
                htmlFor="formFile"
                className="inline-block mb-2 text-gray-700 form-label"
              >
                Format: vcf
              </label>
              <input
                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700focus:bg-white focus:border-blue-600 focus:outline-none"
                type="file"
                id="formFile"
                accept=".vcf"
                onChange={(e) => setImportedContact(e.target.files[0])}
              />
            </div>
          </div>

          <div className="flex justify-between mt-8">
            <label
              className="px-4 py-2 font-bold text-white bg-blue-500 rounded cursor-pointer"
              htmlFor="importContact"
              onClick={handleContactUpload}
            >
              Import Contact
            </label>
            <label
              className="px-4 py-2 font-bold text-white bg-gray-500 rounded cursor-pointer"
              htmlFor="importContact"
            >
              Cancel
            </label>
          </div>
        </label>
      </label>

      {/* update tag modal */}
      <Dialog open={updateTagModalOpen}>
        <DialogContent className="p-0 transition-qliq w-full min-w-[310px] sm:w-[480px]">
          <button
            className="absolute top-3 right-4"
            onClick={() => setUpdateTagModalOpen(false)}
          >
            ✕
          </button>

          <div className="px-6 py-7">
            <h3 className="mb-6 text-2xl font-bold">Update Tag</h3>

            <input
              required
              className="w-full p-2 border border-gray-400 rounded"
              placeholder="Enter your tag name"
              value={selectedTag?.name}
              onChange={(e) =>
                setSelectedTag({
                  ...selectedTag,
                  name: e.target.value,
                })
              }
            />

            <button
              // type="submit"
              type="button"
              onClick={handleUpdateTag}
              className="block w-full px-4 py-2 mt-4 font-semibold text-white border-0 rounded-lg btn bg-primary hover:bg-purple-primary"
            >
              Update Tag
            </button>
            {/* </form> */}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
