import { useNavigate } from "react-router-dom";
import CardItem from "./Components/CardItem";
import useAuth from "Hooks/useAuth";
import avatar from "Images/default_user.png";
import ShareCard from "Components/ShareCard";

const cards = [
  {
    icon: "./assets/images/icons/tasks.svg",
    title: "Tasks",
    url: "/task",
    htmlFor: "",
  },
  {
    icon: "./assets/images/icons/analytics.svg",
    title: "Analytics",
    url: "/analytics",
    htmlFor: "",
  },
  {
    icon: "./assets/images/icons/contacts.svg",
    title: "Contacts",
    url: "/contacts",
    htmlFor: "",
  },
  {
    icon: "./assets/images/icons/contact-add.svg",
    title: "Add Contacts",
    url: "/contacts/new-contact",
    htmlFor: "",
  },
  {
    icon: "./assets/images/icons/share-qr.svg",
    title: "Share QR Code",
    url: "#",
    htmlFor: "shareWithQR",
  },
  {
    icon: "./assets/images/icons/scan-business-card.svg",
    title: "Scan Business Card",
    url: "/contacts/business-cards",
    htmlFor: "",
  },
];

const smallCards = [
  {
    icon: "./assets/images/icons/settings.svg",
    title: "Settings",
    url: "/setting",
    htmlFor: "",
  },
  {
    icon: "./assets/images/icons/share.svg",
    title: "Share",
    url: "/share",
    htmlFor: "shareWithQR",
  },
];

const DashboardPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <div
      style={{
        backgroundImage: "url('/assets/images/dashboard-bg.png')",
      }}
      className={`w-full sm:!bg-none bg-no-repeat bg-cover bg-center min-h-[calc(100vh-67px)]`}
    >
      <h5 className="text-center w-full pt-4 md:pt-8 text-[20px] font-[600] hidden sm:block">
        Dashboard
      </h5>

      <section className="flex px-6 py-4 sm:hidden">
        <div
          className="flex flex-col items-center"
          onClick={() => navigate("/my-card")}
        >
          <img
            className="w-[70px] h-[70px] rounded-full shadow-card-button"
            alt={user?.first_name}
            src={user?.profile?.url || avatar}
          />
          <h6 className="text-[#4D4D4D] font-raleway text-sm">My Card</h6>
        </div>
      </section>

      <section className="flex justify-center sm:pt-14">
        <div className="grid items-center min-w-[calc(160px*2+16px)] sm:max-w-[calc(190px*3+32px*2)] grid-cols-2 gap-4 sm:grid-cols-3 sm:gap-8">
          {cards.map((card) => (
            <CardItem
              key={card.title}
              icon={card.icon}
              title={card.title}
              className="basis-1/4 md:basis-1/3"
              onClick={() => navigate(card.url)}
              htmlFor={card.htmlFor}
            />
          ))}
        </div>
      </section>

      <ShareCard buttonType="dashboard">
        <section className="flex justify-center gap-4 py-4 sm:hidden">
          {smallCards.map((card) => (
            <label
              key={card.title}
              className="flex flex-col items-center"
              onClick={() =>
                card.htmlFor.length === 0 ? navigate(card.url) : null
              }
              htmlFor={card.htmlFor}
            >
              <div className="flex items-center justify-center w-[85px] h-[85px] rounded-full bg-dashboard-icon shadow-card-button">
                <img className="w-10 h-10" src={card.icon} alt={card.title} />
              </div>
              <h6 className="text-[#4D4D4D] font-raleway text-sm">
                {card.title}
              </h6>
            </label>
          ))}
        </section>
      </ShareCard>
    </div>
  );
};

export default DashboardPage;
