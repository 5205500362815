import axios from "axios";
import { BASE_API_URL } from "../Helpers/Env";

export const getUser = (config) => axios.get(BASE_API_URL + "profile", config);

export const updateProfile = (data) =>
  axios.put(BASE_API_URL + "profile", data);

export const updateLiveStatus = (status) =>
  axios.get(BASE_API_URL + `profile/go/live?go_live_status=${status}`); // 1 for live, 0 for not live

export const updatePassword = (data) =>
  axios.put(BASE_API_URL + "profile/password", data);

export const uploadProfilePicture = (data) =>
  axios.post(BASE_API_URL + "profile/upload", data, {
    "Content-Type": "multipart/form-data",
  });

export const uploadCardLogo = (data) =>
  axios.post(BASE_API_URL + "profile/logo/upload", data, {
    "Content-Type": "multipart/form-data",
  });

export const getUserCardInfo = (username) =>
  axios.get(BASE_API_URL + "user-card-info/" + username);

export const setUserCardShare = (username) =>
  axios.get(BASE_API_URL + "user-card-share/" + username);
