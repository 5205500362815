import React, { useCallback, useEffect, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
// import TextField from "@mui/material/TextField";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getContacts } from "Api/Contact";
import { debounce } from "lodash";
import defaultUser from "Images/default_user.png";
import calendarIcon from "icons/calendar-icon.svg";
import profileIcon from "icons/profile-icon.svg";
import Spinner from "Components/Spinner/Spinner";
import { createTask } from "Api/Task";
import { toast } from "react-toastify";

const CreateTaskModal = ({
  open,
  onClose,
  isError,
  taskInputRef,
  setTaskName,
  subTaskInputRef,
  setSubTaskInput,
  subTasks,
  handleSubTasks,
  isDate,
  setIsDate,
  handleAddTask,
  fetchTasks,
}) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [error, setError] = useState({
    title: "",
    date: "",
  });
  const [contacts, setContacts] = useState([]);
  const [searchContacts, setSearchContacts] = useState([]);
  const [contactSearchLoading, setContactSearchLoading] = useState(false);
  const [contactSearchLoadingLink, setContactSearchLoadingLink] =
    useState(false);
  const [contactSearchParam, setContactSearchParam] = useState("");
  const [contactSearchParamLink, setContactSearchParamLink] = useState("");
  const [assignContacts, setAssignContacts] = useState([]);
  const [linkContacts, setLinkContacts] = useState([]);
  const [isShowAddContactSection, setIsShowAddContactSection] = useState(false);
  const [subTaskList, setSubTaskList] = useState([]);

  const searchContact2 = useCallback(
    async (param) => {
      let data = contacts;
      // console.log("param", param);

      if (param !== "") {
        data = await contacts?.filter((item) =>
          item?.name?.toLowerCase()?.includes(param?.toLowerCase())
        );
      } else {
        data = contacts;
      }

      return data;
    },
    [contacts]
  );

  const handleAddSubTask = (e) => {
    e.preventDefault();

    setSubTaskList([
      ...subTaskList,
      {
        id: subTaskList?.length > 0 ? subTaskList?.length + 1 : 1,
        title: "",
        deadline: null,
        assigns: [],
        contactSearchLoading: false,
        contactSearchParam: "",
        searchContacts: [],
        showErrorMessage: false,
        error: {
          title: "",
          date: "",
        },
      },
    ]);
  };

  const handleSubTaskInput = (e, id) => {
    const index = subTaskList.findIndex((item) => item.id === id);
    const newSubTaskList = [...subTaskList];
    newSubTaskList[index].title = e.target.value;
    newSubTaskList[index].error.title = "";
    setSubTaskList(newSubTaskList);
  };

  const handleSubTaskDate = (date, id) => {
    let isValidYear = false;

    if (date?.$y > 2022 && date?.$y < 2025) {
      isValidYear = true;
    }
    if (
      date === null ||
      Object.keys(date).length === 0 ||
      String(date?.$d).includes("Invalid Date") ||
      isValidYear === false
    ) {
      if (subTaskList.length > 0) {
        subTaskList.map((item) => {
          return {
            ...item,
            error: {
              title: item.title.length === 0 ? "You must add Task Name" : "",
              date: item.deadline === null ? "You must add a deadline" : "",
            },
            showErrorMessage: item.title.length === 0 || item.deadline === null,
          };
        });
      }
    } else {
      const index = subTaskList.findIndex((item) => item.id === id);
      const newSubTaskList = [...subTaskList];
      newSubTaskList[index].deadline = date;
      newSubTaskList[index].error.date = "";
      setSubTaskList(newSubTaskList);
    }
  };

  const handleSubTaskSearch = async (e, id) => {
    const index = subTaskList.findIndex((item) => item.id === id);
    const newSubTaskList = [...subTaskList];
    newSubTaskList[index].contactSearchParam = e.target.value;
    if (e.target.value !== "") {
      newSubTaskList[index].contactSearchLoading = true;
      const data = await searchContact2(e.target.value);

      // console.log("search data", data);
      newSubTaskList[index].searchContacts = data;
      newSubTaskList[index].contactSearchLoading = false;
      setSubTaskList(newSubTaskList);
    } else {
      newSubTaskList[index].searchContacts = contacts;
      setSubTaskList(newSubTaskList);
    }

    // console.log("subTaskList", newSubTaskList);
    setSubTaskList(newSubTaskList);
  };

  const handleSubTaskAssign = (contact, id) => {
    const index = subTaskList.findIndex((item) => item.id === id);
    const newSubTaskList = [...subTaskList];
    newSubTaskList[index].assigns = [...newSubTaskList[index].assigns, contact];
    setSubTaskList(newSubTaskList);
  };

  const onAddTaskSubmit = (e) => {
    e.preventDefault();

    let isValidYear = false;

    if (isDate?.$y > 2022 && isDate?.$y < 2025) {
      isValidYear = true;
    }

    if (taskInputRef.current.value.length === 0) {
      setError({ ...error, title: "You must add Task Name" });
      setShowErrorMessage(true);
    } else if (
      isDate === null ||
      Object.keys(isDate).length === 0 ||
      String(isDate?.$d).includes("Invalid Date") ||
      isValidYear === false
    ) {
      setError({ ...error, date: "You must add a deadline" });
      setShowErrorMessage(true);
    } else {
      setError({ ...error, title: "", date: "" });
      setShowErrorMessage(false);
      let _subTaskList = subTaskList;

      if (subTaskList.length > 0) {
        _subTaskList = subTaskList.map((item) => {
          return {
            ...item,
            error: {
              title: item.title.length === 0 ? "You must add Task Name" : "",
              date: item.deadline === null ? "You must add a deadline" : "",
            },
            showErrorMessage: item.title.length === 0 || item.deadline === null,
          };
        });

        setSubTaskList(_subTaskList);
      }

      const isShowError = _subTaskList.find((item) => item.showErrorMessage);

      if (!isShowError) {
        const reqData = {
          title: taskInputRef.current.value,
          deadline: isDate ? isDate.format("YYYY-MM-DD") : null,
          assignContacts,
          linkContacts,
          subTaskList,
        };

        // console.log("reqData", reqData);

        let formData = new FormData();
        formData.append("title", reqData.title);
        formData.append("deadline", reqData.deadline);
        formData.append("status", 1);

        reqData?.assignContacts?.forEach((item, index) => {
          formData.append(`task_assigns[${index}][contact_id]`, item.id);
        });

        reqData?.linkContacts?.forEach((item, index) => {
          formData.append(`task_add[${index}][contact_id]`, item.id);
        });

        reqData?.subTaskList?.forEach((item, index) => {
          formData.append(`subtasks[${index}][title]`, item.title);
          formData.append(
            `subtasks[${index}][deadline]`,
            item.deadline ? item.deadline.format("YYYY-MM-DD") : null
          );
          formData.append(`subtasks[${index}][status]`, 1);
          item?.assigns?.forEach((item2, index2) => {
            formData.append(
              `subtasks[${index}][assigns][${index2}][contact_id]`,
              item2.id
            );
          });
        });

        createTask(formData)
          .then((res) => {
            fetchTasks();
            toast.success(res.data.message); // "Task Created Successfully"

            // reset form
            setTaskName("");
            setIsDate(new Date());
            setSubTaskInput("");
            setAssignContacts([]);
            setLinkContacts([]);
            setIsShowAddContactSection(false);
            setSearchContacts([]);
            setContactSearchParam("");
            setContactSearchParamLink("");
            setSubTaskList([]);

            // close modal
            onClose();
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            // setIsTaskSpinner(false);
          });
      }
    }
  };

  useEffect(() => {
    getContacts()
      .then((res) => {
        setContacts(res?.data);
        setSearchContacts(res?.data);
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    const handleSearchContact = debounce(async (param) => {
      setContactSearchLoading(true);
      // const data = await searchContact(param);
      const data = await searchContact2(param);
      setSearchContacts(data);
      setContactSearchLoading(false);
    }, 1000);

    if (contactSearchParam !== "") {
      handleSearchContact(contactSearchParam);
    } else {
      setSearchContacts(contacts);
    }
  }, [contactSearchParam, contacts, searchContact2]);

  useEffect(() => {
    const handleSearchContact = debounce(async (param) => {
      setContactSearchLoadingLink(true);
      // const data = await searchContact(param);
      const data = await searchContact2(param);
      setContactSearchParamLink(data);
      setContactSearchLoadingLink(false);
    }, 1000);

    if (contactSearchParamLink !== "") {
      handleSearchContact(contactSearchParamLink);
    } else {
      setContactSearchParamLink(contacts);
    }
  }, [contactSearchParamLink, contacts, searchContact2]);

  return (
    <Dialog open={open}>
      <DialogContent className="p-0 transition-qliq w-full min-w-[310px] sm:w-[480px]">
        <div className="px-6 py-7">
          {/* title section */}
          <section className="flex items-center justify-between mb-[24px]">
            <h3 className="text-[20px] font-semibold leading-6 text-[#1F2937]">
              Create Task
            </h3>

            <button onClick={onClose}>✕</button>
          </section>

          <p
            className="font-medium text-sm leading-4 text-[#4F4F4F] mt-[16px]"
            style={{ marginBottom: "10px" }}
          >
            Create Task Name
          </p>
          <input
            required
            className="w-full p-[12px] border border-[#E3E3E3] rounded-[8px] bg-[#FAFAFB] font-normal text-sm text-[#9FA6B2]"
            placeholder="Task Name"
            onChange={(e) => {
              setTaskName(e.target.value);
              setError({ ...error, title: "" });
            }}
            ref={taskInputRef}
          />
          {showErrorMessage && error.title.length > 0 && (
            <p className="font-medium text-xs leading-4 text-[#EB5757]">
              {error.title}
            </p>
          )}

          {/* Assignee */}
          {/* <section>
            <p
              className="font-medium text-sm leading-4 text-[#4F4F4F] mt-[16px]"
              style={{ marginBottom: "10px" }}
            >
              Assignee
            </p>

            <div className="w-full p-[12px] border border-[#E3E3E3] rounded-[8px] bg-[#FAFAFB] mt-[10px] font-normal text-sm text-[#9FA6B2]">
              <div className="flex items-center">
                {contactSearchLoading ? (
                  <div className="flex justify-center mr-[4px]">
                    <Spinner />
                  </div>
                ) : (
                  <img src={profileIcon} alt="profile" className="mr-[4px]" />
                )}
                <input
                  type="text"
                  className="w-full font-normal text-sm text-[#9FA6B2] bg-transparent focus:outline-none"
                  placeholder="Write Name"
                  onChange={(event) =>
                    setContactSearchParam(event.target.value)
                  }
                />
              </div>

              {contacts?.filter((contact) =>
                assignContacts.some(
                  (selectedContact) => selectedContact.id === contact.id
                )
              ).length > 0 && (
                <>
                  <p
                    className="text-xs font-medium leading-4 text-[#4F4F4F] mt-[16px]"
                    style={{
                      marginBottom: "8px",
                    }}
                  >
                    Assigned Contact
                  </p>

                  <div className="flex flex-wrap gap-[8px]">
                    {contacts
                      ?.filter((contact) =>
                        assignContacts.some(
                          (selectedContact) => selectedContact.id === contact.id
                        )
                      )
                      .map((contact, index) => (
                        <div
                          className="flex items-center rounded-[23px] border border-[#EAE7E7] bg-white py-[4px] pl-[4px] pr-[8px]"
                          key={index}
                        >
                          <img
                            className="w-6 h-6 rounded-full"
                            src={
                              contact?.file_manager
                                ? contact?.file_manager?.url
                                : defaultUser
                            }
                            alt="avatar"
                          />
                          <p
                            className="text-sm font-medium leading-4 text-[#4F4F4F] mx-[8px]"
                            style={{ marginBottom: 0 }}
                          >
                            {contact?.name}
                          </p>
                          <button
                            className="h-[20px] w-[20px] rounded-full bg-[#EAEAEA] flex justify-center items-center"
                            onClick={() => {
                              setAssignContacts(
                                assignContacts.filter(
                                  (selectedContact) =>
                                    selectedContact.id !== contact.id
                                )
                              );
                            }}
                          >
                            <span className="text-[11.25px] leading-[0px] text-[#EA4C89]">
                              ✕
                            </span>
                          </button>
                        </div>
                      ))}
                  </div>
                </>
              )}
            </div>

            <div className="flex flex-wrap gap-[8px] mt-[10px] mb-[20px]">
              {searchContacts
                ?.filter(
                  (contact) =>
                    !assignContacts.some(
                      (selectedContact) => selectedContact.id === contact.id
                    )
                )
                ?.slice(0, 7)
                ?.map((contact, index) => (
                  <div
                    className="flex items-center rounded-[23px] border border-[#EAE7E7] py-[4px] pl-[4px] pr-[8px] cursor-pointer bg-[#F2F2F2]"
                    key={index}
                    onClick={() => {
                      setAssignContacts([...assignContacts, contact]);
                    }}
                  >
                    <img
                      className="w-6 h-6 rounded-full"
                      src={
                        contact?.file_manager
                          ? contact?.file_manager?.url
                          : defaultUser
                      }
                      alt="avatar"
                    />
                    <p
                      className="text-sm font-medium leading-4 text-[#4F4F4F] ml-[8px]"
                      style={{ marginBottom: 0 }}
                    >
                      {contact?.name}
                    </p>
                  </div>
                ))}
            </div>
          </section> */}

          {/* link contact */}
          {/* {isShowAddContactSection ? (
            <section>
              <p
                className="font-medium text-sm leading-4 text-[#4F4F4F] mt-[16px]"
                style={{ marginBottom: "10px" }}
              >
                Link Contact
              </p>

              <div className="w-full p-[12px] border border-[#E3E3E3] rounded-[8px] bg-[#FAFAFB] mt-[10px] font-normal text-sm text-[#9FA6B2]">
                <div className="flex items-center">
                  {contactSearchLoadingLink ? (
                    <div className="flex justify-center mr-[4px]">
                      <Spinner />
                    </div>
                  ) : (
                    <img src={profileIcon} alt="profile" className="mr-[4px]" />
                  )}
                  <input
                    type="text"
                    className="w-full font-normal text-sm text-[#9FA6B2] bg-transparent focus:outline-none"
                    placeholder="Write Name"
                    onChange={(event) =>
                      setContactSearchParamLink(event.target.value)
                    }
                  />
                </div>

                {contacts?.filter((contact) =>
                  linkContacts.some(
                    (selectedContact) => selectedContact.id === contact.id
                  )
                ).length > 0 && (
                  <>
                    <p
                      className="text-xs font-medium leading-4 text-[#4F4F4F] mt-[16px]"
                      style={{
                        marginBottom: "8px",
                      }}
                    >
                      Added Contact
                    </p>

                    <div className="flex flex-wrap gap-[8px]">
                      {searchContacts
                        ?.filter((contact) =>
                          linkContacts.some(
                            (selectedContact) =>
                              selectedContact.id === contact.id
                          )
                        )
                        .map((contact, index) => (
                          <div
                            className="flex items-center rounded-[23px] border border-[#EAE7E7] bg-white py-[4px] pl-[4px] pr-[8px]"
                            key={index}
                          >
                            <img
                              className="w-6 h-6 rounded-full"
                              src={
                                contact?.file_manager
                                  ? contact?.file_manager?.url
                                  : defaultUser
                              }
                              alt="avatar"
                            />
                            <p
                              className="text-sm font-medium leading-4 text-[#4F4F4F] mx-[8px]"
                              style={{ marginBottom: 0 }}
                            >
                              {contact?.name}
                            </p>
                            <button
                              className="h-[20px] w-[20px] rounded-full bg-[#EAEAEA] flex justify-center items-center"
                              onClick={() => {
                                setLinkContacts(
                                  linkContacts.filter(
                                    (selectedContact) =>
                                      selectedContact.id !== contact.id
                                  )
                                );
                              }}
                            >
                              <span className="text-[11.25px] leading-[0px] text-[#EA4C89]">
                                ✕
                              </span>
                            </button>
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </div>

              <div className="flex flex-wrap gap-[8px] mt-[10px] mb-[20px]">
                {contacts
                  ?.filter(
                    (contact) =>
                      !linkContacts.some(
                        (selectedContact) => selectedContact.id === contact.id
                      )
                  )
                  ?.map((contact, index) => (
                    <div
                      className="flex items-center rounded-[23px] border border-[#EAE7E7] py-[4px] pl-[4px] pr-[8px] cursor-pointer bg-[#F2F2F2]"
                      key={index}
                      onClick={() => {
                        setLinkContacts([...linkContacts, contact]);
                      }}
                    >
                      <img
                        className="w-6 h-6 rounded-full"
                        src={
                          contact?.file_manager
                            ? contact?.file_manager?.url
                            : defaultUser
                        }
                        alt="avatar"
                      />
                      <p
                        className="text-sm font-medium leading-4 text-[#4F4F4F] ml-[8px]"
                        style={{ marginBottom: 0 }}
                      >
                        {contact?.name}
                      </p>
                    </div>
                  ))}
              </div>
            </section>
          ) : (
            <div className="flex justify-end">
              <button
                type="button"
                className="cursor-pointer text-[15px] font-[500] flex items-center text-[#E45C8C]"
                onClick={() => {
                  setIsShowAddContactSection(true);
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-[8px]"
                >
                  <path
                    d="M9.41406 2.96875H10.5859C10.6901 2.96875 10.7422 3.02083 10.7422 3.125V16.875C10.7422 16.9792 10.6901 17.0312 10.5859 17.0312H9.41406C9.3099 17.0312 9.25781 16.9792 9.25781 16.875V3.125C9.25781 3.02083 9.3099 2.96875 9.41406 2.96875Z"
                    fill="#E45C8C"
                  />
                  <path
                    d="M3.4375 9.25781H16.5625C16.6667 9.25781 16.7188 9.3099 16.7188 9.41406V10.5859C16.7188 10.6901 16.6667 10.7422 16.5625 10.7422H3.4375C3.33333 10.7422 3.28125 10.6901 3.28125 10.5859V9.41406C3.28125 9.3099 3.33333 9.25781 3.4375 9.25781Z"
                    fill="#E45C8C"
                  />
                </svg>
                Link Contact
              </button>
            </div>
          )} */}

          {/* deadline */}
          <p
            className="text-xs font-medium leading-4 text-[#4F4F4F] mt-[16px]"
            style={{ marginBottom: "8px" }}
          >
            Deadline
          </p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={isDate}
              minDate={moment().toDate()}
              onChange={(newValue) => {
                setIsDate(newValue);
              }}
              // label="dd/mm/year"
              // renderInput={(params) => (
              //   <TextField
              //     fullWidth
              //     {...params}
              //     size="small"
              //     // placeholder="Task Deadline"
              //     helperText={params?.inputProps?.placeholder}
              //   />
              // )}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <div className="border border-[#E3E3E3] rounded-[8px] bg-[#FAFAFB] flex justify-between items-center p-[12px]">
                  <input
                    ref={inputRef}
                    {...inputProps}
                    className="w-full bg-transparent focus:outline-none"
                    // placeholder="dd/mm/year"
                  />
                  {InputProps?.endAdornment}
                </div>
              )}
              // className="w-full border border-[#E3E3E3] rounded-[8px] bg-[#FAFAFB] font-normal text-sm text-[#9FA6B2]"
              components={{
                OpenPickerIcon: () => (
                  <img src={calendarIcon} alt="calendar icon" />
                ),
              }}
            />
          </LocalizationProvider>

          {showErrorMessage && error.date.length > 0 && (
            <p className="font-medium text-xs leading-4 text-[#EB5757]">
              {error.date}
            </p>
          )}

          {/* ==============================start: subtask============================== */}
          {subTaskList?.map((subTask) => (
            <section key={`subtask-${subTask.id}`}>
              <hr className="my-[20px]" />

              <div className="flex items-center justify-between mb-[4px]">
                <h3 className="text-[16px] font-semibold leading-5 text-[#1F2937]">
                  Add Sub Task #{subTask.id}
                </h3>

                <button
                  onClick={() => {
                    setSubTaskList(
                      subTaskList.filter((item) => item.id !== subTask.id)
                    );
                  }}
                >
                  ✕
                </button>
              </div>
              <p
                className="font-medium text-sm leading-4 text-[#4F4F4F] mt-[16px]"
                style={{ marginBottom: "10px" }}
              >
                Sub Task Name
              </p>
              <input
                required
                className="w-full p-[12px] border border-[#E3E3E3] rounded-[8px] bg-[#FAFAFB] font-normal text-sm text-[#9FA6B2]"
                placeholder="Sub Task Name"
                // onChange={(e) => setSubTaskInput(e.target.value)}
                // ref={subTaskInputRef}
                value={subTask.subTaskName}
                onChange={(e) => {
                  handleSubTaskInput(e, subTask.id);
                }}
              />

              {subTask.showErrorMessage && subTask.error.title.length > 0 && (
                <p className="font-medium text-xs leading-4 text-[#EB5757]">
                  {subTask.error.title}
                </p>
              )}

              {/* Assignee */}
              {/* <section>
                <p
                  className="font-medium text-sm leading-4 text-[#4F4F4F] mt-[16px]"
                  style={{ marginBottom: "10px" }}
                >
                  Assignee
                </p>

                <div className="w-full p-[12px] border border-[#E3E3E3] rounded-[8px] bg-[#FAFAFB] mt-[10px] font-normal text-sm text-[#9FA6B2]">
                  <div className="flex items-center">
                    {subTask.contactSearchLoading ? (
                      <div className="flex justify-center mr-[4px]">
                        <Spinner />
                      </div>
                    ) : (
                      <img
                        src={profileIcon}
                        alt="profile"
                        className="mr-[4px]"
                      />
                    )}
                    <input
                      type="text"
                      className="w-full font-normal text-sm text-[#9FA6B2] bg-transparent focus:outline-none"
                      placeholder="Write Name"
                      // onChange={(event) =>
                      //   setContactSearchParam(event.target.value)
                      // }
                      onChange={(event) =>
                        handleSubTaskSearch(event, subTask.id)
                      }
                    />
                  </div>

                  {contacts?.filter((contact) =>
                    subTask.assigns.some(
                      (selectedContact) => selectedContact.id === contact.id
                    )
                  ).length > 0 && (
                    <>
                      <p
                        className="text-xs font-medium leading-4 text-[#4F4F4F] mt-[16px]"
                        style={{
                          marginBottom: "8px",
                        }}
                      >
                        Assigned Contact
                      </p>

                      <div className="flex flex-wrap gap-[8px]">
                        {contacts
                          ?.filter((contact) =>
                            subTask.assigns.some(
                              (selectedContact) =>
                                selectedContact.id === contact.id
                            )
                          )
                          .map((contact, index) => (
                            <div
                              className="flex items-center rounded-[23px] border border-[#EAE7E7] bg-white py-[4px] pl-[4px] pr-[8px]"
                              key={index}
                            >
                              <img
                                className="w-6 h-6 rounded-full"
                                src={
                                  contact?.file_manager
                                    ? contact?.file_manager?.url
                                    : defaultUser
                                }
                                alt="avatar"
                              />
                              <p
                                className="text-sm font-medium leading-4 text-[#4F4F4F] mx-[8px]"
                                style={{ marginBottom: 0 }}
                              >
                                {contact?.name}
                              </p>
                              <button
                                className="h-[20px] w-[20px] rounded-full bg-[#EAEAEA] flex justify-center items-center"
                                onClick={() => {
                                  setAssignContacts(
                                    assignContacts.filter(
                                      (selectedContact) =>
                                        selectedContact.id !== contact.id
                                    )
                                  );
                                }}
                              >
                                <span className="text-[11.25px] leading-[0px] text-[#EA4C89]">
                                  ✕
                                </span>
                              </button>
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                </div>

                <div className="flex flex-wrap gap-[8px] mt-[10px] mb-[20px]">
                  {subTask.searchContacts
                    ?.filter(
                      (contact) =>
                        !subTask.assigns.some(
                          (selectedContact) => selectedContact.id === contact.id
                        )
                    )
                    ?.slice(0, 7)
                    ?.map((contact, index) => (
                      <div
                        className="flex items-center rounded-[23px] border border-[#EAE7E7] py-[4px] pl-[4px] pr-[8px] cursor-pointer bg-[#F2F2F2]"
                        key={index}
                        onClick={() => {
                          // setAssignContacts([...assignContacts, contact]);
                          handleSubTaskAssign(contact, subTask.id);
                        }}
                      >
                        <img
                          className="w-6 h-6 rounded-full"
                          src={
                            contact?.file_manager
                              ? contact?.file_manager?.url
                              : defaultUser
                          }
                          alt="avatar"
                        />
                        <p
                          className="text-sm font-medium leading-4 text-[#4F4F4F] ml-[8px]"
                          style={{ marginBottom: 0 }}
                        >
                          {contact?.name}
                        </p>
                      </div>
                    ))}
                </div>
              </section> */}

              <p
                className="text-xs font-medium leading-4 text-[#4F4F4F] mt-[16px]"
                style={{ marginBottom: "8px" }}
              >
                Deadline
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  // value={isDate}
                  value={subTask.deadline}
                  minDate={moment().toDate()}
                  // onChange={(newValue) => setIsDate(newValue)}
                  onChange={(newValue) => {
                    handleSubTaskDate(newValue, subTask.id);
                  }}
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    <div className="border border-[#E3E3E3] rounded-[8px] bg-[#FAFAFB] flex justify-between items-center p-[12px]">
                      <input
                        ref={inputRef}
                        {...inputProps}
                        className="w-full bg-transparent focus:outline-none"
                        // placeholder="dd/mm/year"
                      />
                      {InputProps?.endAdornment}
                    </div>
                  )}
                  // className="w-full border border-[#E3E3E3] rounded-[8px] bg-[#FAFAFB] font-normal text-sm text-[#9FA6B2]"
                  components={{
                    OpenPickerIcon: () => (
                      <img src={calendarIcon} alt="calendar icon" />
                    ),
                  }}
                />
              </LocalizationProvider>

              {subTask.showErrorMessage && subTask.error.date.length > 0 && (
                <p className="font-medium text-xs leading-4 text-[#EB5757]">
                  {subTask.error.date}
                </p>
              )}
            </section>
          ))}
          {/* ==============================end: subtask============================== */}

          {/* <div className="flex flex-col items-center mt-6 lg:flex-row lg:justify-between"> */}
          <div className="flex flex-col items-center mt-6">
            <button
              type="button"
              className="border py-2 lg:py-3 border-brand-primary rounded-full cursor-pointer text-[15px] font-[500] text-center w-full h-[54px] flex items-center justify-center text-[#E45C8C] mb-[8px]"
              onClick={handleAddSubTask}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-[8px]"
              >
                <path
                  d="M9.41406 2.96875H10.5859C10.6901 2.96875 10.7422 3.02083 10.7422 3.125V16.875C10.7422 16.9792 10.6901 17.0312 10.5859 17.0312H9.41406C9.3099 17.0312 9.25781 16.9792 9.25781 16.875V3.125C9.25781 3.02083 9.3099 2.96875 9.41406 2.96875Z"
                  fill="#E45C8C"
                />
                <path
                  d="M3.4375 9.25781H16.5625C16.6667 9.25781 16.7188 9.3099 16.7188 9.41406V10.5859C16.7188 10.6901 16.6667 10.7422 16.5625 10.7422H3.4375C3.33333 10.7422 3.28125 10.6901 3.28125 10.5859V9.41406C3.28125 9.3099 3.33333 9.25781 3.4375 9.25781Z"
                  fill="#E45C8C"
                />
              </svg>
              Add Sub Task
            </button>
            <button
              // type="submit"
              type="button"
              onClick={onAddTaskSubmit}
              // disabled={isSubmitting}
              className="w-full h-[54px] lg:mt-0 border-0 mt-2 lg:ml-2 text-white capitalize rounded-full py-2 lg:py-3 bg-[#E45C8C] hover:bg-purple-primary text-[15px] font-[500]"

              // className="block w-full px-4 py-2 mt-4 font-semibold text-white border-0 rounded-lg btn bg-primary hover:bg-purple-primary"
            >
              Create Task
            </button>
          </div>

          {/* </form> */}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreateTaskModal;
