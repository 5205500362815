import Axios from "axios";
import { BASE_API_URL } from "../Helpers/Env";

export const createTask = async (body) => {
  return Axios.post(`${BASE_API_URL}tasks`, body, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

export const getTasks = async () => {
  try {
    let res = await Axios.get(`${BASE_API_URL}tasks`);
    return res.data?.tasks?.data;
  } catch (err) {
    console.log(err);
  }
};

export const getSingleTasks = async (id) => {
  try {
    let res = await Axios.get(`${BASE_API_URL}tasks/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const editStatus = async (id, status) => {
  try {
    let res = await Axios.put(`${BASE_API_URL}tasks/status/${id}`, { status });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteTask = async (id) => {
  try {
    let res = await Axios.delete(`${BASE_API_URL}tasks/${id}`);
    console.log(res);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const editSubTaskStatus = async (body, id) => {
  try {
    let res = await Axios.post(`${BASE_API_URL}subtasks/status/${id}`, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const editSubTask = async (body, id) => {
  try {
    let res = await Axios.post(`${BASE_API_URL}subtasks/update/${id}`, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteSubTask = async (id) => {
  try {
    let res = await Axios.delete(`${BASE_API_URL}subtasks/${id}`);
    console.log(res);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateTask = async (body, id) => {
  try {
    let res = await Axios.post(`${BASE_API_URL}tasks/${id}`, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return res.data;
  } catch (err) {
    return err;
  }
};
