import {Column} from "@ant-design/plots";

const DashboardActivitiesChart = ({data}) => {
    const config = {
        data,
        isStack: true,
        height: 412,
        xField: "label",
        yField: "value",
        seriesField: "type",
        legend: {layout: "horizontal", position: "top-left", itemHeight: 50},
        color: ["#6A6087", "#FAD541"],
    };

    return (
        <div className="pt-4">
            <div className="flex justify-between items-center px-6">
                <h4 className="text-base font-medium text-[#263238]">Activities</h4>
                {/*<select*/}
                {/*    name="times"*/}
                {/*    className="min-w-21 text-sm border border-[#D9D9D9] py-1 pl-4"*/}
                {/*>*/}
                {/*    <option value="thisMonth">This Month</option>*/}
                {/*    <option value="week">Last 7 Days</option>*/}
                {/*    <option value="month">Last 30 Days</option>*/}
                {/*    <option value="year">Last Year</option>*/}
                {/*</select>*/}
            </div>
            <hr className="mt-4"/>
            <div className="overflow-auto pb-6">
                <div className="px-6">
                    <Column {...config} />
                </div>
            </div>
        </div>
    );
};

export default DashboardActivitiesChart;
