function Box(props) {
  return (
    <>
      <div className="stats shadow">
        <div className="stat space-y-1">
          <div className="stat-title whitespace-normal font-medium">{props.title}</div>
          <div className="stat-value text-3xl sm:text-4xl">{props.value}</div>
          <div className="stat-desc">{props.desc}</div>
        </div>
      </div>
      {/* <div className="rounded-sm bg-white px-4 sm:px-6 py-5 sm:py-7">
        <div className="text-sm text-black/[.75] font-semibold mb-2">
          {props.title}
        </div>
        <div className="stat-value text-3xl font-bold">{props.value}</div>
        <div className="stat-desc mt-1">{props.desc}</div>
      </div> */}
    </>
  );
}

export default Box;
