import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CropBox from "Components/CropBox";
import { Field, FieldArray, Formik } from "formik";
import CloseCircleIcon from "icons/CloseCircleIcon";
import { useRef, useState } from "react";
import AddFolder from "./AddFolderModal";
import RemoveFolderLink from "./RemoveFolderLinkModal";
import RemoveFolder from "./RemoveFolderModal";
import EditFolder from "./EditFolderModal";
import AddMoreLink from "./AddMoreLink";
import imageCompression from "browser-image-compression";
import { toast } from "react-toastify";
import ShowAllFolders from "./ShowAllFolders";
import * as Yup from "yup";
import "yup-phone";

const defaultValues = {
  first_name: "",
  last_name: "",
  username: "",
  phone: "",
  phoneDisplayText: "Call Me",
  emailDisplayText: "Email Me",
  image: null,
  logo: null,
  user_details: {
    website: "",
    // portpolio: "",
    bio: "",
    designation: "",
    schedule: "",
    company: "",
  },
  user_links: [
    {
      name: "",
      url: "",
      masking: "Visit Me",
    },
  ],
};

function ProfileElement({
  user,
  onSubmit,
  handleProfilePicUpload,
  handleLogoUpload,
  handleChangeCardBgImage,
  folders,
  handleRemoveLink,
  setGetDeleteSingleLinkId,
  setShowDeleteSinglerLinkModal,
  showDeleteSingleLinkModal,
  setShowDeleteSinglerFolderModal,
  setGetDeleteSingleFolderId,
  showDeleteSingleFolderModal,
  handleRemoveFolder,
  setShowAddNewFolderModal,
  showAddNewFolderModal,
  setGetEditSingleFolderData,
  showEditFolderModal,
  setShowEditFolderModal,
  getEditSingleFolderData,
  handleCreateFolder,
  handleUpdateFolder,
  showAddMoreLinkModal,
  setShowAddMoreLinkModal,
}) {
  if (user) {
    user = {
      ...user,
      phone: user?.user_phone[0]?.phone,
      phoneDisplayText: user?.user_phone[0]?.masking,
      emailDisplayText: user?.user_email[0]?.masking,
    };
  }
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState({ preview: null, data: null });
  const [logo, setLogo] = useState({ preview: null, data: null });
  const [imgUploadModalOpen, setImgUploadModalOpen] = useState(""); // image, logo

  const uploadRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChooseImage = (event) => {
    const image = {
      preview: null,
      data: event.target.files[0],
    };
    setImage(image);
    setImgUploadModalOpen("image");
  };

  const handleChooseLogo = (event) => {
    const _logo = {
      preview: null,
      data: event.target.files[0],
    };
    setLogo(_logo);
    setImgUploadModalOpen("logo");
  };

  const handleCloseCropModal = () => {
    uploadRef.current.value = null;
    setImage(null);
    setLogo(null);
    setImgUploadModalOpen("");
  };

  const handleSetCroppedImg = (img) => {
    // setImage(img);
    // handleProfilePicUpload(img);

    console.log("imgUploadModalOpen", imgUploadModalOpen);

    if (imgUploadModalOpen === "image") {
      setImage(img);
      handleProfilePicUpload(img);
    } else if (imgUploadModalOpen === "logo") {
      setLogo(img);
      handleLogoUpload(img);
    }
  };

  const ProfileSchema = Yup.object().shape({
    phone: Yup.string()
      .phone("Phone must be a valid phone number.")
      .required("Phone number required."),
  });

  return (
    <div className="px-4 py-6 bg-white rounded-2xl">
      <Formik
        initialValues={user ?? defaultValues}
        validationSchema={ProfileSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="user-info-header xl:flex">
              <div className="flex space-x-2 user-info-img">
                <section className="flex flex-col">
                  <input
                    hidden
                    id="image"
                    type="file"
                    name="image"
                    accept="image/*"
                    ref={uploadRef}
                    onChange={(e) => {
                      handleChooseImage(e);
                      setFieldValue("image", e.target.files[0]);
                    }}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                  {image?.preview ? (
                    <img
                      alt=""
                      src={image?.preview}
                      className="object-cover p-1 rounded-full w-17 h-17 bg-gradient-to-r from-red-400 to-pink-500"
                    />
                  ) : user.profile ? (
                    <img
                      alt={user?.username}
                      src={user?.profile?.url}
                      className="object-cover rounded-full w-17 h-17"
                    />
                  ) : (
                    <img
                      alt=""
                      className="object-cover rounded-full w-17 h-17"
                      src={require("../../../Images/default_user.png")}
                    />
                  )}
                  <label
                    htmlFor="image"
                    className="absolute flex items-center justify-center ml-10 bg-gray-300 rounded-full cursor-pointer h-7 w-7 mt-11"
                  >
                    <i className="fa-solid fa-camera"></i>
                  </label>
                </section>
                <div className="flex flex-col">
                  <h2 className="text-lg font-bold tracking-tight user-name">{`${user?.first_name} ${user?.last_name}`}</h2>
                  <h2 className="text-sm tracking-tight text-gray-500">
                    {user?.email}
                  </h2>
                </div>
              </div>
              <div className="lg:right-5 !-ml-[10px] mt-4 xl:mt-0">
                {/* <label
                  title="Upload Cover Image"
                  htmlFor="fimage2"
                  className="text-gray-800 capitalize border border-gray-200 rounded-full btn btn-ghost"
                >
                  <input
                    hidden
                    id="fimage2"
                    type="file"
                    name="fimage2"
                    accept="image/*"
                    ref={uploadRef}
                    onChange={async (e) => {
                      const imageFile = e.target.files[0];

                      const options = {
                        maxSizeMB: 1,
                        maxWidthOrHeight: 667,
                      };
                      try {
                        const compressedFile = await imageCompression(
                          imageFile,
                          options
                        );
                        // console.log(compressedFile.size/1024/1024);
                        const image = {
                          preview: null,
                          // data: e.target.files[0],
                          data: compressedFile,
                        };
                        handleChangeCardBgImage(image);
                      } catch (error) {
                        console.log(error);
                        toast.error("Image size is too large");
                      }
                    }}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                  <div className="flex items-center">
                    <i className="fa-solid fa-camera"></i>
                    <div className="ml-1">Change card background</div>
                  </div>
                </label> */}

                <section className="ml-2.5">
                  <h6 className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Logo
                  </h6>

                  <div className="flex flex-col">
                    <input
                      hidden
                      id="logo"
                      type="file"
                      name="logo"
                      accept="image/*"
                      ref={uploadRef}
                      onChange={(e) => {
                        handleChooseLogo(e);
                        setFieldValue("logo", e.target.files[0]);
                      }}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    />
                    {logo?.preview ? (
                      <img
                        alt=""
                        src={logo?.preview}
                        className="object-cover p-1 rounded-full w-17 h-17 bg-gradient-to-r from-red-400 to-pink-500"
                      />
                    ) : user.logo ? (
                      <img
                        alt={user?.username}
                        src={user?.logo?.url}
                        className="object-cover rounded-full w-17 h-17"
                      />
                    ) : (
                      <img
                        alt=""
                        className="object-cover rounded-full w-17 h-17"
                        src={require("../../../Images/default_logo.png")}
                      />
                    )}
                    <label
                      htmlFor="logo"
                      className="absolute flex items-center justify-center ml-10 bg-gray-300 rounded-full cursor-pointer h-7 w-7 mt-11"
                    >
                      <i className="fa-solid fa-camera"></i>
                    </label>
                  </div>
                </section>
              </div>
            </div>

            <div className="mt-6">
              <div className="flex w-full mb-4 space-x-2">
                <div className="w-12/12 sm:w-6/12">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    onChange={handleChange}
                    defaultValue={values?.first_name}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
                <div className="w-12/12 sm:w-6/12">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    onChange={handleChange}
                    defaultValue={values?.last_name}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
              </div>

              <div className="w-full mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Company
                </label>
                <input
                  type="text"
                  name="company"
                  placeholder="Enter your Company name"
                  onChange={handleChange}
                  defaultValue={values?.user_details?.company}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>

              <div className="w-full mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Enter your Designation
                </label>
                <input
                  type="text"
                  name="user_details.designation"
                  placeholder="Enter your Designation"
                  onChange={handleChange}
                  defaultValue={values?.user_details?.designation}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>

              <div className="w-full mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Username
                </label>
                <input
                  disabled
                  type="text"
                  name="username"
                  placeholder="Username"
                  onChange={handleChange}
                  defaultValue={values?.username}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>

              <div className="w-full mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Email
                </label>
                <input
                  disabled
                  type="text"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  defaultValue={values?.email}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>

              <div className="w-full mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Email Display Text
                </label>
                <input
                  type="text"
                  name="emailDisplayText"
                  placeholder="Email Display Text"
                  onChange={handleChange}
                  defaultValue={values?.emailDisplayText}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>

              <div className="w-full mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Phone
                </label>
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  defaultValue={values?.phone}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
                {errors.phone && touched.phone && (
                  <span className="text-sm font-normal text-red-500">
                    {errors.phone}
                  </span>
                )}
              </div>

              <div className="w-full mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Phone Display Text
                </label>
                <input
                  type="text"
                  name="phoneDisplayText"
                  placeholder="Phone Display Text"
                  onChange={handleChange}
                  defaultValue={values?.phoneDisplayText}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>

              <div className="w-full mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Visit my website
                </label>
                <Field
                  type="text"
                  name="website"
                  placeholder="Enter your website"
                  defaultValue={values?.user_details?.website}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>

              <div className="w-full mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Schedule (
                  <a
                    href="https://calendly.com/app/signup"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-500"
                  >
                    Calandly
                  </a>
                  )
                </label>
                <Field
                  type="text"
                  name="schedule"
                  placeholder="Enter your calandly link"
                  defaultValue={values?.user_details?.schedule}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>

              <FieldArray
                name="user_links"
                render={(arrayHelpers) => (
                  <div>
                    {/* {values.user_links && values.user_links.length > 0 ? ( */}
                    {
                      values?.user_links.map((link, index) => (
                        <div key={index}>
                          <div className="w-full mb-4">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              Link
                            </label>
                            <section className="">
                              <div className="relative singleWrap !w-full">
                                <div className="mb-4">
                                  <Field
                                    name={`user_links.${index}.url`}
                                    type="text"
                                    placeholder="Enter your website"
                                    defaultValue={link?.url}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  />
                                </div>
                                <div className="absolute top-[11px] right-[10px] linkHoverBtn">
                                  <button
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)}
                                    className=""
                                  >
                                    <CloseCircleIcon />
                                  </button>
                                </div>
                              </div>
                              <div className="w-full mb-4">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                  Link Display Text
                                </label>
                                <Field
                                  name={`user_links.${index}.masking`}
                                  type="text"
                                  placeholder="Link Display Text"
                                  defaultValue={link?.masking}
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                              </div>
                            </section>
                          </div>
                        </div>
                      ))
                      // ) : (
                      //   <div className="w-full mb-4">
                      //     <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      //       Visit my website
                      //     </label>
                      //     <Field
                      //       name={`website`}
                      //       type="text"
                      //       placeholder="Enter your website"
                      //       defaultValue={"https://qliq.bio"}
                      //       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      //     />
                      //   </div>
                      // )
                    }
                    <div className="flex justify-end mt-0">
                      <button
                        className="text-brand-primary py-2 lg:py-3 border-brand-primary cursor-pointer text-[15px] font-[500] flex items-center justify-center"
                        type="button"
                        onClick={() => arrayHelpers.push("")}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.41406 2.96875H10.5859C10.6901 2.96875 10.7422 3.02083 10.7422 3.125V16.875C10.7422 16.9792 10.6901 17.0312 10.5859 17.0312H9.41406C9.3099 17.0312 9.25781 16.9792 9.25781 16.875V3.125C9.25781 3.02083 9.3099 2.96875 9.41406 2.96875Z"
                            fill="#E45C8C"
                          />
                          <path
                            d="M3.4375 9.25781H16.5625C16.6667 9.25781 16.7188 9.3099 16.7188 9.41406V10.5859C16.7188 10.6901 16.6667 10.7422 16.5625 10.7422H3.4375C3.33333 10.7422 3.28125 10.6901 3.28125 10.5859V9.41406C3.28125 9.3099 3.33333 9.25781 3.4375 9.25781Z"
                            fill="#E45C8C"
                          />
                        </svg>
                        Add More Links
                      </button>
                    </div>
                  </div>
                )}
              />

              <ShowAllFolders
                folders={folders}
                setGetDeleteSingleLinkId={setGetDeleteSingleLinkId}
                setShowDeleteSinglerLinkModal={setShowDeleteSinglerLinkModal}
                setShowAddMoreLinkModal={setShowAddMoreLinkModal}
                setShowEditFolderModal={setShowEditFolderModal}
                setShowDeleteSinglerFolderModal={
                  setShowDeleteSinglerFolderModal
                }
                setGetEditSingleFolderData={setGetEditSingleFolderData}
                setGetDeleteSingleFolderId={setGetDeleteSingleFolderId}
              />

              <div className="mt-4">
                <div className="mb-2">
                  <button
                    className="text-brand-primary rounded-full border w-full py-2 lg:py-3 border-brand-primary cursor-pointer text-[15px] font-[500] flex items-center justify-center"
                    type="button"
                    onClick={() => setShowAddNewFolderModal(true)}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.41406 2.96875H10.5859C10.6901 2.96875 10.7422 3.02083 10.7422 3.125V16.875C10.7422 16.9792 10.6901 17.0312 10.5859 17.0312H9.41406C9.3099 17.0312 9.25781 16.9792 9.25781 16.875V3.125C9.25781 3.02083 9.3099 2.96875 9.41406 2.96875Z"
                        fill="#E45C8C"
                      />
                      <path
                        d="M3.4375 9.25781H16.5625C16.6667 9.25781 16.7188 9.3099 16.7188 9.41406V10.5859C16.7188 10.6901 16.6667 10.7422 16.5625 10.7422H3.4375C3.33333 10.7422 3.28125 10.6901 3.28125 10.5859V9.41406C3.28125 9.3099 3.33333 9.25781 3.4375 9.25781Z"
                        fill="#E45C8C"
                      />
                    </svg>
                    Add New Folder
                  </button>
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full lg:mt-0 border-0 mt-2 text-white capitalize rounded-full py-2 lg:py-3 bg-brand-primary hover:bg-purple-primary text-[15px] font-[500]"
                >
                  Update Profile
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>

      {showAddNewFolderModal && (
        <Dialog
          open={showAddNewFolderModal}
          onClose={() => setShowAddNewFolderModal(false)}
          fullWidth
        >
          <DialogContent className="rounded-xl">
            <AddFolder
              setShowAddNewFolderModal={setShowAddNewFolderModal}
              handleCreateFolder={handleCreateFolder}
            />
          </DialogContent>
        </Dialog>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="rounded-xl">
          <DialogContentText id="alert-dialog-description">
            <div
              onClick={handleClickOpen}
              className="flex items-center cursor-pointer"
            >
              <img
                className="w-16 h-16 rounded-xl"
                src="https://phplaravel-604358-2477933.cloudwaysapps.com/assets/placeholders/temp.gif"
                alt=""
              />

              <div className="flex flex-col ml-4">
                <h2 className="font-semibold text-md text-dark-200">
                  Upload your image
                </h2>
                <p className="text-xs leading-5 text-gray-500">
                  Choose an image from your device to get started
                </p>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {imgUploadModalOpen && (
        <Dialog open={imgUploadModalOpen}>
          <DialogContent className="p-0 transition-qliq w-[340px] md:w-[460px]">
            <div className="absolute w-6 h-6 top-5 right-5 closeBtn-with-bg">
              <button
                type="button"
                className="w-6 h-6"
                onClick={handleCloseCropModal}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div>
              <CropBox
                // uploadedImg={URL.createObjectURL(image?.data)}
                uploadedImg={URL.createObjectURL(
                  imgUploadModalOpen === "image" ? image?.data : logo?.data
                )}
                onImageCrop={(img) => {
                  handleSetCroppedImg(img);
                  setImgUploadModalOpen("");
                }}
                imgUploadModalOpen={() => setImgUploadModalOpen("")}
              />
            </div>
          </DialogContent>
        </Dialog>
      )}

      {showDeleteSingleLinkModal && (
        <Dialog
          PaperProps={{ width: "100%" }}
          open={showDeleteSingleLinkModal}
          onClose={() => setShowDeleteSinglerLinkModal(false)}
        >
          <DialogContent
            className="p-0 transition-qliq"
            style={{ overflowY: "unset" }}
          >
            <RemoveFolderLink
              setShowDeleteFolderLinkModal={setShowDeleteSinglerLinkModal}
              handleRemoveLink={handleRemoveLink}
            />
          </DialogContent>
        </Dialog>
      )}

      {showDeleteSingleFolderModal && (
        <Dialog
          PaperProps={{ width: "100%" }}
          open={showDeleteSingleFolderModal}
          onClose={() => setShowDeleteSinglerFolderModal(false)}
        >
          <DialogContent
            className="p-0 transition-qliq"
            style={{ overflowY: "unset" }}
          >
            <RemoveFolder
              setShowDeleteFolderLinkModal={setShowDeleteSinglerFolderModal}
              handleRemoveFolder={handleRemoveFolder}
            />
          </DialogContent>
        </Dialog>
      )}
      {showEditFolderModal && (
        <Dialog
          open={showEditFolderModal}
          onClose={() => setShowEditFolderModal(false)}
          fullWidth
        >
          <DialogContent className="rounded-xl">
            <EditFolder
              setShowEditFolderModal={setShowEditFolderModal}
              getEditSingleFolderData={getEditSingleFolderData}
              handleUpdateFolder={handleUpdateFolder}
            />
          </DialogContent>
        </Dialog>
      )}
      {showAddMoreLinkModal && (
        <Dialog
          open={showAddMoreLinkModal}
          onClose={() => setShowAddMoreLinkModal(false)}
          fullWidth
        >
          <DialogContent className="rounded-xl">
            <AddMoreLink
              setShowAddMoreLinkModal={setShowAddMoreLinkModal}
              getEditSingleFolderData={getEditSingleFolderData}
              handleUpdateFolder={handleUpdateFolder}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default ProfileElement;
