import {Outlet} from "react-router-dom";
import Header from "../Pages/Home/Components/Header";
import Footer from "../Pages/Home/Components/Footer";

const PageLayout = () => {
    return (
        <div>
            <Header/>
            <main>
                <Outlet/>
            </main>
            <Footer/>
        </div>
    );
};

export default PageLayout;
