// import {activeTrial} from "../../../Api/Packages";
// import {toast} from "react-toastify";
// import useAuth from "../../../Hooks/useAuth";
// import {useNavigate} from "react-router-dom";

const ChooseArea = ({ handleTrial }) => {
  return (
    <section className="px-2 py-8 hero bg-gray-100/50 rounded-2xl">
      <div className="container px-4 mx-auto sm:px-8 lg:px-16 xl:px-20">
        <div className="grid items-center grid-cols-1 gap-8 md:grid-cols-12">
          <div className="flex items-center justify-center col-span-6 hero-image md:hidden">
            <img
              className="w-80 h-80"
              src={require("../assets/images/scanqr.png")}
              alt="Qliq"
            />
          </div>
          <div className="hidden col-span-6 hero-image md:block">
            <img src={require("../assets/images/scanqr.png")} alt="Qliq" />
          </div>

          <div className="col-span-6">
            <h1 className="max-w-xl text-3xl font-bold leading-tight tracking-tight text-gray-800 md:text-5xl">
              Why choose QliQ?
            </h1>
            <hr className="w-12 h-1 mt-8 bg-orange-500 rounded-full " />
            <p className="mt-6 leading-7 tracking-tight text-gray-600 text-md ">
              Create your business card in a few minutes - it's clean, fast and
              it costs less than a latte.
            </p>
            <div className="flex justify-center mt-10 space-x-2 md:justify-start">
              <button
                onClick={handleTrial}
                className="px-6 text-gray-800 capitalize border border-gray-200 btn btn-ghost"
              >
                Start your 30 day free trial
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 ml-1 text-[#5d5478]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChooseArea;
