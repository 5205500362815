import { Dialog, DialogContent } from "@mui/material";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { FaQuestionCircle } from "react-icons/fa";
import { getSocialAddIcon } from "Helpers/GetSocialIcon";

const AddSocialLinkDialog = ({
  handleSocialAddToUser,
  handleClosePopup,
  selSocial,
  open,
}) => {
  return (
    <Dialog fullWidth open={open} className="addSocialModal">
      <DialogContent className="p-0 transition-qliq">
        <div className="absolute top-3 right-3 h-6 w-6 closeBtn-with-bg">
          <button type="button" className="h-6 w-6" onClick={handleClosePopup}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="pb-6 pt-12 px-5">
          <Formik
            initialValues={{ url: selSocial?.url }}
            onSubmit={handleSocialAddToUser}
          >
            {({ handleSubmit, handleChange, values, isSubmitting, dirty }) => (
              <form onSubmit={handleSubmit}>
                <div className="">
                  <div className="flex items-center justify-between mb-3">
                    <div className="font-semibold  w-full">
                      Type your {selSocial?.name}{" "}
                      {`${
                        selSocial?.name === "WhatsApp"
                          ? `Phone Number`
                          : `username`
                      }`}
                    </div>
                    <div>
                      <span className="cursor-pointer group relative inline-block text-blue-500  hover:text-red-500 duration-300">
                        <FaQuestionCircle />
                        <span className="absolute top-2 hidden group-hover:flex -left-60 right-[20px] z-50 -translate-y-full px-2 py-2 bg-gray-700 rounded-lg text-center text-white text-sm">
                          Example: {`${selSocial?.url}/username`}
                        </span>
                      </span>
                    </div>
                    <div></div>
                  </div>

                  <div className="border border-[#D9D9D9] rounded flex px-2 py-2">
                    <div className="pr-4 pl-2 ">
                      {getSocialAddIcon(selSocial?.name)}
                    </div>
                    <div className="w-full pl-4 border-l border-[#D9D9D9]">
                      <input
                        name="url"
                        onChange={handleChange}
                        placeholder="username"
                        className="text-gray-900 text-sm block w-full dark:placeholder-gray-400 custom-input"
                      />
                    </div>
                  </div>

                  <button
                    disabled={isSubmitting || !dirty}
                    className="btn btn-ghost mt-3 bg-brand-primary capitalize text-white rounded-full px-9 h-[40px] min-h-[auto]"
                  >
                    Add Social User
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
};

AddSocialLinkDialog.propTypes = {
  open: PropTypes.bool,
  handleSocialAddToUser: PropTypes.func,
  handleCloseAddPopup: PropTypes.func,
  selSocial: PropTypes.object,
};

export default AddSocialLinkDialog;
