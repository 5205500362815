import axios from "axios";

import { BASE_API_URL } from "Helpers/Env";

export const submitFeedback = async (data) => {
  const response = await axios.post(`${BASE_API_URL}feedback`, data, {
    "Content-Type": "multipart/form-data",
  });
  return response;
};
