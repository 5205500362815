import { useState } from "react";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FormControl, FormText } from "react-bootstrap";
import BrandLogo from "./../../Images/logos/brand-logo.png";
import { resetPasswordPost } from "Api/Auth";
import { useEffect } from "react";

const defaultValues = {
  password: "",
  password_confirmation: "",
};

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*#?&])[a-zA-Z\d@$!%*#?&]{8,}$/,
      "Password must contain 8 characters, One digit, One letter and One special character"
    ),
  password_confirmation: yup
    .string()
    .required("Confirm Password is required")
    .when("password", {
      is: (val) => val && val.length > 0,
      then: yup
        .string()
        .oneOf([yup.ref("password")], "Both password need to be the same"),
    }),
});

function ResetPassword() {
  const url = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [getEmail, setGetEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let tokenArr = url.search.split("=");
    const email = localStorage.getItem("resetEmail");
    setToken(tokenArr[1]);
    setGetEmail(email);
  }, [url.search]);

  const handleRegister = (values, { setSubmitting }) => {
    setIsLoading(true)
    resetPasswordPost(getEmail, values.password, token)
      .then((res) => {
        toast.success(res.data.message);
        navigate("/login");
        localStorage.removeItem('resetEmail');
        setIsLoading(false)
      })
      .catch((err) => {
        toast.error(err.data.message);
        setSubmitting(false);
      });

    setSubmitting(false);
  };
  return (
    <div className="w-full display-position-center ">
      <div className="flex justify-center flex-col w-full">
        <div className="flex justify-center">
          <Link to="/">
            <img
              alt="brand logo"
              src={BrandLogo}
              className="object-center rounded-3xl transform transition duration-500 hover:scale-110 w-32 h-auto"
            />
          </Link>
        </div>
        <h1 className="text-center text-xl md:text-2xl font-bold leading-tight mt-12">
          Welcome Back
        </h1>

        <Formik
          onSubmit={handleRegister}
          initialValues={defaultValues}
          validationSchema={validationSchema}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="mt-4">
                <label htmlFor="password" className="block text-gray-700">
                  Password
                </label>
                <FormControl
                  type="password"
                  name="password"
                  onChange={handleChange}
                  placeholder="Enter Password"
                  defaultValue={values.password}
                  isInvalid={errors.password && touched.password}
                  className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-primary focus:bg-white focus:outline-none"
                />
                {errors.password && touched.password && (
                  <FormText className="text-red-700">
                    {errors.password}
                  </FormText>
                )}
              </div>

              <div className="mt-4">
                <label
                  htmlFor="password_confirmation"
                  className="block text-gray-700"
                >
                  Confirm Password
                </label>
                <FormControl
                  type="password"
                  onChange={handleChange}
                  name="password_confirmation"
                  placeholder="Confirm your password"
                  defaultValue={values.password_confirmation}
                  isInvalid={
                    errors.password_confirmation &&
                    touched.password_confirmation
                  }
                  className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-primary focus:bg-white focus:outline-none"
                />
                {errors.password_confirmation &&
                  touched.password_confirmation && (
                    <FormText className="text-red-700">
                      {errors.password_confirmation}
                    </FormText>
                  )}
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full block bg-brand-primary hover:bg-purple-primary focus:bg-purple-primary text-white font-semibold rounded-lg px-4 py-3 mt-6"
              >
                {isLoading ? 'Loading...' : "Password Change"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ResetPassword;
