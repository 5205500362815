import moment from "moment";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import classNames from "classnames";
import { toast } from "react-toastify";
import Tag from "Components/Tags/Tag";
import styles from "../styles.module.css";
import { DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import TaskModal from "../components/TaskModal";
import { Dialog, DialogContent } from "@mui/material";
import Spinner from "../../../Components/Spinner/Spinner";
import defaultUser from "../../../Images/default_user.png";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getContacts, searchContact } from "../../../Api/Contact";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  deleteTask,
  editStatus,
  editSubTaskStatus,
  updateTask,
  deleteSubTask,
  getSingleTasks,
  editSubTask,
} from "../../../Api/Task";
// import ExclamationCircleIcon from "icons/ExclamationCircale";
// import { notify } from "index";

function TaskList({
  tasks,
  fetchTasks,
  status,
  statusValues,
  setIsTaskSpinner,
  tagArr,
  openAssignTaskModal,
  setOpenAssignTaskModal,
  previewTask,
  setPreviewTask,
}) {
  const HidePopup = React.useRef();
  const subTaskInputRef = React.useRef();
  // const [previewTask, setPreviewTask] = useState({});
  const [contacts, setContacts] = useState([]);
  const [contactSearchLoading, setContactSearchLoading] = useState(false);
  const [contactSearchParam, setContactSearchParam] = useState("");
  const [assignTaskSpinner, setAssignTaskSpinner] = useState(false);
  const [isSubTaskSpinner, setIsSubTaskSpinner] = useState(false);
  const [subTaskName, setSubTaskName] = useState("");
  const [date, setDate] = useState(null);
  const [taskTagSpinner, setTaskTagSpinner] = useState(false);
  const [deadlineModalOpen, setDeadlineModalOpen] = useState(false);
  const [showTaskDeleteModal, setShowTaskDeleteModal] = useState(false);
  // const [openAssignTaskModal, setOpenAssignTaskModal] = useState(false);

  useEffect(() => {
    getContacts()
      .then((res) => setContacts(res?.data))
      .catch(console.log);
  }, []);

  useEffect(() => {
    const handleSearchContact = debounce(async (param) => {
      setContactSearchLoading(true);
      const data = await searchContact(param);
      setContacts(data);
      setContactSearchLoading(false);
    }, 1000);

    handleSearchContact(contactSearchParam);
  }, [contactSearchParam]);

  const menuItems = [
    {
      name: "Delete",
      function: (task) => {
        setShowTaskDeleteModal(true);
        setPreviewTask(task);
      },
      icon: <i className="mr-1 fa-sharp fa-solid fa-trash"></i>,
    },
    {
      name: "Assign Task",
      function: (task) => {
        setPreviewTask(task);
      },
      icon: <i className="mr-2 fa-solid fa-list-check"></i>,
      htmlFor: "previewAddContacts",
      hidden: false,
      openModal: () => setOpenAssignTaskModal(true),
    },
    {
      name: "Set Deadline",
      function: (task) => {
        setPreviewTask(task);
        setDeadlineModalOpen(true);
      },
      icon: <i className="mr-2 fa-solid fa-clock"> </i>,
      hidden: false,
    },
    {
      name: "Un-Archive",
      function: (task) => handleStatus(task, statusValues.open),
      icon: <i className="mr-1 fa-solid fa-box-archive"></i>,
      hidden: status !== statusValues.archived,
    },
    {
      name: "Mark as Done",
      function: (task) => handleStatus(task, statusValues.closed),
      icon: <i className="mr-1 fa-sharp fa-solid fa-circle-check"></i>,
      hidden: status === statusValues.closed,
    },
    {
      name: "Mark as Open",
      function: (task) => handleStatus(task, statusValues.open),
      icon: <i className="mr-1 fa-solid fa-box-open"></i>,
      hidden: status === statusValues.archived,
    },
    {
      name: "Mark as Archived",
      function: (task) => handleStatus(task, statusValues.archived),
      icon: <i className="mr-1 fa-solid fa-box-archive"></i>,
      hidden: status === statusValues.archived,
    },
  ];

  const handleCheckbox = async (event, subTask) => {
    let formData = new FormData();
    let checked = false;
    formData.append("_method", "put");
    if (event?.target) {
      if (event.target.checked === true) {
        event.target.parentNode.classList.add("text-line-through");
        formData.append(`status`, 2);
        checked = false;
      } else {
        event.target.parentNode.classList.remove("text-line-through");
        formData.append(`status`, 1);
        checked = true;
      }
    } else {
      if (subTask?.status === 1) {
        formData.append(`status`, 2);
        checked = false;
      } else {
        formData.append(`status`, 1);
        checked = true;
      }
    }
    await editSubTaskStatus(formData, subTask?.id);
    // toast.success("Sub Task Status Updated");

    if (checked) {
      // notify("success", "Sub Task Incomplete");
      toast.success("Sub Task Incomplete");
    } else {
      // notify("success", "Sub Task Completed");
      toast.success("Sub Task Completed");
    }
    fetchTasks();
  };

  const handleStatus = (task, status) => {
    const { status: prevStatus } = task;
    if (status === statusValues.closed) {
      setIsTaskSpinner(true);
      status = statusValues.closed;
    } else if (status === statusValues.archived) {
      setIsTaskSpinner(true);
      status = statusValues.archived;
    } else if (status === statusValues.open) {
      setIsTaskSpinner(true);
      status = statusValues.open;
    }

    if (prevStatus !== status) {
      editStatus(task?.id, status)
        .then((res) => {
          fetchTasks();
          setIsTaskSpinner(false);
          toast.success("Successfully update status");
        })
        .catch((err) => {
          setIsTaskSpinner(false);
        });
    } else {
      fetchTasks();
      setIsTaskSpinner(false);
      toast.error("This task already opened");
    }
  };

  const handleDelete = () => {
    setIsTaskSpinner(true);
    deleteTask(previewTask?.id)
      .then((res) => {
        fetchTasks();
        setShowTaskDeleteModal(false);
        toast.success("Task delete successfully");
      })
      .catch((error) => {
        console.log(error);
        setShowTaskDeleteModal(false);
      });
  };

  const handleAssignContacts = (e) => {
    e.preventDefault();
    let formData = new FormData();
    Object.keys(e?.target)?.forEach((key, index) => {
      let { checked, value } = e.target[key];
      if (checked) {
        value = JSON.parse(value);
        formData.append(`task_assigns[${index}][contact_id]`, value?.id);
      }
    });
    formData.append("_method", "put");
    formData.append("title", previewTask?.title);
    formData.append("status", previewTask?.status);
    if (previewTask?.deadline)
      formData.append("deadline", previewTask?.deadline);
    formData.append("status", previewTask?.status);
    previewTask?.sub_tasks?.forEach((subTask, index) => {
      formData.append(`subtasks[${index}][id]`, subTask?.id);
      formData.append(`subtasks[${index}][title]`, subTask?.title);
      formData.append(`subtasks[${index}][status]`, subTask?.status);
    });
    setAssignTaskSpinner(true);
    for (const pair of formData.entries()) {
      console.log(`${pair[0]}, ${pair[1]}`);
    }
    updateTask(formData, previewTask?.id)
      .then((res) => {
        setAssignTaskSpinner(false);
        e.target.reset();
        if (res?.task) {
          toast.success(res?.message);
          fetchTasks();
          setPreviewTask(res.task);
          setOpenAssignTaskModal(false);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        setAssignTaskSpinner(false);
        console.log(err);
        toast.error(err.response.data.message);
      });
  };

  const handleUpdateSubTask = () => {
    let formData = getTaskFormData();
    subTaskInputRef.current.value = "";
    formData.append(
      `subtasks[${previewTask?.sub_tasks?.length}][title]`,
      subTaskName
    );
    formData.append(`subtasks[${previewTask?.sub_tasks?.length}][status]`, 1);
    setIsSubTaskSpinner(true);
    updateTask(formData, previewTask?.id)
      .then((res) => {
        if (res?.task) {
          setPreviewTask(res.task);
          // toast.success(res?.message);
          setIsSubTaskSpinner(false);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        setIsSubTaskSpinner(false);
        console.log(err);
        toast.error(err.response.data.message);
      });
  };

  const handleUpdateDeadline = () => {
    let formData = getTaskFormData();
    let deadline = `${date?.$y}-${date?.$M + 1}-${date?.$D} ${date?.$H}:${
      date?.$m
    }:${date?.$s}`;
    formData.delete("deadline");
    formData.append(`deadline`, deadline);
    setIsTaskSpinner(true);
    updateTask(formData, previewTask?.id)
      .then((res) => {
        if (res?.task) {
          fetchTasks();
          setDeadlineModalOpen(false);
        } else {
          toast.error(res?.message);
          setIsTaskSpinner(false);
          setDeadlineModalOpen(false);
        }
      })
      .catch((err) => {
        setIsTaskSpinner(false);
        console.log(err);
        toast.error(err.response.data.message);
        setDeadlineModalOpen(false);
      });
  };

  const getTaskFormData = () => {
    let formData = new FormData();
    formData.append("_method", "put");
    formData.append("title", previewTask?.title);
    formData.append("status", previewTask?.status);
    if (previewTask.deadline)
      formData.append(
        "deadline",
        moment(previewTask?.deadline).format("  DD MMMM YYYY")
      );
    previewTask?.sub_tasks?.forEach((subTask, index) => {
      formData.append(`subtasks[${index}][id]`, subTask?.id);
      formData.append(`subtasks[${index}][title]`, subTask?.title);
      formData.append(`subtasks[${index}][status]`, subTask?.status);
    });
    previewTask?.task_assigns?.forEach((contact, index) => {
      formData.append(
        `task_assigns[${index}][contact_id]`,
        contact?.contact_id
      );
    });
    previewTask?.task_tags?.forEach((tag, index) => {
      formData.append(`task_tags[${index}][tag_id]`, tag?.tag_id);
      formData.append(`task_tags[${index}][id]`, tag?.id);
    });
    return formData;
  };

  const handleTaskTags = (tagId, index) => {
    let formData = getTaskFormData();
    if (index > -1) {
      formData.delete(`task_tags[${index}][tag_id]`);
      formData.delete(`task_tags[${index}][id]`);
    } else
      formData.append(
        `task_tags[${previewTask?.task_tags?.length}][tag_id]`,
        tagId
      );
    for (const pair of formData.entries()) {
      console.log(`${pair[0]}, ${pair[1]}`);
    }

    setTaskTagSpinner(true);
    updateTask(formData, previewTask?.id)
      .then((res) => {
        setTaskTagSpinner(false);
        if (res?.task) {
          fetchTasks();
          setPreviewTask(res?.task);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setTaskTagSpinner(true);
        toast.error(err.response.data.message);
      });
  };

  const [previewPopup, setPreviewPopup] = useState(false);
  const onOpenPreviewPopup = (task) => {
    setPreviewTask(task);
    setPreviewPopup(true);
  };

  const onClosePreviewPopup = () => {
    setPreviewPopup(false);
    setDate(null);
    setPreviewTask(null);
  };

  const deleteSubTaskHandler = async (data) => {
    await deleteSubTask(data?.id)
      .then((res) => {
        getSingleTasks(previewTask.id).then((res) => {
          setPreviewTask(res?.task);
          toast.success("Sub Task Successfully Delete");
        });
        fetchTasks();
      })
      .catch(console.log);
  };

  const updateSubTaskHandler = async (
    updatedData,
    data,
    updateDate,
    e,
    assignContacts
  ) => {
    let formData = new FormData();
    formData.append("_method", "put");
    formData.append("task_id", data?.task_id);
    formData.append("title", updatedData);
    formData.append("status", data?.status);
    if (updateDate) {
      if (typeof updateDate === "object") {
        formData.append("deadline", updateDate.format("YYYY-MM-DD"));
      } else {
        formData.append("deadline", updateDate);
      }
    }

    if (assignContacts) {
      assignContacts.forEach((assign, index) => {
        formData.append(`assigns[${index}][contact_id]`, assign?.id);
      });
    } else if (e?.target) {
      Object.keys(e?.target)?.forEach((key, index) => {
        let { checked, value } = e.target[key];
        if (checked) {
          value = JSON.parse(value);
          formData.append(`assigns[${index}][contact_id]`, value?.id);
        }
      });
    } else {
      data?.assigns?.forEach((assign, index) => {
        formData.append(`assigns[${index}][contact_id]`, assign?.contact_id);
      });
    }

    editSubTask(formData, data?.id)
      .then((res) => {
        if (res?.task) {
          getSingleTasks(previewTask.id).then((res) => {
            if (res?.task) {
              setPreviewTask(res?.task);
              toast.success("Sub Task Updated Successfully");
              fetchTasks();
            } else {
              toast.error(res?.message || "Something went wrong");
            }
          });
        } else {
          toast.error(res?.message || "Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message || "Something went wrong");
      });
  };

  return (
    <div>
      <div className="grid h-full grid-cols-1 gap-2 mt-4 mb-48 md:grid-cols-1">
        <div ref={HidePopup} className="previewTask">
          {tasks?.map((task, index) => {
            return (
              <div
                className="relative px-4 py-4 mb-3 bg-white border border-gray-300 shadow cursor-pointer task-box-wrap rounded-xl"
                key={index}
              >
                <div className="flex items-center justify-between gap-1">
                  <div
                    className="task-h"
                    onClick={() => onOpenPreviewPopup(task)}
                  >
                    <h2 className="task-title">{task?.title}</h2>
                    {/* <span className='mt-1 task-sub-title'>Mobile Responsive Version</span> */}
                  </div>
                  <div className="task-button">
                    <div className="flex items-center justify-center">
                      {task?.status === 2 && (
                        <div className="mr-2 -mt-1.5">
                          <i className="text-lg fa-sharp fa-solid fa-circle-check text-primary"></i>
                        </div>
                      )}
                      <Dropdown
                        param={task}
                        rotate={true}
                        menuItems={menuItems.filter((item) => !item.hidden)}
                      />
                    </div>
                  </div>
                </div>
                {task?.task_assigns.length > 0 && (
                  <div>
                    <hr className="w-full mt-2 mb-4 border-gray-300"></hr>
                    <div
                      className="task-c"
                      onClick={() => onOpenPreviewPopup(task)}
                    >
                      <div className="flex flex-wrap items-center -m-1 sm:-m-2">
                        {task?.task_assigns?.map((item, index) =>
                          index < 5 ? (
                            <div key={index} className="p-1 sm:p-2">
                              <div className="flex text-sm items-center text-[#A8A6AD]">
                                <div className="flex-shrink-0 block w-4 h-4 mr-1 overflow-hidden rounded-full sm:h-5 sm:w-5">
                                  {item?.contact?.file_manager?.url ? (
                                    <img
                                      alt="user"
                                      src={item?.contact?.file_manager?.url}
                                      className="object-cover w-full h-full"
                                    />
                                  ) : (
                                    <div className="text-white bg-[#31C48D] h-full w-full block text-center leading-4 sm:leading-5 text-xs font-medium">
                                      {item?.contact?.name.slice(0, 1)}
                                    </div>
                                  )}
                                </div>
                                {item?.contact?.name}
                                {task?.task_assigns?.length - 1 !== index &&
                                  ","}
                              </div>
                            </div>
                          ) : null
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {/* Preview Task Modal START */}
      {previewPopup && (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={previewPopup}
          onClose={onClosePreviewPopup}
        >
          <DialogContent>
            <TaskModal
              tagArr={tagArr}
              fetchTasks={fetchTasks}
              previewTask={previewTask}
              handleCheckbox={handleCheckbox}
              handleTaskTags={handleTaskTags}
              setPreviewTask={setPreviewTask}
              isSubTaskSpinner={isSubTaskSpinner}
              onClosePreviewPopup={onClosePreviewPopup}
              deleteSubTaskHandler={deleteSubTaskHandler}
              updateSubTaskHandler={updateSubTaskHandler}
            />
          </DialogContent>
        </Dialog>
      )}

      {showTaskDeleteModal ? (
        <Dialog
          PaperProps={{ width: "100%" }}
          open={showTaskDeleteModal}
          onClose={() => setShowTaskDeleteModal(false)}
        >
          <DialogContent
            className="p-0 transition-qliq"
            style={{ overflowY: "unset" }}
          >
            <div className="relative p-6 w-[290px] sm:w-[350px]">
              <div className="absolute top-0 transform -translate-x-1/2 -translate-y-1/2 left-1/2">
                <div className=" flex justify-center items-center w-[70px] h-[70px] rounded-full bg-[#f3f3f3] border-[6px] border-white">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M28 8.97333C27.9733 8.97333 27.9333 8.97333 27.8933 8.97333C20.84 8.26667 13.8 8 6.82668 8.70667L4.10668 8.97333C3.54668 9.02667 3.05334 8.62667 3.00001 8.06667C2.94668 7.50667 3.34668 7.02667 3.89334 6.97333L6.61334 6.70667C13.7067 5.98667 20.8933 6.26667 28.0933 6.97333C28.64 7.02667 29.04 7.52 28.9867 8.06667C28.9467 8.58667 28.5067 8.97333 28 8.97333Z"
                      fill="#EB5757"
                    />
                    <path
                      d="M11.3333 7.62667C11.28 7.62667 11.2267 7.62667 11.16 7.61333C10.6267 7.52 10.2533 7 10.3467 6.46667L10.64 4.72C10.8533 3.44 11.1467 1.66667 14.2533 1.66667H17.7467C20.8667 1.66667 21.16 3.50667 21.36 4.73333L21.6533 6.46667C21.7467 7.01333 21.3733 7.53333 20.84 7.61333C20.2933 7.70667 19.7733 7.33333 19.6933 6.8L19.4 5.06667C19.2133 3.90667 19.1733 3.68 17.76 3.68H14.2667C12.8533 3.68 12.8267 3.86667 12.6267 5.05333L12.32 6.78667C12.24 7.28 11.8133 7.62667 11.3333 7.62667Z"
                      fill="#EB5757"
                    />
                    <path
                      d="M20.28 30.3333H11.72C7.06667 30.3333 6.88001 27.76 6.73334 25.68L5.86667 12.2533C5.82667 11.7067 6.25334 11.2267 6.80001 11.1867C7.36001 11.16 7.82667 11.5733 7.86667 12.12L8.73334 25.5467C8.88001 27.5733 8.93334 28.3333 11.72 28.3333H20.28C23.08 28.3333 23.1333 27.5733 23.2667 25.5467L24.1333 12.12C24.1733 11.5733 24.6533 11.16 25.2 11.1867C25.7467 11.2267 26.1733 11.6933 26.1333 12.2533L25.2667 25.68C25.12 27.76 24.9333 30.3333 20.28 30.3333Z"
                      fill="#EB5757"
                    />
                    <path
                      d="M18.2133 23H13.7733C13.2266 23 12.7733 22.5467 12.7733 22C12.7733 21.4533 13.2266 21 13.7733 21H18.2133C18.76 21 19.2133 21.4533 19.2133 22C19.2133 22.5467 18.76 23 18.2133 23Z"
                      fill="#EB5757"
                    />
                    <path
                      d="M19.3334 17.6667H12.6667C12.12 17.6667 11.6667 17.2133 11.6667 16.6667C11.6667 16.12 12.12 15.6667 12.6667 15.6667H19.3334C19.88 15.6667 20.3334 16.12 20.3334 16.6667C20.3334 17.2133 19.88 17.6667 19.3334 17.6667Z"
                      fill="#EB5757"
                    />
                  </svg>
                </div>
              </div>
              <div className="mt-5 mb-4 text-center">
                <h4 className="text-base font-[500] text-[#000000d9]">
                  Are you sure to delete the <br /> Task?
                </h4>
                <h4 className="text-[12px] mt-2 font-[500] text-[#000000d9]">
                  You can’t undo this action
                </h4>
              </div>

              <div className="flex items-center justify-center mt-8">
                <div
                  className="px-4 mr-2 py-2 text-base border border-[#D9D9D9] text-[#000000d9] text-white bg-white rounded cursor-pointer"
                  onClick={() => setShowTaskDeleteModal(false)}
                >
                  Cancel
                </div>
                <div
                  className="px-[15px] py-[6.4px] text-base text-white bg-[#EF5367] rounded cursor-pointer"
                  onClick={handleDelete}
                >
                  Delete
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      ) : null}

      {/* Add Contacts Task Modal START */}
      <input
        type="checkbox"
        id="previewAddContacts"
        className="modal-toggle"
        checked={openAssignTaskModal}
        onChange={() => null}
        // onChange={() => setOpenAssignTaskModal(!openAssignTaskModal)}
      />
      <label
        htmlFor="previewAddContacts"
        className="cursor-pointer modal modalButton"
      >
        <label className="relative py-6 modal-box" htmlFor="">
          <div className="flex items-center justify-between mb-4 modal-content">
            <h4 className="text-lg font-bold">Assign Task</h4>
            <label
              htmlFor="previewAddContacts"
              onClick={() => setOpenAssignTaskModal(false)}
            >
              <i className="cursor-pointer fa-solid fa-xmark"></i>
            </label>
          </div>

          <p>
            <span className="font-bold">Task:</span> {previewTask?.title}
          </p>

          <p>
            <span className="font-bold">Deadline:</span>{" "}
            {moment(previewTask?.deadline).format("  DD MMMM YYYY")}
          </p>

          <div>
            <div className="relative">
              <input
                onChange={(event) => setContactSearchParam(event.target.value)}
                type="search"
                className="w-full border border-gray-400 input focus:border-primary focus:outline-none"
              />
              <button className="absolute top-0 right-0 w-16 h-full text-white bg-primary rounded-r-md">
                {contactSearchLoading ? (
                  <div className="display-position-center !min-h-[auto]">
                    <Spinner />
                  </div>
                ) : (
                  <FontAwesomeIcon icon={faSearch} />
                )}
              </button>
            </div>
            <form className="mt-8" onSubmit={handleAssignContacts}>
              {contacts?.map((contact, index) => {
                return (
                  <div className="z-0 mb-4" key={index}>
                    <div className="flex items-center justify-around mb-4">
                      <div className="flex items-center w-3/4 gap-2 form-check">
                        <img
                          className="rounded-full w-14 h-14"
                          src={
                            contact?.file_manager
                              ? contact?.file_manager?.url
                              : defaultUser
                          }
                          alt=""
                        />
                        <label
                          className="inline-block w-3/4 overflow-hidden text-xl text-gray-800 form-check-label "
                          htmlFor="flexCheckDefault"
                        >
                          {contact?.name}
                        </label>
                      </div>
                      <input
                        type="checkbox"
                        value={JSON.stringify(contact)}
                        name={`contact${contact?.id}`}
                        defaultChecked={previewTask?.task_assigns?.some(
                          (obj) => obj?.contact_id === contact?.id
                        )}
                        className="w-5 h-5 text-teal-600 border-gray-300 rounded accent-teal-600 focus:ring-teal-500 dark:focus:ring-teal-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                    </div>
                    <hr />
                  </div>
                );
              })}
              <div
                className="sticky bottom-0 z-10 flex items-center gap-2 w-4/4"
                style={{ width: `${assignTaskSpinner ? "93%" : "100%"}` }}
              >
                <label
                  htmlFor="previewAddContacts"
                  className="w-full modal-button"
                >
                  <button
                    className="w-full text-white capitalize rounded btn bg-purple-primary"
                    type="submit"
                  >
                    Assign
                  </button>
                </label>
                {assignTaskSpinner && <Spinner />}
              </div>
            </form>
          </div>
        </label>
      </label>

      {/* SubTask Modal */}
      <input type="checkbox" id="subtaskModal" className="modal-toggle" />
      <label
        htmlFor="subtaskModal"
        className="transition-all duration-300 cursor-pointer modal modalButton"
      >
        <label className="relative py-6 modal-box" htmlFor="">
          <div className="mb-4 modal-content">
            <h4 className="text-lg">Add Subtask</h4>
          </div>

          <div className="flex justify-between mt-8">
            <input
              className="w-full p-2 mt-2 bg-gray-200 rounded-lg"
              placeholder="Enter your subtask"
              onChange={(e) => setSubTaskName(e.target.value)}
              ref={subTaskInputRef}
            />
          </div>
          <label
            htmlFor="subtaskModal"
            className="w-full px-4 py-2 mt-8 font-semibold text-white border-2 rounded btn bg-purple-primary"
            onClick={handleUpdateSubTask}
          >
            Create Sub-Task
          </label>
        </label>
      </label>

      {/* Deadline Modal */}
      <Dialog
        fullWidth
        open={deadlineModalOpen}
        onClose={() => setDeadlineModalOpen(false)}
      >
        <DialogContent>
          <div className="pb-5">
            <div className="absolute w-6 h-6 top-5 right-5 closeBtn-with-bg">
              <button
                type="button"
                className="w-6 h-6"
                onClick={() => setDeadlineModalOpen(false)}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className={classNames("relative pt-4", styles.deadlineModal)}>
              <div className="flex items-center justify-center w-full mt-8 mb-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={date}
                    minDate={moment().toDate()}
                    onChange={(newValue) => setDate(newValue)}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        size="small"
                        placeholder="Task Deadline"
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>

            <button
              className="w-full px-4 py-2 font-semibold text-white border-2 rounded btn bg-purple-primary"
              onClick={handleUpdateDeadline}
            >
              Set Deadline
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <input type="checkbox" id="deadlineModal" className="modal-toggle" />
      <label
        htmlFor="deadlineModal"
        className="transition-all duration-300 cursor-pointer modal modalButton"
      >
        <label className="relative py-6 modal-box" htmlFor=""></label>
      </label>

      {/* Add Tags Modal */}
      <input type="checkbox" id="addTagModal" className="modal-toggle" />
      <label
        htmlFor="tagModal"
        className="transition-all duration-300 cursor-pointer modal modalButton"
      >
        <label className="relative py-6 modal-box" htmlFor="addTagModal">
          <div className="mb-4 modal-content">
            <h4 className="text-lg">Add Tags to Task</h4>

            <div className="flex flex-col">
              <div className="flex flex-wrap gap-2 mt-4 mb-4 divide-y">
                {previewTask?.task_tags?.length !== 0
                  ? previewTask?.task_tags?.map((tagObj, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          <Tag
                            title={tagObj?.tag?.name}
                            tagId={tagObj?.tag?.id}
                            userId={tagObj?.tag?.user_id}
                            index={idx}
                            handleDeleteTag={handleTaskTags}
                          />
                        </React.Fragment>
                      );
                    })
                  : "No tags for Task"}
                {taskTagSpinner && <Spinner />}
              </div>
              <div className="w-full border-t-2 border-gray-400">
                <div className="flex flex-wrap gap-2 mt-4 border4">
                  {tagArr?.map((tag, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <div onClick={() => handleTaskTags(tag?.id)}>
                          <Tag title={tag.name} tagId={idx} />
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </label>
      </label>
    </div>
  );
}

TaskList.propTypes = {
  tasks: PropTypes.array,
  tagArr: PropTypes.array,
  status: PropTypes.number,
  fetchTasks: PropTypes.func,
  statusValues: PropTypes.object,
  setIsTaskSpinner: PropTypes.func,
};

export default TaskList;
