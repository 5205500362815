import vactor from "../../../Images/bg/welcome.svg";
import DefaultPHoto from "../../../Images/bg/default.jpg";
import Cropper from "react-easy-crop";
import React from "react";
import Slider from "@mui/material/Slider";

function StepFour() {
  const inputRef = React.useRef();
  const triggerFileSelectPopup = () => inputRef.current.click();

  const [image, setImage] = React.useState(null);
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result);
      });
    }
  };

  return (
    <div className="w-full display-position-center">
      <div className="flex justify-center">
        <img
          className="object-center w-32 h-auto transition duration-500 transform rounded-3xl hover:scale-110"
          src={vactor}
          alt="vactor"
        />
      </div>

      <h1 className="mt-12 text-xl font-bold leading-tight md:text-2xl">
        Choose Your Profile Picture
      </h1>
      <p className="mt-1 text-sm text-gray-600">
        Upload a file to start cropping
      </p>

      <form className="w-full mt-6" method="post" action="#">
        <div className="cropper-wrapper">
          <div className="mb-3 crop-container">
            <div className="cropper">
              <Cropper
                image={image ? image : DefaultPHoto}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
                cropShape="round"
                showGrid={false}
                objectFit="contain"
              />
            </div>
            <div className="px-6 pt-6 slider">
              <Slider
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
          </div>
          <div className="text-center">
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              style={{ display: "none" }}
              onChange={onSelectFile}
            />
            <button
              type="button"
              onClick={triggerFileSelectPopup}
              className="tracking-tight text-white capitalize bg-purple-500 uploadBtnWithIcon btn-sm hover:bg-purple-600 focus:bg-purple-700 rounded-2xl d-flex"
            >
              Upload a new image{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="ml-2 bi bi-camera"
                viewBox="0 0 16 16"
              >
                {" "}
                <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />{" "}
                <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />{" "}
              </svg>
            </button>
          </div>
        </div>
        <div className="flex space-x-2">
          <a
            href="/register/step-3"
            className="w-full px-2 py-3 mt-6 font-semibold tracking-tight text-center text-gray-700 bg-gray-300 rounded-lg hover:bg-gray-400 focus:bg-gray-500"
          >
            Back
          </a>
          <button
            type="button"
            className="block w-full px-2 py-3 mt-6 font-semibold tracking-tight text-center text-white rounded-lg bg-brand-primary hover:bg-purple-600 focus:bg-purple-700"
          >
            Next Step
          </button>
          <a
            href="/register/step-5"
            className="w-full px-2 py-3 mt-6 font-semibold tracking-tight text-center text-gray-700 bg-gray-300 rounded-lg hover:bg-gray-400 focus:bg-gray-500"
          >
            Skip
          </a>
        </div>
      </form>
    </div>
  );
}

export default StepFour;
