import { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import defaultUser from "../../Images/default_user.png";
import "react-toastify/dist/ReactToastify.css";
import { getCompanies } from "../../Api/Company";
import {
  addTagToContact,
  createTag,
  getTags,
  updateContact,
} from "../../Api/Contact";
import { Button } from "react-bootstrap";

import { faCheck, faPlus, faClose } from "@fortawesome/free-solid-svg-icons";
// import Breadcrumbs from "../../Components/Breadcrumbs";
// import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import useAuth from "../../Hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tag from "Components/Tags/Tag";
import { CHARACTERS_ALLOWED } from "Helpers/Env";
import Spinner from "Components/Spinner/Spinner";

// const breadcrumbItems = [
//   { label: "Contacts", to: "/contacts", icon: faAddressBook },
//   { label: "Edit Contact", to: "/contacts/edit", icon: faEdit, isActive: true },
// ];

function Edit() {
  const { user } = useAuth();
  const [image, setImage] = useState({ preview: "", data: "" });
  const [companies, setCompanies] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isShowMore, setItShowMore] = useState(false);
  const [tagArr, setTagArr] = useState([]);
  const [tagText, setTagText] = useState("");
  const [isTagSpinner, setIsTagSpinner] = useState(false);

  const navigate = useNavigate();

  const { state } = useLocation();
  const { contact } = state;

  const fetchUserTags = useCallback(() => {
    getTags()
      .then((tags) => {
        setTagArr(tags.filter((tag) => tag?.user_id === user?.id));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user?.id]);

  useEffect(() => {
    fetchUserTags();
    getCompanies()
      .then((res) => {
        setCompanies(res);
      })
      .catch(console.log);
    setSelectedTags(contact?.tag);
  }, [contact?.tag, fetchUserTags]);

  const handleChooseImage = (event) => {
    const image = {
      preview: URL.createObjectURL(event.target.files[0]),
      data: event.target.files[0],
    };
    setImage(image);
  };

  const handleUpdate = async (event) => {
    let contactData = {};
    event.preventDefault();
    const { target } = event;
    Object.keys(target).forEach((input) => {
      const { value, type, name, files } = target[input];
      if (
        type === "text" ||
        type === "textarea" ||
        type === "number" ||
        type === "email" ||
        type === "select-one"
      ) {
        contactData = { ...contactData, [name]: value };
      } else if (type === "file") {
        contactData = {
          ...contactData,
          file_manager: files[0],
        };
      }
    });

    contactData = {
      name: `${contactData.firstName.replace(
        /\s/g,
        ""
      )} ${contactData.lastName.replace(/\s/g, "")}`,
      phone: contactData.phone,
      image: contactData.file_manager ? contactData.file_manager : "",
      designation: contactData.job,
      email: contactData.email,
      first_met: contactData.meet,
      company: contactData.company,
      notes: contactData.notes,
      firstName: contactData.firstName,
      lastName: contactData.lastName,
    };
    let formData = new FormData();
    formData.append("_method", "put");
    formData.append("name", contactData.name);
    formData.append("phone", contactData.phone);
    formData.append("image", contactData.image);
    formData.append("designation", contactData.designation);
    formData.append("email", contactData.email);
    formData.append("first_met", contactData.first_met);
    formData.append("company_id", contactData.company);
    formData.append("notes[0][note]", contactData.notes);
    formData.append("notes[0][id]", contactData?.notes[0]?.id);
    formData.append(
      "notes[0][date]",
      `${new Date().getFullYear}-${new Date().getMonth + 1}-${
        new Date().getDate
      }`
    );

    if (selectedTags.length > 0) {
      selectedTags.map((tag, index) =>
        formData.append(`tags[${index}][id]`, tag.id)
      );
    }

    if (contactData?.firstName?.length > 16) {
      toast.error("Name cannot be greater than 15 characters", { toastId: 1 });
    } else if (contactData?.lastName?.length > 16) {
      toast.error("Name cannot be greater than 15 characters", { toastId: 1 });
    } else {
      updateContact(formData, contact.id)
        .then((res) => {
          if (res) {
            toast.success("Contact is updated successfully");
            setTimeout(
              () => navigate("/contacts", { state: { contact: res?.contact } }),
              500
            );
          } else {
            toast.error("Contact cannot be updated");
          }
        })
        .catch((err) => {
          toast.error("Contact cannot be updated");
        });
    }
  };

  const handleDeleteTag = (tagId, index) => {
    let formData = new FormData();
    formData.append("_method", "put");
    formData.append("name", contact.name);
    formData.append("phone", contact.phone);
    formData.append("image", contact.file_manager ? contact.file_manager : "");
    formData.append("designation", contact.designation);
    formData.append("email", contact.email);
    formData.append("first_met", contact.first_met);
    formData.append("company_id", contact.company);
    formData.append("notes[0][note]", contact.notes);
    formData.append("notes[0][id]", contact?.notes[0]?.id);
    formData.append(
      "notes[0][date]",
      `${new Date().getFullYear}-${new Date().getMonth + 1}-${
        new Date().getDate
      }`
    );

    if (selectedTags.length > 0) {
      selectedTags.forEach((tag, index) => {
        if (tag?.id !== tagId) formData.append(`tags[${index}][id]`, tag?.id);
      });
    }

    updateContact(formData, contact.id)
      .then((res) => {
        if (res) {
          toast.success("Delete Tag successfully");
          setSelectedTags(res?.contact?.tag);
        } else {
          toast.error("Delete cannot be updated");
        }
      })
      .catch((err) => {
        toast.error("Delete Tag cannot be updated");
      });
  };

  const handleAssignTagToContact = (tag) => {
    addTagToContact({ contact_id: contact.id, tag_id: tag.id })
      .then((response) => {
        toast.success(response?.data?.message);
        setSelectedTags(response?.data?.contact?.tag);
        setTagText("");
        setIsTagSpinner(false);
        fetchUserTags();
      })
      .catch((err) => {
        setTagText("");
        setIsTagSpinner(false);
        toast.error(err.response.data.message);
      });
  };

  const handleAddTags = async () => {
    if (tagText.length > CHARACTERS_ALLOWED) {
      toast.error(
        `Tag Name cannot be greater than ${CHARACTERS_ALLOWED} characters`,
        { toastId: 1 }
      );
    } else {
      setIsTagSpinner(true);
      let { id: user_id } = user;
      try {
        const res = await createTag({
          name: tagText,
          color_id: 1,
          description: "test",
          user_id,
        });

        if (res?.message.includes("Tag Already Exists!")) {
          toast.error(res?.message);
        } else {
          handleAssignTagToContact(res?.tag);
        }
      } catch (err) {
        setIsTagSpinner(false);
        console.log(err);
      }
    }
  };

  return (
    <div>
      {/* <Breadcrumbs items={breadcrumbItems} nav /> */}
      <div className="grid grid-cols-1 md:grid-cols-1">
        <div className="px-4 mx-4 my-4 bg-white md:px-8 py-7 rounded-xl">
          <div className="flex items-start justify-between">
            <div>
              <h2 className="text-xl text-gray-800">Edit a contact</h2>
              <p className="mt-2 mb-6 text-xs text-gray-500">
                Fields marked with <strong>*</strong> are required
              </p>
            </div>
            <button
              className="w-8 h-8 text-sm text-white rounded-full bg-primary"
              onClick={() => navigate("/contacts")}
            >
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>
          <div className="grid grid-cols-12">
            <div className="col-span-12 md:col-span-6">
              <form onSubmit={handleUpdate}>
                <div className="flex items-center mb-6 space-x-2">
                  <input
                    type="file"
                    hidden
                    name="image"
                    id="image"
                    onChange={handleChooseImage}
                  />
                  {image?.preview ? (
                    <img
                      className="object-cover w-16 h-16 rounded-full"
                      src={image?.preview}
                      id="image"
                      alt="qliq"
                    />
                  ) : (
                    <img
                      className="object-cover w-16 h-16 rounded-full"
                      src={
                        contact?.file_manager
                          ? contact?.file_manager?.url
                          : defaultUser
                      }
                      id="image"
                      alt="qliq"
                    />
                  )}

                  <label
                    htmlFor="image"
                    className="text-gray-800 capitalize border-gray-200 rounded-full btn-sm btn btn-ghost"
                  >
                    Upload Image
                  </label>
                </div>

                <div className="grid sm:mb-6 sm:grid-cols-2 gap-x-3">
                  <div className="mb-6 sm:mb-0">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      First Name*
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="firstName"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="John"
                      required
                      defaultValue={contact?.name?.split(" ")[0]}
                    />
                  </div>

                  <div className="mb-6 sm:mb-0">
                    <label
                      htmlFor="lastName"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lastname"
                      name="lastName"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Doe"
                      defaultValue={contact?.name?.split(" ")[1]}
                    />
                  </div>
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="phone"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Phone Number*
                  </label>
                  <input
                    required
                    type="number"
                    id="phone"
                    name="phone"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="555-555-555"
                    defaultValue={contact?.phone}
                  />
                </div>
                {isShowMore && (
                  <>
                    <div className="mb-6">
                      <label
                        htmlFor="email"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Email Address
                      </label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="email@company.com"
                        defaultValue={contact?.email}
                      />
                    </div>

                    <div className="grid sm:grid-cols-2 gap-x-3">
                      <div className="mb-6">
                        <label
                          htmlFor="jobtitle"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Job Title
                        </label>
                        <input
                          type="text"
                          id="jobtitle"
                          name="job"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Software Engineer"
                          defaultValue={contact?.designation}
                        />
                      </div>

                      <div className="mb-6 sm:mb-0">
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Select Company
                        </label>
                        <select
                          name="company"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Ex - Qliq ltd"
                          style={{ height: 42 }}
                        >
                          <option disabled> Choose a company</option>
                          {companies?.map((company, index) =>
                            contact?.company?.id === company.id ? (
                              <option key={index} value={company.id} selected>
                                {company.name}
                              </option>
                            ) : (
                              <option key={index} value={company.id}>
                                {company.name}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>

                    <div className="mb-6">
                      <label
                        htmlFor="email"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        First Meet
                      </label>
                      <input
                        type="text"
                        id="meet"
                        name="meet"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Somewhere"
                        defaultValue={contact?.first_met}
                      />
                    </div>

                    <div className="mb-6">
                      <label
                        htmlFor="notes"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                      >
                        Extra Notes
                      </label>
                      <textarea
                        id="notes"
                        name="notes"
                        rows="4"
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Cool guy..."
                        defaultValue={contact?.notes[0]?.note}
                      />
                    </div>

                    <div className="mb-6">
                      <label
                        htmlFor="phone"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Select Tags
                      </label>
                      {selectedTags?.length !== 0 && (
                        <div className="flex flex-wrap items-center gap-2 mb-4">
                          {selectedTags.map((tag) => (
                            <Tag
                              key={tag.id}
                              tagId={tag?.id}
                              title={tag.name}
                              handleDeleteTag={handleDeleteTag}
                            />
                          ))}
                        </div>
                      )}
                      {isTagSpinner && <Spinner />}
                      <div className="relative pr-10 overflow-hidden rounded-lg max-w-112 sm:pr-14">
                        <TextField
                          className="bg-[#E5E7EB] border-none w-full h-10 sm:h-14 pl-5 justify-center text-xs sm:text-base"
                          placeholder="Add Tag"
                          onChange={(e) => setTagText(e.target.value)}
                          value={tagText}
                          sx={{
                            paddingLeft: "10px",
                            ".MuiAutocomplete-endAdornment": {
                              display: "none",
                            },
                            fieldset: {
                              display: "none",
                            },
                            input: {
                              padding: 0,
                              fontSize: "inherit",
                            },
                            ".MuiInputBase-root": {
                              fontSize: "inherit",
                            },
                          }}
                        />
                        <button
                          className={`absolute right-0 top-0 h-full w-10 sm:w-14 ${
                            !tagText ? "bg-[#665C82]" : "bg-[#31C48D]"
                          } text-white text-lg sm:text-3xl rounded-r-lg`}
                          onClick={handleAddTags}
                          disabled={!tagText}
                          type="button"
                        >
                          {tagText ? (
                            <FontAwesomeIcon icon={faCheck} />
                          ) : (
                            <FontAwesomeIcon icon={faPlus} />
                          )}
                        </button>
                      </div>
                      <div className="mt-3 all-tags">
                        <div className="flex flex-wrap gap-2">
                          {tagArr
                            ?.filter(
                              (tag) =>
                                !selectedTags
                                  ?.map((t) => t?.id)
                                  .includes(tag?.id)
                            )
                            ?.map((tag) => (
                              <Tag
                                key={tag.id}
                                title={tag?.name}
                                tagId={tag?.id}
                                // handleDeleteTag={handleDeleteTag}
                                onClick={() => handleAssignTagToContact(tag)}
                              />
                            ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="flex flex-col items-center h-full gap-4 mt-8 md:flex-row">
                  <Button
                    onClick={() => setItShowMore((prevState) => !prevState)}
                    type="button"
                    className="w-full px-5 py-2 text-sm font-medium text-center text-white bg-gray-400 border-0 border-gray-400 rounded-lg hover:bg-primary focus:outline-none focus:ring-blue-300 sm:w-auto dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    {isShowMore ? "Hide Details" : "Show more"}
                  </Button>
                  {isShowMore ? (
                    <Button
                      type="submit"
                      className="border-0 text-white bg-brand-primary hover:bg-purple-primary focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Update Contact
                    </Button>
                  ) : null}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;
