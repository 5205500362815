import { cardsList } from "Helpers/data";
import useAuth from "../../Hooks/useAuth";

function CardPreview({ cardBg, className }) {
  const { user } = useAuth();

  const activeCardId = user?.active_card ? user?.active_card?.id : 1;

  return (
    <>
      {user ? cardsList[activeCardId]?.card({ user, cardBg, className }) : null}
    </>
  );
}

export default CardPreview;
