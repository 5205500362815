import Axios from "axios";
import { BASE_API_URL } from "../Helpers/Env";

const config = {
  headers: {},
};

export const getCompanies = async () => {
  try {
    let res = await Axios.get(`${BASE_API_URL}companies`);
    return res?.data?.companies?.data;
  } catch (err) {
    console.log(err);
  }
};

export const editCompany = async (body, id) => {
  config.headers = {
    "content-type": "multipart/form-data",
  };
  try {
    let res = await Axios.post(`${BASE_API_URL}companies/${id}`, body, config);
    return res.data?.company;
  } catch (err) {
    console.log(err);
  }
};

export const createCompany = async (body) => {
  config.headers = {
    "content-type": "multipart/form-data",
  };
  try {
    let res = await Axios.post(`${BASE_API_URL}companies`, body, config);
    return res.data?.company;
  } catch (err) {
    console.log(err);
  }
};

export const deleteCompany = async (id) => {
  try {
    let res = await Axios.delete(`${BASE_API_URL}companies/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const searchComapny = async (name) => {
  try {
    let res = await Axios.get(`${BASE_API_URL}companies?search=${name}`);
    return res.data.companies.data;
  } catch (err) {
    console.log(err);
  }
};

export const getSingleCompany = async (id) => {
  try {
    let res = await Axios.get(`${BASE_API_URL}companies/${id}`);
    console.log(res);
  } catch (err) {
    console.log(err);
  }
};

export const downloadCompany = async (id) => {
  try {
    let res = await Axios.get(`${BASE_API_URL}companies/download/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
