const SampleComponent = () => {
    return (
        <div>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad animi architecto doloremque dolores eos error eum exercitationem, facere in iure iusto laborum magni molestiae natus nobis nostrum quae quisquam repellendus saepe sapiente similique sit sunt. Eaque earum exercitationem fugit illum, inventore laudantium ratione vitae? Aliquam amet aperiam beatae blanditiis, cupiditate deserunt dolorem dolores ea, eligendi error est eum eveniet expedita at incidunt iste iusto laboriosam laborum libero maiores neque nesciunt nihil nobis officiis pariatur quas quisquam rem repellendus rerum saepe, soluta tenetur ullam unde vel veniam vitae voluptate. Ducimus eaque eius eos ipsam officiis praesentium quidem quisquam suscipit tempora tempore!</p>


            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci amet animi dicta eligendi eos fugiat harum in libero modi odio, quia quidem recusandae velit? Eveniet exercitationem minus quam. At aut corporis exercitationem necessitatibus nobis nostrum quidem reprehenderit voluptatem voluptates. Ad amet architecto cum distinctio eaque, eos eveniet fugiat id laboriosam nesciunt odio praesentium qui quibusdam reprehenderit sapiente, suscipit veniam voluptate?</p>

            <img src="https://images.unsplash.com/photo-1589758438368-0ad531db3366?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80" alt="test" className="w-full mb-5"/>

            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aut cupiditate dolorem eius est molestias provident soluta sunt, unde voluptates?</p>

            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid dignissimos esse illum laborum molestias optio rem repudiandae sit tempore tenetur. A ab alias, aperiam at blanditiis commodi, consequatur cum doloremque facilis iusto, molestias nesciunt non nulla omnis porro possimus repudiandae tenetur ullam unde veritatis? At dignissimos dolore eveniet hic labore modi nulla odio temporibus voluptas voluptates. Accusamus ad aliquid, animi eaque eos esse, exercitationem facere harum iste iusto labore magni minima modi omnis optio porro praesentium quasi quia repellendus tempore tenetur totam velit vitae voluptatibus voluptatum? Aspernatur assumenda autem beatae consequatur consequuntur debitis dicta dolor eveniet explicabo fuga harum, hic magnam modi necessitatibus nihil non odio, porro praesentium provident quis quo sequi sint. Aspernatur corporis ducimus esse quisquam reprehenderit!</p>
        </div>
    );
};

export default SampleComponent;
