import axios from "axios";
import { registerUrl } from "../Helpers/ApiUrl";
import { BASE_API_URL, BASE_FRONTEND_URL } from "../Helpers/Env";

// export const signIn = (data) => axios.post(registerUrl, data);

export const signup = (data) => axios.post(registerUrl, data);

export const logout = () => axios.delete(BASE_API_URL + "logout");

export const checkEmailUsername = (data) =>
  axios.post(BASE_API_URL + "email-user", data);

export const forgotPasswordMail = (data) => {
  return axios.post(BASE_API_URL + "password/forget", {
    email: data,
    base_url: BASE_FRONTEND_URL.slice(0, -1),
  });
};

export const resetPasswordPost = (email, password, token) =>
  axios.post(`${BASE_API_URL}password/reset?token=${token}`, {
    email,
    password,
  });
