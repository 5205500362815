import React, {useEffect, useState} from "react";
import {getPackages} from "../../../Api/Packages";
import PackageCard from "../../../Components/Package/PackageCard";
import bg from "../assets/images/package-bg.png";

const Packages = ({handleTrial}) => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        getPackages()
            .then((res) => {
                setPackages(res?.data?.data?.packages);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <section
            className="pt-30 pb-32"
            style={{background: `url(${bg})`}}
            id="pricing"
        >
            <div className="container mx-auto">
                <h3 className="font-bold text-3xl md:text-4xl max-w-lg text-gray-800 leading-tight tracking-tight text-center mx-auto capitalize">
                    Ready to use our service? Choose a package.
                </h3>
                <hr className=" w-12 h-1 bg-orange-500 rounded-full mt-6 mx-auto mb-17"/>
                <div className="flex justify-center -mx-4 flex-wrap">
                    {packages?.map((pkg, index) => (
                        <div
                            className="px-4 mb-8 md:mb-0 flex-shrink-0 max-w-88 w-full"
                            key={pkg.id}
                        >
                            <PackageCard
                                variant={index % 2 === 0 ? "dark" : "light"}
                                data={pkg}
                                handleTrial={handleTrial}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Packages;
