import { Dialog, DialogContent } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

function CollectEmailFromUser({ isShowModal, closeModal, getEmailFromModal }) {
  const [email, setEmail] = useState("");
  const [hasShowMailWarning, setHasShowMailWarning] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    if (email.length > 0) {
      getEmailFromModal(email);
      closeModal();
    } else {
      setHasShowMailWarning(true);
    }
  };
  return (
    <Dialog fullWidth open={isShowModal} onClose={closeModal}>
      <DialogContent className="bg-[#f3f3f1] !py-12 transition-qliq">
        <div className="absolute top-5 right-5 h-6 w-6 closeBtn-with-bg">
          <button type="button" className="h-6 w-6" onClick={closeModal}>
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
        <form onSubmit={onSubmit}>
          <div>
            <label htmlFor="email" className="block text-gray-700">
              Email Address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="example@mail.com"
              onChange={(e) => setEmail(e.target.value)}
              defaultValue={email}
              required
              className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-brand-primary focus:bg-white focus:outline-none"
            />
            {hasShowMailWarning && <p>Please enter your mail</p>}
          </div>
          <button
            type="submit"
            className="w-full block bg-brand-primary hover:bg-purple-primary focus:bg-purple-primary text-white font-semibold rounded-lg px-4 py-3 mt-6"
          >
            Submit
          </button>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default CollectEmailFromUser;
