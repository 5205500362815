import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "./../../Images/logos/brand-logo.png";

const sidebarNavItems = [
  {
    display: "Dashboard",
    image: require("../../Images/icons/dashboard.png"),
    to: "/dashboard",
    section: "dashboard",
  },
  {
    display: "My Card",
    image: require("../../Images/icons/cards.png"),
    to: "/my-card",
    section: "my-card",
  },
  {
    display: "Contacts",
    image: require("../../Images/icons/contacts.png"),
    to: "/contacts",
    section: "contacts",
  },
  {
    display: "Task",
    image: require("../../Images/icons/tasks.png"),
    to: "/task",
    section: "task",
  },
  {
    display: "Email Signature",
    image: require("../../Images/icons/signature.png"),
    to: "/email-signature",
    section: "email-signature",
  },
  {
    display: "Analytics",
    image: require("../../Images/icons/analytics.png"),
    to: "/analytics",
    section: "analytics",
  },
  {
    display: "Setting",
    image: require("../../Images/icons/settings.png"),
    to: "/setting",
    section: "setting",
  },
  {
    display: "Packages",
    image: require("../../Images/icons/packages.png"),
    to: "/packages",
    section: "packages",
  },
  {
    display: "Feedback",
    image: require("../../Images/icons/feedback.png"),
    to: "/feedback",
    section: "feedback",
  },
];

const handleSidebarHide = () => {
  document.getElementById("isbody").classList.remove("collapse-sidebar");
};

const Sidebar = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const [isSidebarOpen] = useState(false);

  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    const activeItem = sidebarNavItems.findIndex(
      (item) => item.section === curPath
    );
    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  return (
    <aside
      className={`fixed inset-y-0 z-10 flex flex-shrink-0 md:static focus:outline-none ${
        isSidebarOpen === true ? "block" : "hidden"
      } md:block border-r  border-gray-200`}
    >
      <nav className="relative flex flex-col flex-shrink-0 h-full dark:bg-app-2 w-[220px] md:w-[4.9rem] z-30  bg-white">
        <div className="flex items-center justify-center w-12 h-12 mt-5 ml-6 md:ml-auto md:mr-auto">
          <Link to="/dashboard">
            <img src={logo} alt="Qliq" />
          </Link>
        </div>
        <div className="flex flex-col flex-1 mt-12">
          <ul className="space-y-2 tracking-wide">
            {sidebarNavItems.map((item, index) => (
              <div
                onClick={() => navigate(item.to)}
                key={index}
                title={item?.display}
              >
                <li className="mb-1" onClick={handleSidebarHide}>
                  <div
                    className={`pl-5 flex items-center cursor-pointer space-x-3 font-medium tracking-tighter 
										${activeIndex === index ? "active-item" : "sidebar-item"} font-bold `}
                  >
                    <img src={item.image} alt="" className="w-6 h-auto" />
                    <span className="font-medium md:hidden">
                      {item?.display}
                    </span>
                  </div>
                </li>
              </div>
            ))}
          </ul>
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;
