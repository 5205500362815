import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import App from "./App";
import { AuthProvider } from "./Contexts/AuthContext";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "./Helpers/Env";

// icons
import errorIcon from "./icons/error-icon.svg";
import successIcon from "./icons/success-icon.svg";

// styles
import "animate.css/animate.css";
import "react-image-crop/dist/ReactCrop.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

export const notify = (type, msg) => {
  toast(
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 16px",
        // maxWidth: "267px",
        height: "42px",
        background: "#FFFFFF",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
        borderRadius: "2px",
      }}
    >
      {type === "error" && <img src={errorIcon} alt="icon" />}
      {type === "success" && <img src={successIcon} alt="icon" />}
      <span style={{ marginLeft: "9px" }}>{msg}</span>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Router>
        <AuthProvider>
          {/* <ToastContainer autoClose={1000} position="top-center" /> */}
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            // pauseOnHover
            theme="light"
          />
          <App />
        </AuthProvider>
      </Router>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
