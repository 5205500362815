import * as React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { MdExpandMore } from "react-icons/md";
import { BASE_API_URL, CHARACTERS_ALLOWED } from "../../Helpers/Env";
// import iconChat from "../../Images/icons/chat.svg";
// import iconEmail from "../../Images/icons/mail.svg";
// import iconPhone from "../../Images/icons/phone.svg";
import Notes from "../../Components/Contacts/NoteTabs";
import defaultUser from "../../Images/default_user.png";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import TagsTabs from "../../Components/Contacts/TagsTabs";
// import TaskTabs from "../../Components/Contacts/TaskTabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DetailsTabs from "../../Components/Contacts/DetailsTabs";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import {
  addNoteToContact,
  addSubTaskToContactTask,
  addTagToContact,
  addTaskToContact,
  getContact,
  tagCreate,
  updateContact,
} from "../../Api/Contact";
import {
  faContactCard,
  // faInfoCircle,
  // faNoteSticky,
  // faTags,
  // faTasks,
  // faClose,
} from "@fortawesome/free-solid-svg-icons";
import getContactDetailsIcon from "Components/Contacts/ContactDetailsIcon";
// import CreateTask from "Components/Contacts/CreateTask";

const Contact = () => {
  const params = useParams();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState(null);
  const [selTask, setSelTask] = useState(null);
  // const [subTaskModal, setSubTaskModal] = useState(false);
  // const taskInputRef = React.useRef();
  // const [subTaskInput, setSubTaskInput] = useState("");
  // const [isDate, setIsDate] = useState(null);
  // const [taskName, setTaskName] = useState("");

  const isOpenTag = Boolean(searchParams.get("openTag") === "true");

  const handleDeleteContactTag = (tagId) => {
    let formData = new FormData();
    formData.append("_method", "put");
    formData.append("name", data?.name);
    formData.append("phone", data?.phone);
    formData.append("image", "");
    formData.append("designation", data?.designation);
    formData.append("email", data?.email);
    formData.append("first_met", data?.first_met);
    formData.append("company_id", data?.company);

    data?.tag?.forEach((tag, index) => {
      if (tag?.id !== tagId) formData.append(`tags[${index}][id]`, tag?.id);
    });

    data?.notes?.forEach((note, index) => {
      formData.append(`notes[${index}][note]`, note?.note);
      formData.append(`notes[${index}][id]`, note?.id);
      formData.append(`notes[${index}][date]`, note?.date);
    });

    updateContact(formData, data?.id)
      .then((res) => {
        if (res) {
          setData(res?.contact);
        } else {
          toast.error("Contact cannot be updated");
        }
      })
      .catch((err) => {
        toast.error("Contact cannot be updated");
      });
  };

  const handleAssignTagToContact = (values, { setSubmitting, resetForm }) => {
    if (values.title.length > CHARACTERS_ALLOWED) {
      toast.error(
        `Tag Name cannot be greater than ${CHARACTERS_ALLOWED} characters`,
        { toastId: 1 }
      );
      setSubmitting(false);
    } else {
      const tagData = {
        name: values.title,
        color_id: 1,
        description: "description",
      };

      tagCreate(tagData)
        .then((res) => {
          if (res?.data?.message.includes("Tag Already Exists!")) {
            toast.error(res?.data?.message);
            setSubmitting(false);
          } else {
            addTagToContact({ contact_id: data?.id, tag_id: res.data.tag.id })
              .then((response) => {
                setData(response.data.contact);
                setSubmitting(false);
                resetForm();
              })
              .catch((err) => {
                setSubmitting(false);
                console.log(err);
                toast.error(err.response.data.message);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleAssignTaskToContact = (values, { setSubmitting, resetForm }) => {
    const taskData = {
      contact_id: data.id,
      title: values.title,
    };
    addTaskToContact(taskData)
      .then((res) => {
        if (res) {
          setSubmitting(false);
          setData(res.data.contact);
          toast.success(res.data.message);
          resetForm();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setSubmitting(false);
      });
  };

  const handleSubTaskToContactTask = (values, { setSubmitting, resetForm }) => {
    const subTaskData = {
      contact_id: data.id,
      task_id: selTask.id,
      title: values.title,
    };
    addSubTaskToContactTask(subTaskData)
      .then((res) => {
        if (res) {
          setSubmitting(false);
          setData(res.data.contact);
          toast.success(res.data.message);
          resetForm();
          // setSubTaskModal(false);
          setSelTask(null);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setSubmitting(false);
      });
  };

  // const handleCloseSubTaskModal = () => {
  //   setSelTask(null);
  //   setSubTaskModal(false);
  // };

  // const handleOpenSubTaskModal = (task) => {
  //   setSelTask(task);
  //   setSubTaskModal(true);
  // };

  const handleAddNoteToContact = (values, { setSubmitting, resetForm }) => {
    addNoteToContact({ contact_id: data?.id, note: values?.note })
      .then((res) => {
        setData(res.data.contact);
        setSubmitting(false);
        resetForm();
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
        toast.error(err.response.data.message);
      });
  };

  const handleAddToPhone = () => {
    window.open(`${BASE_API_URL}contacts/${data.id}/download`, "_blank");
  };

  const getSingleContact = React.useCallback(() => {
    getContact(params.id)
      .then((res) => {
        if (res) {
          setData(res.data.contact);
        }
      })
      .catch((err) => console.log(err));
  }, [params.id]);

  useEffect(() => {
    getSingleContact();
  }, [params.id, state, getSingleContact]);

  const navigate = useNavigate();

  if (!data) return null;

  return (
    <div className="md:w-3/5 md:mx-auto md:my-12">
      {/* <div className="flex items-start justify-between w-full p-4 mb-6">
        <div></div>
        <button
          className="w-8 h-8 text-sm text-white rounded-full bg-primary"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faClose} />
        </button>
      </div> */}
      <div className="md:w-3/5 md:mx-auto md:my-12">
        <div className="contact">
          <div className="p-6 text-center bg-white contact_info rounded-xl">
            <img
              alt={data?.name}
              src={data?.file_manager ? data?.file_manager?.url : defaultUser}
              className="object-cover mx-auto rounded-full w-22 h-22"
            />
            <h3 className="mt-6 text-xl font-bold capitalize text-heading">
              {data?.name}
            </h3>
            {data?.designation && (
              <h3 className="mt-1 text-xs font-semibold text-black capitalize">
                {data?.designation}
              </h3>
            )}

            <div className="flex items-center justify-center mt-3 space-x-3">
              {data?.phone && (
                <a href={`tel:${data?.phone}`}>
                  {getContactDetailsIcon("phone")}
                </a>
                // <button className="bg-white md:bg-gray-100 p-1.5 rounded-lg">
                //   <img
                //     alt={data?.name}
                //     src={iconPhone}
                //     className="w-6 h-6 cursor-pointer"
                //     onClick={() => (window.location = `tel:${data?.phone}`)}
                //   />
                // </button>
              )}
              {data?.phone && (
                <a href={`sms://${data?.phone}`}>
                  {getContactDetailsIcon("message")}
                </a>
                // <button className="bg-white md:bg-gray-100 p-1.5 rounded-lg">
                //   <img
                //     alt={data?.name}
                //     src={iconChat}
                //     className="w-6 h-6 cursor-pointer"
                //     onClick={() => (window.location = `sms://${data?.phone}`)}
                //   />
                // </button>
              )}
              {data?.email && (
                <a href={`mailto:${data?.email}`}>
                  {getContactDetailsIcon("email")}
                </a>
                // <button className="bg-white md:bg-gray-100 p-1.5 rounded-lg">
                //   <img
                //     alt={data?.name}
                //     src={iconEmail}
                //     className="w-6 h-6 cursor-pointer"
                //     onClick={() => (window.location = `mailto:${data?.email}`)}
                //   />
                // </button>
              )}
            </div>
          </div>

          <div className="p-6 mt-6 bg-white contact_actions rounded-xl">
            {/* Tag Accordion */}
            <Accordion sx={{ ...accordionStyles }} expanded={isOpenTag}>
              <AccordionSummary
                id="contact_tags"
                expandIcon={<MdExpandMore />}
                sx={{ ...accordionSummaryStyles }}
              >
                <h2 className="flex items-center">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.16989 15.3L8.69989 19.83C10.5599 21.69 13.5799 21.69 15.4499 19.83L19.8399 15.44C21.6999 13.58 21.6999 10.56 19.8399 8.69005L15.2999 4.17005C14.3499 3.22005 13.0399 2.71005 11.6999 2.78005L6.69989 3.02005C4.69989 3.11005 3.10989 4.70005 3.00989 6.69005L2.76989 11.69C2.70989 13.04 3.21989 14.35 4.16989 15.3Z"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.5 12C10.8807 12 12 10.8807 12 9.5C12 8.11929 10.8807 7 9.5 7C8.11929 7 7 8.11929 7 9.5C7 10.8807 8.11929 12 9.5 12Z"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>

                  <span className="ml-2 text-[#1F2937] font-medium text-sm">
                    Add Tags
                  </span>
                </h2>
              </AccordionSummary>

              <AccordionDetails>
                <TagsTabs
                  contact={data}
                  setContact={setData}
                  handleDeleteTag={handleDeleteContactTag}
                  handleTagAssignToContact={handleAssignTagToContact}
                />
              </AccordionDetails>
            </Accordion>

            {/* Tasks Accordion */}
            {/* <Accordion sx={{ ...accordionStyles }}>
              <AccordionSummary
                id="contact_tasks"
                expandIcon={<MdExpandMore />}
                sx={{ ...accordionSummaryStyles }}
              >
                <h2 className="flex items-center">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.56006 18V13"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 15.5H7"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 2V5"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16 2V5"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M15.8098 3.41998C19.1498 3.53998 20.8398 4.76998 20.9398 9.46998L21.0698 15.64C21.1498 19.76 20.1998 21.83 15.1998 21.94L9.19983 22.06C4.19983 22.16 3.15983 20.12 3.07983 16.01L2.93983 9.82998C2.83983 5.12998 4.48983 3.82998 7.80983 3.57998L15.8098 3.41998Z"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <span className="ml-2 text-[#1F2937] font-medium text-sm">
                    Create Tasks
                  </span>
                </h2>
              </AccordionSummary>

              <AccordionDetails>
                // <TaskTabs
                //   contact={data}
                //   subTaskModal={subTaskModal}
                //   handleCloseSubTaskModal={handleCloseSubTaskModal}
                //   handleOpenSubTaskModal={handleOpenSubTaskModal}
                //   handleAssignTask={handleAssignTaskToContact}
                //   handleAssignSubTaskToContactTask={handleSubTaskToContactTask}
                // />
                <CreateTask
                  taskInputRef={taskInputRef}
                  // setTaskName={setTaskName}
                  // setSubTaskInput={setSubTaskInput}
                  isDate={isDate}
                  setIsDate={setIsDate}
                  contact={data}
                  updateContact={getSingleContact}
                />
              </AccordionDetails>
            </Accordion> */}

            {/* Notes Accordion */}
            <Accordion sx={{ ...accordionStyles }}>
              <AccordionSummary
                id="contact_tags"
                expandIcon={<MdExpandMore />}
                sx={{ ...accordionSummaryStyles }}
              >
                <h2 className="flex items-center">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 2.90826V5.90826"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16 2.90826V5.90826"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7 13.9083H15"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7 17.9083H12"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16 4.40826C19.33 4.58826 21 5.85826 21 10.5583V16.7383C21 20.8583 20 22.9183 15 22.9183H9C4 22.9183 3 20.8583 3 16.7383V10.5583C3 5.85826 4.67 4.59826 8 4.40826H16Z"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <span className="ml-2 text-[#1F2937] font-medium text-sm">
                    Notes
                  </span>
                </h2>
              </AccordionSummary>

              <AccordionDetails>
                <Notes
                  contact={data}
                  handleCreateNote={handleAddNoteToContact}
                />
              </AccordionDetails>
            </Accordion>

            {/* More Info Accordion */}
            <Accordion sx={{ ...accordionStyles }}>
              <AccordionSummary
                id="contact_tags"
                expandIcon={<MdExpandMore />}
                sx={{ ...accordionSummaryStyles }}
              >
                <h2 className="flex items-center">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 22.9083C17.5 22.9083 22 18.4083 22 12.9083C22 7.40826 17.5 2.90826 12 2.90826C6.5 2.90826 2 7.40826 2 12.9083C2 18.4083 6.5 22.9083 12 22.9083Z"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 8.90826V13.9083"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.9946 16.9083H12.0036"
                      stroke="#292D32"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <span className="ml-2 text-[#1F2937] font-medium text-sm">
                    More Info
                  </span>
                </h2>
              </AccordionSummary>

              <AccordionDetails>
                <DetailsTabs contact={data} />
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="mt-8 text-center contact_download">
            <button
              onClick={handleAddToPhone}
              className="px-3 py-2 text-sm font-medium text-white rounded bg-primary"
            >
              <FontAwesomeIcon icon={faContactCard} className="mr-1.5" />
              Add to Phone
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const accordionStyles = {
  backgroundColor: "transparent",
  borderRadius: 0,
  boxShadow: "none",
  borderBottom: "1px solid #D1D2D3",
  "&:last-child": {
    borderRadius: 0,
  },
  "&:before": {
    display: "none",
  },
};

const accordionSummaryStyles = {
  paddingX: 0,
  margin: "5px 0",
};

export default Contact;
