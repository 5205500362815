import {Link} from "react-router-dom";

const HeroArea = () => {
    return (
        <section className="hero bg-white pb-16 min-h-screen" id="slider">
            <div className="container px-4 sm:px-8 lg:px-16 xl:px-20 mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-12 gap-8 items-center">
                    <div className="hero-image col-span-6 md:hidden flex items-center justify-center">
                        <img
                            className="w-80 h-80"
                            src={require("../assets/images/header.png")}
                            alt="Qliq"
                        />
                    </div>
                    <div className="col-span-6">
                        <h1 className="font-extrabold text-4xl md:text-6xl max-w-xl text-gray-800 leading-tight tracking-tight">
                            Business cards for the new age
                        </h1>
                        <hr className=" w-12 h-1 bg-orange-500 rounded-full mt-8"/>
                        <p className="text-gray-600 text-base leading-relaxed mt-6 tracking-tight ">
                            Your Digital Business Card is just one click away! Get started for
                            free and start sharing your cards
                        </p>
                        <div className="flex  flex-col mt-10 justify-center md:justify-start">
                            <Link
                                to="/getting-started"
                                className="btn btn-ghost capitalize bg-[#5d5478] text-white hover:text-black"
                            >
                                Get Started For Free
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 ml-1"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </Link>
                            <Link
                                to="/find-out-more"
                                className="btn btn-ghost capitalize border border-gray-200 text-gray-800 mt-2"
                            >
                                Find out more
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 ml-1"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                                    />
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <div className="hero-image col-span-6 hidden md:block">
                        <img src={require("../assets/images/header.png")} alt="Qliq"/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroArea;
