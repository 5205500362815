import { useCallback, useEffect, useState } from "react";
import Tag from "../Tags/Tag";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, FormControl, FormText } from "react-bootstrap";
import Spinner from "../Spinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { addTagToContact, getTags } from "Api/Contact";
import useAuth from "Hooks/useAuth";
import { toast } from "react-toastify";

const TagsTabs = ({
  contact,
  setContact,
  handleDeleteTag,
  handleTagAssignToContact,
}) => {
  let { user } = useAuth();
  const [tagArr, setTagArr] = useState([]);

  const fetchTags = useCallback(() => {
    getTags()
      .then((tags) => {
        setTagArr(tags.filter((tag) => tag?.user_id === user?.id));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user?.id]);

  const handleAssignTagToContact = (tag) => {
    addTagToContact({ contact_id: contact.id, tag_id: tag.id })
      .then((response) => {
        setContact(response?.data?.contact);
        fetchTags();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    handleTagAssignToContact(values, { setSubmitting, resetForm });
    fetchTags();
  };

  useEffect(() => {
    if (tagArr.length === 0) {
      fetchTags();
    }
  }, [fetchTags, tagArr]);

  useEffect(() => {
    if (contact?.tag?.length !== 0) {
      fetchTags();
    }
  }, [contact?.tag?.length, fetchTags]);

  return (
    <div>
      {contact?.tag?.length !== 0 && (
        <div className="flex flex-wrap items-center gap-2 mb-4">
          {contact?.tag.map((tag) => (
            <Tag
              key={tag.id}
              tagId={tag?.id}
              title={tag.name}
              handleDeleteTag={handleDeleteTag}
            />
          ))}
        </div>
      )}

      <div>
        <Formik
          initialValues={{ title: "" }}
          onSubmit={onSubmit}
          validationSchema={yup.object().shape({
            title: yup.string().required("Tag is required!"),
          })}
        >
          {({
            handleSubmit,
            handleChange,
            errors,
            touched,
            isSubmitting,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="relative">
                <FormControl
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  placeholder="Add a new tag"
                  isInvalid={Boolean(errors.title && touched.title)}
                  className="w-full py-2 bg-gray-200 input focus:outline-0"
                />
                {Boolean(errors.title) && (
                  <FormText className="mt-1 text-red-600">
                    {errors.title}
                  </FormText>
                )}

                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="absolute top-0 right-0 border-none bg-primary"
                >
                  {isSubmitting ? (
                    <Spinner />
                  ) : (
                    <FontAwesomeIcon icon={faPlus} className="text-2xl" />
                  )}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>

      <div className="mt-3 all-tags">
        <div className="flex flex-wrap gap-2">
          {tagArr
            ?.filter(
              (tag) => !contact?.tag?.map((t) => t?.id).includes(tag?.id)
            )
            ?.map((tag) => (
              <Tag
                key={tag.id}
                title={tag?.name}
                tagId={tag?.id}
                // handleDeleteTag={handleDeleteTag}
                onClick={() => handleAssignTagToContact(tag)}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default TagsTabs;
