import { activateCoupon } from "Api/Coupon";
import { useState } from "react";
import { toast } from "react-toastify";

const CouponForm = ({
  couponText,
  setCouponText,
  setCouponPrice,
  totalPrice,
}) => {
  const [loading, setLoading] = useState(false);
  const handleCouponSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    activateCoupon(couponText)
      .then((res) => {
        const couponDetails = res?.data?.data?.coupon_use?.coupon;

        if (couponDetails?.min_spend_amount >= totalPrice) {
          toast.error(
            `You have to spend minimum $${couponDetails?.min_spend_amount} to get this discount.`
          );
        } else if (couponDetails?.min_spend_amount > couponDetails?.price) {
          setCouponPrice(couponDetails?.min_spend_amount);
          toast.success("Coupon Applied SuccessFully!");
        } else {
          setCouponPrice(couponDetails?.price);
          toast.success("Coupon Applied SuccessFully!");
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };
  return (
    <form className="flex" onSubmit={handleCouponSubmit}>
      <input
        name="couponCode"
        placeholder="Add a coupon"
        className="block p-2 px-2 sm:px-1 max-w-full sm:max-w-60 sm:text-xs text-xs text-gray-900 rounded-tl shadow appearance-none dark:text-white dark:border-gray-600 focus:outline-none focus:ring-0  peer"
        value={couponText}
        onChange={({ target }) => setCouponText(target.value)}
      />
      <button
        type="submit"
        className="bg-brand-primary rounded-tr text-sm text-white px-2 py-1 w-[60px]"
        disabled={loading}
      >
        Use
      </button>
    </form>
  );
};

export default CouponForm;
