import * as React from "react";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import Tag from "../Tags/Tag";
// import Spinner from "../Spinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faEnvelope,
  faHandshake,
  faLocationDot,
  faPhone,
  faStickyNote,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import { formatDateWithTime } from "utils";

function DetailsTabs({ contact }) {
  return (
    <div className="tab-content">
      <ul className="space-y-4 text-sm">
        {contact?.email && (
          <li className="relative pl-[25px]">
            <FontAwesomeIcon
              icon={faEnvelope}
              className="absolute left-0 text-primary top-1"
            />
            <a href={`mailto:${contact?.email}`}>{contact?.email ?? "-"}</a>
          </li>
        )}

        {contact?.phone && (
          <li className="relative pl-[25px]">
            <FontAwesomeIcon
              icon={faPhone}
              className="absolute left-0 text-primary top-1"
            />
            <a href={`tel:${contact?.phone}`}>{contact?.phone ?? "-"}</a>
          </li>
        )}

        {contact?.company && (
          <li className="relative pl-[25px]">
            <FontAwesomeIcon
              icon={faBuilding}
              className="absolute left-0 text-primary top-1"
            />
            {contact?.company?.name ?? "-"}
          </li>
        )}

        {contact?.first_met && (
          <li className="relative pl-[25px]">
            <FontAwesomeIcon
              icon={faHandshake}
              className="absolute left-0 text-primary top-1"
            />
            {contact?.first_met ?? "-"}
          </li>
        )}

        {contact?.street && (
          <li className="relative pl-[25px]">
            <FontAwesomeIcon
              icon={faLocationDot}
              className="absolute left-0 text-primary top-1"
            />
            {`${contact?.street}, ${contact?.city}, ${contact?.country}` ?? ""}
          </li>
        )}

        {contact?.notes?.length > 0 && (
          <li className="relative pl-[25px]">
            <FontAwesomeIcon
              icon={faStickyNote}
              className="absolute left-0 text-primary top-1"
            />
            {contact?.notes[0]?.note ?? "-"}
          </li>
        )}

        <li className="relative pl-[25px]">
          <FontAwesomeIcon
            icon={faCalendarAlt}
            className="absolute left-0 text-primary top-1"
          />
          {formatDateWithTime(contact?.created_at)}
        </li>

        <li className="relative pl-[25px]">
          <FontAwesomeIcon
            icon={faCalendarAlt}
            className="absolute left-0 text-primary top-1"
          />
          {formatDateWithTime(contact?.updated_at)}
        </li>
      </ul>
    </div>
  );
}

export default DetailsTabs;
