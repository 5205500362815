import * as React from "react";

const CloseCircleIcon = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 1.5c4.148 0 7.5 3.353 7.5 7.5 0 4.148-3.352 7.5-7.5 7.5A7.493 7.493 0 0 1 1.5 9c0-4.147 3.353-7.5 7.5-7.5Zm2.693 3.75L9 7.942 6.308 5.25 5.25 6.308 7.942 9 5.25 11.693l1.058 1.057L9 10.057l2.693 2.693 1.057-1.057L10.057 9l2.693-2.692-1.057-1.058Z"
      fill="#6A6087"
    />
  </svg>
);

export default CloseCircleIcon;
