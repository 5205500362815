import { React, useState } from "react";
import defaultUser from "../../Images/default_user.png";
import { createCompany } from "../../Api/Company";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../Components/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

function NewCompany() {
  const [image, setImage] = useState({ preview: "", data: "" });
  const navigate = useNavigate();
  const [isSpinner, setIsSpinner] = useState(false);
  const handleChooseImage = (event) => {
    const image = {
      preview: URL.createObjectURL(event.target.files[0]),
      data: event.target.files[0],
    };
    setImage(image);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData(event.target);

    setIsSpinner(true);

    createCompany(formData)
      .then((res) => {
        setIsSpinner(false);
        if (res) {
          toast.success("Company is created successfully", { toastId: 2 });
          setTimeout(
            () => navigate("/contacts/companys", { state: { company: res } }),
            3000
          );
        } else {
          toast.error("Company cannot be created", { toastId: 3 });
        }
      })
      .catch((err) => {
        setIsSpinner(false);
        toast.error("Company cannot be created", { toastId: 4 });
      });
  };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-1">
        <div className="px-4 py-6 mx-4 my-4 bg-white rounded-xl">
          <div className="flex items-start justify-between mb-6">
            <div>
              <h2 className="text-xl text-gray-800">Create a company</h2>
              <p className="mt-2 mb-6 text-xs text-gray-500">
                Fields marked with <strong>*</strong> are required
              </p>
            </div>
            <button
              className="w-8 h-8 text-sm text-white rounded-full bg-primary"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="flex items-center mb-6 space-x-2">
              <input
                type="file"
                hidden
                name="image"
                id="image"
                onChange={handleChooseImage}
              />
              {image.preview ? (
                <img
                  className="object-cover w-16 h-16 rounded-full"
                  src={image.preview}
                  alt="img"
                  id="image"
                />
              ) : (
                <img
                  className="object-cover w-16 h-16 rounded-full"
                  src={defaultUser}
                  alt="img"
                  id="image"
                />
              )}

              <label
                for="image"
                className="text-gray-800 capitalize border-gray-200 rounded-full btn-sm btn btn-ghost"
              >
                Upload Image
              </label>
            </div>
            <div className="mb-6">
              <label
                for="name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Company Name *
              </label>
              <input
                type="text"
                name="name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Ex - Qliq ltd"
                required
              />
            </div>
            <div className="mb-6">
              <label
                for="number"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Phone
              </label>
              <input
                type="number"
                name="phone"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="1254785488"
                required
              />
            </div>
            <div className="flex w-full gap-2 mb-6">
              <div className="w-full">
                <label
                  for="country"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Country *
                </label>
                <input
                  type="text"
                  name="country"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Type your country.."
                  required
                />
              </div>

              <div className="w-full">
                <label
                  for="state"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  State *
                </label>
                <input
                  type="text"
                  name="state"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Type your state.."
                  required
                />
              </div>

              <div className="w-full">
                <label
                  for="city"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  City *
                </label>
                <input
                  type="text"
                  name="city"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Type your city.."
                  required
                />
              </div>
            </div>
            <div className="mb-6">
              <label
                for="address"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Address *
              </label>
              <input
                type="text"
                name="street"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter your full Address"
                required
              />
            </div>

            <div className="mb-6">
              <label
                for="owner"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Owner *
              </label>
              <input
                type="text"
                name="owner"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Type owner name.."
                required
              />
            </div>
            <div className="flex items-center gap-4">
              <button
                type="submit"
                className="text-white bg-brand-primary hover:bg-purple-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Save Company
              </button>
              {isSpinner && <Spinner />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default NewCompany;
