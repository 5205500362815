import {Button, FormControl, FormText} from "react-bootstrap";
import {Formik} from "formik";
import * as yup from "yup"
import Spinner from "../Spinner/Spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faClipboard} from "@fortawesome/free-solid-svg-icons";
import React from "react";

function NoteTabs({handleCreateNote, contact}) {
    return (
        <div className="tab-content text-left">
            <Formik
                onSubmit={handleCreateNote}
                initialValues={{note: ""}}
                validationSchema={yup.object().shape({
                    note: yup.string().required("Note is required!")
                })}
            >
                {({handleSubmit, handleChange, errors, touched, isSubmitting, values}) => (
                    <form onSubmit={handleSubmit}>
                        <div className="relative">
                            <FormControl
                                name="note"
                                required
                                className='input w-full bg-gray-200 focus:outline-0 py-2 pr-14'
                                placeholder='Write here your note...'
                                isInvalid={Boolean(errors.note && touched.note)}
                                onChange={handleChange}
                                value={values.note}
                            />
                            {Boolean(errors.note) && (
                                <FormText className="text-red-600 mt-1">{errors.note}</FormText>
                            )}

                            <Button
                                type="submit"
                                disabled={isSubmitting}
                                className="bg-primary border-none absolute top-0 right-0"
                            >
                                {isSubmitting ? (
                                    <Spinner/>
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faAdd}
                                        className="text-2xl"
                                    />
                                )}
                            </Button>
                        </div>
                    </form>
                )}
            </Formik>

            <div className="mt-6">
                {contact?.notes.length === 0 ? (
                    <h5>There are no notes</h5>
                ) : (
                    <ul className="p-0 m-0 space-y-3 text-[#A8A6AD]">
                        {contact?.notes.map(note => (
                            <li key={note.id}>
                                <FontAwesomeIcon icon={faClipboard} className="mr-1.5"/>
                                {note.note}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
}

export default NoteTabs;