import PropTypes from "prop-types";

const FormatAddress = ({address}) => {
    return (
        <>
            {`${address?.street}, ${address?.city}, ${address?.country} - ${address?.zip}`}
        </>
    );
};

FormatAddress.propTypes = {
    address: PropTypes.object.isRequired
}

export default FormatAddress;
