export const isValidEmailAndPass = (email, pass) => {
  let validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  let validPass =
    /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*#?&])[a-zA-Z\d@$!%*#?&]{8,}$/;

  if (email.match(validEmail)) {
    if (pass.match(validPass)) {
      return true;
    } else {
      return false;
    }
  }
};
