import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import defaultUser from "../../Images/default_user.png";
import "react-toastify/dist/ReactToastify.css";
import { getCompanies } from "../../Api/Company";
import { Button } from "react-bootstrap";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { updateScanCard } from "Api/ScanCards";

function Edit() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { card } = state;
  const [companies, setCompanies] = useState([]);
  const [isShowMore, setItShowMore] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  if (!card) {
    navigate("/contacts/business-cards");
  }

  const getValue = (value = "") => {
    if (
      value === "" ||
      value === "null" ||
      value === "undefined" ||
      value === null ||
      value === undefined
    ) {
      return "";
    } else {
      return value;
    }
  };

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    let formData = new FormData();
    formData.append("_method", "put");
    formData.append("name", values?.name);
    formData.append("designation", values?.designation);
    formData.append("country", getValue(values?.country));
    formData.append("state", getValue(values?.state));
    formData.append("street", getValue(values?.street));
    formData.append("phone", values?.phone);
    formData.append("email", values?.email);
    // formData.append("company_id", values?.company);
    formData.append("company_id", Number(selectedCompany));
    formData.append("website", getValue(values?.website));

    // console.log("formData", formData);

    updateScanCard(values?.id, formData)
      .then((res) => {
        if (res) {
          setSubmitting(false);
          console.log("Updated card res", res);
          toast.success("Card updated successfully");
          // getScanCards();
          setTimeout(
            () =>
              navigate("/contacts/business-cards", {
                state: { card: res?.contact },
              }),
            500
          );
        } else {
          setSubmitting(false);
          toast.error("Card cannot be updated");
        }
      })
      .catch((err) => {
        setSubmitting(false);
        toast.error("Card cannot be updated");
      });
  };

  useEffect(() => {
    getCompanies()
      .then((res) => {
        setCompanies(res);
        setSelectedCompany(res.find((c) => c.id === card?.company?.id).id);
      })
      .catch(console.log);
  }, []);

  return (
    <div>
      {/* <Breadcrumbs items={breadcrumbItems} nav /> */}
      <div className="grid grid-cols-1 md:grid-cols-1">
        <div className="px-4 mx-4 my-4 bg-white md:px-8 py-7 rounded-xl">
          <div className="flex items-start justify-between">
            <div>
              <h2 className="text-xl text-gray-800">Edit a card</h2>
              <p className="mt-2 mb-6 text-xs text-gray-500">
                Fields marked with <strong>*</strong> are required
              </p>
            </div>
            <button
              className="w-8 h-8 text-sm text-white rounded-full bg-primary"
              onClick={() => navigate("/contacts/business-cards")}
            >
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>
          <div className="grid grid-cols-12">
            <div className="col-span-12 md:col-span-6">
              <Formik initialValues={card} onSubmit={onSubmit}>
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  setFieldValue,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="flex items-center mb-6 space-x-2">
                      <img
                        className="object-cover w-16 h-16 rounded-full"
                        src={
                          values?.file_manager
                            ? values?.file_manager?.url
                            : defaultUser
                        }
                        id="image"
                        alt="qliq"
                      />
                    </div>

                    {/* form input */}
                    <div>
                      <Input
                        label="Full Name"
                        name="name"
                        placeholder="Full Name"
                        onChange={handleChange}
                        defaultValue={values?.name}
                        required
                      />

                      <Input
                        label="Designation"
                        name="designation"
                        placeholder="Designation"
                        onChange={handleChange}
                        defaultValue={values?.designation}
                        required
                      />

                      <Input
                        label="Phone"
                        name="phone"
                        placeholder="Phone"
                        onChange={handleChange}
                        defaultValue={values?.phone}
                        required
                      />

                      <Input
                        type="email"
                        label="Email"
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        defaultValue={values?.email}
                        required
                      />
                    </div>

                    {isShowMore && (
                      <>
                        {/* select company */}
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Select Company
                          </label>

                          <select
                            name="company"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Ex - Qliq ltd"
                            style={{ height: 42 }}
                            // defaultValue={values?.company?.id}
                            value={selectedCompany}
                            onChange={(e) => {
                              // setFieldValue("company", e.target.value);
                              // handleChange(e);
                              setSelectedCompany(e.target.value);
                            }}
                          >
                            <option disabled> Choose a company</option>
                            {companies?.map((company, index) =>
                              card?.company?.id === company.id ? (
                                // <option key={index} value={company.id} selected>
                                <option key={index} value={company.id}>
                                  {company.name}
                                </option>
                              ) : (
                                <option key={index} value={company.id}>
                                  {company.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>

                        {/* country and state */}
                        <section className="flex w-full space-x-2">
                          <div className="w-12/12 sm:w-6/12">
                            <Input
                              label="Country"
                              name="country"
                              placeholder="Country"
                              onChange={handleChange}
                              defaultValue={values?.country}
                            />
                          </div>
                          <div className="w-12/12 sm:w-6/12">
                            <Input
                              label="State"
                              name="state"
                              placeholder="State"
                              onChange={handleChange}
                              defaultValue={values?.state}
                            />
                          </div>
                        </section>

                        {/* street */}
                        <Input
                          label="Street"
                          name="street"
                          placeholder="Street"
                          onChange={handleChange}
                          defaultValue={values?.street}
                        />

                        {/* website */}
                        <Input
                          label="Website"
                          name="website"
                          placeholder="Website"
                          onChange={handleChange}
                          defaultValue={values?.website}
                        />
                      </>
                    )}

                    {/* show or hide */}
                    <div className="flex justify-center h-full mt-6 mb-4">
                      <Button
                        onClick={() => setItShowMore((prevState) => !prevState)}
                        type="button"
                        className="w-full px-5 py-2 text-sm font-medium text-center text-white bg-gray-400 border-gray-400 rounded-lg hover:bg-primary focus:outline-none focus:ring-blue-300 sm:w-auto dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        {isShowMore ? "Hide Details" : "Show more"}
                      </Button>
                    </div>

                    {/* submit btn */}
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="w-full mt-2 text-white capitalize rounded-full py-2 lg:py-3 bg-brand-primary hover:bg-purple-primary text-[15px] font-[500]"
                    >
                      Update Card
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Input = ({ label, ...props }) => {
  return (
    <div className="w-full mb-4">
      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        {label}
        {props.required && <span className="ml-1 text-red-500">*</span>}
      </label>
      <input
        {...props}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      />
    </div>
  );
};

export default Edit;
