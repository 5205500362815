import { useEffect, useState } from "react";

import General from "./General";
import PasswordChange from "./Password";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { getUser, updateProfile, uploadProfilePicture } from "Api/User";
import Spinner from "Components/Spinner/Spinner";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = useState(0);
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState();
  const [profileImage, setProfileImage] = useState({ preview: "", data: "" });

  const fetchUser = () => {
    getUser()
      .then((res) => {
        setUser(res.data.user);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    setIsLoading(true);
    fetchUser();
  }, []);

  const updateUserProfile = (data, setSubmitting) => {
    updateProfile(data)
      .then((res) => {
        toast.success(res.data.message);
        setSubmitting(false);
        fetchUser();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setSubmitting(false);
      });
  };

  const handleUpdateUser = (values, { setSubmitting, dirty }) => {
    // const hasImage = Boolean(values?.image);
    const data = {
      ...values,
      user_phone: [
        {
          phone: values.phone,
          masking: "default phone",
        },
      ],
    };

    if (profileImage?.data) {
      const formData = new FormData();
      formData.append("image", profileImage?.data);
      formData.append("type", 1);
      uploadProfilePicture(formData)
        .then(() => {
          updateUserProfile(data, setSubmitting);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setSubmitting(false);
          console.log(err);
        });
    } else {
      updateUserProfile(data, setSubmitting);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // Bg white only current page
  useEffect(() => {
    document.body.className = "bg-white";
    return () => {
      document.body.className = "";
    };
  });

  return isLoading ? (
    <div className="display-position-center">
      <Spinner />
    </div>
  ) : (
    <Box className="qliq-tabs-wrap">
      <Box className="qliq-tabs-header">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab className="" label="Genaral" {...a11yProps(0)} />
          {/* <Tab label="2fa" {...a11yProps(1)} /> */}

          {user?.google_id?.length < 10 && (
            <Tab label="Password Change" {...a11yProps(2)} />
          )}
        </Tabs>
      </Box>
      <TabPanel className="bg-white qliq-tabs-content" value={value} index={0}>
        <General
          user={user}
          handleUpdateUser={handleUpdateUser}
          setProfileImage={setProfileImage}
        />
      </TabPanel>
      {/* <TabPanel className='qliq-tabs-content' value={value} index={1}>
                <TwoFactorVerification/>
            </TabPanel> */}
      <TabPanel className="qliq-tabs-content" value={value} index={1}>
        <PasswordChange />
      </TabPanel>
    </Box>
  );
}
