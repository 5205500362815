import classNames from "classnames";

const Tag = ({
  title,
  handleDeleteTag,
  tagId,
  index,
  className,
  onClick,
  userId,
}) => {
  return (
    <div
      className={classNames(
        "px-2 sm:px-3 py-1.5 rounded-md flex align-center text-white cursor-pointer  transition duration-300 ease  max-w-xl",
        className
      )}
      style={{ backgroundColor: " #665C82" }}
    >
      <div className="text-xs font-medium leading-4" onClick={onClick}>
        {title}
      </div>
      {handleDeleteTag && userId !== null ? (
        <button
          className="bg-transparent hover focus:outline-none"
          onClick={() => handleDeleteTag(tagId, index)}
          type="button"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="times"
            className="w-2 sm:w-2.5 ml-2"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 352 512"
          >
            <path
              fill="currentColor"
              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
            ></path>
          </svg>
        </button>
      ) : null}
    </div>
  );
};

export default Tag;
