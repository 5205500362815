import { getUser } from "Api/User";
import Signature3 from "Components/Signature/Signature3";
import { useEffect, useState } from "react";
// import ReactDOMServer from "react-dom/server";
import html2canvas from "html2canvas";
import { toast } from "react-toastify";

function Index() {
  const [user, setUser] = useState(null);

  // const el = ReactDOMServer.renderToStaticMarkup(<Signature3 user={user} />);
  const fetchUser = () => {
    getUser()
      .then((res) => {
        setUser(res.data.user);
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <div className="container px-4 py-6">
      <div className="grid p-4 rounded">
        <h2 className="text-xl text-gray-800">Digital Signature</h2>
        <p className="mt-2 mb-6 text-xs text-gray-500">
          Choose what to display on your public profile:
        </p>
        <div className="signature-template-wrap">
          {/* <Signature1/> */}
          <div className="flex justify-center">
            <Signature3 user={user} />
          </div>

          <div className="mx-auto mt-8 text-center btn-action">
            <button
              type="button"
              className="text-white bg-brand-primary hover:bg-purple-primary focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={() => {
                html2canvas(document.querySelector("#capture")).then(
                  (canvas) => {
                    canvas.toBlob((blob) => {
                      toast.success("Copied Successfully!");
                      return navigator.clipboard.write([
                        new window.ClipboardItem({ "image/png": blob }),
                      ]);
                    });
                  }
                );
              }}
            >
              Copy to Clipboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Index;
