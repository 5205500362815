import { Outlet } from "react-router-dom";
import Navbar from "./Navigation/Navbar";
import Sidebar from "./Navigation/Sidebar";

const AppLayout = () => {
  return (
    <div className="bg-[#f3f3f1]">
      <div className="flex w-screen h-screen antialiased">
        <Sidebar />
        <div className="flex flex-1 overflow-y-scroll content-transition">
          <main className="flex-1">
            <Navbar />
            <div className="mx-auto">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
