import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createContact, getTags, createTag } from "../../Api/Contact";
import { getCompanies } from "../../Api/Company";
import defaultUser from "../../Images/default_user.png";
import { CHARACTERS_ALLOWED } from "../../Helpers/Env";
import { Button } from "react-bootstrap";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faClose,
  faCheck,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../Hooks/useAuth";
import TextField from "@mui/material/TextField";
import Tag from "Components/Tags/Tag";
import Spinner from "Components/Spinner/Spinner";

const breadcrumbItems = [
  { label: "Contacts", to: "/contacts", icon: faAddressBook },
];

export default function NewContact() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [image, setImage] = useState({ preview: "", data: "" });
  const [isLoading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagText, setTagText] = useState("");
  const [isTagSpinner, setIsTagSpinner] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const [contact, setContact] = useState({
    company: "",
  });

  const fetchUserTags = useCallback(() => {
    getTags()
      .then((tags) => {
        setTags(tags.filter((tag) => tag?.user_id === user?.id));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  useEffect(() => {
    getCompanies()
      .then((res) => {
        setCompanies(res);
      })
      .catch(console.log);

    fetchUserTags();
  }, [fetchUserTags]);

  const handleChooseImage = (event) => {
    const image = {
      preview: URL.createObjectURL(event.target.files[0]),
      data: event.target.files[0],
    };
    setImage(image);
  };

  const handleSubmit = async (event) => {
    let contactData = {};
    event.preventDefault();
    const { target } = event;
    Object.keys(target).forEach((input) => {
      const { value, type, name, files } = target[input];
      if (
        type === "text" ||
        type === "textarea" ||
        type === "email" ||
        type === "number"
      ) {
        contactData = { ...contactData, [name]: value };
      } else if (type === "file") {
        contactData = { ...contactData, file_manager: files[0] };
      } else if (type === "select-one") {
        contactData = { ...contactData, company: value };
      }
    });

    contactData = {
      name: `${contactData.firstName} ${contactData.lastName}`,
      phone: contactData.phone,
      image: contactData.file_manager ? contactData.file_manager : "",
      designation: contactData.job,
      email: contactData.email,
      first_met: contactData.meet,
      company: contactData.company,
      notes: contactData.notes,
      task: contactData.task,
      firstName: contactData.firstName,
      lastName: contactData.lastName,
    };

    let formData = new FormData();
    formData.append("name", contactData.name ?? "");
    formData.append("phone", contactData.phone ?? "");
    formData.append("image", contactData.image ?? null);
    formData.append("designation", contactData.designation ?? "");
    formData.append("email", contactData.email ?? "");
    formData.append("first_met", contactData.first_met ?? "");
    formData.append("company_id", contactData.company ?? "");

    if (contactData.notes) {
      formData.append("notes[0][note]", contactData.notes ?? "");
      formData.append("notes[0][id]", 1);
      formData.append("notes[0][date]", new Date());
    }

    if (selectedTags.length > 0) {
      selectedTags.map((tag, index) =>
        formData.append(`tags[${index}][id]`, tag.id)
      );
    }

    setLoading(true);
    if (contactData?.firstName?.length > CHARACTERS_ALLOWED) {
      toast.error(
        `First Name cannot be greater than ${CHARACTERS_ALLOWED} characters`,
        { toastId: 1 }
      );
      setLoading(false);
    } else if (contactData?.lastName?.length > CHARACTERS_ALLOWED) {
      toast.error(
        `Last Name cannot be greater than ${CHARACTERS_ALLOWED} characters`,
        { toastId: 1 }
      );
      setLoading(false);
    } else {
      createContact(formData)
        .then((res) => {
          setLoading(false);
          if (res) {
            toast.success("Contact is created successfully", { toastId: 2 });
            setTimeout(
              () => navigate("/contacts", { state: { contact: res?.contact } }),
              400
            );
          } else {
            toast.error("Contact cannot be created", { toastId: 3 });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          toast.error("Contact cannot be created", { toastId: 4 });
        });
    }
  };

  const updatePost = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const updateCompany = (value) => {
    setContact({ ...contact, company: value });
    setIsShown(false);
  };

  const handleClose = () => {
    setIsShown(false);
  };

  const handleAddTags = async () => {
    if (tagText.length > CHARACTERS_ALLOWED) {
      toast.error(
        `Tag Name cannot be greater than ${CHARACTERS_ALLOWED} characters`,
        { toastId: 1 }
      );
    } else {
      setIsTagSpinner(true);
      let { id: user_id } = user;
      try {
        const res = await createTag({
          name: tagText,
          color_id: 1,
          description: "test",
          user_id,
        });

        if (res?.message.includes("Tag Already Exists!")) {
          toast.error(res?.message);
        } else {
          toast.success(res?.message);
          setSelectedTags([res?.tag, ...selectedTags]);
        }

        fetchUserTags();
        setIsTagSpinner(false);
        setTagText("");
      } catch (err) {
        setIsTagSpinner(false);
        console.log(err);
      }
    }
  };

  const handleDeleteTag = (tagId, index) => {
    const deleteItem = selectedTags.filter((item) => item?.id !== tagId);
    setSelectedTags(deleteItem);
  };

  return (
    <div>
      <Breadcrumbs items={breadcrumbItems} nav />

      <div className="grid grid-cols-1 md:grid-cols-1">
        <div
          // className="px-4 mx-4 my-4 bg-white md:px-8 py-7 rounded-xl"
          className="px-4 mx-4 my-4 bg-qliq-new md:px-8 py-7 rounded-xl"
        >
          <div className="flex items-start justify-between mb-6">
            <div>
              <h2 className="text-xl text-gray-800">Create a contact</h2>
              <p className="mt-2 text-xs text-gray-500">
                Fields marked with <strong>*</strong> are required
              </p>
            </div>
            <button
              className="w-8 h-8 text-sm text-white rounded-full bg-primary"
              onClick={() => navigate("/contacts")}
            >
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>
          <div className="grid grid-cols-12">
            <div className="col-span-12 md:col-span-6 xl:col-span-4">
              <form onSubmit={handleSubmit}>
                <div className="flex items-center mb-6 space-x-2">
                  <input
                    type="file"
                    hidden
                    name="image"
                    id="image"
                    onChange={handleChooseImage}
                  />
                  {image.preview ? (
                    <img
                      alt="Qliq"
                      src={image.preview}
                      id="image"
                      className="object-cover w-16 h-16 rounded-full"
                    />
                  ) : (
                    <img
                      alt="Qliq"
                      id="image"
                      src={defaultUser}
                      className="object-cover w-16 h-16 rounded-full"
                    />
                  )}

                  <label
                    htmlFor="image"
                    className="text-gray-800 capitalize border-gray-200 rounded-full btn-sm btn btn-ghost"
                  >
                    Upload Image
                  </label>
                </div>

                <div className="grid sm:mb-6 sm:grid-cols-2 gap-x-3">
                  <div className="mb-6 sm:mb-0">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      First Name*
                    </label>
                    <input
                      type="text"
                      id="firstname"
                      name="firstName"
                      className="input-new"
                      placeholder="John"
                      required
                    />
                  </div>

                  <div className="mb-6 sm:mb-0">
                    <label
                      htmlFor="lastname"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lastname"
                      name="lastName"
                      className="input-new"
                      placeholder="Doe"
                    />
                  </div>
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="phone"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Phone Number*
                  </label>
                  <input
                    required
                    type="number"
                    id="phone"
                    name="phone"
                    className="input-new"
                    placeholder="555-555-555"
                  />
                </div>
                <div>
                  <div className="flex flex-wrap gap-2 mt-4 mb-4">
                    {selectedTags.length !== 0
                      ? selectedTags.map((singleTag, idx) => {
                          return (
                            <React.Fragment key={idx}>
                              <Tag
                                title={singleTag?.name}
                                tagId={singleTag?.id}
                                index={idx}
                                handleDeleteTag={handleDeleteTag}
                                // className="capitalize"
                              />
                            </React.Fragment>
                          );
                        })
                      : "No tags"}
                    {isTagSpinner && <Spinner />}
                  </div>

                  <div className="relative pr-10 overflow-hidden rounded-lg max-w-112 sm:pr-14 shadow-dashboard-card">
                    <TextField
                      // className="bg-[#E5E7EB] border-none w-full h-10 sm:h-14 pl-5 justify-center text-xs sm:text-base"
                      className="justify-center w-full h-10 text-xs bg-transparent border-none"
                      placeholder="Add Tag"
                      onChange={(e) => setTagText(e.target.value)}
                      value={tagText}
                      sx={{
                        // paddingLeft: "10px",
                        ".MuiAutocomplete-endAdornment": {
                          display: "none",
                        },
                        fieldset: {
                          display: "none",
                        },
                        input: {
                          padding: 0,
                          fontSize: "inherit",
                        },
                        ".MuiInputBase-root": {
                          fontSize: "inherit",
                          height: "inherit",
                          paddingLeft: "10px",
                        },
                      }}
                    />
                    <button
                      className={`absolute right-0 top-0 h-full w-10 sm:w-14 ${
                        !tagText ? "bg-[#665C82]" : "bg-[#31C48D]"
                      } text-white text-lg sm:text-3xl rounded-r-lg`}
                      onClick={handleAddTags}
                      disabled={!tagText}
                      type="button"
                    >
                      <FontAwesomeIcon icon={tagText ? faCheck : faPlus} />
                    </button>
                  </div>
                  <div className="flex flex-wrap gap-2 mt-4">
                    {tags
                      ?.filter(
                        (tag) =>
                          !tags
                            ?.map((tagObj) => tagObj?.tag?.id)
                            .includes(tag?.id)
                      )
                      ?.map((tag, idx) => (
                        <Tag
                          key={idx}
                          title={tag?.name}
                          tagId={idx}
                          onClick={() =>
                            setSelectedTags([tag, ...selectedTags])
                          }
                        />
                      ))}
                  </div>
                </div>

                {showMore && (
                  <>
                    <div className="my-6">
                      <label
                        htmlFor="email"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Email Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="input-new"
                        placeholder="email@company.com"
                      />
                    </div>

                    <div className="grid gap-3 sm:mb-6 sm:grid-cols-2">
                      <div>
                        <label
                          htmlFor="jobtitle"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Job Title
                        </label>
                        <input
                          type="text"
                          id="job"
                          name="job"
                          className="input-new"
                          placeholder="Software Engineer"
                        />
                      </div>

                      <div
                        className="relative mb-6 sm:mb-0 "
                        onMouseLeave={() => handleClose()}
                      >
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Company
                        </label>
                        <input
                          type="text"
                          id="company"
                          name="company"
                          value={contact.company}
                          onChange={updatePost}
                          className="input-new"
                          placeholder="Enter Company"
                          onMouseUp={() => setIsShown(true)}
                        />
                        {isShown && (
                          <ul className="w-full mt-0.2 absolute border border-gray-200 rounded overflow-hidden shadow-md">
                            {companies?.map((company) => (
                              <li
                                className="px-4 py-2 transition-all duration-300 ease-in-out bg-white border-b border-gray-200 cursor-pointer hover:bg-gray-700 hover:text-gray-50 last:border-none"
                                onClick={() => updateCompany(`${company.name}`)}
                              >
                                {company.name}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>

                    <div className="mb-6">
                      <label
                        htmlFor="email"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        First Meet
                      </label>
                      <input
                        type="text"
                        id="meet"
                        name="meet"
                        className="input-new"
                        placeholder="Somewhere"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="notes"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                      >
                        Extra Notes
                      </label>
                      <textarea
                        id="notes"
                        name="notes"
                        rows="4"
                        className="input-new"
                        placeholder="Cool guy..."
                      />
                    </div>
                  </>
                )}

                <div className="flex flex-row items-center gap-3 mt-4 sm:gap-4">
                  <button
                    onClick={() => setShowMore((prevState) => !prevState)}
                    type="button"
                    // className="w-full px-5 py-2 text-sm font-medium text-center text-white bg-gray-400 border-gray-400 rounded-lg hover:bg-primary focus:outline-none focus:ring-blue-300 sm:w-auto dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    className="w-[135px] px-5 py-2 bg-white button-new"
                    style={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                  >
                    {showMore ? "Hide Details" : "Add more"}
                  </button>

                  <button
                    type="submit"
                    disabled={isLoading}
                    // className="w-full px-5 py-2 text-sm font-medium text-center text-white border-0 rounded-lg bg-brand-primary hover:bg-purple-primary focus:ring-0 focus:outline-none focus:ring-blue-300 sm:w-auto dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    className="w-[155px] px-5 py-2 bg-white button-new"
                    style={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                  >
                    {isLoading ? "Creating..." : "Save Contact"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
