import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";

const Confirmation = ({open, title, confirmLabel, onConfirm, onCancel}) => {
    return (
        open && (
            <SweetAlert
                warning
                showCancel
                onCancel={onCancel}
                onConfirm={onConfirm}
                confirmBtnBsStyle="danger"
                title={title ?? "Delete Item"}
                confirmBtnCssClass="!bg-red-700"
                confirmBtnText={confirmLabel ?? "Yes, delete it!"}
            >
                Are you sure you want to delete this?
            </SweetAlert>
        )
    );
};

Confirmation.propTypes = {
    title: PropTypes.string,
    cancelLabel: PropTypes.string,
    confirmLabel: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
}

export default Confirmation;
