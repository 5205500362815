import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';


function StepThree({handleChange}) {
    return (
        <div className="w-full display-position-center">
            <div className='mb-4 text-center'>
                <h1 className="text-xl md:text-2xl font-bold leading-tight">Choose a username</h1>
                <p className="text-dark-600 text-sm mt-1">Make it Unique!</p>
            </div>


            <div className="mt-6 w-full">
                <div className="content-wrapper">
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon3" className='h-50-px'>
                            qliq.com/
                        </InputGroup.Text>
                        <Form.Control
                            onChange={handleChange}
                            id="basic-url"
                            name="username"
                            aria-describedby="basic-addon3"
                            placeholder='@username'
                            className='w-full px-4 py-3 rounded-lg bg-white border-purple-500 border focus:border-purple-500 focus:bg-white focus:outline-none'
                            autoFocus
                        />
                    </InputGroup>
                </div>

                {/*<hr className="my-6 border-gray-300 w-full"/>*/}

                {/*<button*/}
                {/*    type="submit"*/}
                {/*        className="w-full block bg-brand-primary hover:bg-purple-600  focus:bg-purple-700 text-white font-semibold rounded-lg px-4 py-3 mt-6">*/}
                {/*    Complete Setup*/}
                {/*</button>*/}
            </div>
        </div>
    );
}

export default StepThree;