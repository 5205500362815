import QRCode from "react-qr-code";
import defaultImg from "../../Images/default_user.png";
import GlobeIcon from "./components/reactIcons/GlobeIcon";
import LocationIcon from "./components/reactIcons/LocationIcon";
import MailIcon from "./components/reactIcons/Mail";
import PhoneIcon from "./components/reactIcons/PhoneIcon";
import FormatAddress from "../FormatAddress";

const Signature3 = ({ user }) => {
  const makeLink = (url) => {
    let link = url.split(".");
    if (!link.includes("http")) {
      return `http://${url}`;
    }
    return url;
  };
  return (
    <table
      id="capture"
      style={{
        maxWidth: "343px",
        width: "100%",
        background: "#ffffff",
        borderRadius: "18px",
      }}
    >
      <tr>
        <td style={{ padding: "36px 36px 28px" }}>
          <table>
            <tr
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "24px",
                borderBottom: "1px solid #e7edff",
              }}
            >
              <td style={{ paddingRight: "12px", border: "none" }}>
                <span
                  className="avatar"
                  style={{
                    height: "70px",
                    width: "70px",
                    display: "block",
                    borderRadius: "50%",
                    border: "4px solid #6a6087",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={`${user?.profile?.url || defaultImg}`}
                    alt="user"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </span>
              </td>
              <td>
                <h2
                  style={{ fontSize: "20px", margin: 0, marginBottom: "4px" }}
                >
                  {(user?.first_name || user?.last_name) &&
                    `${user?.first_name} ${user?.last_name}`}
                </h2>
                {user?.user_details?.designation && (
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: "12px",
                      color: "#6a6087",
                      margin: 0,
                    }}
                  >
                    {user?.user_details?.designation}
                  </p>
                )}
              </td>
            </tr>
            <tr style={{ paddingTop: "20px" }}>
              <td style={{ border: "none" }}>
                <ul
                  style={{
                    padding: "20px 4px 0",
                    marginBottom: "40px",
                    marginTop: 0,
                  }}
                >
                  {user?.email?.length > 0 && (
                    <li
                      style={{
                        listStyle: "none",
                        marginBottom: "2px",
                      }}
                      className=""
                    >
                      <a
                        style={{
                          fontSize: "12px",
                        }}
                        className=" text-[#6a6087] hover:bg-brand-primary py-2 pl-2 rounded hover:text-white flex items-center"
                        href={`mailto:${user?.user_email[0]?.email}`}
                      >
                        <MailIcon
                          style={{
                            height: "auto",
                            width: "16px",
                            marginRight: "16px",
                          }}
                          viewBox={`0 0 500 500`}
                        />
                        {user?.user_email[0]?.email}
                      </a>
                    </li>
                  )}

                  {user?.user_phone?.length > 0 && (
                    <li
                      style={{
                        listStyle: "none",
                        marginBottom: "2px",
                      }}
                    >
                      <a
                        style={{
                          fontSize: "12px",
                        }}
                        className=" text-[#6a6087] hover:bg-brand-primary py-2 pl-2 rounded hover:text-white flex items-center"
                        href={`tel:${user?.user_phone[0]?.phone}`}
                      >
                        <PhoneIcon
                          style={{
                            height: "auto",
                            width: "16px",
                            marginRight: "16px",
                          }}
                        />
                        {user?.user_phone[0]?.phone}
                      </a>
                    </li>
                  )}

                  {user?.user_details?.website && (
                    <li
                      style={{
                        listStyle: "none",
                        marginBottom: "2px",
                      }}
                    >
                      <a
                        style={{
                          fontSize: "12px",
                        }}
                        className=" text-[#6a6087] hover:bg-brand-primary py-2 pl-2 rounded hover:text-white flex items-center"
                        href={makeLink(user?.user_details?.website)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <GlobeIcon
                          style={{
                            height: "auto",
                            width: "16px",
                            marginRight: "16px",
                          }}
                        />
                        {user?.user_details?.website}
                      </a>
                    </li>
                  )}

                  {user?.user_address && (
                    <li
                      style={{
                        listStyle: "none",
                        marginBottom: "5px",
                      }}
                    >
                      <a
                        style={{
                          fontSize: "12px",
                        }}
                        className=" text-[#6a6087] hover:bg-brand-primary py-2 pl-2 rounded hover:text-white flex items-center"
                        href={`https://www.google.com/maps/place/${user?.user_details?.portpolio}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LocationIcon
                          style={{
                            height: "auto",
                            width: "16px",
                            marginRight: "16px",
                          }}
                        />
                        <FormatAddress address={user?.user_address} />
                      </a>
                    </li>
                  )}
                </ul>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "none",
                }}
              >
                <QRCode
                  id="QRCode"
                  style={{ height: "auto", width: "72px", marginRight: "14px" }}
                  viewBox={`0 0 256 256`}
                  value={`Name: ${user?.first_name} ${user?.last_name}\nEmail: ${user?.email}\nPhone:${user?.user_phone[0]?.phone}`}
                />
                <p
                  style={{
                    margin: 0,
                    maxWidth: "139px",
                    color: "#6a6087",
                    fontSize: "12px",
                  }}
                >
                  If you feel more about me just scan the QR code.
                </p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  );
};

export default Signature3;
