import HeroArea from "./Components/HeroArea";
import Packages from "./Components/Packages";
import ChooseArea from "./Components/ChooseArea";
import SocialMedia from "./Components/SocialMedia";
import NetworksArea from "./Components/NetworksArea";
import useAuth from "../../Hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { activeTrial } from "../../Api/Packages";
import { toast } from "react-toastify";

const LandingPage = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const handleTrial = () => {
    if (isAuthenticated) {
      activeTrial()
        .then((res) => {
          toast.success(res.data.message);
          navigate("/my-card");
        })
        .catch((err) => {
          console.log(err);
          toast.success(err.response.data.message);
        });
    } else {
      navigate("/login?trial=yes");
    }
  };

  return (
    <>
      <HeroArea />
      <ChooseArea handleTrial={handleTrial} />
      <SocialMedia handleTrial={handleTrial} />
      <NetworksArea handleTrial={handleTrial} />
      <Packages handleTrial={handleTrial} />
    </>
  );
};

export default LandingPage;
