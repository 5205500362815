import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabUnstyled from "@mui/base/TabUnstyled";
// import Badge from "@mui/material/Badge";
// import Divider from "@mui/material/Divider";
// import TextField from "@mui/material/TextField";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CHARACTERS_ALLOWED } from "../../Helpers/Env";

import { Dialog, DialogContent } from "@mui/material";
import { createTag, deleteTag, getTags, updateTag } from "../../Api/Contact";
import { createTask, getTasks } from "../../Api/Task";
import Spinner from "../../Components/Spinner/Spinner";
import Tag from "../../Components/Tags/Tag";
// import { DatePicker } from "@mui/x-date-pickers";
// import moment from "moment";
import TaskList from "./components/TaskList";
// import { notify } from "index";
import CreateTaskModal from "./components/CreateTaskModal";

function Index() {
  const [tagArr, setTagArr] = useState([]);
  const [tag, setTag] = useState("");
  const [selectedTag, setSelectedTag] = useState(null);
  const [isDate, setIsDate] = useState(null);
  const [taskName, setTaskName] = useState("");
  const [isTaskSpinner, setIsTaskSpinner] = useState(true);
  const [allTasks, setAllTasks] = useState([]);
  const [openTasks, setOpenTasks] = useState([]);
  const [closedTasks, setClosedTasks] = useState([]);
  const [archivedTasks, setArchivedTasks] = useState([]);
  const [subTaskInput, setSubTaskInput] = useState("");
  const [subTasks, setSubTasks] = useState([]);
  const [isTagSpinner, setIsTagSpinner] = useState(false);
  const [createTaskModalOpen, setCreateTaskModalOpen] = useState(false);
  const [updateTagModalOpen, setUpdateTagModalOpen] = useState(false);
  const taskInputRef = useRef();
  const subTaskInputRef = useRef();
  const [isError, setIsError] = useState(null);
  const [openAssignTaskModal, setOpenAssignTaskModal] = useState(false);
  const [previewTask, setPreviewTask] = useState({});

  const statusValues = {
    open: 1,
    closed: 2,
    archived: 3,
  };

  const fetchTags = () => {
    setIsTagSpinner(true);
    getTags()
      .then((tags) => {
        setTagArr(tags);
        setIsTagSpinner(false);
      })
      .catch((err) => {
        console.error(err);
        setIsTagSpinner(false);
      });
  };

  const fetchTasks = useCallback(async () => {
    getTasks()
      .then((_tasks) => {
        setIsTaskSpinner(false);
        setAllTasks(
          _tasks?.filter((tas) => tas.status !== statusValues.archived)
        );
        setOpenTasks(_tasks?.filter((tas) => tas.status === statusValues.open));
        setClosedTasks(
          _tasks?.filter((tas) => tas.status === statusValues.closed)
        );
        setArchivedTasks(
          _tasks?.filter((tas) => tas.status === statusValues.archived)
        );
        return _tasks;
      })
      .catch((err) => {
        console.error(err);
        setIsTaskSpinner(false);
        return err;
      });
  }, [statusValues.archived, statusValues.closed, statusValues.open]);

  useEffect(() => {
    fetchTags();
    fetchTasks();
  }, [fetchTasks]);

  const handleAddTags = async () => {
    if (tag.length > CHARACTERS_ALLOWED) {
      toast.error(
        `Tag Name cannot be greater than ${CHARACTERS_ALLOWED} characters`,
        { toastId: 1 }
      );
    } else {
      setIsTagSpinner(true);
      try {
        const res = await createTag({
          name: tag,
          color_id: 1,
          description: "test",
        });
        setTag("");
        fetchTags();
        if (res?.message.includes("Tag Already Exists!")) {
          toast.error(res?.message);
        } else {
          toast.success(res?.message);
        }
      } catch (err) {
        console.error(err);
        setIsTagSpinner(false);
      }
    }
  };

  const handleChangeTag = (event) => {
    setTag(event.target.value);
  };

  const handleSubTasks = (e) => {
    if (subTaskInput.length > 0) {
      subTaskInputRef.current.value = "";
      setSubTasks([...subTasks, subTaskInput]);
    }
  };

  const handleAddTask = (e) => {
    e.preventDefault();
    let formData = new FormData();
    if (taskName.length > 0 && isDate) {
      setIsTaskSpinner(true);
      taskInputRef.current.value = "";

      formData.append("title", taskName);
      formData.append("deadline", isDate.format("YYYY-MM-DD"));
      formData.append("status", 1);
      subTasks?.forEach((subTask, index) => {
        formData.append(`subtasks[${index}][title]`, subTask);
        formData.append(`subtasks[${index}][status]`, 1);
      });
      createTask(formData)
        .then((res) => {
          fetchTasks();
          setTaskName("");
          setIsDate(new Date());
          setSubTasks([]);
          setSubTaskInput("");
          setIsError(null);
          toast.success(res.data.message); // "Task Created Successfully"
          // notify("success", res.data.message);
          setIsTaskSpinner(false);
          setCreateTaskModalOpen(false);
          setOpenAssignTaskModal(true);
          setPreviewTask(res.data.task);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setIsTaskSpinner(false);
        });
    } else {
      setIsError("Task name & Date is required");
    }
  };

  const handleUpdateTag = () => {
    setIsTagSpinner(true);
    const reqObj = {
      ...selectedTag,
      color_id: selectedTag.color_id || 1,
    };

    updateTag(reqObj)
      .then((res) => {
        if (res?.message.includes("you can't update default Tag")) {
          toast.error(res?.message);
          setUpdateTagModalOpen(false);
        } else {
          toast.success(res?.message);
          setUpdateTagModalOpen(false);
          fetchTags();
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setIsTagSpinner(false);
        setUpdateTagModalOpen(false);
      });
  };

  const handleDeleteTag = (tagId) => {
    setIsTagSpinner(true);
    deleteTag(tagId)
      .then((res) => {
        fetchTags();
        toast.success(res?.message);
      })
      .catch((err) => {
        console.error(err.response.data.message);
        setIsTagSpinner(false);
      });
  };

  return (
    <div className="container px-4 py-6">
      <div className="grid px-5 py-6 bg-white">
        <div className="flex justify-between gap-2 pb-4 mb-5 border-b">
          <h4 className="text-xl font-bold">Tasks</h4>
          <button
            onClick={() => setCreateTaskModalOpen(true)}
            className="tracking-tight text-white capitalize rounded modal-button btn btn-ghost btn-sm bg-purple-primary"
          >
            Create new Task
          </button>
        </div>

        <div className="overflow-hidden custom-tabs-wrapper task-tab-wrapper">
          <TabsUnstyled className="custom-tabs-wrap" defaultValue={0}>
            <TabsListUnstyled className="flex pt-3 space-x-3 overflow-x-auto custom-tabs-header md:space-x-5">
              <TabUnstyled className="flex justify-center font-bold text-[14px] tracking-[-0.03em] rounded-t text-[#454D7C] align-content-center ">
                All <span className="ml-1">({allTasks?.length})</span>
              </TabUnstyled>
              <TabUnstyled className="flex justify-center font-bold text-[14px] tracking-[-0.03em] rounded-t text-[#454D7C] align-content-center ">
                Open <span className="ml-1">({openTasks?.length})</span>
              </TabUnstyled>
              <TabUnstyled className="flex justify-center font-bold text-[14px] tracking-[-0.03em] rounded-t text-[#454D7C] align-content-center ">
                Closed <span className="ml-1">({closedTasks?.length})</span>
              </TabUnstyled>
              <TabUnstyled className="flex justify-center font-bold text-[14px] tracking-[-0.03em] rounded-t text-[#454D7C] align-content-center ">
                Archived <span className="ml-1">({archivedTasks?.length})</span>
              </TabUnstyled>
            </TabsListUnstyled>

            <div className="flex justify-between w-full gap-12">
              <div className="flex flex-col items-center w-full gap-4">
                <div>{isTaskSpinner && <Spinner />}</div>
                <TabPanelUnstyled
                  className="w-full mt-4 custom-tabs-content"
                  value={0}
                >
                  <TaskList
                    tagArr={tagArr}
                    tasks={allTasks}
                    fetchTasks={fetchTasks}
                    statusValues={statusValues}
                    handleDeleteTag={handleDeleteTag}
                    setIsTaskSpinner={setIsTaskSpinner}
                    openAssignTaskModal={openAssignTaskModal}
                    setOpenAssignTaskModal={setOpenAssignTaskModal}
                    previewTask={previewTask}
                    setPreviewTask={setPreviewTask}
                  />
                </TabPanelUnstyled>
                <TabPanelUnstyled
                  className="w-full mt-4 custom-tabs-content"
                  value={1}
                >
                  <TaskList
                    tagArr={tagArr}
                    tasks={openTasks}
                    fetchTasks={fetchTasks}
                    status={statusValues.open}
                    statusValues={statusValues}
                    handleDeleteTag={handleDeleteTag}
                    setIsTaskSpinner={setIsTaskSpinner}
                    openAssignTaskModal={openAssignTaskModal}
                    setOpenAssignTaskModal={setOpenAssignTaskModal}
                    previewTask={previewTask}
                    setPreviewTask={setPreviewTask}
                  />
                </TabPanelUnstyled>
                <TabPanelUnstyled
                  className="w-full mt-4 custom-tabs-content"
                  value={2}
                >
                  <TaskList
                    tagArr={tagArr}
                    tasks={closedTasks}
                    fetchTasks={fetchTasks}
                    statusValues={statusValues}
                    status={statusValues.closed}
                    handleDeleteTag={handleDeleteTag}
                    setIsTaskSpinner={setIsTaskSpinner}
                    openAssignTaskModal={openAssignTaskModal}
                    setOpenAssignTaskModal={setOpenAssignTaskModal}
                    previewTask={previewTask}
                    setPreviewTask={setPreviewTask}
                  />
                </TabPanelUnstyled>
                <TabPanelUnstyled
                  className="w-full mt-4 custom-tabs-content"
                  value={3}
                >
                  <TaskList
                    tagArr={tagArr}
                    tasks={archivedTasks}
                    fetchTasks={fetchTasks}
                    statusValues={statusValues}
                    status={statusValues.archived}
                    handleDeleteTag={handleDeleteTag}
                    setIsTaskSpinner={setIsTaskSpinner}
                    openAssignTaskModal={openAssignTaskModal}
                    setOpenAssignTaskModal={setOpenAssignTaskModal}
                    previewTask={previewTask}
                    setPreviewTask={setPreviewTask}
                  />
                </TabPanelUnstyled>
              </div>
              <div className="hidden w-full p-8 pt-0 border-l-2 border-gray-300 grow md:inline">
                <h4 className="text-xl font-bold">Tags List</h4>
                <div className="flex items-center mt-4">
                  <input
                    className="w-full p-2 bg-gray-200 rounded-lg"
                    placeholder="+ Create New Tags"
                    onChange={handleChangeTag}
                    value={tag}
                  />
                  <button
                    className="w-full max-w-[140px] ml-2 bg-transparent hover:bg-purple-primary text-gray-600 font-semibold py-2 px-4 border-2 border-gray-600 hover:text-gray-900  hover:border-transparent rounded"
                    onClick={handleAddTags}
                  >
                    Add Tag
                  </button>
                </div>

                {isTagSpinner && (
                  <div className="flex justify-center w-full mt-4 h-3/4">
                    <Spinner />
                  </div>
                )}
                <div className="flex flex-wrap gap-4 mt-4">
                  {tagArr?.map((tag, idx) => (
                    <Tag
                      key={idx}
                      title={tag?.name}
                      tagId={idx}
                      userId={tag?.user_id}
                      handleDeleteTag={() => handleDeleteTag(tag?.id)}
                      // onClick={() => handleUpdateTag(tag)}
                      onClick={() => {
                        setSelectedTag(tag);
                        setUpdateTagModalOpen(true);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </TabsUnstyled>
        </div>
      </div>

      {/* Create Task Modal */}
      <CreateTaskModal
        open={createTaskModalOpen}
        onClose={() => setCreateTaskModalOpen(false)}
        isError={isError}
        taskInputRef={taskInputRef}
        setTaskName={setTaskName}
        subTaskInputRef={subTaskInputRef}
        setSubTaskInput={setSubTaskInput}
        subTasks={subTasks}
        handleSubTasks={handleSubTasks}
        isDate={isDate}
        setIsDate={setIsDate}
        handleAddTask={handleAddTask}
        fetchTasks={fetchTasks}
      />

      {/* update tag modal */}
      <Dialog open={updateTagModalOpen}>
        <DialogContent className="p-0 transition-qliq w-full min-w-[310px] sm:w-[480px]">
          <button
            className="absolute top-3 right-4"
            onClick={() => setUpdateTagModalOpen(false)}
          >
            ✕
          </button>

          <div className="px-6 py-7">
            <h3 className="mb-6 text-2xl font-bold">Update Tag</h3>

            <input
              required
              className="w-full p-2 border border-gray-400 rounded"
              placeholder="Enter your tag name"
              value={selectedTag?.name}
              onChange={(e) => {
                if (selectedTag?.description === null) {
                  setSelectedTag({
                    ...selectedTag,
                    name: e.target.value,
                    description: "Description",
                  });
                } else {
                  setSelectedTag({
                    ...selectedTag,
                    name: e.target.value,
                  });
                }
              }}
            />

            <button
              // type="submit"
              type="button"
              onClick={handleUpdateTag}
              className="block w-full px-4 py-2 mt-4 font-semibold text-white border-0 rounded-lg btn bg-primary hover:bg-purple-primary"
            >
              Update Tag
            </button>
            {/* </form> */}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Index;
